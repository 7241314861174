import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { ChangeEvent, FC, useEffect, useMemo, useState } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { setShowLogo } from '../../../redux/HeaderSlice/headerSlice';
import { useAppDispatch } from '../../../redux/hooks';
import { RouteModule } from '../../../routes/routes';
import { IconName, TitleType } from '../../../shared/types';
import Navbar from '../../Navbar';
import Page from '../../Page';

export const TabPanel = ({ children, value, index, ...other }: any): JSX.Element => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index ? children : ''}
    </div>
  );
};

interface ListLayoutProps {
  title: string;
  icon: IconName;
  titleType: TitleType;
  routes: RouteModule;
  withNavbar?: boolean;
}

const ListLayout: FC<ListLayoutProps> = ({ title, icon, titleType, routes, withNavbar = true }) => {
  const dispatch = useAppDispatch();
  const [activeTab, setActiveTab] = useState(0);
  const availableListRoutes = useMemo(() => {
    return (
      routes.lists?.submodules.map(({ path }) => `${routes.lists?.to}${path.replace('/', '')}`) ||
      []
    );
  }, [routes.lists]);

  // eslint-disable-next-line @typescript-eslint/ban-types
  const handleActiveTab = (event: ChangeEvent<{}>, activeTab: number) => {
    setActiveTab(activeTab);
  };

  /* const showMapa = useMemo(
    () => routes.lists?.submodules.some(({ MapComponent }) => !!MapComponent),
    [],
  ); */

  const currentRoute = useLocation().pathname.split('/').pop();

  const showMapa = useMemo(
    () => {
      console.log('Valor de currentRoute:', currentRoute); // Imprime el valor de "currentRoute"
      return routes.lists?.submodules.some(({ path, MapComponent }) => {
        console.log('Valor de path:', path); // Imprime el valor de "path"
        const cleanedPath = path.startsWith('/') ? path.substring(1) : path;
        let cleanedCurrentRoute = '';
        if (currentRoute != undefined) {
          cleanedCurrentRoute = currentRoute.startsWith('/')
            ? currentRoute.substring(1)
            : currentRoute;
        }
        return cleanedPath === cleanedCurrentRoute && MapComponent !== undefined;
      });
    },
    [currentRoute], // Agrega "currentRoute" a la lista de dependencias si deseas que el efecto se ejecute cuando cambie "currentRoute"
  );

  useEffect(() => {
    dispatch(setShowLogo({ showLogo: true }));
  }, []);

  if (availableListRoutes.length > 0 && !availableListRoutes.includes(useLocation().pathname)) {
    return <Navigate to="/" />;
  }

  return (
    <Page title={title} icon={icon} titleType={titleType}>
      {withNavbar && <Navbar submodules={routes.lists?.submodules || []} />}
      <Tabs
        value={activeTab}
        onChange={handleActiveTab}
        aria-label="tabs"
        className="list-map-tabs"
      >
        <Tab label="Lista" />
        {showMapa && <Tab id="map-tab" label="Mapa" />}
      </Tabs>
      <TabPanel value={activeTab} index={0} className="tab-panel">
        <Routes>
          {routes.lists?.submodules.map(({ path, Component }) => (
            <Route key={path} path={path} element={<Component />} />
          ))}
        </Routes>
      </TabPanel>
      <TabPanel value={activeTab} index={1}>
        <Routes>
          {routes.lists?.submodules.map(
            ({ path, MapComponent }) =>
              MapComponent && <Route key={path} path={path} element={<MapComponent />} />,
          )}
        </Routes>
      </TabPanel>
    </Page>
  );
};

export default ListLayout;
