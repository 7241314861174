import { Inventario } from '../../../../API/limpieza/inventarios/interfaces';

/* ####### Inventarios Utils ####### */

export const getInventariosUtils = async (inventarios: Inventario[]) => {
  if (inventarios.length === 0) return [];

  return inventarios.map(inventario => ({
    id: inventario.id.toString(),
    barrio: inventario.barrio,
    distrito: inventario.distrito,
    codigoPostal: inventario.codigoPostal,
    contrato: inventario.contrato,
    contratoDescripcion: inventario.contratoDescripcion,
    ndp: inventario.ndp,
    lote: inventario.lote,
    loteDescripcion: inventario.loteDescripcion,
    jerarquiaClasificacion: inventario.jerarquiaClasificacion,
    nombreVia: inventario.nombreVia,
    nroVia: inventario.nroVia,
    tipoVia: inventario.tipoVia,
    claseFuncionGIS: inventario.claseFuncionGIS,
    descripcion: inventario.descripcion,
    coordenadas: inventario.coordenadas,
    coordenadaX: inventario.coordenadaX,
    coordenadaY: inventario.coordenadaY,
  }));
};
