import { useEffect } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import Icon from '../../components/Icon';
import { appConfig } from '../../config/app';
import { CommonModule } from '../../config/interfaces';
import { useProtectedModules } from '../../hooks/useProtectedModules';
import { setShowLogo } from '../../redux/HeaderSlice/headerSlice';
import { useAppDispatch } from '../../redux/hooks';
import { setActiveItem } from '../../redux/NavbarSlice/navbarSlice';

interface ModuleProps extends CommonModule {
  navigate: NavigateFunction;
}

const ModuleComponent = ({ icon, label, basePath, navigate }: ModuleProps) => (
  <div className="modules__module" onClick={() => navigate(basePath)}>
    <Icon name={icon} color="white" />
    <p className="modules__module-text">{label}</p>
  </div>
);

const Home = (): JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { availableModules } = useProtectedModules();
  const { logo } = appConfig;

  useEffect(() => {
    dispatch(setShowLogo({ showLogo: false }));
    dispatch(setActiveItem({ activeItem: '' }));
  }, []);

  return (
    <div className="home">
      <div className="home__grid">
        <div className="home__container">
          <div className="home__logo">
            <img src={logo.white} alt="Logo" />
          </div>
          <div className="modules">
            {availableModules.map((module, idx) => (
              <ModuleComponent key={idx} {...module} navigate={navigate} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
