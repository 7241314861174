import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

type GetByIdQuery<T> = (id: number) => Promise<T | null>;

const useEdit = <T>(getById: GetByIdQuery<T>, pushBack: string): T | null => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [data, setData] = useState<T | null>(null);

  useEffect(() => {
    if (!id) return;

    getById(Number(id)).then(res => {
      if (res) setData(res);
      else navigate(pushBack);
    });
  }, [id]);

  return data;
};

export default useEdit;
