import axios from 'axios';
import { createApiInstance } from '../../../api';
import { EspecificacionTecnica } from '../../../shared/actuaciones/interfaces';
import { Documento } from '../../../shared/interfaces';
import { MainInfo } from '../interfaces';
import { requestTransformer } from './request-transformer';

export const execute = async (request: {
  mainInfo: MainInfo;
  documentos: Documento[];
  especificacionesTecnicas: EspecificacionTecnica[];
}): Promise<any> => {
  const res = await createApiInstance().post('/riego/actuaciones', request, {
    transformRequest: [requestTransformer, ...(axios.defaults.transformRequest as any)],
  });

  return res.data;
};
