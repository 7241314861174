import cn from 'classnames';
import { FC, useMemo, useRef } from 'react';
import { InputType } from '../../shared/types';
import OutlineButton from '../OutlineButton';

export interface LazarusInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  /**
   * Tipo de input
   */
  type: InputType;

  /**
   * Indicar si hay error
   */
  error?: string;

  /**
   * Indicar label
   */
  label: string;

  /**
   * Id
   */
  id?: string;

  /**
   * Agregar clases de css propias
   */
  className?: string;
}

const Input: FC<LazarusInputProps> = ({ type, error, label, id, className, ...props }) => {
  const inputId = useMemo(() => id || Math.random().toString().substring(2), [id]);

  const input = useRef<HTMLInputElement>(null);
  const classNames = cn(
    'input-group',
    {
      error: error,
    },
    className,
  );

  const handleLabelClick = () => {
    if (input.current) {
      input.current.focus();
    }
  };

  const labelClassName = cn({
    active: props.value || input.current?.value,
  });

  return (
    <div className={classNames}>
      <input
        ref={input}
        {...props}
        type={type}
        id={inputId}
        value={type !== 'file' ? props.value : undefined}
      />
      <label onClick={handleLabelClick} className={labelClassName} htmlFor={inputId}>
        {label}
        {props.required && ' *'}
      </label>
      {type === 'file' && (
        <span>
          <OutlineButton
            color="primary"
            icon="upload"
            disabled={props.disabled}
            onClick={e => {
              e.preventDefault();
              input.current?.click();
            }}>
            {label || 'Cargar'}
          </OutlineButton>
          {props.value || 'No seleccionado'}
        </span>
      )}
      {error && (
        <span>
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M8 5V8.45M8 11H8.0075M14.75 8C14.75 11.7279 11.7279 14.75 8 14.75C4.27208 14.75 1.25 11.7279 1.25 8C1.25 4.27208 4.27208 1.25 8 1.25C11.7279 1.25 14.75 4.27208 14.75 8Z"
              stroke="#E7641A"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          {error}
        </span>
      )}
    </div>
  );
};

export default Input;
