import { RoleData } from '../../../pages/Permissions/context';
import { createApiInstance } from '../../api';
import { Role } from '../interfaces';

export const execute = async (roleData: RoleData): Promise<Role> => {
  const api = createApiInstance('application/json');
  const { data } = await api.post<Role>('/roles', roleData);

  return data;
};
