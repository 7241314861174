import cn from 'classnames';
import { FC } from 'react';
import { NavLink } from 'react-router-dom';
import { setOpenMenu } from '../../redux/HeaderSlice/headerSlice';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { User } from '../../redux/UserSlice/userSlice';
import Icon from '../Icon';
import HeaderMenuComponent from './HeaderMenu';
import { HeaderMenu } from './interfaces';
import UserMenu from './UserMenu';

interface Props {
  /**
   * Logo a mostrar en el header
   */
  logo?: string;

  /**
   * Mostrar logo en el header
   */
  showLogo?: boolean;

  /**
   * Usuario a mostrar en el header
   */
  user: User;

  /**
   * Items a mostrar en el header
   */
  headerMenu: HeaderMenu[];

  /**
   * Evento al ejecutar cuando hace click en el logo
   */
  onClickLogo?: () => void;

  /**
   * Evento al ejecutar cuando hace click en el Cerrar Sesión
   */
  onLogout?: () => void;

  /**
   * Agregar clases de css propias
   */
  className?: string;
}

const Header: FC<Props & React.HTMLAttributes<HTMLDivElement>> = ({
  user,
  logo,
  showLogo,
  onClickLogo,
  onLogout,
  headerMenu,
  className,
  ...props
}) => {
  const classNames = cn('header', {}, className);
  const openMenu = useAppSelector(state => state.header.openMenu);
  const dispatch = useAppDispatch();

  const handleOpenHeader = () => {
    dispatch(setOpenMenu({ openMenu: !openMenu }));
  };

  return (
    <div className={classNames} {...props}>
      <header>
        <div className="header__menu">
          <div className="header__menu-icon" onClick={handleOpenHeader}>
            <Icon color="white" name="menu" />
            <p className="text-white">Menu</p>
          </div>

          <HeaderMenuComponent
            headerMenu={headerMenu}
            open={openMenu}
            handleOpenHeader={handleOpenHeader}
          />
        </div>

        <div className={`header__logo ${!showLogo ? 'hidden' : ''}`}>
          <NavLink to="/" onClick={onClickLogo}>
            <img src={logo} alt="logo" />
          </NavLink>
        </div>

        <UserMenu user={user} onLogout={onLogout} />
      </header>
    </div>
  );
};

export default Header;
