import { AxiosError } from 'axios';
import { useState } from 'react';
import { CommunicationConfig } from '../config/interfaces';
import { showErrorAlert, showSuccessAlert } from '../libs/LazarusAlert';

interface Props {
  config?: CommunicationConfig;
  importApi: (formData: FormData) => Promise<{ message: string }>;
}

interface CommunicationImport {
  loadPercentage: number;
  finalMessage: string;
  onUploadFile: (file: File) => void;
}

export function useImportFile({ importApi }: Props): CommunicationImport {
  const [loadPercentage, setLoadPercentage] = useState<number>(0);
  const [finalMessage, setFinalMessage] = useState<string>('');

  const onUploadFile = (file: File) => {
    setLoadPercentage(0);
    const data = new FormData();
    data.append('file', file);

    importApi(data)
      .then(response => {
        setFinalMessage(response.message);
        setLoadPercentage(100);
        showSuccessAlert({
          title: 'Proceso ejecutado correctamente',
          text: response.message,
        });
      })
      .catch((err: AxiosError) => {
        console.log('err', err);
        let errorMessage = 'Error desconocido'; // Mensaje de error por defecto

        const responseData: any = err.response?.data; // Asignar err.response.data a una variable de tipo any

        if (responseData && typeof responseData === 'object' && 'error' in responseData) {
          errorMessage = responseData.error;
          console.log('Respuesta de error del servidor:', errorMessage);
        } else {
          console.log('Error sin respuesta del servidor:', err.message);
        }

        showErrorAlert({ title: 'Error al importar información', text: errorMessage });
      });
  };

  return { loadPercentage, onUploadFile, finalMessage };
}
