import { FC, useContext } from 'react';
import { PermissionSubModule, Section } from '../../../API/permissions/interfaces';
import Checkbox from '../../../components/Checkbox';
import Col from '../../../components/Col';
import Row from '../../../components/Row';
import { RoleContext } from '../context';
import PermissionLevel from './PermissionLevel';
import PermissionLevel3 from './PermissionLevel3';

interface Props {
  section: Section;
  permissions: PermissionSubModule;
}

const subSectionsToCheckForApp = ['zzvv', 'limpieza', 'riego', 'flotas'];

const PermissionsGroup: FC<Props> = ({ section, permissions }): JSX.Element => {
  const subsections = Object.entries(permissions);
  const { allChecked, modules, handleCheckAll, activeSections, activeSubSections } =
    useContext(RoleContext);

  return (
    <div className="permissions__group">
      <Row className="permisions__subsection" justifyContent="flex-end">
        <Col md={12}>
          <Checkbox
            checked={allChecked.includes(section)}
            defaultChecked={allChecked.includes(section)}
            onChecked={() => {
              handleCheckAll(section);
            }}
            label="Seleccionar todo"
            readOnly
          />
        </Col>
      </Row>
      <div className="permissions__headings-container">
        <h3 className="permisions__headings">Sección</h3>
        <h3 className="permisions__headings">Subsecciones</h3>
        <h3 className="permisions__headings">Funciones</h3>
      </div>
      <PermissionLevel section={section} label={section.toUpperCase()} level={1}>
        {activeSections.includes(section) && (
          <div className="permissions__level2-wrapper">
            {subsections.map(([subsection, permissions], idx) => {
              if (
                section === 'app' &&
                subSectionsToCheckForApp.includes(subsection.toLocaleLowerCase()) &&
                !modules[subsection.toLocaleLowerCase()].active
              )
                return null;

              return (
                <PermissionLevel
                  section={section}
                  subsection={subsection}
                  key={idx}
                  label={subsection}
                  level={2}
                >
                  {activeSubSections &&
                    activeSubSections[section] &&
                    activeSubSections[section].includes(subsection) && (
                      <PermissionLevel3
                        section={section}
                        subsection={subsection}
                        permissions={permissions}
                      />
                    )}
                </PermissionLevel>
              );
            })}
          </div>
        )}
      </PermissionLevel>
    </div>
  );
};

export default PermissionsGroup;
