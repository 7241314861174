import { appConfig } from '../../../config/app';
import { CommunicationConfig, CoreModule, ZZVVSubModule } from '../../../config/interfaces';

export const getZZVVCoreModule = (): CoreModule | undefined => {
  const { coreModules } = appConfig;
  return coreModules.find(coreModule => coreModule.module === 'zzvv');
};

export const getCommunicationConfig = (
  subModule: ZZVVSubModule,
): CommunicationConfig | undefined => {
  const zzvvModule = getZZVVCoreModule();
  const config = zzvvModule?.communicationConfig.find(cc => cc.subModule === subModule);

  return config;
};
