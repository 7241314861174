import { EspecificacionTecnica } from '../../../shared/actuaciones/interfaces';
import { Documento } from '../../../shared/interfaces';
import { arrayToNumberedObject } from '../../../shared/utils';
import { MainInfo } from '../interfaces';

export const requestTransformer = (request: {
  mainInfo: MainInfo;
  documentos: Documento[];
  especificacionesTecnicas: EspecificacionTecnica[];
}): FormData => {
  const form = new FormData();
  const requestItems: Record<string, any> = {
    ...request.mainInfo,
    ...arrayToNumberedObject(request.documentos, 'idDocumentoLista'),
    ...arrayToNumberedObject(request.especificacionesTecnicas, 'idEspecificacionTecnica'),
  };

  Object.entries(requestItems).forEach(([key, value]) => form.append(key, value));
  if (!request.mainInfo.id) form.append('id', '-1');
  if (!request.mainInfo.id_actuacion) form.append('id_actuacion', '');
  form.append('multi', '');
  form.append('multiItems', '');

  return form;
};
