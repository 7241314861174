import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getById, post } from '../../../../API/riego/materiales';
import { MainInfo } from '../../../../API/riego/materiales/interfaces';
import Button from '../../../../components/Button';
import Col from '../../../../components/Col';
import CollapsibleSection from '../../../../components/CollapsibleSection';
import DynamicForm from '../../../../components/DynamicForm';
import H1 from '../../../../components/H1';
import Row from '../../../../components/Row';
import useEdit from '../../../../hooks/useEdit';
import { showErrorAlert, showSuccessAlert } from '../../../../libs/LazarusAlert';
import useMetadata from './hooks';

const MaterialesCreatePage = (): JSX.Element => {
  const navigate = useNavigate();

  const dataToEdit = useEdit(getById, '/riego/listado/materiales');

  useEffect(() => {
    if (dataToEdit) setMainInfo(dataToEdit);
  }, [dataToEdit]);

  const [mainInfo, setMainInfo] = useState<MainInfo>({} as MainInfo);
  const [loading, setLoading] = useState(false);
  const { mainInfoMetadata } = useMetadata(mainInfo);

  const handleSubmit = () => {
    const request = {
      mainInfo,
    };

    setLoading(true);
    post(request)
      .then(() => {
        showSuccessAlert({ title: '¡Éxito!', text: 'Material guardado con éxito.' });
        navigate('/riego/listado/materiales');
      })
      .catch(() => {
        showErrorAlert({ title: 'Error', text: 'Error al guardar el material.' });
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      <H1>Datos</H1>
      <CollapsibleSection title="Información principal">
        <DynamicForm
          spacing="lg"
          onChange={setMainInfo}
          metadata={mainInfoMetadata}
          value={mainInfo}
        />
      </CollapsibleSection>
      <Row justifyContent="center">
        <Col xs={3} sm={2}>
          <Button color="primary" outline disabled={loading}>
            Cancelar
          </Button>
        </Col>
        <Col xs={3} sm={2}>
          <Button color="primary" disabled={loading} onClick={handleSubmit}>
            Guardar
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default MaterialesCreatePage;
