import { createApiInstance } from '../../../api';
import { Dispositivo, MainInfo } from '../interfaces';
import { responseTransformer } from './response-transformer';

export interface GetByIdResponse {
  mainInfo: MainInfo;
  dispositivos: Dispositivo[];
}

export const execute = async (id: number): Promise<GetByIdResponse | null> => {
  const api = createApiInstance();
  const { data } = await api.get<GetByIdResponse | null>(`/riego/contador/${id}`, {
    transformResponse: [responseTransformer],
  });

  return data;
};
