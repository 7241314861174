import { FilterItem } from '../../../../components/Table/Filters';
import { createApiInstance } from '../../../api';
import { Ruta } from '../interfaces';
import { requestTransformer } from './request-transformer';

export const execute = async (filterItems: FilterItem[]): Promise<Ruta[]> => {
  const api = createApiInstance();
  const { data } = await api.get<Ruta[]>('/flotas/rutas', {
    data: filterItems,
    params: requestTransformer(filterItems),
  });

  return data;
};
