import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { appConfig } from '../../config/app';
import { useAuth } from '../../hooks/useAuth';
import { useProtectedModules } from '../../hooks/useProtectedModules';
import { setOpenMenu, setOpenUserMenu } from '../../redux/HeaderSlice/headerSlice';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import Header from '../Header';

const Layout = (): JSX.Element => {
  const { isAuthenticated, expiredToken, remember, signOut, user } = useAuth();
  const location = useLocation();
  const showLogo = useAppSelector(state => state.header.showLogo);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { availableModules, availableRoutes } = useProtectedModules();
  const headerMenu = availableModules.map(({ icon, label, basePath }) => ({
    icon,
    label,
    path: basePath,
  }));
  const { logo } = appConfig;

  const handleClickLogo = () => {
    dispatch(setOpenMenu({ openMenu: false }));
    dispatch(setOpenUserMenu({ openUserMenu: false }));
  };

  const onLogout = () => {
    signOut().then(() => {
      navigate('/login');
    });
  };

  if (!isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if (!remember && expiredToken()) {
    onLogout();
    return <></>;
  }

  return (
    <>
      <Header
        user={user}
        logo={logo.white}
        showLogo={showLogo}
        headerMenu={headerMenu}
        onClickLogo={handleClickLogo}
        onLogout={onLogout}
      />
      <Routes>
        {availableRoutes.map(({ path, Component }) => (
          <Route key={path} path={path} element={<Component />} />
        ))}
      </Routes>
    </>
  );
};

export default Layout;
