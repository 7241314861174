/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { AxiosError } from 'axios';
import { FC, useRef, useState } from 'react';
import { Users } from '../../../API';
import { Role } from '../../../API/roles/interfaces';
import Button from '../../../components/Button';
import Icon from '../../../components/Icon';
import Input from '../../../components/Input';
import Modal from '../../../components/Modal';
import ModalBody from '../../../components/Modal/ModalBody';
import ModalFooter from '../../../components/Modal/ModalFooter';
import ModalTitle from '../../../components/Modal/ModalTitle';
import Select from '../../../components/Select';
import { showSuccessAlert } from '../../../libs/LazarusAlert';
import { createSchema } from '../schemas/create.schema';
import { editSchema } from '../schemas/edit.schema';

interface Props {
  title: string;
  openModal: boolean;
  handleOpenModal: (ev?: React.MouseEvent | React.FormEvent) => void;
  userData?: UserData;
  roles: Role[];
  successCallback?: () => void;
}

interface UserData {
  id?: number | string;
  name?: string;
  email?: string;
  password?: string;
  roleId?: string;
  photo?: File;
}

const emptyUserData = {
  name: '',
  email: '',
  password: '',
  roleId: '',
};

const UserModal: FC<Props> = ({
  title,
  userData,
  roles,
  openModal,
  handleOpenModal,
  successCallback,
}) => {
  const [user, setUser] = useState<UserData>(
    userData ?? ({ id: '-1', ...emptyUserData } as UserData),
  );
  const [errors, setErrors] = useState<Record<string, string>>({ ...emptyUserData });
  const labelFile = useRef<HTMLLabelElement>(null);
  const handleChange = (key: string, value: string | File) => {
    setUser({ ...user, [key]: value });
  };

  const handleSave = (ev: { preventDefault: () => void }) => {
    setErrors({});
    ev.preventDefault();

    try {
      if (user.id === '-1') {
        createSchema.validateSync(user, { abortEarly: false });
      } else {
        editSchema.validateSync(user, { abortEarly: false });
        /*
          Esto es porque el back necesita esto para no cambiarle la password 
          si el usuario deja en blanco el campo cuando edita
        */
        if (!user.password) setUser({ ...user, password: '****' });
      }
    } catch (exception: any) {
      const newErrors: Record<string, string> = { ...emptyUserData };
      for (const err of exception.inner) {
        newErrors[err.path] = err.message;
      }

      setErrors(newErrors);
      return;
    }

    const data = new FormData();
    data.append('id', String(user.id)!);
    data.append('name', user.name!);
    data.append('email', user.email!);
    data.append('roleId', user.roleId!);
    if (user.password) data.append('password', user.password);
    if (user.photo) data.append('foto', user.photo);

    Users.save(data)
      .then(() => {
        showSuccessAlert({ title: 'Usuario guardado' });
        successCallback && successCallback();
        handleOpenModal();
      })
      .catch((err: AxiosError) => {
        console.log(err);
      });
  };

  return (
    <Modal open={openModal} handleOpen={handleOpenModal}>
      <ModalTitle>{title}</ModalTitle>
      <form className="form-user" onSubmit={handleSave} autoComplete="off">
        <ModalBody>
          <Input
            onChange={ev => handleChange('name', ev.target.value)}
            value={user.name}
            error={errors.name}
            type="text"
            label="Nombre"
          />
          <Input
            onChange={ev => handleChange('email', ev.target.value)}
            value={user.email}
            error={errors.email}
            type="email"
            label="Correo"
          />
          <Select
            label="Rol"
            value={user.roleId}
            error={errors.roleId}
            onChange={ev => handleChange('roleId', ev.target.value)}
            options={roles.map(role => ({
              text: role.name,
              value: role.id,
            }))}
          />
          <Input
            onChange={ev => handleChange('password', ev.target.value)}
            value={user.password}
            error={errors.password}
            type="password"
            label="Contraseña"
          />

          <div className="form-user__file">
            <Icon
              onClick={() => labelFile.current && labelFile.current.click()}
              color="primary"
              name="photo"
            />
            <label ref={labelFile} htmlFor="userFile" className="form-user__file-text">
              <span>Cargar imagen</span>
              {user.photo && (
                <span>
                  <b>Foto cargada: </b>
                  {user.photo.name}
                </span>
              )}
            </label>
            <Input
              onChange={ev => ev.target.files && handleChange('photo', ev.target.files[0])}
              id="userFile"
              accept="image/*"
              type="file"
              label=""
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button onClick={handleOpenModal} color="primary" outline>
            Cancelar
          </Button>
          <Button color="primary">Guardar</Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default UserModal;
