import { ReactNode, useMemo } from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';
import { useNavigate } from 'react-router-dom';
import { Inventarios } from '../../../../API/riego';
import { Inventario } from '../../../../API/riego/inventarios/interfaces';
import Badge from '../../../../components/Badge';
import Checkbox from '../../../../components/Checkbox';
import MaterialIconMenu, { MaterialIconMenuProps } from '../../../../components/MaterialIconMenu';
import NoResults from '../../../../components/NoResults';
import LazarusPagination from '../../../../components/Pagination';
import Filters, {
  DynamicFormMetadata,
  ModuleNavigations,
  FilterItem,
} from '../../../../components/Table/Filters';
import { useDatatable } from '../../../../hooks/useDatatable';
import { Operator } from '../../../../hooks/useProtectedModules';

const addSelectedToRow = (row: Inventario): void => {
  document.getElementById(`row-${row.id}`)?.classList.add('active');
};

const removeSelectedToRow = (row: Inventario) => {
  document.getElementById(`row-${row.id}`)?.classList.remove('active');
};

const moduleNavigations: ModuleNavigations = {
  communications: `/riego/comunicaciones/inventario`,
  creates: `/riego/crear/inventario`,
};

const InventariosListPage = (): JSX.Element => {
  const {
    filteredItems,
    handleSetRows,
    handleChangeSelected,
    filterText,
    handleClear,
    handleChangeFilterText,
    selectedRows,
  } = useDatatable<Inventario>({
    removeSelectedToRow,
    addSelectedToRow,
  });

  const navigate = useNavigate();

  const columns: TableColumn<Inventario>[] = useMemo(
    () => [
      {
        name: 'ID',
        selector: row => row.id,
      },
      {
        name: 'Barrio',
        selector: row => row.barrio,
      },
      {
        name: 'Distrito',
        selector: row => row.distrito,
      },
      {
        name: 'Jerarquía de clasificación',
        selector: row => row.jerarquiaClasificacion,
      },
      {
        name: 'Nombre vía',
        selector: row => row.nombreVia,
      },
      {
        name: 'Nro vía',
        selector: row => row.nroVia,
      },
      {
        name: 'Estado',
        cell: row => <Badge color={row.estado.color} text={row.estado.label} />,
      },
      {
        cell: row => {
          const paramsToMenu: MaterialIconMenuProps<Inventario> = {
            row,
            size: 'medium',
            rows: selectedRows.length ? selectedRows : [row],
            onOpenMenu: () => addSelectedToRow(row),
            onCloseMenu: () => removeSelectedToRow(row),
            actions: [
              { label: 'Ver en mapa', onClick: row => console.log('Ver en mapa', row) },
              {
                label: 'Editar',
                onClick: row => navigate(`/riego/editar/inventario/${row.id}`),
                onlyApplyToOne: true,
              },
              { label: 'Enviar a MiNT', onClick: row => console.log('Enviar a MiNT', row) },
            ],
          };
          return <MaterialIconMenu {...paramsToMenu} />;
        },
        allowOverflow: true,
        button: true,
        width: '71px',
      },
    ],
    [selectedRows],
  );

  const SelectableCheckbox = (Checkbox as unknown) as ReactNode;

  const handleFilter = (filterItems: FilterItem[]) => {
    Inventarios.get(filterItems).then(res => handleSetRows(res));
  };

  return (
    <>
      <Filters
        handleClear={handleClear}
        filterText={filterText}
        handleChangeFilterText={handleChangeFilterText}
        handleFilter={handleFilter}
        metadata={filterMetadata}
        hasItems={!!filteredItems.length}
        moduleNavigations={moduleNavigations}
        requirePermissions={requirePermissions}
      />
      <DataTable
        responsive
        columns={columns}
        data={filteredItems}
        pagination
        selectableRows
        selectableRowsHighlight
        paginationPerPage={10}
        noDataComponent={<NoResults />}
        paginationComponent={LazarusPagination}
        onSelectedRowsChange={handleChangeSelected}
        selectableRowsComponent={SelectableCheckbox}
      />
    </>
  );
};

export default InventariosListPage;

const requirePermissions = {
  lists: { section: 'riego', permission: 'list-inventario' },
  communications: {
    section: 'riego',
    permission: {
      permissions: [
        'import-inventario',
        'export-inventario',
        'import-inventario-ndp',
        'import-inventario-elementos-relacionados',
        'import-inventario-piezas-recambio',
      ],
      operator: '||' as Operator,
    },
  },
  creates: { section: 'riego', permission: 'create-inventario' },
};

const filterMetadata: DynamicFormMetadata[] = [
  {
    grid: { xs: 6, sm: 4, md: 2 },
    input: {
      tag: 'Input',
      propKey: 'idInventario',
      props: {
        label: 'Activo',
        type: 'text',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 2 },
    input: {
      tag: 'Input',
      propKey: 'idInventarioExterno',
      props: {
        label: 'Código Externo',
        type: 'text',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 2 },
    input: {
      tag: 'Input',
      propKey: 'barrio',
      props: {
        label: 'Barrio',
        type: 'text',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 2 },
    input: {
      tag: 'Input',
      propKey: 'claseFuncionGIS',
      props: {
        label: 'Clase Función',
        type: 'text',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 2 },
    input: {
      tag: 'Input',
      propKey: 'descripcion',
      props: {
        label: 'Descripción',
        type: 'text',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 2 },
    input: {
      tag: 'Input',
      propKey: 'distrito',
      props: {
        label: 'Distrito',
        type: 'number',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 2 },
    input: {
      tag: 'Input',
      propKey: 'estado',
      props: {
        label: 'Estado',
        type: 'text',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 2 },
    input: {
      tag: 'Input',
      propKey: 'fechaInstalacion',
      props: {
        label: 'Fecha de instalación',
        type: 'text',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 2 },
    input: {
      tag: 'Input',
      propKey: 'fechaDesinstalacion',
      props: {
        label: 'Fecha de desinstalación',
        type: 'text',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 2 },
    input: {
      tag: 'Input',
      propKey: 'jerarquiaClasificacion',
      props: {
        label: 'Clasificación',
        type: 'text',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 2 },
    input: {
      tag: 'Input',
      propKey: 'lote',
      props: {
        label: 'Lote',
        type: 'text',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 2 },
    input: {
      tag: 'Input',
      propKey: 'ndp',
      props: {
        label: 'NDP',
        type: 'number',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 2 },
    input: {
      tag: 'Input',
      propKey: 'numeroVia',
      props: {
        label: 'Número Vía',
        type: 'text',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 2 },
    input: {
      tag: 'Input',
      propKey: 'nombreVia',
      props: {
        label: 'Nombre Vía',
        type: 'text',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 2 },
    input: {
      tag: 'Input',
      propKey: 'tipoVia',
      props: {
        label: 'Tipo Vía',
        type: 'text',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 2 },
    input: {
      tag: 'Input',
      propKey: 'padre',
      props: {
        label: 'Nivel superior',
        type: 'text',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 2 },
    input: {
      tag: 'Input',
      propKey: 'observaciones',
      props: {
        label: 'Resumen',
        type: 'text',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 2 },
    input: {
      tag: 'Input',
      propKey: 'servicio',
      props: {
        label: 'Servicio',
        type: 'text',
      },
    },
  },
];
