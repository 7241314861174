export const campos = [
  { value: 'sector', text: 'Id. sector' },
  { value: 'descripcion', text: 'Descripción' },
  { value: 'descripcionLarga', text: 'Descripción larga' },
  { value: 'estado', text: 'Estado' },
  { value: 'servicio', text: 'Servicio' },
  { value: 'servicioDescripcion', text: 'Descripción del servicio' },
  { value: 'contrato', text: 'Contrato' },
  { value: 'contratoDescripcion', text: 'Descripción del contrato' },
  { value: 'aDemanda', text: ' Testigo de frecuencia a demanda' },
  { value: 'usuarioEditor', text: 'Usuario editor' },
  { value: 'fechaCambio', text: 'Fecha de cambio' },
  { value: 'tipoActuacion', text: 'Tipo de actuación ' },
  { value: 'jerarquiaClasificacion', text: 'Jerarquía de clasificación' },
  { value: 'concesionaria', text: 'Id. de la concesionaria' },
  { value: 'concesionariaDescripcion', text: 'Descripción de la concesionaria' },
  { value: 'fechaInicio', text: 'Fecha de inicio' },
  { value: 'fechaFin', text: 'Fecha fin' },
  { value: 'lote', text: 'Número del lote' },
  { value: 'loteDescripcion', text: 'Descripción del lote' },
  { value: 'distrito', text: 'Distrito' },
  { value: 'distritoDescripcion', text: 'Descripción del distrito' },
  { value: 'barrio', text: 'Barrio' },
  { value: 'barrioDescripcion', text: 'Descripción del barrio' },
  { value: 'tipoEje', text: 'Tipo de eje' },
  { value: 'categoriaSector', text: 'Categoría del sector' },
  { value: 'prestacion', text: 'Prestación del Lote' },
  { value: 'prestacionDescripcion', text: 'Descripción de la prestación' },
  { value: 'unidadMedidaSector', text: 'Unidad de medida' },
  { value: 'medidaSector', text: 'Medida del sector' },
  { value: 'coordenadaSector', text: 'Coordenadas del sector' },
  { value: 'claseFuncionGIS', text: 'Nombre de la capa de GIS.' },
  { value: 'descripcionCapa', text: 'Descripción de la capa de GIS.' },
  { value: 'posicionamientoMapa', text: 'Posicionamiento en mapa' },
  { value: 'motivoRevision', text: 'Motivo de la revisión' },
  { value: 'inicioSector', text: 'Inicio del sector' },
  { value: 'finSector', text: 'Fin del sector' },
  { value: 'revisionSector', text: 'Revisión del sector' },
  { value: 'nivelSuperior', text: 'Nivel superior' },
];
