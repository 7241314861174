import { lazy } from 'react';
import { RouteModule } from '../../routes/routes';
import { communicationsRoutes } from './routes.communications';
import { createsRoutes } from './routes.creates';
import { editsRoutes } from './routes.edits';
import { listsRoutes } from './routes.lists';
import { getRiegoCoreModule } from './shared/utils';

export const basePath = '/riego';

const LazyListLayout = lazy(
  () => import(/* webpackChunkName: "RiegoLayout" */ './Layouts/ListLayout'),
);
const LazyCommunicationsLayout = lazy(
  () => import(/* webpackChunkName: "RiegoLayout" */ './Layouts/CommunicationsLayout'),
);
const LazyCreateLayout = lazy(
  () => import(/* webpackChunkName: "RiegoLayout" */ './Layouts/CreateLayout'),
);

let riegoRoutes: RouteModule = {};

const riegoCoreModule = getRiegoCoreModule();

if (riegoCoreModule && riegoCoreModule.submodulesGroups.includes('lists')) {
  riegoRoutes = {
    ...riegoRoutes,
    lists: {
      path: `/riego/listado/*`,
      to: `/riego/listado/`,
      Component: LazyListLayout,
      name: 'Riego - Listado Layout',
      submodules: listsRoutes,
    },
  };
}

if (riegoCoreModule && riegoCoreModule.submodulesGroups.includes('communications')) {
  riegoRoutes = {
    ...riegoRoutes,
    communications: {
      path: `/riego/comunicaciones/*`,
      to: `/riego/comunicaciones/`,
      Component: LazyCommunicationsLayout,
      name: 'Riego - Comunicaciones Layout',
      submodules: communicationsRoutes,
    },
  };
}

if (riegoCoreModule && riegoCoreModule.submodulesGroups.includes('creates')) {
  riegoRoutes = {
    ...riegoRoutes,
    creates: {
      path: `/riego/crear/*`,
      to: `/riego/crear/`,
      Component: LazyCreateLayout,
      name: 'Riego - Crear Layout',
      submodules: createsRoutes,
    },
  };
}

if (riegoCoreModule && riegoCoreModule.submodulesGroups.includes('edits')) {
  riegoRoutes = {
    ...riegoRoutes,
    edits: {
      path: `/riego/editar/*`,
      to: `/riego/editar/`,
      Component: LazyCreateLayout,
      name: 'Riego - Editar Layout',
      submodules: editsRoutes,
    },
  };
}

export { riegoRoutes };
