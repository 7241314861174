import { GetByIdResponse } from '.';
import { MainInfo, Dispositivo, ElementoContador } from '../interfaces';

export const responseTransformer = (response: string): GetByIdResponse | null => {
  const contador: ElementoContador = JSON.parse(response);

  if (!contador.id) return null;

  return {
    mainInfo: {
      id: contador.id,
      idInventario: contador.idInventario,
      instalacion: contador.instalacion,
      instalacionCultivo: contador.instalacionCultivo,
      zonaControl: contador.zonaControl,
      planta: contador.planta,
    } as MainInfo,
    dispositivos: contador.dispositivo_medida_elemento_contador.map(d => ({
      id: d.id,
      aceptarHerencia: d.aceptarHerencia,
      dispositivoMedida: d.dispositivoMedida,
      estaActivo: d.estaActivo,
      unidadMedidaActivo: d.unidadMedidaActivo,
      fechaNuevaLectura: d.fechaNuevaLectura?.substring(0, 10),
      fechaUltimaLectura: d.fechaUltimaLectura?.substring(0, 10),
      nuevaLectura: d.nuevaLectura,
      observacionesMedida: d.observacionesMedida,
      secuencia: d.secuencia,
      ultimaLectura: d.ultimaLectura,
    })) as Dispositivo[],
  };
};
