import cn from 'classnames';
import { FC } from 'react';
import { NavLink } from 'react-router-dom';
import { IconName } from '../../shared/types';
import Icon from '../Icon';

export interface BreadcrumbItem {
  icon?: IconName;
  label: string;
  path?: string;
}

interface Props {
  /**
   * Items del breadcrumb
   */
  items: BreadcrumbItem[];
  /**
   * Agregar clases de css propias
   */
  className?: string;
}

const Breadcrumb: FC<Props & React.InputHTMLAttributes<HTMLDivElement>> = ({
  items,
  className,
  ...props
}) => {
  const classNames = cn('breadcrumb', className);

  return (
    <div className={classNames} {...props}>
      {items.map((item, i) => {
        return item.path ? (
          <NavLink key={i} className="breadcrumb__item" to={item.path}>
            {item.icon && <Icon name={item.icon} color="black" />}
            <span>{item.label}</span>
          </NavLink>
        ) : (
          <p key={i} className="breadcrumb__item">
            {item.icon && <Icon name={item.icon} color="black" />}
            <span>{item.label}</span>
          </p>
        );
      })}
    </div>
  );
};

export default Breadcrumb;
