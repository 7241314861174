import { Route } from '../../routes/routes';
import RolesPage from './';
import PermissionsPage from '../Permissions';

export const rolesRoutes: Route[] = [
  {
    to: '/roles',
    path: 'roles',
    Component: RolesPage,
    name: 'Roles',
  },
  {
    to: '/roles/crear',
    path: 'roles/crear',
    Component: PermissionsPage,
    name: 'Crear Roles',
  },
  {
    to: '/roles/editar/:roleId',
    path: 'roles/editar/:roleId',
    Component: PermissionsPage,
    name: 'Editar Roles',
  },
];
