import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface NavbarState {
  activeItem: string;
}

const initialState: NavbarState = {
  activeItem: '',
};

export const navbarSlice = createSlice({
  name: 'navbar',
  initialState,
  reducers: {
    setActiveItem: (state, action: PayloadAction<{ activeItem: string }>) => {
      state.activeItem = action.payload.activeItem;
    },
  },
});

export const { setActiveItem } = navbarSlice.actions;

export default navbarSlice.reducer;
