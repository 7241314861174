import { appConfig } from '../config/app';
import { CommonModule } from '../config/interfaces';

export const getKeyValue =
  (key: string) =>
  (obj: Record<string, any>): any =>
    obj[key];

export const getCommonModule = (moduleToSearch: string): CommonModule | undefined => {
  const { commonModules } = appConfig;
  return commonModules.find(commonModule => commonModule.label === moduleToSearch);
};

interface Column {
  columnSlug: string;
  columnHeader: string;
}

const convertArrayOfObjectsToCSV = (data: any[], columns: Column[]): string => {
  let result = '';

  const columnDelimiter = ';';
  const lineDelimiter = '\n';
  const headers = columns.map(column => column.columnHeader);

  result += headers.join(columnDelimiter);
  result += lineDelimiter;

  data.forEach(item => {
    let ctr = 0;
    columns.forEach(column => {
      if (ctr > 0) result += columnDelimiter;

      result += item[column.columnSlug];

      ctr++;
    });
    result += lineDelimiter;
  });

  return result;
};

export const downloadCSV = (data: any[], columns: Column[], filename: string) => {
  const link = document.createElement('a');
  let csv = convertArrayOfObjectsToCSV(data, columns);
  if (csv == null) return;

  if (!csv.match(/^data:text\/csv/i)) {
    csv = `data:text/csv;charset=utf-8,${csv}`;
  }

  link.setAttribute('href', encodeURI(csv));
  link.setAttribute('download', filename);
  link.click();
};
