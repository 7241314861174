import { EspecificacionTecnica } from '../../../shared/actuaciones/interfaces';
import { Documento } from '../../../shared/interfaces';
import { MainInfo } from '../interfaces';
import { createApiInstance } from './../../../api';
import { responseTransformer } from './response-transformer';

export interface GetByIdResponse {
  mainInfo: MainInfo;
  documentos: Documento[];
  especificacionesTecnicas: EspecificacionTecnica[];
}

export const execute = async (id: number): Promise<GetByIdResponse | null> => {
  const api = createApiInstance();
  const { data } = await api.get<GetByIdResponse | null>(`/riego/actuaciones/${id}`, {
    transformResponse: [responseTransformer],
  });

  return data;
};
