import axios from 'axios';
import { createApiInstance } from '../../../api';
import { Dispositivo, MainInfo } from '../interfaces';
import { requestTransformer } from './request-transformer';

export interface PostRequest {
  mainInfo: MainInfo;
  dispositivos: Dispositivo[];
}

export const execute = async (request: PostRequest): Promise<any> => {
  const res = await createApiInstance().post('/riego/contador', request, {
    transformRequest: [requestTransformer, ...(axios.defaults.transformRequest as any)],
  });

  return res.data;
};
