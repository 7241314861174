import { ZonasVerdes } from '../../../../API';
import CommunicationExport from '../../../../components/CommunicationExport';
import CommunicationImport from '../../../../components/CommunicationImport';
import Page from '../../../../components/Page';
import { getCommunicationConfig } from '../../shared/utils';
import { campos } from './campos';

const IncidenciasCommunicationsPage = (): JSX.Element => {
  return (
    <Page className="communications" titleType="title">
      <CommunicationImport
        title="Cargas"
        config={getCommunicationConfig('incidencias')}
        importApi={ZonasVerdes.import}
        requirePermission={{ section: 'zzvv', permission: 'import-incidencias' }}
      />

      <hr />

      <CommunicationImport
        title="Cargas de Fotos"
        config={getCommunicationConfig('incidencias-fotos')}
        importApi={ZonasVerdes.import}
        requirePermission={{ section: 'zzvv', permission: 'import-fotos-incidencias' }}
      />

      <hr />

      <CommunicationExport
        config={getCommunicationConfig('incidencias')}
        exportApi={ZonasVerdes.export}
        title="Consultas"
        campos={campos}
        subModule="incidencias"
        requirePermission={{ section: 'zzvv', permission: 'export-incidencias' }}
      />
    </Page>
  );
};

export default IncidenciasCommunicationsPage;
