import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { useState } from 'react';
import { appConfig } from '../../config/app';

interface Action<T> {
  label: string;
  onClick: (row: T, rows: T[]) => void;
  onlyApplyToOne?: boolean;
}

export interface MaterialIconMenuProps<T> {
  row: T;
  rows: T[];
  size?: 'small' | 'medium';
  onOpenMenu?: () => void;
  onCloseMenu?: () => void;
  actions: Action<T>[];
}

const MaterialIconMenu = <T,>({
  row,
  rows,
  onOpenMenu,
  onCloseMenu,
  size,
  actions,
}: MaterialIconMenuProps<T>): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
    if (onOpenMenu) onOpenMenu();
  };

  const handleClose = () => {
    setAnchorEl(null);
    if (onCloseMenu) onCloseMenu();
  };

  return (
    <div className={`theme--${appConfig.theme}`}>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
        size={size}
      >
        <MoreVertIcon />
      </IconButton>

      <Menu
        id="menu"
        getContentAnchorEl={null}
        className="material-icon-menu"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {actions.map((action, idx) => (
          <MenuItem onClick={() => action.onClick(row, rows)} key={idx}>
            {action.label} {rows.length > 1 && !action.onlyApplyToOne ? `(${rows.length})` : ''}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default MaterialIconMenu;
