import { Route } from '../../routes/routes';
import ActuacionesCreatePage from './Actuaciones/Create';
import InventarioCreatePage from './Inventario/Create';
import ContadoresCreatePage from './Contadores/Create';
import PuntosDeSuministroCreatePage from './PuntosDeSuministro/Create';
import MaterialesCreatePage from './Materiales/Create';

export const editsRoutes: Route[] = [
  {
    name: 'Inventario',
    path: 'inventario/:id',
    to: `inventario`,
    Component: InventarioCreatePage,
    requirePermission: 'create-inventario',
  },
  {
    name: 'Actuaciones',
    path: 'actuaciones/:id',
    to: `actuaciones`,
    Component: ActuacionesCreatePage,
    requirePermission: 'create-actuaciones',
  },
  {
    name: 'Materiales',
    path: 'materiales/:id',
    to: `materiales/`,
    Component: MaterialesCreatePage,
    requirePermission: 'create-materiales',
  },
  {
    name: 'Contadores',
    path: 'contadores/:id',
    to: `contadores`,
    Component: ContadoresCreatePage,
    requirePermission: 'create-contadores',
  },
  {
    name: 'Puntos de suministros',
    path: 'puntos-de-suministro/:id',
    to: `puntos-de-suministro`,
    Component: PuntosDeSuministroCreatePage,
    requirePermission: 'puntos-de-suministro',
  },
];
