import { GetByIdResponse } from '.';
import { ElementoPuntoDeSuministro } from '../interfaces';

export const responseTransformer = (response: string): GetByIdResponse | null => {
  const puntoSuministro: ElementoPuntoDeSuministro = JSON.parse(response);

  if (!puntoSuministro?.id) return null;

  return {
    mainInfo: {
      id: puntoSuministro.id,
      barrio: 1,
      barrioDescripcion: '',
      claseFuncionGIS: '',
      codigoPostal: 1,
      coordenadas: '',
      descripcion: '',
      distrito: 1,
      distritoDescripcion: '',
      estado: '',
      fechaEstado: '',
      fechaDesinstalacion: '',
      fechaInstalacion: '',
      idInventarioExterno: '1',
      idInventarioInterno: '1',
      jerarquiaClasificacion: '',
      jerarquiaClasificacionDescripcion: '',
      ndp: 1,
      numeroVia: 1,
      nombreVia: '',
      observaciones: '',
      observacionesLargas: '',
      padre: '',
      tipoVia: '',
    },
    riegoAutomatico: {
      riegoAutomatico: 'Sí',
    },
    tarifa: {
      tarifa: 1,
      observacionTarifa: '',
    },
    bocasRiego: {
      bocasRiegoAcera: 'Sí',
      bocasRiegoParque: 'Sí',
    },
    camposPrincipales: {
      idInventario: 1,
      idPuntoSuministro: 1,
    },
    ultimaLectura: {
      anoUltimaLectura: '',
      tarifaUltimaLectura: '',
    },
    codigos: {
      codigoZonaVerde: '',
      grupoParque: 1,
      parque: 1,
    },
    edificio: {
      edificioMunicipal: 'Sí',
      edificioNoMunicipal: 'No',
    },
    sitios: {
      casetaJardinero: 'Sí',
      jardinPrivado: 'Sí',
      otros: 'Sí',
      polideportivo: 'Sí',
      usoPrivado: 'Sí',
    },
    comentarios: {
      comentarios: '',
      comentariosLargos: '',
    },
    fuente: {
      fuenteBeber: '',
      fuenteOrnamental: 'Sí',
      fuentePublica: 'No',
      fuenteParque: 'No',
    },
    contrato: {
      auditadoContrato: 'Sí',
      breveContrato: '',
      conclusionContrato: '',
      contratoCYII: '',
      estadoContrato: '',
      pendienteInspeccion: '',
      pendienteBaja: '',
      serviciosSuministrados: '',
    },
    otros: {
      calibre: '',
      contador: '',
      marca: '',
      puntoAcometida: '',
      tipoAgua: '',
    },
    serviciosSuministrados: [],
  };
};
