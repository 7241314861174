import { DynamicFormMetadata } from '../../../components/DynamicForm';
import { EspecificacionTecnica } from './interfaces';

export const especificacionesTecnicasMetadata: DynamicFormMetadata<EspecificacionTecnica>[] = [
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'et_id',
      props: {
        label: 'ID',
        type: 'text',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'et_descripcion',
      props: {
        label: 'Descripción',
        type: 'text',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'et_seccion',
      props: {
        label: 'Sección',
        type: 'text',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'et_unidadMedida',
      props: {
        label: 'Unidad medida',
        type: 'text',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Select',
      propKey: 'opcion',
      props: {
        label: 'Opción',
        options: [
          {
            text: 'Alfanumérico',
            value: 'ET_ValorAlfanumerico',
          },
          {
            text: 'Numérico',
            value: 'ET_ValorNumerico',
          },
          {
            text: 'Tabla',
            value: 'ET_Tabla',
          },
        ],
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'et_valor',
      props: {
        type: 'text',
        label: 'Valor',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Select',
      propKey: 'et_cambiado',
      props: {
        label: '¿Se ha cambiado?',
        options: [
          {
            text: 'Sí',
            value: 'Sí',
          },
          {
            text: 'No',
            value: 'No',
          },
        ],
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'et_idLinea',
      props: {
        label: 'Id linea',
        type: 'text',
      },
    },
  },
];
