import { CargaPaginate, GetCargasResponse } from '../interfaces';

export const responseTransformer = (response: string): CargaPaginate => {
  const responseJSON: GetCargasResponse = JSON.parse(response);

  return {
    data: responseJSON.data.map(row => ({
      id: row.id,
      descripcion: row.descripcion,
      estado: row.estado,
      modulo: row.modulo,
      seccion: row.operacion,
      fecha: row.fecha,
      total: row.total,
      finalizados: row.finalizados,
      errores: row.errores,
      archivoDescarga: row.archivoDescarga,
      archivoCarga: row.archivoCarga,
    })),
    meta: {
      current_page: responseJSON.current_page,
      from: responseJSON.from,
      last_page: responseJSON.last_page,
      next_page_url: responseJSON.next_page_url,
      per_page: responseJSON.per_page,
      prev_page_url: responseJSON.prev_page_url,
      to: responseJSON.to,
      total: responseJSON.total,
    },
  };
};
