import cn from "classnames";
import { FC } from "react";
import { Color } from "../../shared/types";

interface Props {
  /**
   * Color del ícono
   */
  color: Color;

  /**
   * Nombre del ícono
   */
  text: string;

  /**
   * Agregar clases de css propias
   */
  className?: string;
}

const Badge: FC<Props & React.HTMLAttributes<HTMLParagraphElement>> = ({
  color,
  className,
  text,
  ...props
}) => {
  const classNames = cn(
    "badge",
    {
      [`badge-${color}`]: color,
    },
    className
  );

  return (
    <p className={classNames} {...props}>
      {text}
    </p>
  );
};

export default Badge;
