import { ZonasVerdes } from '../../../../API';
import CommunicationExport from '../../../../components/CommunicationExport';
import Page from '../../../../components/Page';
import { getCommunicationConfig } from '../../shared/utils';
import { campos } from './campos';

const EspeciesCommunicationsPage = (): JSX.Element => {
  return (
    <Page className="communications" titleType="title">
      <CommunicationExport
        config={getCommunicationConfig('especies')}
        exportApi={ZonasVerdes.export}
        title="Consultas"
        campos={campos}
        requirePermission={{ section: 'zzvv', permission: 'export-especies' }}
      />
    </Page>
  );
};

export default EspeciesCommunicationsPage;
