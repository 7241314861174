import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import Col from '../../../components/Col';
import InputSearch from '../../../components/InputSearch';
import OutlineButton from '../../../components/OutlineButton';
import Row from '../../../components/Row';
import { appConfig } from '../../../config/app';
import { useAuth } from '../../../hooks/useAuth';

export type ActiveModule = 'users' | 'roles' | 'zzvv' | 'riego' | 'limpieza';

interface Props {
  handleClickNewButton: (
    ev: React.MouseEvent<Element, MouseEvent> | React.FormEvent<Element>,
  ) => void;
  textNewButton: string;
  activeModule: ActiveModule;
  handleChangeFilterText: (text: string) => void;
  handleClear: () => void;
  filterText: string;
}

const NavFilters: FC<Props> = ({
  handleClickNewButton,
  textNewButton,
  activeModule,
  handleChangeFilterText,
  handleClear,
  filterText,
}) => {
  const navigate = useNavigate();
  const { appTitle, coreModules } = appConfig;
  const limpiezaModule = coreModules.find(coreModule => coreModule.module === 'limpieza');
  const riegoModule = coreModules.find(coreModule => coreModule.module === 'riego');
  const zzvvModule = coreModules.find(coreModule => coreModule.module === 'zzvv');
  const { canUse } = useAuth();
  const isArtemisa = appTitle === 'Artemisa';

  const showNewBtn = () => {
    return canUse('create-users', 'app') || canUse('create-roles', 'app');
  };

  return (
    <Row alignItems="flex-end" justifyContent="space-between" className="user-filters">
      <Col md={6} sm={12} xs={12}>
        <OutlineButton
          lg
          active={activeModule === 'users'}
          borderRadius={{ topLeft: '3px', bottomLeft: '3px' }}
          icon="users"
          color="disabled"
          className="user-filters__btn-module user-filters__btn-module--users"
          onClick={() => navigate('/usuarios')}
        >
          Todos
        </OutlineButton>
        <OutlineButton
          lg
          active={activeModule === 'roles'}
          icon="helmet"
          color="disabled"
          className="user-filters__btn-module user-filters__btn-module--roles"
          onClick={() => navigate('/roles')}
        >
          Roles
        </OutlineButton>
        {zzvvModule && zzvvModule.active && !isArtemisa && (
          <OutlineButton
            lg
            active={activeModule === 'zzvv'}
            icon="tree"
            color="disabled"
            className="user-filters__btn-module user-filters__btn-module--zzvv"
            onClick={() => navigate('/usuarios/zzvv')}
          >
            Zonas verdes
          </OutlineButton>
        )}
        {riegoModule && riegoModule.active && !isArtemisa && (
          <OutlineButton
            lg
            active={activeModule === 'riego'}
            borderRadius={{ topRight: '3px', bottomRight: '3px' }}
            icon="water"
            color="disabled"
            className="user-filters__btn-module user-filters__btn-module--riego"
            onClick={() => navigate('/usuarios/riego')}
          >
            Riego
          </OutlineButton>
        )}
        {limpiezaModule && limpiezaModule.active && !isArtemisa && (
          <OutlineButton
            lg
            active={activeModule === 'limpieza'}
            borderRadius={{ topRight: '3px', bottomRight: '3px' }}
            icon="trash"
            color="disabled"
            className="user-filters__btn-module"
            onClick={() => navigate('/usuarios/limpieza')}
          >
            Limpieza
          </OutlineButton>
        )}
      </Col>
      <Col className="user-filters__btn-search" md={6} sm={12} xs={12}>
        <Row
          alignItems="center"
          justifyContent="flex-end"
          justifyContentResponsive={{
            lg: 'flex-end',
            md: 'flex-end',
            sm: 'center',
            xs: 'center',
          }}
        >
          {showNewBtn() && (
            <Col className="user-filters__btn-new">
              <OutlineButton
                borderRadius={{
                  topLeft: '3px',
                  topRight: '3px',
                  bottomLeft: '3px',
                  bottomRight: '3px',
                }}
                active
                boxShadow
                icon="plus"
                color="disabled"
                onClick={handleClickNewButton}
              >
                {textNewButton}
              </OutlineButton>
            </Col>
          )}
          <Col>
            <InputSearch
              onChange={e => handleChangeFilterText(e.target.value)}
              onClear={handleClear}
              value={filterText}
              placeholder="Buscar"
              type="text"
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default NavFilters;
