import { FC, ReactNode } from 'react';
import styles from './Col.module.scss';
import cn from 'classnames';

type Cols = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

export interface LazarusColProps extends React.HTMLAttributes<HTMLDivElement> {
  children?: ReactNode;
  container?: boolean;
  item?: boolean;
  xs?: Cols;
  sm?: Cols;
  md?: Cols;
  lg?: Cols;
  className?: string;
}

const Col: FC<LazarusColProps> = ({ children, xs, sm, md, lg, className, ...props }) => {
  const classNames = cn(
    'col',
    {
      [styles[`col_xs_${xs}`]]: xs,
      [styles[`col_sm_${sm}`]]: sm,
      [styles[`col_md_${md}`]]: md,
      [styles[`col_lg_${lg}`]]: lg,
    },
    className,
  );

  return (
    <div className={classNames} {...props}>
      {children}
    </div>
  );
};

export default Col;
