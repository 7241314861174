export const campos = [
  { value: 'codigo', text: 'Código' },
  { value: 'nombreComun', text: 'Nombre común' },
  { value: 'nombreTecnico', text: 'Nombre técnico' },
  { value: 'tipoPlanta', text: 'Tipo planta' },
  { value: 'genero', text: 'Género' },
  { value: 'generoDescripcion', text: 'Descripción género' },
  { value: 'estadoNombre', text: 'Estado nombre' },
  { value: 'familia', text: 'Familia' },
  { value: 'subfamilia', text: 'Subfamilia' },
  { value: 'variedad', text: 'Variedad' },
  { value: 'crecimiento', text: 'Crecimiento' },
  { value: 'crecimientoDescripcion', text: 'Crecimiento descripción' },
  { value: 'sexualidad', text: 'Sexualidad' },
  { value: 'sexualidadDescripcion', text: 'Sexualidad descripción' },
  { value: 'forma', text: 'Forma' },
  { value: 'formaDescripcion', text: 'Forma descripción' },
  { value: 'ramas', text: 'Ramas' },
  { value: 'ramasDescripcion', text: 'Ramas descripción' },
  { value: 'hojas', text: 'Hojas' },
  { value: 'hojasDescripcion', text: 'Hojas descripción' },
  { value: 'frutos', text: 'Frutos' },
  { value: 'frutosDescripcion', text: 'Frutos descripción' },
  { value: 'tronco', text: 'Tronco' },
  { value: 'troncoDescripcion', text: 'Tronco descripción' },
  { value: 'yemas', text: 'Yemas' },
  { value: 'yemasDescripcion', text: 'Yemas descripción' },
  { value: 'flores', text: 'Flores' },
  { value: 'floresDescripcion', text: 'Flores descripción' },
  { value: 'semilla', text: 'Semilla' },
  { value: 'semillaDescripcion', text: 'Semilla descripción' },
  { value: 'tipoSenescencia', text: 'Tipo senescencia' },
  { value: 'alturaMax', text: 'Altura máxima' },
  { value: 'alturaMin', text: 'Altura mínima' },
  { value: 'alturaMed', text: 'Altura media' },
  { value: 'diametroMax', text: 'Diámetro máximo' },
  { value: 'diametroMin', text: 'Diámetro mínimo' },
  { value: 'diametroMed', text: 'Diámetro medio' },
  { value: 'perimetroMax', text: 'Perímetro máximo' },
  { value: 'perimetroMin', text: 'Perímetro mínimo' },
  { value: 'perimetroMed', text: 'Perímetro medio' },
  { value: 'resumen', text: 'Resumen' },
  { value: 'detalleResumen', text: 'Destalle resumen' },
];
