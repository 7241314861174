import { useState, FC, ReactNode, DetailedHTMLProps, HTMLAttributes } from 'react';
import H2 from '../H2';
import OutlineButton from '../OutlineButton';

interface Props extends Omit<DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>, 'title'> {
  title: string | ReactNode;
  initialValue?: boolean;
  open?: boolean;
  onToggle?: (isOpen: boolean) => void;
}

const CollapsibleSection: FC<Props> = ({
  title,
  initialValue = true,
  open,
  onToggle,
  children,
  ...props
}) => {
  const [value, setValue] = useState(!!initialValue);

  if (typeof open === 'boolean' && value != open) setValue(open);

  const handleToggle = () => {
    if (onToggle) onToggle(!value);
    else setValue(curr => !curr);
  };

  return (
    <section {...props} className="collapsible-section">
      <div className="collapsible-section__title">
        <H2>{title}</H2>
        <OutlineButton color="black" onClick={handleToggle} icon={value ? 'down' : 'right'} />
      </div>
      {value && children}
    </section>
  );
};

export default CollapsibleSection;
