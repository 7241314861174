import { Route } from '../../routes/routes';
import ActuacionesListPage from './Actuaciones/List';
import ContadoresListPage from './Contadores/List';
import InventorioListPage from './Inventario/List';
import MaterialesListPage from './Materiales/List';
import PuntosDeSuministroListPage from './PuntosDeSuministro/List';

export const listsRoutes: Route[] = [
  {
    name: 'Inventario',
    path: 'inventario',
    to: `inventario`,
    Component: InventorioListPage,
    requirePermission: 'view-inventario',
  },
  {
    name: 'Actuaciones',
    path: 'actuaciones',
    to: `actuaciones`,
    Component: ActuacionesListPage,
    requirePermission: 'view-actuaciones',
  },
  {
    name: 'Puntos de Suministro',
    path: 'puntos-de-suministro',
    to: `puntos-de-suministro`,
    Component: PuntosDeSuministroListPage,
    requirePermission: 'view-puntos-suministro',
  },
  {
    name: 'Contadores',
    path: 'contadores',
    to: `contadores`,
    Component: ContadoresListPage,
    requirePermission: 'view-contadores',
  },
  {
    name: 'Materiales',
    path: 'materiales',
    to: `materiales`,
    Component: MaterialesListPage,
    requirePermission: 'view-materiales',
  },
];
