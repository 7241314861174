import React, { useState, useEffect, useContext } from 'react';
import LeafletMap from './Components/LeafletMap';
import { getInventariosUtils } from './utils';
import { InventarioMapa } from './interface';
import { InventariosContext } from '../../context';

const InventarioMapPage = () => {
  const [inventories, setInventories] = useState<InventarioMapa[]>([]);
  const { inventarios } = useContext(InventariosContext);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (inventarios.length > 0) {
          // Ejecutar las dos funciones asincrónicas en paralelo
          const [inventory] = await Promise.all([getInventariosUtils(inventarios)]);

          // Verificar si se recibieron las planificaciones
          if (inventory) {
            setInventories(inventory);
          } else {
            setInventories([]);
          }
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchData();
  }, [inventarios]);

  return <div>{inventories.length > 0 && <LeafletMap inventories={inventories} />}</div>;
};

export default InventarioMapPage;
