import { createApiInstance } from '../../../api';
import { MainInfo } from '../interfaces';
import { responseTransformer } from './response-transformer';

export const execute = async (id: number): Promise<MainInfo | null> => {
  const api = createApiInstance();
  const { data } = await api.get<MainInfo | null>(`/riego/materiales/${id}`, {
    transformResponse: [responseTransformer],
  });

  return data;
};
