import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface Pivot {
  role_id: number;
  permission_id: number;
}
interface Permission {
  id: number;
  name: string;
  slug: string;
  section: string;
  subsection: string;
  descripcion: string;
  grupo: string;
  pivot: Pivot;
  created_at: string;
  updated_at: string;
}

export interface User {
  id?: number;
  name?: string;
  email?: string;
  foto?: string;
  created_at?: string;
  updated_at?: string;
  permisos?: Permission[];
}

export interface UserState {
  user?: User;
}

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    data: {},
    remember: false,
  },
  reducers: {
    setUser: (state, action: PayloadAction<User>) => {
      state.data = action.payload;
    },
    setRemember: (state, action: PayloadAction<boolean>) => {
      state.remember = action.payload;
    },
  },
});

export const { setUser, setRemember } = userSlice.actions;

export default userSlice.reducer;
