import { FC } from 'react';

const NoResults: FC = () => {
  return (
    <div className="rdt_NoData">
      <p>No hay resultados encontrados</p>
    </div>
  );
};

export default NoResults;
