import cn from 'classnames';
import { FC } from 'react';
import { InputType } from '../../shared/types';
import Icon from '../Icon';

interface Props {
  /**
   * Tipo de input
   */
  type: InputType;

  /**
   * Id
   */
  id?: string;

  /**
   * Handler clear
   */
  onClear?: () => void;

  /**
   * Agregar clases de css propias
   */
  className?: string;
}

const InputSearch: FC<Props & React.InputHTMLAttributes<HTMLInputElement>> = ({
  id,
  type,
  onClear,
  className,
  ...props
}) => {
  const classNames = cn('input-search', className);

  return (
    <div className={classNames}>
      <Icon name="search" color="black" />
      <input {...props} type={type} id={id} />
      <Icon
        onClick={() => {
          if (onClear) onClear();
        }}
        name="remove"
        color="black"
      />
    </div>
  );
};

export default InputSearch;
