import { createApiInstance } from '../../api';

export const execute = async (cargasId: number[]): Promise<{ success: boolean }> => {
  const api = createApiInstance('application/json');
  const { data } = await api.delete<{ success: boolean }>('/cargas-envios', {
    data: { cargasId },
  });

  return data;
};
