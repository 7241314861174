import { createApiInstance } from '../../api';
import { FileResponse } from '../interfaces';

export const execute = async (fecha: string): Promise<FileResponse> => {
  const api = createApiInstance();
  const { data } = await api.get<FileResponse>(`/ficheros`, {
    params: { fecha: fecha },
  });

  return data;
};
