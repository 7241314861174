import { JWTDecoded } from './../shared/interfaces';
import axios, { AxiosInstance } from 'axios';
import dayjs from 'dayjs';
import jwtDecode from 'jwt-decode';
import { appConfig } from '../config/app';
import { Auth } from '.';

export const accessTokenKey = 'lazarus-front-access-token';

export const createOauthApiInstance = (): AxiosInstance => {
  return axios.create({ baseURL: `${appConfig.apiConfig.baseURL}/api/v2/oauth` });
};

export const createAjaxApiInstance = (): AxiosInstance => {
  return axios.create({
    baseURL: `${appConfig.apiConfig.baseURL}/ajax`,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });
};

export const createApiInstance = (contentType = 'multipart/form-data'): AxiosInstance => {
  const bearerToken = localStorage.getItem(accessTokenKey);

  const api = axios.create({
    baseURL: `${appConfig.apiConfig.baseURL}/api/v2`,
    headers: {
      Accept: 'application/json',
      'Content-Type': contentType,
      Authorization: bearerToken ? `Bearer ${bearerToken}` : '',
    },
  });

  api.interceptors.request.use(async req => {
    if (req.headers && bearerToken) {
      req.headers.Authorization = `Bearer ${bearerToken}`;
      const tokenDecoded: JWTDecoded = jwtDecode(bearerToken);
      const isExpired = dayjs.unix(tokenDecoded.exp).diff(dayjs()) < 1;
      if (!isExpired) return req;

      const refreshTokenResponse = await Auth.refreshToken(bearerToken);

      localStorage.setItem(accessTokenKey, refreshTokenResponse.newToken);
      if (req.headers) {
        api.defaults.headers.common['Authorization'] = `Bearer ${refreshTokenResponse.newToken}`;
        req.headers.Authorization = `Bearer ${refreshTokenResponse.newToken}`;
      }

      return req;
    }

    return req;
  });

  return api;
};
