import { useEffect, useState } from 'react';
import { MainInfo } from '../../../../API/riego/inventarios/interfaces';
import BoxComplexInput from '../../../../components/BoxComplexInput';
import Button from '../../../../components/Button';
import Col from '../../../../components/Col';
import H1 from '../../../../components/H1';
import Row from '../../../../components/Row';
import DynamicForm from '../../../../components/DynamicForm';
import { showErrorAlert, showSuccessAlert } from '../../../../libs/LazarusAlert';
import { useNavigate } from 'react-router-dom';
import useMetadata from './hooks';
import { getById, post } from '../../../../API/riego/inventarios';
import {
  AtributoObservacion,
  ElementoRelacionado,
  PiezaRecambio,
} from '../../../../API/riego/inventarios/interfaces';
import { Documento } from '../../../../API/shared/interfaces';
import useEdit from '../../../../hooks/useEdit';
import CollapsibleSection from '../../../../components/CollapsibleSection';
import { EspecificacionTecnica } from '../../../../API/shared/inventarios/interfaces';

const InventarioCreatePage = (): JSX.Element => {
  const [documentos, setDocumentos] = useState<Documento[]>([]);
  const [especificacionesTecnicas, setEspecificacionesTecnicas] = useState<EspecificacionTecnica[]>(
    [],
  );
  const [atributosObservacion, setAtributosObservacion] = useState<AtributoObservacion[]>([]);
  const [elementosRelacionados, setElementosRelacionado] = useState<ElementoRelacionado[]>([]);
  const [piezasRecambio, setPiezasRecambio] = useState<PiezaRecambio[]>([]);
  const [mainInfo, setMainInfo] = useState<MainInfo>({} as MainInfo);

  const {
    mainInfoMetadata,
    documentosMetadata,
    atributoObservacionMetadata,
    elementoRelacionadoMetadata,
    piezaRecambioMetadata,
    especificacionesTecnicasMetadata,
  } = useMetadata(setMainInfo);
  const [loading, setLoading] = useState(false);

  const dataToEdit = useEdit(getById, '/riego/listado/inventario');

  useEffect(() => {
    if (dataToEdit) {
      setMainInfo(dataToEdit.mainInfo);
      setElementosRelacionado(dataToEdit.elementosRelacionados),
        setPiezasRecambio(dataToEdit.piezasRecambios);
      setEspecificacionesTecnicas(dataToEdit.especificacionesTecnicas);
      setDocumentos(dataToEdit.documentos);
      setAtributosObservacion(dataToEdit.atributosObservacion);
    }
  }, [dataToEdit]);

  const navigate = useNavigate();

  const handleSubmit = () => {
    setLoading(true);
    post({
      mainInfo,
      elementosRelacionados,
      documentos,
      especificacionesTecnicas,
      piezasRecambio,
      atributosObservacion,
    })
      .then(() => {
        showSuccessAlert({ title: '¡Éxito!', text: 'Inventario guardado con éxito.' });
        navigate('/riego/listado/inventario');
      })
      .catch(() => {
        showErrorAlert({ title: 'Error', text: 'Error al guardar el inventario.' });
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      <H1>Datos</H1>
      <CollapsibleSection title="Información principal">
        <DynamicForm
          spacing="lg"
          onChange={setMainInfo}
          metadata={mainInfoMetadata}
          value={mainInfo}
        />
      </CollapsibleSection>
      <Row style={{ marginBottom: 30 }}>
        <BoxComplexInput
          closedMessage={`Hay ${atributosObservacion.length} atributos de observación.`}
          metadata={atributoObservacionMetadata}
          onRemove={row => setAtributosObservacion(cur => cur.filter(c => c !== row))}
          onAdd={row => setAtributosObservacion(cur => [...cur, row])}
          label="Atributos de obersvación"
          dataSource={atributosObservacion}
        />
      </Row>
      <Row style={{ marginBottom: 30 }}>
        <BoxComplexInput
          closedMessage={`Hay ${documentos.length} documentos.`}
          metadata={documentosMetadata}
          onRemove={row => setDocumentos(cur => cur.filter(c => c !== row))}
          onAdd={row => setDocumentos(cur => [...cur, row])}
          label="Documentos"
          dataSource={documentos}
        />
      </Row>
      <Row style={{ marginBottom: 30 }}>
        <BoxComplexInput
          closedMessage={`Hay ${especificacionesTecnicas.length} especificaciones técnicas.`}
          metadata={especificacionesTecnicasMetadata}
          onRemove={row => setEspecificacionesTecnicas(cur => cur.filter(c => c !== row))}
          onAdd={row => setEspecificacionesTecnicas(cur => [...cur, row])}
          label="Especificaciones técnicas"
          dataSource={especificacionesTecnicas}
        />
      </Row>
      <Row style={{ marginBottom: 30 }}>
        <BoxComplexInput
          closedMessage={`Hay ${elementosRelacionados.length} elementos relacionados.`}
          metadata={elementoRelacionadoMetadata}
          onRemove={row => setElementosRelacionado(cur => cur.filter(c => c !== row))}
          onAdd={row => setElementosRelacionado(cur => [...cur, row])}
          label="Elementos relacionados"
          dataSource={elementosRelacionados}
        />
      </Row>
      <Row style={{ marginBottom: 30 }}>
        <BoxComplexInput
          closedMessage={`Hay ${piezasRecambio.length} piezas de recambio.`}
          metadata={piezaRecambioMetadata}
          onRemove={row => setPiezasRecambio(cur => cur.filter(c => c !== row))}
          onAdd={row => setPiezasRecambio(cur => [...cur, row])}
          label="Piezas de recambio"
          dataSource={piezasRecambio}
        />
      </Row>
      <Row justifyContent="center">
        <Col xs={3} sm={2}>
          <Button color="primary" outline disabled={loading}>
            Cancelar
          </Button>
        </Col>
        <Col xs={3} sm={2}>
          <Button color="primary" onClick={handleSubmit} disabled={loading}>
            Guardar
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default InventarioCreatePage;
