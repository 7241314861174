import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import {
  getEstadosInventario,
  getJerarquiasClasificacionInventario,
} from '../../../../API/shared/inventarios';
import { MainInfo } from '../../../../API/limpieza/inventarios/interfaces';
import { EstadoElemento, JerarquiaClasificacion } from '../../../../API/shared/interfaces';
import { especificacionesTecnicasMetadata } from '../../../../API/shared/inventarios/utils';
import { DynamicFormMetadata } from '../../../../components/DynamicForm';
import { EspecificacionTecnica } from '../../../../API/shared/inventarios/interfaces';

interface UseMetadataResult {
  mainInfoMetadata: DynamicFormMetadata<MainInfo>[];
  especificacionesTecnicasMetadata: DynamicFormMetadata<EspecificacionTecnica>[];
}

export default function useMetadata(
  setMainInfo: Dispatch<SetStateAction<MainInfo>>,
): UseMetadataResult {
  const [estados, setEstados] = useState<EstadoElemento[]>([]);
  const [jerarquiasClasificacion, setJerarquiasClasificacion] = useState<JerarquiaClasificacion[]>(
    [],
  );

  useEffect(() => {
    getEstadosInventario().then(res => setEstados(res));
    getJerarquiasClasificacionInventario('limpieza').then(res => setJerarquiasClasificacion(res));
  }, []);

  const mainInfoMetadata = useMemo(
    () => getMainInfoMetadata(setMainInfo, estados /*, jerarquiasClasificacion*/),
    [setMainInfo, jerarquiasClasificacion, estados],
  );

  return { mainInfoMetadata, especificacionesTecnicasMetadata };
}

const getMainInfoMetadata = (
  setMainInfo: Dispatch<SetStateAction<MainInfo>>,
  estados: EstadoElemento[],
  //jerarquias: JerarquiaClasificacion[],
): DynamicFormMetadata<MainInfo>[] => [
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'id_elemento_inventario',
      props: {
        readOnly: true,
        label: 'Id Inventario',
        type: 'text',
      },
    },
  },

  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'activo',
      props: {
        readOnly: true,
        label: 'Activo',
        type: 'text',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'descripcion',
      props: {
        required: true,
        label: 'Descripción',
        type: 'text',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'padre',
      props: {
        label: 'Nivel superior',
        type: 'text',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      //tag: 'Select',
      tag: 'Input',
      propKey: 'jerarquiaClasificacion',
      props: {
        label: 'Jerarquía clasificación',
        type: 'text',
        /*options: jerarquias.map(j => ({ text: j.codigo, value: j.codigo })),
        onChange: e => {
          const jerarquia = jerarquias.find(j => j.codigo === e.currentTarget.value);
          setMainInfo(cur => ({
            ...cur,
            jerarquiaClasificacionDescripcion: jerarquia?.descripcion || '',
            claseFuncionGIS: jerarquia?.capa_gis || cur.claseFuncionGIS,
            descripcion: cur.descripcion ? cur.descripcion : jerarquia?.descripcion || '',
          }));
        },*/
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'jerarquiaClasificacionDescripcion',
      props: {
        label: 'Descripción de jerarquía clasificación',
        type: 'text',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'lote',
      props: {
        label: 'Lote',
        type: 'number',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'loteDescripcion',
      props: {
        label: 'Lote descripción',
        type: 'number',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'contrato',
      props: {
        label: 'Contrato',
        type: 'text',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'contratoDescripcion',
      props: {
        label: 'Contrato descripción',
        type: 'text',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'servicio',
      props: {
        label: 'Servicio',
        type: 'text',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'servicioDescripcion',
      props: {
        label: 'Servicio descripción',
        type: 'text',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'padre',
      props: {
        label: 'Padre',
        type: 'text',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'posicionamientoMapa',
      props: {
        label: 'Posicionamiento mapa',
        type: 'text',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'distrito',
      props: {
        label: 'Distrito',
        type: 'number',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'distritoDescripcion',
      props: {
        label: 'Distrito descripción',
        type: 'text',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'barrio',
      props: {
        label: 'Barrio',
        type: 'number',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'barrioDescripcion',
      props: {
        label: 'Barrio descripción',
        type: 'text',
      },
    },
  },

  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'tipoVia',
      props: {
        label: 'Tipo de vía',
        type: 'text',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'nombreVia',
      props: {
        label: 'Nombre vía',
        type: 'text',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'numeroVia',
      props: {
        label: 'Número vía',
        type: 'number',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'idInventarioInterno',
      props: {
        label: 'Id inventario interno',
        type: 'text',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'idInventarioExterno',
      props: {
        label: 'Id inventario externo',
        type: 'text',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'claseFuncionGIS',
      props: {
        label: 'Clase Función',
        type: 'text',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'codigoPostal',
      props: {
        required: true,
        label: 'Código Postal',
        type: 'text',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'coordenadas',
      props: {
        label: 'Ubicación GIS *',
        type: 'text',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'descripcionUbicacion',
      props: {
        label: 'Descripción ubicación',
        type: 'text',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Select',
      propKey: 'estado',
      props: {
        label: 'Estado',
        options: estados.map(e => ({ text: e.valor, value: e.valor })),
        onChange: e => {
          const desc = estados.find(s => s.valor === e.currentTarget.value)?.descripcion || '';
          setMainInfo(cur => ({ ...cur, estadoDescripcion: desc }));
        },
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'fechaEstado',
      props: {
        label: 'Fecha de estado',
        type: 'date',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'fechaDesinstalacion',
      props: {
        label: 'Fecha de desinstalación',
        type: 'date',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'fechaInstalacion',
      props: {
        label: 'Fecha de instalación',
        type: 'date',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'ndp',
      props: {
        required: true,
        label: 'NDP',
        type: 'number',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'observaciones',
      props: {
        label: 'Resumen',
        type: 'text',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'observacionesLargas',
      props: {
        label: 'Descripción detallada',
        type: 'text',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'sensorLlenado',
      props: {
        label: 'Sensor llenado',
        type: 'text',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'sensorExpendedor',
      props: {
        label: 'Sensor expendedor',
        type: 'text',
      },
    },
  },
];
