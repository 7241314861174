import { ExoticComponent, LazyExoticComponent } from 'react';
import Layout from '../components/Layout';
import LoginPage from '../pages/Login';

type JSXComponent = () => JSX.Element;

export interface Route {
  to: string;
  path: string;
  Component: LazyExoticComponent<JSXComponent> | JSXComponent | ExoticComponent;
  MapComponent?: LazyExoticComponent<JSXComponent> | JSXComponent | ExoticComponent;
  name: string;
  exact?: boolean;
  requirePermission?: string;
}

export interface SubModule extends Route {
  subRoutes?: Route[];
}

export interface RouteWithSubmodules extends Route {
  submodules: SubModule[];
}

export interface RouteModule {
  lists?: RouteWithSubmodules;
  communications?: RouteWithSubmodules;
  creates?: RouteWithSubmodules;
  edits?: RouteWithSubmodules;
}

export const routes: Route[] = [
  {
    to: '/login',
    path: 'login',
    Component: LoginPage,
    name: 'Login',
  },
  {
    to: '/',
    path: '/*',
    Component: Layout,
    name: 'Layout',
  },
];
