import { ReactElement, useState } from 'react';
import Col from '../Col';
import DynamicInput from '../DynamicInput';
import { DynamicFormMetadata } from '../Table/Filters';
import H2 from '../H2';
import OutlineButton from '../OutlineButton';
import Row from '../Row';
import DynamicForm from '../DynamicForm';

interface BoxComplexInputProps<T> {
  label: string;
  dataSource: T[];
  onAdd: (entity: T) => void;
  onRemove: (entity: T) => void;
  metadata: DynamicFormMetadata[];
  closedMessage: string;
}

const BoxComplexInput = <T extends Record<string, any>>(
  props: BoxComplexInputProps<T>,
): ReactElement => {
  const { label, dataSource, metadata, closedMessage, onAdd, onRemove } = props;

  const [newItem, setNewItem] = useState<T>({} as T);
  const [page, setPage] = useState(0);

  const [displayed, setDisplayed] = useState(false);

  const toggleDisplayed = () => setDisplayed(cur => !cur);
  const prevPage = () => setPage(cur => (cur === 0 ? 0 : cur - 1));
  const nextPage = () => setPage(cur => (cur === dataSource.length ? dataSource.length : cur + 1));

  const isFullFiled = metadata.every(m => newItem[m.input.propKey] !== undefined);

  const handleAdd = () => {
    if (page !== 0) {
      setPage(0);
      return;
    }

    if (!isFullFiled) {
      //TODO: Centralizar logica para campos required
      return;
    }
    onAdd(newItem);
    setNewItem({} as T);
    setPage(dataSource.length + 1);
  };

  const handleRemove = (item: T) => {
    prevPage();
    onRemove(item);
  };

  return (
    <div className="box-complex-input">
      <div className="box-complex-input__title">
        <div className="box-complex-input__title__info">
          <H2>{label}</H2>
          <OutlineButton
            color="black"
            onClick={toggleDisplayed}
            icon={displayed ? 'right' : 'down'}
          />
          <OutlineButton
            disabled={!isFullFiled && page === 0}
            color="primary"
            icon="plus"
            onClick={handleAdd}>
            Agregar
          </OutlineButton>
        </div>
        {displayed && (
          <span className="box-complex-input__page">
            <OutlineButton onClick={prevPage} icon="left" color="success" />
            <span className="box-complex-input__p">
              {page || 'nuevo'} / {dataSource.length || '-'}
            </span>
            <OutlineButton onClick={nextPage} icon="right" color="success" />
          </span>
        )}
      </div>
      {displayed && (
        <div>
          {page === 0 && (
            <DynamicForm
              spacing="md"
              className="box-complex-input__grid"
              metadata={metadata}
              value={newItem}
              onChange={setNewItem}
            />
          )}
          {dataSource
            .filter((i, idx) => idx + 1 === page)
            .map((i, idx) => (
              <span key={idx}>
                <Row className="box-complex-input__grid" spacing="md">
                  <Col sm={12} className="box-complex-input__grid__trash">
                    <OutlineButton icon="trash" color="primary" onClick={() => handleRemove(i)} />
                  </Col>
                  {metadata.map((m, idx2) => (
                    <Col {...m.grid} key={idx2}>
                      <DynamicInput disabled {...m.input} value={i[m.input.propKey]} />
                    </Col>
                  ))}
                </Row>
              </span>
            ))}
        </div>
      )}
      {!displayed && <p>{closedMessage}</p>}
    </div>
  );
};

export default BoxComplexInput;
