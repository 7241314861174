import { Actuacion } from '../../../../API/limpieza/actuaciones/interfaces';

/* ####### Actuaciones Utils ####### */

export const getActuacionesUtils = async (actuaciones: Actuacion[]) => {
  if (actuaciones.length === 0) return [];

  return actuaciones.map(actuacion => ({
    id: actuacion.id.toString(),
    barrio: actuacion.barrio,
    distrito: actuacion.distrito,
    codigoPostal: actuacion.codigoPostal,
    contrato: actuacion.contrato,
    contratoDescripcion: actuacion.contratoDescripcion,
    ndp: actuacion.ndp,
    lote: actuacion.lote,
    loteDescripcion: actuacion.loteDescripcion,
    jerarquiaClasificacion: actuacion.jerarquiaClasificacion,
    nombreVia: actuacion.nombreVia,
    nroVia: actuacion.nroVia,
    tipoVia: actuacion.tipoVia,
    claseFuncionGIS: actuacion.claseFuncionGIS,
    descripcion: actuacion.descripcion,
    coordenadas: actuacion.coordenadas,
    coordenadaX: actuacion.coordenadaX,
    coordenadaY: actuacion.coordenadaY,
  }));
};
