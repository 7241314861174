import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Roles } from '../../API';
import { Section } from '../../API/permissions/interfaces';
import Button from '../../components/Button';
import Col from '../../components/Col';
import H1 from '../../components/H1';
import H2 from '../../components/H2';
import Input from '../../components/Input';
import Page from '../../components/Page';
import Row from '../../components/Row';
import { CoreModule } from '../../config/interfaces';
import { showErrorAlert, showSuccessAlert } from '../../libs/LazarusAlert';
import { IconName } from '../../shared/types';
import PermissionsGroup from './components/PermissionsGroup';
import { RoleContext } from './context';
import { usePermission } from './hooks/usePermission';

const PermissionsPage = (): JSX.Element => {
  const navigate = useNavigate();
  const {
    permissions,
    allChecked,
    role,
    handleChange,
    handleCheck,
    activeSections,
    activeSubSections,
    handleCheckSection,
    permissionKeys,
    modules,
    handleCheckSubSection,
    handleCheckAll,
    validate,
    errors,
  } = usePermission();

  const breadcrumbItems = [
    {
      icon: 'helmet' as IconName,
      path: '/roles',
      label: 'Roles',
    },
  ];

  const btnBackConfig = {
    onClick: () => navigate('/roles'),
  };

  const handleCreateRole = () => {
    if (!validate()) return;
    const request = role.id ? Roles.update(role.id, role) : Roles.create(role);

    request
      .then(() => {
        showSuccessAlert({ title: 'Rol guardado correctamente' }).then(() => {
          navigate('/roles');
        });
      })
      .catch(err => {
        console.log(err);
        let message = '';
        if (err.response.data.errors) {
          const errorsMessages = Object.keys(err.response.data.errors).map(
            (errorKey: string) => err.response.data.errors[errorKey][0],
          );
          message = errorsMessages.join('\n');
        }
        showErrorAlert({ title: 'Error al guardar el rol', text: message });
      });
  };

  const handleCancel = () => {
    navigate('/roles');
  };

  if (!permissions) return <></>;

  return (
    <RoleContext.Provider
      value={{
        role,
        modules,
        handleChange,
        handleCheck,
        activeSections,
        activeSubSections,
        handleCheckSection,
        handleCheckSubSection,
        handleCheckAll,
        allChecked,
      }}>
      <Page
        title="Usuarios"
        icon="user"
        titleType="breadcrumb"
        className="permisions"
        breadcrumb={breadcrumbItems}
        btnBackConfig={btnBackConfig}>
        <H1>Datos del rol</H1>
        <H2>Información principal</H2>

        <Row className="permisions__name-container">
          <Col md={4} xs={12}>
            <Input
              type="text"
              value={role.name}
              error={errors.name}
              onChange={ev => handleChange('name', ev.target.value)}
              label="Nombre del rol"
            />
          </Col>
        </Row>

        <div className="permissions__section">
          <div className="permissions__section-title">
            <H2>Accesos</H2>
          </div>

          {permissionKeys.map((permissionKey: Section, idx: number) => {
            const modulePermission: CoreModule = modules[permissionKey];
            const subModulePermission = permissions[permissionKey];

            if (!modulePermission && !!subModulePermission) {
              return (
                <PermissionsGroup
                  key={idx}
                  section={permissionKey}
                  permissions={subModulePermission}
                />
              );
            }

            if (modulePermission && modulePermission.active && subModulePermission)
              return (
                <PermissionsGroup
                  key={idx}
                  section={permissionKey}
                  permissions={subModulePermission}
                />
              );

            return null;
          })}
        </div>

        <Row className="permissions__buttons-container" justifyContent="center">
          <Col xs={12}>
            <div className="permissions__actions">
              <Button onClick={handleCancel} color="primary" outline>
                Cancelar
              </Button>
              <Button onClick={handleCreateRole} color="primary">
                Guardar
              </Button>
            </div>
          </Col>
        </Row>
      </Page>
    </RoleContext.Provider>
  );
};

export default PermissionsPage;
