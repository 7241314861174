import { FC } from 'react';
import { setOpenUserMenu } from '../../../redux/HeaderSlice/headerSlice';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { User } from '../../../redux/UserSlice/userSlice';
import DefaultPhoto from '../../../assets/img/default-user.jpg';
import Icon from '../../Icon';
import { appConfig } from '../../../config/app';

interface Props {
  /**
   * Usuario a mostrar en el header
   */
  user: User;

  /**
   * Evento al ejecutar cuando hace click en el Cerrar Sesión
   */
  onLogout?: () => void;
}

const UserMenu: FC<Props & React.HTMLAttributes<HTMLDivElement>> = ({ user, onLogout }) => {
  const openUserMenu = useAppSelector(state => state.header.openUserMenu);
  const dispatch = useAppDispatch();

  const profilePhoto = (): string => {
    if (!user || !user.foto) {
      return DefaultPhoto;
    }

    const server = appConfig.apiConfig.baseURL.replace('/api/v2', '');
    return `${server}${user.foto}`;
  };
  const handleLogout = () => {
    dispatch(setOpenUserMenu({ openUserMenu: false }));
    if (onLogout) onLogout();
  };

  const handleToggleMenu = () => {
    dispatch(setOpenUserMenu({ openUserMenu: !openUserMenu }));
  };

  return (
    <div className="user-menu">
      <div className="user-menu__img" onClick={handleToggleMenu}>
        <img src={profilePhoto()} alt="Foto de perfil" />
      </div>
      <div className={`user-menu__items ${openUserMenu ? 'open' : ''}`}>
        <div className="user-menu__item">
          <p className="user-menu__item-name">{user.name}</p>
          <p className="user-menu__item-email">{user.email}</p>
        </div>
        <div className="user-menu__item" onClick={handleLogout}>
          <div className="user-menu__session-container">
            <Icon color="white" name="logout" />
            <p className="user-menu__item-logout">Cerrar Sesión</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserMenu;
