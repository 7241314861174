import { AxiosError } from 'axios';
import dayjs from 'dayjs';
import jwtDecode from 'jwt-decode';
import { Auth } from '../API';
import { accessTokenKey, createApiInstance } from '../API/api';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { setRemember, setUser, User } from '../redux/UserSlice/userSlice';
import { JWTDecoded } from '../shared/interfaces';

export interface Credentials {
  email: string;
  password: string;
  remember: boolean;
}

interface LazarusAuth {
  isAuthenticated: boolean;
  user: User;
  signIn: (credentials: Credentials) => Promise<User>;
  signOut: () => Promise<void>;
  whoami: (token: string) => Promise<User>;
  expiredToken: () => boolean;
  canUse: (feature: string, section?: string, subsection?: string) => boolean;
  remember: boolean;
}

export function useAuth(): LazarusAuth {
  const user: User = useAppSelector(state => state.user.data);
  const remember = useAppSelector(state => state.user.remember);
  const dispatch = useAppDispatch();
  const api = createApiInstance();

  const signIn = async (credentials: Credentials): Promise<User> => {
    const signInRespone = await Auth.signIn(credentials).catch((err: AxiosError) => {
      throw err;
    });

    const token = signInRespone.token;
    localStorage.setItem(accessTokenKey, token);
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    const user = await Auth.whoami(token);
    dispatch(setUser(user));
    dispatch(setRemember(credentials.remember));

    return user;
  };

  const signOut = async (): Promise<void> => {
    delete api.defaults.headers.common['Authorization'];
    localStorage.removeItem(accessTokenKey);
    dispatch(setUser({}));
  };

  const isAuthenticated = () => {
    return user && localStorage.getItem(accessTokenKey) && Object.keys(user).length > 0
      ? true
      : false;
  };

  const expiredToken = (): boolean => {
    const lazarusAccessToken = localStorage.getItem(accessTokenKey);
    if (!lazarusAccessToken) return true;

    const tokenDecoded: JWTDecoded = jwtDecode(lazarusAccessToken);
    return dayjs.unix(tokenDecoded.exp).diff(dayjs()) < 1;
  };

  const canUse = (feature: string, section?: string, subsection?: string): boolean => {
    if (user && user.permisos) {
      return user.permisos.some(permiso => {
        const featureCondition = permiso.slug === feature;
        const sectionCondition = !section || permiso.section === section;
        const subsectionCondition = !subsection || permiso.subsection === subsection;

        return featureCondition && sectionCondition && subsectionCondition;
      });
    }

    return false;
  };

  return {
    remember,
    signIn,
    signOut,
    whoami: Auth.whoami,
    user,
    expiredToken,
    isAuthenticated: isAuthenticated(),
    canUse,
  };
}
