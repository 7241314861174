import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface HeaderState {
  showLogo: boolean;
  openUserMenu: boolean;
  openMenu: boolean;
}

const initialState: HeaderState = {
  showLogo: true,
  openUserMenu: false,
  openMenu: false,
};

export const headerSlice = createSlice({
  name: 'header',
  initialState,
  reducers: {
    setShowLogo: (state, action: PayloadAction<{ showLogo: boolean }>) => {
      state.showLogo = action.payload.showLogo;
    },
    setOpenUserMenu: (state, action: PayloadAction<{ openUserMenu: boolean }>) => {
      state.openUserMenu = action.payload.openUserMenu;
    },
    setOpenMenu: (state, action: PayloadAction<{ openMenu: boolean }>) => {
      state.openMenu = action.payload.openMenu;
    },
  },
});

export const { setShowLogo, setOpenUserMenu, setOpenMenu } = headerSlice.actions;

export default headerSlice.reducer;
