import { FC } from 'react';
import { NavLink } from 'react-router-dom';
import { IconName } from '../../shared/types';
import Icon from '../Icon';

interface MenuItem {
  icon?: IconName;
  name: string;
  path: string;
}

interface Props {
  /**
   * Menu abierto?
   */
  open: boolean;

  /**
   * Items a mostrar en el menu
   */
  items: MenuItem[];

  /**
   * On click item
   */
  onClickItem?: (item: MenuItem) => void;
}

type MenuProps = Props & React.HTMLAttributes<HTMLDivElement>;

const MenuComponent: FC<MenuProps> = ({ open, items, onClickItem }) => {
  return (
    <div className={`menu__items ${open ? 'open' : ''}`}>
      {items.map((item, i) => (
        <NavLink
          to={item.path}
          key={i}
          onClick={() => {
            onClickItem && onClickItem(item);
          }}>
          <div className="menu__item">
            <div className="menu__item-container">
              {item.icon && <Icon color="white" name={item.icon} />}
              <p className="menu__item-text text-white">{item.name}</p>
            </div>
          </div>
        </NavLink>
      ))}
    </div>
  );
};

export default MenuComponent;
