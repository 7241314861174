/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { AxiosError } from 'axios';
import { FC, useState } from 'react';
import { Vehiculos } from '../../../../../API/flotas';
import Button from '../../../../../components/Button';
import Input from '../../../../../components/Input';
import Modal from '../../../../../components/Modal';
import ModalBody from '../../../../../components/Modal/ModalBody';
import ModalFooter from '../../../../../components/Modal/ModalFooter';
import ModalTitle from '../../../../../components/Modal/ModalTitle';
import { showSuccessAlert } from '../../../../../libs/LazarusAlert';
import { editSchema } from '../schemas/edit.schema';

interface Props {
  title: string;
  openModal: boolean;
  handleOpenModal: (ev?: React.MouseEvent | React.FormEvent) => void;
  vehicleData: VehicleData;
  successCallback?: () => void;
}

interface VehicleData {
  id: number;
  cDescripcion: string;
}

const emptyVehicleData = {
  cDescripcion: '',
};

const VehicleModal: FC<Props> = ({
  title,
  vehicleData,
  openModal,
  handleOpenModal,
  successCallback,
}) => {
  const [vehicle, setVehicle] = useState<VehicleData>(vehicleData);
  const [errors, setErrors] = useState<Record<string, string>>({ ...emptyVehicleData });

  const handleChange = (key: string, value: string) => {
    setVehicle({ ...vehicle, [key]: value });
  };

  const handleSave = (ev: { preventDefault: () => void }) => {
    setErrors({});
    ev.preventDefault();

    try {
      editSchema.validateSync(vehicle, { abortEarly: false });
    } catch (exception: any) {
      const newErrors: Record<string, string> = { ...emptyVehicleData };
      for (const err of exception.inner) {
        newErrors[err.path] = err.message;
      }

      setErrors(newErrors);
      return;
    }

    Vehiculos.patch(vehicle, vehicle.id)
      .then(() => {
        showSuccessAlert({ title: 'Vehículo guardado' });
        successCallback && successCallback();
        handleOpenModal();
      })
      .catch((err: AxiosError) => {
        console.log(err);
      });
  };

  return (
    <Modal open={openModal} handleOpen={handleOpenModal}>
      <ModalTitle>{title}</ModalTitle>
      <form className="form-user" onSubmit={handleSave} autoComplete="off">
        <ModalBody>
          <Input
            onChange={(ev: { target: { value: string } }) =>
              handleChange('cDescripcion', ev.target.value)
            }
            value={vehicle.cDescripcion}
            error={errors.cDescripcion}
            type="text"
            label="Descripción"
          />
        </ModalBody>
        <ModalFooter>
          <Button onClick={handleOpenModal} color="primary" outline>
            Cancelar
          </Button>
          <Button color="primary">Guardar</Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default VehicleModal;
