import { GetLimpiezaPlanificacionResponse, Planificacion } from '../interfaces';

export const responseTransformer = (response: string): Planificacion[] => {
  const responseJSON: GetLimpiezaPlanificacionResponse = JSON.parse(response);

  return responseJSON.planificacion.map(
    (planificacion): Planificacion => ({
      id: planificacion.id,
      sector: planificacion?.sector || '',
      barrio: planificacion?.barrio || '0',
      distrito: planificacion?.distrito || '0',
      // jerarquiaClasificacion: planificacion?.jerarquiaClasificacion || '',
      tipoActuacion: planificacion?.tipoActuacion || '',
      fechaInicio: planificacion?.fechaInicio?.split(' ')[0] || '',
      fechaFin: planificacion?.fechaFin?.split(' ')[0] || '',
    }),
  );
};
