import React, { useEffect, useRef, useState } from 'react';
import L from 'leaflet';
import { parseCoordenadas, parseMultiPolygon } from '../../../shared/utils';

import 'leaflet/dist/leaflet.css';

interface ActuacionesData {
  id: string;
  barrio: number;
  distrito: number;
  codigoPostal: number;
  contrato: number;
  contratoDescripcion: string;
  ndp: number;
  lote: number;
  loteDescripcion: string;
  jerarquiaClasificacion: string;
  nombreVia: string;
  nroVia: number;
  tipoVia: string;
  claseFuncionGIS: string;
  descripcion: string;
  coordenadas: string;
  coordenadaX: string;
  coordenadaY: string;
}

interface LeafletMapProps {
  actuaciones: ActuacionesData[];
}

class CustomMarker extends L.Marker {
  elementId!: string; // Indicamos que la propiedad será asignada posteriormente.
}

const LeafletMap: React.FC<LeafletMapProps> = ({ actuaciones }) => {
  const mapContainerRef = useRef<HTMLDivElement | null>(null);
  const [map, setMap] = useState<L.Map | null>(null);

  function createCustomIcon(
    feature: any,
    elementId: string,
    latlng: L.LatLng,
    color: string,
    iconSize: [number, number],
    iconAnchor: [number, number],
    actuacionData: ActuacionesData,
    shadowUrl?: string,
  ) {
    const encodedColor = encodeURIComponent(color);
    const iconUrl = `data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 25"><circle cx="12.5" cy="12.5" r="10" fill="${encodedColor}" stroke="black" stroke-width="1"/></svg>`;
    const myIcon = L.icon({
      iconSize,
      iconAnchor,
      iconUrl,
      shadowUrl,
    });

    const marker = new CustomMarker(latlng, { icon: myIcon });
    marker.elementId = elementId;
    const popup = createPopup(actuacionData);
    marker.bindPopup(popup);

    return marker;
  }

  const createPopup = (actuacionData: ActuacionesData) => {
    return `<b>ID:</b> ${actuacionData.id != null ? actuacionData.id : 'N/A'}<br>
      <b>Descripcion:</b> ${
        actuacionData.descripcion != null ? actuacionData.descripcion : 'N/A'
      }<br>
      <b>Barrio:</b> ${actuacionData.barrio != null ? actuacionData.barrio : 'N/A'}<br>
      <b>Distrito:</b> ${actuacionData.distrito != null ? actuacionData.distrito : 'N/A'}<br>
      <b>Código postal:</b> ${
        actuacionData.codigoPostal != null ? actuacionData.codigoPostal : 'N/A'
      }<br>
      <b>Contrato:</b> ${actuacionData.contrato != null ? actuacionData.contrato : 'N/A'}<br>
      <b>Contrato Descripción:</b> ${
        actuacionData.contratoDescripcion != null ? actuacionData.contratoDescripcion : 'N/A'
      }<br>
      <b>Ndp:</b> ${actuacionData.ndp != null ? actuacionData.ndp : 'N/A'}<br>
      <b>Lote:</b> ${actuacionData.lote != null ? actuacionData.lote : 'N/A'}<br>
      <b>Lotedescripcion:</b> ${
        actuacionData.loteDescripcion != null ? actuacionData.loteDescripcion : 'N/A'
      }<br>
      <b>Jerarquia Clasificacin:</b> ${
        actuacionData.jerarquiaClasificacion != null ? actuacionData.jerarquiaClasificacion : 'N/A'
      }<br>
      <b>Nombre vía:</b> ${actuacionData.nombreVia != null ? actuacionData.nombreVia : 'N/A'}<br>
      <b>Número vía:</b> ${actuacionData.nroVia != null ? actuacionData.nroVia : 'N/A'}<br>
      <b>Tipo vía:</b> ${actuacionData.tipoVia != null ? actuacionData.tipoVia : 'N/A'}<br>
      <b>Clase función GIS:</b> ${
        actuacionData.claseFuncionGIS != null ? actuacionData.claseFuncionGIS : 'N/A'
      }<br>`;
  };

  const createMapInstance = () => {
    if (mapContainerRef.current !== null) {
      const newMap = L.map(mapContainerRef.current);
      setMap(newMap);
    }
  };

  useEffect(() => {
    if (mapContainerRef.current && mapContainerRef.current.childElementCount == 0) {
      createMapInstance();
    }

    if (map) {
      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution:
          'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors',
      }).addTo(map);

      // Inventarios
      const colorPalette = [
        '#FF1493', // Rosa
        '#FF4500', // Naranja Rojizo
        '#32CD32', // Verde Lima
        '#FF69B4', // Rosa Claro
        '#00BFFF', // Azul Claro
        '#FF0000', // Rojo
        '#FFA500', // Naranja
        '#FFFF00', // Amarillo
        '#0000FF', // Azul
      ];

      let bounds: L.LatLngBounds | null = null;

      actuaciones.forEach((actuacionData, index) => {
        const color = colorPalette[index % colorPalette.length]; // Obtener el color correspondiente a la ruta actual

        if (!actuacionData.coordenadas) {
          return;
        }

        if (actuacionData.coordenadas.startsWith('MultiPolygon')) {
          const polygons = parseMultiPolygon(actuacionData.coordenadas);

          const customStyle = {
            fillColor: color,
            color: 'black',
            weight: 2,
            opacity: 1,
            fillOpacity: 0.5,
          };

          // Itera sobre los polígonos y agrega cada uno al mapa
          polygons.forEach((polygonCoordinates: any[]) => {
            const polygon = L.polygon(polygonCoordinates, customStyle).addTo(map);

            const popupContent = createPopup(actuacionData);
            polygon.bindPopup(popupContent);

            if (!bounds) {
              bounds = polygon.getBounds();
            } else {
              bounds.extend(polygon.getBounds());
            }
          });
        } else if (actuacionData.coordenadas.startsWith('POINT')) {
          const latLng: number[] = parseCoordenadas(actuacionData.coordenadas);
          const latLngTuple: L.LatLngTuple = [latLng[0], latLng[1]];
          const latLngObject: L.LatLngExpression = latLngTuple;

          if (!bounds) {
            bounds = L.latLngBounds(latLngTuple, latLngTuple);
          } else {
            bounds.extend(latLngTuple);
          }

          // Pintamos el marcador del vehículo
          const feature = {
            name: 'Marcador ${index}',
            description: '',
          };

          const marker = createCustomIcon(
            feature,
            actuacionData.id.toString(),
            L.latLng(latLngObject),
            color,
            [30, 30],
            [15, 15],
            actuacionData,
          );
          marker.setZIndexOffset(1005);

          if (marker) {
            marker.addTo(map);
          }
        }
      });

      // Ajustar el zoom y el centro según los límites del mapa
      if (bounds) {
        map.fitBounds(bounds);
      }
    }
  }, [actuaciones, map]);

  return (
    <div ref={mapContainerRef} style={{ height: '70vh', zIndex: '0' }}>
      {mapContainerRef.current && mapContainerRef.current.childElementCount > 0 ? (
        <p>El contenedor del mapa ya está instanciado.</p>
      ) : (
        <></>
      )}
    </div>
  );
};

export default LeafletMap;
