import { ReactElement } from 'react';
import Col, { LazarusColProps } from '../Col';
import Row, { LazarusRowProps } from '../Row';
import DynamicInput, { DynamicInputMetadata } from '../DynamicInput';

export type DynamicFormMetadata<T> = {
  input: DynamicInputMetadata<T>;
  grid: LazarusColProps;
};
interface DynamicFormProps<T> extends Omit<LazarusRowProps, 'onChange' | 'children'> {
  metadata: DynamicFormMetadata<T>[];
  value: T;
  onChange: (newValue: T) => void;
}

export type FilterItem = { key: string; value?: string };

const DynamicForm = <T extends Record<string, any>>({
  metadata,
  onChange,
  value,
  ...props
}: DynamicFormProps<T>): ReactElement => {
  const handleValueChange = (event: [key: string, propValue: any][]) => {
    const newValue: Record<string, any> = { ...value };

    event.forEach(e => (newValue[e[0]] = e[1]));

    onChange(newValue as T);
  };

  return (
    <Row {...props}>
      {metadata.map((m, i) => (
        <Col {...m.grid} key={i}>
          <DynamicInput
            {...m.input}
            onChange={handleValueChange}
            value={value[m.input.propKey] || ''}
          />
        </Col>
      ))}
    </Row>
  );
};

export default DynamicForm;
