import { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Planificaciones, Programaciones } from '../../../../API/flotas';
import Button from '../../../../components/Button';
import Col from '../../../../components/Col';
import H1 from '../../../../components/H1';
import Row from '../../../../components/Row';
import Input from '../../../../components/Input';
import ImportFile from '../../../../components/ImportFile';
import Page, { PageProps } from '../../../../components/Page';
import { IconName } from '../../../../shared/types';
import { getCommunicationConfig, getFlotasCoreModule } from '../../shared/utils';
import { getMaestros } from './utils';
import { showSuccessAlert, showWarningAlert } from '../../../../libs/LazarusAlert';
import {
  MaestroGet,
  ProgramacionPlanificacion,
} from '../../../../API/flotas/programaciones/interfaces';

const PlanificacionesCommunicationsPage = (): JSX.Element => {
  const navigate = useNavigate();
  const flotaCoreModule = getFlotasCoreModule();

  // Valores de los campos del formulario de carga de programaciones
  const [nombreValue, setNombreValue] = useState('');
  const [fileValue, setFileValue] = useState<File | null>(null); // null si no se ha seleccionado un archivo
  const inputFile = useRef<HTMLInputElement>(null);
  const [selectedFileName, setSelectedFileName] = useState('');

  // Valores de los campos del formulario de programación de planificaciones
  const [maestroSelectValue, setMaestroSelectValue] = useState('');
  const [dateValue, setDateValue] = useState('');

  const [maestros, setMaestros] = useState<MaestroGet[]>([]);

  // Función para manejar el envío del formulario
  const handleMaestrosSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault(); // Evitar la recarga de la página

    const formData = new FormData();
    formData.append('nombre', nombreValue);
    if (fileValue !== null) {
      formData.append('file', fileValue);
    }

    // miramos si ya hay un maestro con ese nombre
    const data = await getMaestros([{ key: 'nombre', value: nombreValue }]); // Esperar a que la promesa se resuelva y obtener los datos
    if (data && data.length > 0) {
      showWarningAlert({
        title: `Ya existe el maestro "` + nombreValue + '"',
        text: 'Al continuar, se sobrescribirán las programaciones para este maestro',
      }).then(async result => {
        if (result.isConfirmed) {
          const response = await Programaciones.import(formData);
          if (response) {
            showSuccessAlert({
              title: 'Proceso ejecutado correctamente',
              text: response.message,
            });

            fetchMaestros();
          }
        }
      });
    } else {
      const response = await Programaciones.import(formData);
      if (response) {
        showSuccessAlert({
          title: 'Proceso ejecutado correctamente',
          text: response.message,
        });

        fetchMaestros();
      }
    }
  };

  const handleFileButtonClick = (e: React.MouseEvent) => {
    e.preventDefault();
    if (inputFile.current) {
      inputFile.current.click();
    }
  };

  const handleFileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setSelectedFileName(e.target.files[0].name);
      setFileValue(e.target.files[0]);
    }
  };

  const handleProgramacionesSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault(); // Evitar la recarga de la página
    const programacionData: ProgramacionPlanificacion = {
      id_maestro: parseInt(maestroSelectValue, 10),
      fecha: dateValue,
    };

    const response = await Programaciones.postProgramarPlanificaciones(programacionData);
    if (response) {
      showSuccessAlert({
        title: 'Proceso ejecutado correctamente',
        text: response.message,
      });
    }
  };

  const handleChangeDate = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedDate = new Date(e.target.value);
    const formattedDate = selectedDate.toISOString().split('T')[0];
    setDateValue(formattedDate);
  };

  if (!flotaCoreModule) return <></>;

  let pageProps: PageProps = {
    className: 'communications flota',
    titleType: 'title',
    children: undefined,
  };

  if (flotaCoreModule.submodulesGroups?.length > 0) {
    const breadcrumb = [
      {
        icon: 'water' as IconName,
        label: 'Flotas',
      },
      {
        label: 'Planificaciones',
      },
    ];

    const btnBackConfig = {
      onClick: () => navigate('/flotas/listado/planificaciones'),
    };

    pageProps = {
      ...pageProps,
      titleType: 'breadcrumb',
      breadcrumb,
      title: 'Flotas',
      icon: 'truck',
      btnBackConfig,
    };
  }

  // Función para obtener los nombres desde la API
  const fetchMaestros = async () => {
    try {
      const data = await getMaestros([]); // Esperar a que la promesa se resuelva y obtener los datos
      if (data) {
        setMaestros(data); // Actualizar el estado solo si se obtuvieron datos válidos
      }
    } catch (error) {
      console.error('Error al obtener los nombres desde la API:', error);
    }
  };

  useEffect(() => {
    fetchMaestros();
  }, []);

  return (
    <Page {...pageProps}>
      <H1>Planificaciones</H1>

      <ImportFile
        title="Importar"
        config={getCommunicationConfig('planificaciones')}
        importApi={Planificaciones.import}
        requirePermission={{ section: 'flotas', permission: 'import-flotas-planificaciones' }}
      />

      <hr></hr>

      <H1>Maestros de programaciones</H1>

      <h2 className="module-page__h2">Importar</h2>

      <form onSubmit={handleMaestrosSubmit}>
        <Row>
          <Col md={4}>
            <div className="import-btn__container">
              {/* Campo de texto libre para nombre del maestro de programación */}
              <Input
                type="text"
                value={nombreValue}
                onChange={e => setNombreValue(e.target.value)}
                required
                label="Nombre"
              />
            </div>
          </Col>

          <Col md={4}>
            <div className="import-btn__container">
              {/* Campo de archivo importado */}
              <Button onClick={handleFileButtonClick} color="primary">
                {selectedFileName ? 'Cargado: ' + selectedFileName : 'Adjuntar archivo'}
              </Button>
              <input
                ref={inputFile}
                onChange={handleFileInputChange}
                type="file"
                accept="application/zip"
                style={{ display: 'none' }}
              />
            </div>
          </Col>

          <Col md={4}>
            <div className="import-btn__container">
              {/* Botón de envio de formulario */}
              <Button
                type="submit"
                className="btn btn-primary btn-primary--outline"
                color="primary"
              >
                Enviar
              </Button>
            </div>
          </Col>
        </Row>
      </form>

      <hr></hr>

      <H1>Programaciones</H1>

      <h2 className="module-page__h2">Programar planificaciones</h2>

      <form onSubmit={handleProgramacionesSubmit}>
        <Row>
          <Col md={4}>
            <div className="import-btn__container input-group">
              <select
                value={maestroSelectValue}
                onChange={e => setMaestroSelectValue(e.target.value)}
                required
              >
                <option value="">Seleccione un maestro de programaciones</option>
                {maestros.map(elem => (
                  <option key={elem.id} value={elem.id}>
                    {elem.nombre}
                  </option>
                ))}
              </select>
            </div>
          </Col>

          <Col md={4}>
            <div className="import-btn__container">
              {/* Campo de calendario */}
              <Input
                type="date"
                onChange={handleChangeDate}
                value={dateValue}
                label="Fecha progamación"
                required
              />
            </div>
          </Col>

          <Col md={4}>
            <div className="import-btn__container">
              {/* Botón de envio de formulario */}
              <Button
                type="submit"
                className="btn btn-primary btn-primary--outline"
                color="primary"
              >
                Programar planificaciones
              </Button>
            </div>
          </Col>
        </Row>
      </form>
    </Page>
  );
};

export default PlanificacionesCommunicationsPage;
