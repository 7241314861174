import { useEffect, useState } from 'react';
import { appConfig } from '../config/app';
import { CommonModule } from '../config/interfaces';
import { basicRoutes, flotasRoutes } from '../pages/Flotas/routes';
import { gestionEnviosRoutes } from '../pages/GestionEnvios/routes';
import { homeRoutes } from '../pages/Home/routes';
import { limpiezaRoutes } from '../pages/Limpieza/routes';
import { riegoRoutes } from '../pages/Riego/routes';
import { rolesRoutes } from '../pages/Roles/routes';
import { userRoutes } from '../pages/Users/routes';
import { zonasVerdesRoutes } from '../pages/ZonasVerdes/routes';
import { useAppSelector } from '../redux/hooks';
import { Route, RouteModule, RouteWithSubmodules } from '../routes/routes';
import { useAuth } from './useAuth';

export type Operator = '&&' | '||';

export interface ModulePermission {
  section: string;
  permission: string | { permissions: string[]; operator: Operator };
}

export interface RequireModuelPermissions {
  communications?: ModulePermission;
  creates?: ModulePermission;
  lists?: ModulePermission;
  removes?: ModulePermission;
}

interface RouteConfig {
  availableRoutes: Route[];
  availableModules: CommonModule[];
  filterRouteModule: (routes: RouteModule, section?: string) => RouteModule;
  showModule: (modulePermission?: ModulePermission) => boolean;
}

export function useProtectedModules(): RouteConfig {
  const [availableRoutes, setAvailableRoutes] = useState<Route[]>([]);
  const [availableModules, setAvailableModules] = useState<CommonModule[]>([]);
  const user = useAppSelector(state => state.user.data);
  const { canUse } = useAuth();
  const { coreModules, commonModules } = appConfig;

  useEffect(() => {
    let routes = homeRoutes;
    if (canUse('view-users', 'app', 'Usuarios')) routes = routes.concat(userRoutes);
    if (canUse('view-roles', 'app', 'Roles')) routes = routes.concat(rolesRoutes);
    if (canUse('view-gestion-envios', 'app', 'Gestión de Envíos'))
      routes = routes.concat(gestionEnviosRoutes);
    if (canUse('app-zzvv', 'app', 'ZZVV'))
      routes = routes
        .concat(zonasVerdesRoutes.lists || [])
        .concat(zonasVerdesRoutes.communications || []);
    if (canUse('app-riego', 'app', 'Riego'))
      routes = routes
        .concat(riegoRoutes.lists || [])
        .concat(riegoRoutes.communications || [])
        .concat(riegoRoutes.creates || [])
        .concat(riegoRoutes.edits || []);
    if (canUse('app-limpieza', 'app', 'Limpieza'))
      routes = routes
        .concat(limpiezaRoutes.lists || [])
        .concat(limpiezaRoutes.communications || [])
        .concat(limpiezaRoutes.creates || [])
        .concat(limpiezaRoutes.edits || []);
    if (canUse('app-flotas', 'app', 'Flotas')) routes = routes.concat(flotasRoutes.lists || []);
    if (canUse('app-flotas', 'app', 'Flotas'))
      routes = routes.concat(flotasRoutes.communications || []);
    if (canUse('app-flotas', 'app', 'Flotas')) routes = routes.concat(basicRoutes || []);

    setAvailableRoutes(routes);
  }, [user]);

  useEffect(() => {
    setAvailableModules([
      ...coreModules.filter(coreModule => {
        if (!coreModule.active) return false;

        if (coreModule.requirePermission && !canUse(coreModule.requirePermission)) return false;

        return true;
      }),
      ...commonModules.filter(commonModule => {
        if (!commonModule.active) return false;

        if (commonModule.requirePermission && !canUse(commonModule.requirePermission)) return false;

        return true;
      }),
    ]);
  }, [user]);

  const filterRouteModule = (routeModule: RouteModule, section?: string): RouteModule => {
    const newRouteModule: RouteModule = {};
    Object.entries(routeModule).forEach(entry => {
      const moduleName = entry[0] as keyof RouteModule;
      const moduleRoutes = entry[1] as RouteWithSubmodules;

      newRouteModule[moduleName] = {
        ...moduleRoutes,
        submodules: moduleRoutes.submodules.filter((route: Route) => {
          if (!route.requirePermission || canUse(route.requirePermission, section)) return true;

          return false;
        }),
      };
    });

    return newRouteModule;
  };

  const showModule = (modulePermission?: ModulePermission): boolean => {
    if (!modulePermission) return true;
    if (typeof modulePermission.permission === 'string')
      return canUse(modulePermission.permission, modulePermission.section);

    if (modulePermission.permission.operator === '&&')
      return modulePermission.permission.permissions.every(permission =>
        canUse(permission, modulePermission.section),
      );

    return modulePermission.permission.permissions.some(permission =>
      canUse(permission, modulePermission.section),
    );
  };

  return { availableModules, availableRoutes, filterRouteModule, showModule };
}
