import { Route } from '../../routes/routes';
import ActuacionesCommunicationsPage from './Actuaciones/Communications';
import InventorioCommunicationsPage from './Inventario/Communications';
import PlanificacionesCommunicationsPage from './Planificaciones/Communications';
import UBLCommunicationsPage from './UBL/Communications';
import VehiculosCommunicationsPage from './Vehiculos/Communications';

export const communicationsRoutes: Route[] = [
  {
    name: 'Inventario',
    path: 'inventario',
    to: `inventario`,
    Component: InventorioCommunicationsPage,
    requirePermission: 'view-inventario',
  },
  {
    name: 'Actuaciones',
    path: 'actuaciones',
    to: `actuaciones`,
    Component: ActuacionesCommunicationsPage,
    requirePermission: 'view-actuaciones',
  },
  {
    name: 'Planificaciones',
    path: 'planificaciones',
    to: `planificaciones`,
    Component: PlanificacionesCommunicationsPage,
    requirePermission: 'view-planificacion-sectores',
  },
  {
    name: 'UBL',
    path: 'UBL',
    to: `UBL`,
    Component: UBLCommunicationsPage,
    requirePermission: 'view-ubl',
  },
  {
    name: 'Vehiculos',
    path: 'vehiculos',
    to: `vehiculos`,
    Component: VehiculosCommunicationsPage,
    requirePermission: 'view-vehiculos',
  },
];
