import { createApiInstance } from '../../../api';
import { FormInformeVehiculos } from '../interfaces';

export const execute = async (dataForCreate: FormInformeVehiculos): Promise<any> => {
  const api = createApiInstance();
  const { data } = await api.post<FormInformeVehiculos>(
    '/flotas/informes/vehiculos',
    dataForCreate,
  );

  return data;
};
