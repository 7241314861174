import { FC, ReactNode, useContext } from 'react';
import { Section } from '../../../API/permissions/interfaces';
import Checkbox from '../../../components/Checkbox';
import { RoleContext } from '../context';

interface Props {
  children: ReactNode;
  label: string;
  level: number;
  section: Section;
  subsection?: string;
}

const PermissionLevel: FC<Props> = ({ children, label, level, section, subsection }) => {
  const {
    activeSections,
    activeSubSections,
    handleCheckSubSection,
    handleCheckSection,
  } = useContext(RoleContext);

  const isChecked = (): boolean => {
    if (level === 1) return activeSections.includes(section);
    if (level === 2 && activeSubSections && subsection) {
      return activeSubSections[section].includes(subsection);
    }

    return false;
  };

  const onChecked = () => {
    if (level === 1) handleCheckSection(section);
    if (level === 2 && subsection) handleCheckSubSection(section, subsection);
  };

  return (
    <div className={`permissions__level${level}`}>
      <Checkbox
        defaultChecked={isChecked()}
        checked={isChecked()}
        onChecked={onChecked}
        label={label}
        readOnly
        value={isChecked() ? 'on' : undefined}
      />

      {children}
    </div>
  );
};

export default PermissionLevel;
