import { Dispositivo, MainInfo } from '../../../../API/riego/contadores/interfaces';
import { DynamicFormMetadata } from '../../../../components/DynamicForm';

interface UseMetadataResult {
  mainInfoMetadata: DynamicFormMetadata<MainInfo>[];
  dispositivosMetadata: DynamicFormMetadata<Dispositivo>[];
}

const useMetadata = (): UseMetadataResult => ({
  mainInfoMetadata,
  dispositivosMetadata,
});

export default useMetadata;

const dispositivosMetadata: DynamicFormMetadata<Dispositivo>[] = [
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Select',
      propKey: 'estaActivo',
      props: {
        label: '¿Está activo?',
        options: [
          { value: 'Sí', text: 'Sí' },
          { value: 'No', text: 'No' },
        ],
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'ultimaLectura',
      props: {
        label: 'Ultima lectura',
        type: 'text',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'fechaUltimaLectura',
      props: {
        label: 'FechaUltimaLectura',
        type: 'date',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'nuevaLectura',
      props: {
        label: 'Nueva lectura',
        type: 'text',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'fechaNuevaLectura',
      props: {
        label: 'Fecha nueva lectura',
        type: 'date',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'unidadMedidaActivo',
      props: {
        label: 'Unidad de medida activa',
        type: 'text',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'dispositivoMedida',
      props: {
        label: 'Dispositivo de medida',
        type: 'text',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'observacionesMedida',
      props: {
        label: 'Observaciones de la medida',
        type: 'text',
      },
    },
  },
];

const mainInfoMetadata: DynamicFormMetadata<MainInfo>[] = [
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'instalacion',
      props: {
        label: 'Instalación',
        type: 'text',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'instalacionCultivo',
      props: {
        label: 'Instalación Cultivo',
        type: 'text',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'zonaControl',
      props: {
        label: 'Zona de control',
        type: 'text',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'planta',
      props: {
        label: 'Planta',
        type: 'text',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'idInventario',
      props: {
        label: 'Id inventario Mint',
        type: 'text',
      },
    },
  },
];
