export const operadores = [
  { value: '=', text: 'Igual a' },
  { value: '>', text: 'Mayor que' },
  { value: '<', text: 'Menor que' },
  { value: '>=', text: 'Mayor o igual que' },
  { value: '<=', text: 'Menor o igual que' },
  { value: '!=', text: 'Distinto a' },
  { value: 'EW', text: 'Que contenga1' },
  { value: 'SW', text: 'Que contenga2' },
];
