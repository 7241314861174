import { ReactNode, useMemo } from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';
import { useNavigate } from 'react-router-dom';
import { Materiales } from '../../../../API/riego';
import { Material } from '../../../../API/riego/materiales/interfaces';
import Checkbox from '../../../../components/Checkbox';
import MaterialIconMenu, { MaterialIconMenuProps } from '../../../../components/MaterialIconMenu';
import NoResults from '../../../../components/NoResults';
import LazarusPagination from '../../../../components/Pagination';
import Filters, {
  DynamicFormMetadata,
  FilterItem,
  ModuleNavigations,
} from '../../../../components/Table/Filters';
import { useDatatable } from '../../../../hooks/useDatatable';

const addSelectedToRow = (row: Material): void => {
  document.getElementById(`row-${row.id}`)?.classList.add('active');
};

const removeSelectedToRow = (row: Material) => {
  document.getElementById(`row-${row.id}`)?.classList.remove('active');
};

const moduleNavigations: ModuleNavigations = {
  communications: `/riego/comunicaciones/materiales`,
  creates: `/riego/crear/materiales`,
};

const MaterialesListPage = (): JSX.Element => {
  const navigate = useNavigate();

  const {
    filteredItems,
    handleSetRows,
    handleChangeSelected,
    filterText,
    handleClear,
    handleChangeFilterText,
    selectedRows,
  } = useDatatable<Material>({
    removeSelectedToRow,
    addSelectedToRow,
  });

  const columns: TableColumn<Material>[] = useMemo(
    () => [
      {
        name: 'ID',
        selector: row => row.id,
        style: {
          maxWidth: '10%',
          minWidth: 50,
        },
      },
      {
        name: 'Descripción',
        selector: row => row.descripcion,
        style: {
          textAlign: 'center',
          maxWidth: '60%',
        },
      },
      {
        name: 'Cantidad',
        selector: row => row.cantidad || '',
        style: {
          maxWidth: '10%',
          // justifyContent: 'flex-end',
          minWidth: 50,
        },
      },
      {
        name: 'Tarifa',
        selector: row => (row.tarifa ? `€${row.tarifa}` : ''),
        style: {
          maxWidth: '10%',
          // justifyContent: 'flex-end',
          minWidth: 50,
        },
      },
      {
        cell: row => {
          const paramsToMenu: MaterialIconMenuProps<Material> = {
            row,
            size: 'medium',
            rows: selectedRows.length ? selectedRows : [row],
            onOpenMenu: () => addSelectedToRow(row),
            onCloseMenu: () => removeSelectedToRow(row),
            actions: [
              { label: 'Ver en mapa', onClick: row => console.log('Ver en mapa', row) },
              {
                label: 'Editar',
                onClick: row => navigate(`/riego/editar/materiales/${row.id}`),
                onlyApplyToOne: true,
              },
              { label: 'Enviar a MiNT', onClick: row => console.log('Enviar a MiNT', row) },
            ],
          };
          return <MaterialIconMenu {...paramsToMenu} />;
        },
        allowOverflow: true,
        button: true,
        width: '71px',
      },
    ],
    [selectedRows],
  );

  const SelectableCheckbox = (Checkbox as unknown) as ReactNode;

  const handleFilter = (filterItems: FilterItem[]) => {
    Materiales.get(filterItems).then(materialesResponse => handleSetRows(materialesResponse));
  };

  return (
    <div className="listado-materiales">
      <Filters
        handleClear={handleClear}
        filterText={filterText}
        handleChangeFilterText={handleChangeFilterText}
        handleFilter={handleFilter}
        metadata={filterMetadata}
        hasItems={!!filteredItems.length}
        moduleNavigations={moduleNavigations}
      />
      <DataTable
        responsive
        columns={columns}
        data={filteredItems}
        pagination
        selectableRows
        selectableRowsHighlight
        paginationPerPage={10}
        noDataComponent={<NoResults />}
        paginationComponent={LazarusPagination}
        onSelectedRowsChange={handleChangeSelected}
        selectableRowsComponent={SelectableCheckbox}
      />
    </div>
  );
};

export default MaterialesListPage;

const filterMetadata: DynamicFormMetadata[] = [
  {
    grid: { xs: 6, sm: 4, md: 2 },
    input: {
      tag: 'Input',
      propKey: 'descripcion_del_material',
      props: {
        label: 'Descripción del material',
        type: 'text',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 2 },
    input: {
      tag: 'Input',
      propKey: 'material_del_material',
      props: {
        label: 'Material del material',
        type: 'text',
      },
    },
  },
];
