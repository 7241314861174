export const campos = [
  { value: 'idInventario', text: 'Id inventario' },
  { value: 'idInventarioExterno', text: 'Id inventario externo' },
  { value: 'idInventarioInterno', text: 'Id inventario interno' },
  { value: 'estado', text: 'Estado' },
  { value: 'barrio', text: 'Barrio' },
  { value: 'barrioDescripcion', text: 'Barrio descripción' },
  { value: 'distrito', text: 'Distrito' },
  { value: 'distritoDescrpcion', text: 'Distrito descripción' },
  { value: 'lote', text: 'Lote' },
  { value: 'loteDescripcion', text: 'Lote Descripción' },
  { value: 'usuarioEditor', text: 'Usuario editor' },
  { value: 'descripcion', text: 'Descripción' },
  { value: 'descripcionLarga', text: 'Descripción larga' },
  { value: 'codigoPostal', text: 'Código postal' },
  { value: 'contrato', text: 'Contrato' },
  { value: 'contratoDescripcion', text: 'Contrato descripción' },
  { value: 'coordenadas', text: 'coordenadas' },
  { value: 'descripcionUbicacion', text: 'Descripción ubicación' },
  { value: 'jerarquiaClasificacion', text: 'Jerarquía clasificación' },
  { value: 'jerarquiaClasificacionDescripcion', text: 'Jerarquía clasificación descripcion' },
  { value: 'ndp', text: 'NDP' },
  { value: 'tipoVia', text: 'Tipo vía' },
  { value: 'nombreVia', text: 'Nombre vía' },
  { value: 'numeroVia', text: 'Número vía' },
  { value: 'observaciones', text: 'Observaciones' },
  { value: 'observacionesLargas', text: 'Observaciones largas' },
  { value: 'servicio', text: 'Servicio' },
  { value: 'servicioDescripcion', text: 'Servicio descripción' },
  { value: 'padre', text: 'Padre' },
  { value: 'claseFuncionGIS', text: 'Clase función GIS' },
  { value: 'posicionamientoMapa', text: 'Posicionamiento mapa' },
  { value: 'cordenadaXGIS', text: 'Coordenada X GIS' },
  { value: 'coordenadaYGIS', text: 'Coordenada Y GIS' },
  { value: 'fechaEstado', text: 'Fecha estado' },
];
