import { FC, useContext, useEffect, useState } from 'react';
import { Materiales } from '../../../../../../API/riego';
import H2 from '../../../../../../components/H2';
import OutlineButton from '../../../../../../components/OutlineButton';
import { Option } from '../../../../../../components/Select/index';
import { MaterialesContext } from '../../context';
import MaterialNoCodificado, { MaterialNoCodificadoData } from '../MaterialNoCodificado';

const MaterialesNoCodificados: FC = () => {
  const [materiales, setMateriales] = useState<Option[]>([]);
  const [materialesNoCodificados, setMaterialesNoCodificados] = useState<
    MaterialNoCodificadoData[]
  >([
    {
      materialId: '',
      cantidad: '',
    },
  ]);
  const { handleChange } = useContext(MaterialesContext);

  const handleChangeMaterial = (idx: number, material: MaterialNoCodificadoData) => {
    const newMateriales = [...materialesNoCodificados];
    newMateriales[idx] = material;
    setMaterialesNoCodificados(newMateriales);
    updateContext(newMateriales);
  };

  const updateContext = (newMateriales: MaterialNoCodificadoData[]) => {
    handleChange({
      name: 'idsElementosNoCodificados',
      value: newMateriales.map(m => m.materialId).join(','),
    });
    handleChange({
      name: 'cantidadesElementosNoCodificados',
      value: newMateriales.map(m => m.cantidad).join(','),
    });
  };

  const handleRemoveMaterial = (idx: number) => {
    const newMateriales = materialesNoCodificados.filter((m, i) => i !== idx);
    if (newMateriales.length === 0)
      setMaterialesNoCodificados([
        {
          materialId: '',
          cantidad: '',
        },
      ]);
    else setMaterialesNoCodificados(newMateriales);

    updateContext(newMateriales);
  };

  const addMaterial = () => {
    const isValidMateriales = materialesNoCodificados.every(m => m.materialId && m.cantidad);
    if (!isValidMateriales) return;

    setMaterialesNoCodificados([
      ...materialesNoCodificados,
      {
        materialId: '',
        cantidad: '',
      },
    ]);
  };

  useEffect(() => {
    Materiales.getNoCodificados().then(materialesNoCodificados => {
      setMateriales(materialesNoCodificados.map(m => ({ text: m.nombre, value: m.id })));
    });
  }, []);

  return (
    <div className="materiales-no-codificados">
      <H2>
        Materiales no codificados
        <OutlineButton color="primary" icon="plus" onClick={addMaterial}>
          Agregar
        </OutlineButton>
      </H2>

      {materialesNoCodificados.map((m, idx) => (
        <MaterialNoCodificado
          key={idx}
          idx={idx}
          materiales={materiales}
          material={m}
          handleChangeMaterial={handleChangeMaterial}
          handleRemoveMaterial={handleRemoveMaterial}
        />
      ))}
    </div>
  );
};

export default MaterialesNoCodificados;
