export const campos = [
  { value: 'denominacion', text: 'Denominación' },
  { value: 'descripcion', text: 'Descripción' },
  { value: 'descripcionLarga', text: 'Descripción larga' },
  { value: 'estado', text: 'Estado' },
  { value: 'claseFuncionGIS', text: 'Clase función GIS' },
  { value: 'coordenadas', text: 'Coordenadas' },
  { value: 'resumen', text: 'Resumen' },
  { value: 'fechaInicioPlanificada', text: 'Fecha inicio planificada' },
  { value: 'fechaFinPlanificada', text: 'Fecha fin planificada' },
  { value: 'loteMiNT', text: 'Lote MiNT' },
];
