import axios from 'axios';
import { createApiInstance } from '../../../api';
import {
  FechaFrecuencia,
  Frecuencia,
  MainInfo,
  TestigosFrecuencia,
  TramoPlanificado,
} from '../interfaces';
import { requestTransformer } from './requestTransformer';

export interface PostRequest {
  mainInfo: MainInfo;
  tramosPlanificados: TramoPlanificado[];
  frecuencias: Frecuencia[];
  fechasFrecuencias: FechaFrecuencia[];
  testigosFrecuencias: TestigosFrecuencia[];
}

export const execute = async (request: PostRequest): Promise<any> => {
  const res = await createApiInstance().post('/limpieza/planificacion-sector', request, {
    transformRequest: [requestTransformer, ...(axios.defaults.transformRequest as any)],
  });

  return res.data;
};
