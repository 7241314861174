import axios from 'axios';
import { createApiInstance } from '../../../api';
import { Documento } from '../../../shared/interfaces';
import { EspecificacionTecnica } from '../../../shared/inventarios/interfaces';
import { AtributoObservacion, ElementoRelacionado, MainInfo, PiezaRecambio } from '../interfaces';
import { requestTransformer } from './requestTransformer';

export interface PostRequest {
  mainInfo: MainInfo;
  documentos: Documento[];
  atributosObservacion: AtributoObservacion[];
  elementosRelacionados: ElementoRelacionado[];
  especificacionesTecnicas: EspecificacionTecnica[];
  piezasRecambio: PiezaRecambio[];
}

export const execute = async (request: PostRequest): Promise<any> => {
  const res = await createApiInstance().post('/riego/inventario', request, {
    transformRequest: [requestTransformer, ...(axios.defaults.transformRequest as any)],
  });

  return res.data;
};
