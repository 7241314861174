/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { FC, useState } from 'react';
import Button from '../../../components/Button';
import Input from '../../../components/Input';
import Modal from '../../../components/Modal';
import ModalBody from '../../../components/Modal/ModalBody';
import ModalFooter from '../../../components/Modal/ModalFooter';
import ModalTitle from '../../../components/Modal/ModalTitle';
import { showSuccessAlert, showErrorAlert } from '../../../libs/LazarusAlert';
import { GestionEnvios } from '../../../API';
import { appConfig } from '../../../config/app';

interface Props {
  title: string;
  openModal: boolean;
  handleOpenModal: (ev?: React.MouseEvent | React.FormEvent) => void;
}

const DescargaXMLModal: FC<Props> = ({ title, openModal, handleOpenModal }) => {
  const [fecha, setFecha] = useState<string>('');

  const handleChange = (value: string) => {
    setFecha(value);
  };

  const handleSave = (ev: { preventDefault: () => void }) => {
    ev.preventDefault();

    GestionEnvios.getArchivosXML(fecha).then(res => {
      if (res.file) {
        window.open(`${appConfig.apiConfig.baseURL}/${res.file.replace('../', '')}`, '_blank');
        showSuccessAlert({ title: 'Descarga de ficheros finalizada' });
        handleOpenModal();
      } else {
        showErrorAlert({
          text: 'No se ha podido obtener el archivo de descarga desde el servidor',
        });
      }
    });
  };

  return (
    <Modal open={openModal} handleOpen={handleOpenModal}>
      <ModalTitle>{title}</ModalTitle>
      <form className="form-user" onSubmit={handleSave} autoComplete="off">
        <ModalBody>
          <Input
            onChange={(ev: { target: { value: string } }) => handleChange(ev.target.value)}
            type="date"
            label="Descripción"
          />
        </ModalBody>
        <ModalFooter>
          <Button onClick={handleOpenModal} color="primary" outline>
            Cancelar
          </Button>
          <Button color="primary">Descargar ficheros</Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default DescargaXMLModal;
