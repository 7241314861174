export const campos = [
  { value: 'unidadGestion', text: 'Unidad gestión' },
  { value: 'descripcion', text: 'Descripción' },
  { value: 'descripcionLarga', text: 'Descripción larga' },
  { value: 'codigoDistrito', text: 'Código distrito' },
  { value: 'barrio', text: 'Barrio' },
  { value: 'estado', text: 'Estado' },
  { value: 'claseFuncionGIS', text: 'Clase función GIS' },
  { value: 'coordenadas', text: 'Coordenadas' },
  { value: 'resumen', text: 'Resumen' },
  { value: 'detalleResumen', text: 'Detalle resumen' },
];
