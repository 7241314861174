import { Dispatch, SetStateAction, useContext, useState } from 'react';

interface ContextBase<T> {
  rows: T[];
  setRows: Dispatch<SetStateAction<T[]>>;
}

interface LazarusDatatable<T> {
  handleClear: () => void;
  rows: T[];
  resetPaginationToggle: boolean;
  filterText: string;
  selectedRows: T[];
  filteredItems: T[];
  handleChangeSelected: ({ selectedRows }: ChangeSelected<T>) => void;
  handleSetRows: (rows: T[]) => void;
  handleChangeFilterText: (text: string) => void;
}

export interface ChangeSelected<T> {
  allSelected: boolean;
  selectedCount: number;
  selectedRows: T[];
}

interface Props<T> {
  removeSelectedToRow?: (row: T) => void;
  addSelectedToRow?: (row: T) => void;
  initialFilters?: any;
  context?: React.Context<ContextBase<T>>;
}

export function useDatatable<T>({
  removeSelectedToRow,
  addSelectedToRow,
  context,
}: Props<T>): LazarusDatatable<T> {
  let [rows, setRows] = useState<T[]>([]);
  if (context) {
    const { rows: contextRows, setRows: contextSetRows } = useContext(context);
    rows = contextRows;
    setRows = contextSetRows;
  }
  const [filterText, setFilterText] = useState<string>('');
  const [selectedRows, setSelectedRows] = useState<T[]>([]);
  const [resetPaginationToggle, setResetPaginationToggle] = useState<boolean>(false);

  const handleClear = () => {
    if (filterText) {
      setResetPaginationToggle(!resetPaginationToggle);
      setFilterText('');
    }
  };

  const handleSetRows = (rows: T[]) => {
    setRows(rows);
  };

  const handleChangeFilterText = (text: string) => {
    setFilterText(text);
  };

  const filteredItems = rows.filter(item => {
    const data = JSON.stringify(item).toLocaleLowerCase();
    if (filterText === '') return true;

    const words = filterText.split(' ').filter(word => word.length > 0);

    const hits = words.map(word => data.indexOf(word.toLocaleLowerCase()) !== -1);
    return hits.some(hit => hit);
  });

  const handleChangeSelected = ({ selectedRows }: ChangeSelected<T>) => {
    setSelectedRows(selectedRows);
    if (removeSelectedToRow) {
      rows.forEach(row => {
        removeSelectedToRow(row);
      });
    }

    if (addSelectedToRow) {
      selectedRows.forEach(selected => {
        addSelectedToRow(selected);
      });
    }
  };

  /* const handleChangeSelectedWithLimit = ({ selectedRows }: ChangeSelected<T>) => {
    const limit = 3;
    if (selectedRows.length <= limit) {
      setSelectedRows(selectedRows);
      if (removeSelectedToRow) {
        rows.forEach(row => {
          removeSelectedToRow(row);
        });
      }

      if (addSelectedToRow) {
        selectedRows.forEach(selected => {
          addSelectedToRow(selected);
        });
      }
    } else {

    }
  };*/

  return {
    handleSetRows,
    handleChangeSelected,
    //handleChangeSelectedWithLimit,
    handleChangeFilterText,
    handleClear,
    filteredItems,
    rows,
    selectedRows,
    resetPaginationToggle,
    filterText,
  };
}
