import { PostRequest } from '.';
import { arrayToNumberedObject } from '../../../shared/utils';

export const requestTransformer = (request: PostRequest): FormData => {
  const form = new FormData();
  const requestItems: Record<string, any> = {
    ...request.mainInfo,
    ...arrayToNumberedObject(request.especificacionesTecnicas, 'idEspecificacionTecnica'),
    ...arrayToNumberedObject(request.documentos, 'idDocumentoLista'),
    ...arrayToNumberedObject(request.elementosRelacionados, 'idElementoRelacionado'),
    ...arrayToNumberedObject(request.piezasRecambio, 'idPiezaRecambio'),
    ...arrayToNumberedObject(request.atributosObservacion, 'idAtributoObservacion'),
  };

  form.append('_method', 'POST');
  Object.entries(requestItems).forEach(([key, value]) => form.append(key, value));

  if (!request.mainInfo.servicio) form.append('servicio', '10');
  if (!request.mainInfo.id) form.append('id', '-1');
  if (!request.mainInfo.id_elemento_inventario) form.append('id_elemento_inventario', '-1');
  form.append('usuarioEditor', ''); //ESTE CAMPO NO IMPACTA
  form.append('multi', '');
  form.append('multiItems', '');

  return form;
};
