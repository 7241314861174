import { PostRequest } from '.';
import { arrayToNumberedObject } from '../../../shared/utils';

export const requestTransformer = (request: PostRequest): FormData => {
  const form = new FormData();
  const requestItems: Record<string, any> = {
    ...request.mainInfo,
    ...arrayToNumberedObject(request.frecuencias, 'idFrecuenciaLimpieza'),
    ...arrayToNumberedObject(request.tramosPlanificados, 'idFrecuenciaTramo'),
    ...arrayToNumberedObject(request.fechasFrecuencias, 'idFrecuenciaFechas'),
    ...arrayToNumberedObject(request.testigosFrecuencias, 'idFrecuenciaTestigos'),
  };

  Object.entries(requestItems).forEach(([key, value]) => form.append(key, value));
  if (!request.mainInfo.id) form.append('id', '-1');

  form.append('multi', '');
  form.append('multiItems', '');

  return form;
};
