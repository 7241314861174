import { FC, ReactNode } from 'react';
import cn from 'classnames';
import { Color, IconName } from '../../shared/types';
import Icon from '../Icon';

interface BorderRadiusConfig {
  topLeft?: string;
  topRight?: string;
  bottomLeft?: string;
  bottomRight?: string;
}

interface Props {
  /**
   * Color de botón
   */
  color: Color;

  /**
   * Icono a mostrar
   */
  icon: IconName;

  /**
   * Ocupar 100% de su contenedor
   */
  block?: boolean;

  /**
   * Button Large
   */
  lg?: boolean;

  /**
   * Aplicar sombra
   */
  boxShadow?: boolean;

  /**
   * Boton activo
   */
  active?: boolean;

  /**
   * Agregar clases de css propias
   */
  className?: string;

  /**
   * Configuración de redondeo de bordes
   */
  borderRadius?: BorderRadiusConfig;

  /**
   * Contenido del botón
   */
  children?: ReactNode;
}

const OutlineButton: FC<Props & React.ButtonHTMLAttributes<HTMLButtonElement>> = ({
  color,
  icon,
  block,
  boxShadow,
  children,
  className,
  active,
  lg,
  borderRadius,
  ...props
}) => {
  const classNames = cn(
    'btn-outline',
    {
      'btn-outline__block': block,
      'btn-outline__lg': lg,
      [`btn-outline__${color}`]: color,
      'box-shadow': boxShadow,
      active: active,
    },
    className,
  );

  const styles: React.CSSProperties = {
    borderTopLeftRadius: borderRadius?.topLeft || '0px',
    borderTopRightRadius: borderRadius?.topRight || '0px',
    borderBottomLeftRadius: borderRadius?.bottomLeft || '0px',
    borderBottomRightRadius: borderRadius?.bottomRight || '0px',
    ...props.style,
  };

  return (
    <button style={styles} className={classNames} {...props}>
      <div className="btn-outline__container">
        <Icon name={icon} color={color} />
        {children}
      </div>
    </button>
  );
};

export default OutlineButton;
