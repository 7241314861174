export const campos = [
  { value: 'idUBL', text: 'Id. UBL' },
  { value: 'descripcion', text: 'Descripción de la UBL' },
  { value: 'descripcionLarga', text: 'Descripción ampliada de la UBL' },
  { value: 'codigoDistrito', text: 'Distrito' },
  { value: 'codigoBarrio', text: 'Barrio' },
  { value: 'claseFuncionGIS', text: 'Clase de función' },
  { value: 'coordenadas', text: 'Coordenadas' },
  { value: 'inicioUBL', text: 'Inicio UBL' },
  { value: 'finUBL', text: 'Fin UBL' },
  { value: 'metrosLineales', text: 'Metros lineales' },
  { value: 'metrosCuadrados', text: 'Metros cuadrados' },
  { value: 'longitud', text: 'Longitud' },
];
