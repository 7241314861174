import { lazy } from 'react';
import { RouteModule } from '../../routes/routes';
import { communicationsRoutes } from './routes.communications';

export const basePath = '/zonas-verdes';

const LazyCommunicationsLayout = lazy(
  () => import(/* webpackChunkName: "ZonasVerdesLayout" */ './Layouts/CommunicationsLayout'),
);

export const zonasVerdesRoutes: RouteModule = {
  communications: {
    path: `/zonas-verdes/comunicaciones/*`,
    to: `/zonas-verdes/comunicaciones/`,
    Component: LazyCommunicationsLayout,
    name: 'Zonas Verdes - Comunicaciones Layout',
    submodules: communicationsRoutes,
  },
};
