import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import cn from 'classnames';
import { setOpenMenu, setOpenUserMenu } from '../../redux/HeaderSlice/headerSlice';

const BlockOpenMenu = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const openUserMenu = useAppSelector(state => state.header.openUserMenu);
  const openMenu = useAppSelector(state => state.header.openMenu);
  const showBlock = openUserMenu || openMenu;

  const handleClick = () => {
    dispatch(setOpenMenu({ openMenu: false }));
    dispatch(setOpenUserMenu({ openUserMenu: false }));
  };

  const classNames = cn('dark-opacity', {
    show: showBlock,
  });

  return <div className={classNames} onClick={handleClick}></div>;
};

export default BlockOpenMenu;
