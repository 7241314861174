import { getKeyValue } from '../../../../shared/utils';
import { mapEstadoColor } from '../../../shared/utils';
import { GetRiegoContadorResponse, Contador } from '../interfaces';

export const responseTransformer = (response: string): Contador[] => {
  const responseJSON: GetRiegoContadorResponse = JSON.parse(response);

  return responseJSON.elementosContador.map(contador => {
    const dispositivoMedida =
      contador.dispositivo_medida_elemento_contador.length > 0
        ? contador.dispositivo_medida_elemento_contador[0]
        : { ultimaLectura: null, fechaUltimaLectura: null };

    return {
      id: contador.id,
      planta: contador.planta || '',
      ultimaLectura: dispositivoMedida.ultimaLectura || '',
      fechaUltimaLectura: dispositivoMedida.fechaUltimaLectura || '',
      estado: {
        color: getKeyValue(contador.estado_general.nombre)(mapEstadoColor),
        label: contador.estado_general.nombre,
      },
    };
  });
};
