import { createApiInstance } from '../../api';
import { ImportExportResult } from '../../shared/interfaces';
import { responseTransformer } from './response-transformer';

export const execute = async (formData: FormData): Promise<ImportExportResult[]> => {
  const api = createApiInstance();
  const { data } = await api.post<ImportExportResult[]>('/riego/import', formData, {
    transformResponse: [responseTransformer],
  });

  return data;
};
