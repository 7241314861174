import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { post } from '../../../../API/limpieza/planificaciones';
import {
  Frecuencia,
  TramoPlanificado,
  MainInfo,
  FechaFrecuencia,
  TestigosFrecuencia,
} from '../../../../API/limpieza/planificaciones/interfaces';
import BoxComplexInput from '../../../../components/BoxComplexInput';
import Button from '../../../../components/Button';
import Col from '../../../../components/Col';
import CollapsibleSection from '../../../../components/CollapsibleSection';
import DynamicForm from '../../../../components/DynamicForm';
import H1 from '../../../../components/H1';
import Row from '../../../../components/Row';
import { showErrorAlert, showSuccessAlert } from '../../../../libs/LazarusAlert';
import useMetadata from './hooks';
import useEdit from '../../../../hooks/useEdit';
import { getById } from '../../../../API/limpieza/planificaciones';

const PlanificacionesCreatePage = (): JSX.Element => {
  const [frecuencias, setFrecuencias] = useState<Frecuencia[]>([]);
  const [tramosPlanificados, setTramosPlanificados] = useState<TramoPlanificado[]>([]);
  const [mainInfo, setMainInfo] = useState<MainInfo>({} as MainInfo);
  const [loading, setLoading] = useState(false);
  const [fechasFrecuencias, setFechasFrecuencias] = useState<FechaFrecuencia[]>([]);
  const [testigosFrecuencias, setTestigosFrecuencias] = useState<TestigosFrecuencia[]>([]);

  const navigate = useNavigate();
  const dataToEdit = useEdit(getById, '/limpieza/listado/actuaciones');

  const {
    mainInfoMetadata,
    tramosPlanificadosMetadata,
    frecuenciasMetadata,
    fechasFrecuenciasMetadata,
    testigosFrecuenciasMetadata,
  } = useMetadata();

  useEffect(() => {
    console.log('dataToEdit', dataToEdit);
    if (dataToEdit) {
      setMainInfo(dataToEdit.mainInfo);
      setFechasFrecuencias(dataToEdit.fechasFrecuencias);
      setTestigosFrecuencias(dataToEdit.testigosFrecuencias);
      setTramosPlanificados(dataToEdit.tramosPlanificados);
      setFrecuencias(dataToEdit.frecuencias);
    }
  }, [dataToEdit]);

  const handleSubmit = () => {
    const request = {
      mainInfo,
      tramosPlanificados,
      frecuencias,
      fechasFrecuencias: frecuencias.length ? fechasFrecuencias : [],
      testigosFrecuencias: frecuencias.length ? testigosFrecuencias : [],
    };

    setLoading(true);
    post(request)
      .then(() => {
        showSuccessAlert({ title: '¡Éxito!', text: 'Planificación guardado con éxito.' });
        navigate('/limpieza/listado/planificaciones');
      })
      .catch(() => {
        showErrorAlert({ title: 'Error', text: 'Error al guardar la planificación.' });
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      <H1>Datos</H1>
      <CollapsibleSection title="Información principal">
        <DynamicForm
          spacing="lg"
          onChange={setMainInfo}
          metadata={mainInfoMetadata}
          value={mainInfo}
        />
      </CollapsibleSection>
      <Row style={{ marginBottom: 30 }}>
        <BoxComplexInput
          closedMessage={`Hay ${tramosPlanificados.length} tramos planificados.`}
          metadata={tramosPlanificadosMetadata}
          onRemove={row => setTramosPlanificados(cur => cur.filter(c => c !== row))}
          onAdd={row => setTramosPlanificados(cur => [...cur, row])}
          label="Tramos planificados"
          dataSource={tramosPlanificados}
        />
      </Row>
      <Row style={{ marginBottom: 30 }}>
        <BoxComplexInput
          closedMessage={`Hay ${frecuencias.length} frecuencias.`}
          metadata={frecuenciasMetadata}
          onRemove={row => setFrecuencias(cur => cur.filter(c => c !== row))}
          onAdd={row => setFrecuencias(cur => [...cur, row])}
          label="Frecuencias"
          dataSource={frecuencias}
        />
      </Row>
      {!!frecuencias.length && (
        <>
          <Row style={{ marginBottom: 30 }}>
            <BoxComplexInput
              closedMessage={`Hay ${fechasFrecuencias.length} fechas de frecuencias.`}
              metadata={fechasFrecuenciasMetadata}
              onRemove={row => setFechasFrecuencias(cur => cur.filter(c => c !== row))}
              onAdd={row => setFechasFrecuencias(cur => [...cur, row])}
              label="Fechas de frecuencias"
              dataSource={fechasFrecuencias}
            />
          </Row>
          <Row style={{ marginBottom: 30 }}>
            <BoxComplexInput
              closedMessage={`Hay ${testigosFrecuencias.length} testigos de frecuencias.`}
              metadata={testigosFrecuenciasMetadata}
              onRemove={row => setTestigosFrecuencias(cur => cur.filter(c => c !== row))}
              onAdd={row => setTestigosFrecuencias(cur => [...cur, row])}
              label="Testigos de frecuencias"
              dataSource={testigosFrecuencias}
            />
          </Row>
        </>
      )}
      <Row justifyContent="center">
        <Col xs={3} sm={2}>
          <Button color="primary" disabled={loading} outline>
            Cancelar
          </Button>
        </Col>
        <Col xs={3} sm={2}>
          <Button color="primary" onClick={handleSubmit} disabled={loading}>
            Guardar
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default PlanificacionesCreatePage;
