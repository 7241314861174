import { Material, GetRiegoMaterialResponse } from '../interfaces';

export const responseTransformer = (response: string): Material[] => {
  const responseJSON: GetRiegoMaterialResponse = JSON.parse(response);

  return responseJSON.materialActuacion.map(material => ({
    id: material.id,
    descripcion: material.descripcion || '',
    cantidad: material?.materiales_actuaciones?.cantidad,
    tarifa: material?.materiales_actuaciones?.tarifaMaterialCodificado,
  }));
};
