import Swal, { SweetAlertResult } from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Icon from '../../components/Icon';
import { appConfig } from '../../config/app';

const LazarusSwal = withReactContent(Swal);
const { theme } = appConfig;

const defaultOptions = {
  showCloseButton: true,
  showConfirmButton: true,
  reverseButtons: true,
  buttonsStyling: false,
  allowOutsideClick: false,
  allowEscapeKey: false,
};

interface LazarusAlertProps {
  title?: string;
  text?: string;
}

interface LazarusWarningAlertProps {
  confirmButtonText?: string;
  cancelButtonText?: string;
}

interface LazarusSuccessAlertProps {
  confirmButtonText?: string;
}

export const showWarningAlert = ({
  title,
  text,
  confirmButtonText = 'Continuar',
  cancelButtonText = 'Cancelar',
}: LazarusAlertProps & LazarusWarningAlertProps): Promise<SweetAlertResult> => {
  return LazarusSwal.fire({
    title,
    text,
    iconHtml: <Icon name="alert" color="warning" />,
    customClass: {
      confirmButton: 'btn btn-warning',
      cancelButton: 'btn btn-warning--outline',
      container: `theme--${theme}`,
    },
    confirmButtonText,
    cancelButtonText,
    showCancelButton: true,
    ...defaultOptions,
  });
};

export const showErrorAlert = ({
  title,
  text,
  confirmButtonText = 'OK',
}: LazarusAlertProps & LazarusWarningAlertProps): Promise<SweetAlertResult> => {
  return LazarusSwal.fire({
    title,
    text,
    iconHtml: <Icon name="remove" color="danger" />,
    customClass: {
      confirmButton: 'btn btn-danger',
      container: `theme--${theme}`,
    },
    confirmButtonText,
    ...defaultOptions,
  });
};

export const showSuccessAlert = ({
  title,
  text,
  confirmButtonText = 'Entendido',
}: LazarusAlertProps & LazarusSuccessAlertProps): Promise<SweetAlertResult> => {
  return LazarusSwal.fire({
    title,
    text,
    iconHtml: <Icon name="check" color="success" />,
    customClass: {
      confirmButton: 'btn btn-success',
      cancelButton: 'btn btn-success--outline',
      container: `theme--${theme}`,
    },
    confirmButtonText,
    ...defaultOptions,
  });
};
