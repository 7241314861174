import { getKeyValue } from '../../../../shared/utils';
import { mapEstadoColor } from '../../../shared/utils';
import { Actuacion, GetLimpiezaActuacionesResponse } from '../interfaces';

export const responseTransformer = (response: string): Actuacion[] => {
  const responseJSON: GetLimpiezaActuacionesResponse = JSON.parse(response);

  return responseJSON.actuaciones.map(
    ({ actuacion }): Actuacion => ({
      id: actuacion.id,
      idActuacion: actuacion.idActuacion,
      barrio: actuacion?.barrio || 0,
      distrito: actuacion?.distrito || 0,
      codigoPostal: actuacion?.codigoPostal || 0,
      contrato: actuacion?.contrato || 0,
      contratoDescripcion: actuacion?.contratoDescripcion || '',
      ndp: actuacion?.ndp || 0,
      lote: actuacion?.lote || 0,
      loteDescripcion: actuacion?.loteDescripcion || '',
      jerarquiaClasificacion: actuacion?.jerarquiaClasificacion || '',
      nombreVia: actuacion?.nombreVia || '',
      nroVia: actuacion?.numeroVia || 0,
      tipoVia: actuacion?.tipoVia || '',
      estado: {
        color: getKeyValue(actuacion.estado_general.nombre)(mapEstadoColor),
        label: actuacion.estado_general.nombre,
      },
      claseFuncionGIS: actuacion?.claseFuncionGIS || '',
      descripcion: actuacion?.descripcion || '',
      coordenadas: actuacion?.coordenadas || '',
      coordenadaX: actuacion?.coordenadaX || '',
      coordenadaY: actuacion?.coordenadaY || '',
    }),
  );

  return [];
};
