import { Route } from '../../routes/routes';
import PlanificacionesListPage from './Planificaciones/List';
import ActuacionesListPage from './Actuaciones/List';
import ActuacionesMapPage from './Actuaciones/Map';
import InventorioListPage from './Inventario/List';
import InventarioMapPage from './Inventario/Map';
import UBLCommunicationsPage from './UBL/Communications';
import VehiculosCommunicationsPage from './Vehiculos/Communications';

export const listsRoutes: Route[] = [
  {
    name: 'Inventario',
    path: 'inventario',
    to: `inventario`,
    Component: InventorioListPage,
    MapComponent: InventarioMapPage,
    requirePermission: 'view-inventario',
  },
  {
    name: 'Actuaciones',
    path: 'actuaciones',
    to: `actuaciones`,
    Component: ActuacionesListPage,
    MapComponent: ActuacionesMapPage,
    requirePermission: 'view-actuaciones',
  },
  {
    name: 'Planificaciones',
    path: 'planificaciones',
    to: `planificaciones`,
    Component: PlanificacionesListPage,
    requirePermission: 'view-planificacion-sectores',
  },
  {
    name: 'UBL',
    path: '/limpieza/comunicaciones/UBL',
    to: `/limpieza/comunicaciones/UBL`,
    Component: UBLCommunicationsPage,
    requirePermission: 'view-ubl',
  },
  {
    name: 'Vehiculos',
    path: '/limpieza/comunicaciones/vehiculos',
    to: `/limpieza/comunicaciones/vehiculos`,
    Component: VehiculosCommunicationsPage,
    requirePermission: 'view-vehiculos',
  },
];
