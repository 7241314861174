import { FC, ReactNode } from 'react';
import cn from 'classnames';
import { Color, IconName } from '../../shared/types';
import Icon from '../Icon';

interface Props {
  /**
   * Boton con fondo transparente
   */
  outline?: boolean;

  /**
   * Icono de botón
   */
  icon?: IconName;

  /**
   * Color de botón
   */
  color: Color;

  /**
   * Ocupar 100% de su contenedor
   */
  block?: boolean;

  /**
   * Agregar clases de css propias
   */
  className?: string;

  /**
   * Contenido del botón
   */
  children: ReactNode;
}

const Button: FC<Props & React.ButtonHTMLAttributes<HTMLButtonElement>> = ({
  outline,
  icon,
  color,
  block,
  children,
  className,
  ...props
}) => {
  const classNames = cn(
    'btn',
    {
      btn__block: block,
      [`btn-${color}`]: color,
      [`btn-${color}--outline`]: color && outline,
      [`btn--outline`]: !color && outline,
    },
    className,
  );

  return (
    <button className={classNames} {...props}>
      {icon && <Icon name={icon} color={color} />}
      {children}
    </button>
  );
};

export default Button;
