import { createApiInstance } from '../../api';
import { CargaPaginate, GetFilter } from '../interfaces';
import { responseTransformer } from './response-transformer';

export const execute = async (filters: GetFilter): Promise<CargaPaginate> => {
  const api = createApiInstance();
  const { data } = await api.get<CargaPaginate>('/cargas-envios/data', {
    params: filters,
    transformResponse: [responseTransformer],
  });

  return data;
};
