import React, { Dispatch, SetStateAction } from 'react';
import { Inventario } from '../../API/limpieza/inventarios/interfaces';
import { Actuacion } from '../../API/limpieza/actuaciones/interfaces';

interface InventarioContext {
  inventarios: Inventario[];
  setInventarios: Dispatch<SetStateAction<Inventario[]>>;
}

const inventariosInitialState = {
  inventarios: [],
  setInventarios: () => null,
};

interface ActuacionContext {
  actuaciones: Actuacion[];
  setActuaciones: Dispatch<SetStateAction<Actuacion[]>>;
}

const actuacionesInitialState = {
  actuaciones: [],
  setActuaciones: () => null,
};

interface RowsContext {
  rows: Inventario[];
  setRows: Dispatch<SetStateAction<Inventario[]>>;
}

interface RowsActuacionesContext {
  rows: Actuacion[];
  setRows: Dispatch<SetStateAction<Actuacion[]>>;
}

const rowInitialState = {
  rows: [],
  setRows: () => null,
};

const rowActuacionesInitialState = {
  rows: [],
  setRows: () => null,
};

export const InventariosContext = React.createContext<InventarioContext>(inventariosInitialState);
export const ActuacionesContext = React.createContext<ActuacionContext>(actuacionesInitialState);

export const RowsContext = React.createContext<RowsContext>(rowInitialState);
export const RowsActuacionesContext = React.createContext<RowsActuacionesContext>(
  rowActuacionesInitialState,
);
