import React, { useState, useEffect, useContext } from 'react';
import LeafletMap from './Components/LeafletMap';
import { getCartografia, getPlanificacionesUtils } from './utils';
import { PlanificacionMapa, GeoJSONLayer } from './interface';
import { PlanificacionesContext } from '../../context';

const PlanificacionMapPage = () => {
  const [geoJSONLayers, setGeoJSONLayers] = useState<GeoJSONLayer[]>([]);
  const [planning, setPlanning] = useState<PlanificacionMapa[]>([]);

  const { planificaciones } = useContext(PlanificacionesContext);

  const center: [number, number] = [40.4920557, -3.6338581];
  const zoom = 13;

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (planificaciones.length > 0) {
          // Ejecutar las dos funciones asincrónicas en paralelo
          const [geoJSONLayers, planning] = await Promise.all([
            getCartografia(),
            getPlanificacionesUtils(planificaciones),
          ]);

          // Verificar si se recibieron las capas de cartografía
          if (geoJSONLayers) {
            setGeoJSONLayers(geoJSONLayers);
          } else {
            setGeoJSONLayers([]);
          }

          // Verificar si se recibieron las planificaciones
          if (planning) {
            setPlanning(planning);
          } else {
            setPlanning([]);
          }
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchData();
  }, [planificaciones]);

  return (
    <div>
      {geoJSONLayers.length > 0 && planning.length > 0 && (
        <LeafletMap center={center} zoom={zoom} routes={planning} geoJSONLayers={geoJSONLayers} />
      )}
    </div>
  );
};

export default PlanificacionMapPage;
