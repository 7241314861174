import { Route } from '../../routes/routes';
import ActuacionesCommunicationsPage from './Actuaciones/Communications';
import ContadoresCommunicationsPage from './Contadores/Communications';
import InventorioCommunicationsPage from './Inventario/Communications';
import PuntosDeSuministroCommunicationsPage from './PuntosDeSuministro/Communications';
import MaterialesCommunicationsPage from './Materiales/Communications';

export const communicationsRoutes: Route[] = [
  {
    name: 'Inventario',
    path: 'inventario',
    to: `inventario`,
    Component: InventorioCommunicationsPage,
    requirePermission: 'view-inventario',
  },
  {
    name: 'Actuaciones',
    path: 'actuaciones',
    to: `actuaciones`,
    Component: ActuacionesCommunicationsPage,
    requirePermission: 'view-actuaciones',
  },
  {
    name: 'Puntos de Suministro',
    path: 'puntos-de-suministro',
    to: `puntos-de-suministro`,
    Component: PuntosDeSuministroCommunicationsPage,
    requirePermission: 'view-puntos-suministro',
  },
  {
    name: 'Contadores',
    path: 'contadores',
    to: `contadores`,
    Component: ContadoresCommunicationsPage,
    requirePermission: 'view-contadores',
  },
  {
    name: 'Materiales',
    path: 'materiales',
    to: `materiales`,
    Component: MaterialesCommunicationsPage,
    requirePermission: 'view-materiales',
  },
];
