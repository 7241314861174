import React, { Dispatch, SetStateAction } from 'react';
import {
  Planificacion,
  // PlanificacionGet,
  PlanificacionDetailGet,
} from '../../API/flotas/planificaciones/interfaces';
import { Ruta } from '../../API/flotas/rutas/interfaces';
import { Vehiculo } from '../../API/flotas/vehiculos/interfaces';
import { FilterItem } from '../../components/DynamicForm';

interface RutaContext {
  rutas: Ruta[];
  setRutas: Dispatch<SetStateAction<Ruta[]>>;
}

interface PlanificacionContext {
  planificaciones: Planificacion[];
  setPlanificaciones: Dispatch<SetStateAction<Planificacion[]>>;
}

interface VehiculoContext {
  vehiculos: Vehiculo[];
  setVehiculos: Dispatch<SetStateAction<Vehiculo[]>>;
}

interface RowContext {
  rows: Ruta[];
  setRows: Dispatch<SetStateAction<Ruta[]>>;
}

interface FiltersContext {
  filterValue: FilterItem[];
  setFilterValue: Dispatch<SetStateAction<FilterItem[]>>;
}

interface PlanificacionDetailContext {
  planificacionDetail: PlanificacionDetailGet | null;
  setPlanificacionDetail: Dispatch<SetStateAction<PlanificacionDetailGet | null>>;
}

const initialState = {
  rutas: [],
  setRutas: () => null,
};

const rowInitialState = {
  rows: [],
  setRows: () => null,
};

const filterInitialState = {
  filterValue: [],
  setFilterValue: () => null,
};

const planificacionesInitialState = {
  planificaciones: [],
  setPlanificaciones: () => null,
};

const planificacionDetailInitialState = {
  planificacionDetail: null,
  setPlanificacionDetail: () => null,
};

const vehiculosInitialState = {
  vehiculos: [],
  setVehiculos: () => null,
};

export const RutasContext = React.createContext<RutaContext>(initialState);
export const PlanificacionesContext = React.createContext<PlanificacionContext>(
  planificacionesInitialState,
);
export const VehiculosContext = React.createContext<VehiculoContext>(vehiculosInitialState);
export const PlanificacionDetailContext = React.createContext<PlanificacionDetailContext>(
  planificacionDetailInitialState,
);

export const RowsContext = React.createContext<RowContext>(rowInitialState);
export const FiltersContext = React.createContext<FiltersContext>(filterInitialState);
