import { appConfig } from '../../../config/app';
import { GetUsuariosResponse, User } from '../interfaces';

export const responseTransformer = (response: string): User[] => {
  const responseJSON: GetUsuariosResponse = JSON.parse(response);
  const server = appConfig.apiConfig.baseURL.replace('/api/v2', '');

  return responseJSON.usuarios.map(usuario => ({
    id: usuario.id,
    photo: `${server}${usuario.foto}`,
    name: usuario.name,
    email: usuario.email,
    role: usuario.role,
  }));
};
