import { useNavigate } from 'react-router-dom';
import { Riego } from '../../../../API';
import CommunicationExport from '../../../../components/CommunicationExport';
import CommunicationImport from '../../../../components/CommunicationImport';
import H1 from '../../../../components/H1';
import Page, { PageProps } from '../../../../components/Page';
import { IconName } from '../../../../shared/types';
import { getCommunicationConfig, getRiegoCoreModule } from '../../shared/utils';
import { campos } from './campos';

const InventarioCommunicationsPage = (): JSX.Element => {
  const navigate = useNavigate();
  const riegoCoreModule = getRiegoCoreModule();
  if (!riegoCoreModule) return <></>;

  let pageProps: PageProps = {
    className: 'communications riego',
    titleType: 'title',
    children: undefined,
  };

  if (riegoCoreModule.submodulesGroups?.length > 1) {
    const breadcrumb = [
      {
        icon: 'water' as IconName,
        label: 'Riego',
      },
      {
        label: 'Inventario',
      },
    ];

    const btnBackConfig = {
      onClick: () => navigate('/riego/listado/inventario'),
    };

    pageProps = {
      ...pageProps,
      titleType: 'breadcrumb',
      breadcrumb,
      title: 'Riego',
      icon: 'water',
      btnBackConfig,
    };
  }

  return (
    <Page {...pageProps}>
      {riegoCoreModule.submodulesGroups?.length > 1 && <H1>Comunicaciones</H1>}

      <CommunicationImport
        title="Cargas"
        config={getCommunicationConfig('inventario')}
        importApi={Riego.import}
        requirePermission={{ section: 'riego', permission: 'import-inventario' }}
      />

      <hr />

      <CommunicationImport
        title="Piezas de Recambio"
        config={getCommunicationConfig('piezas-recambio')}
        importApi={Riego.import}
        requirePermission={{ section: 'riego', permission: 'import-inventario-piezas-recambio' }}
      />

      <hr />

      <CommunicationImport
        title="Cargas de Fuentes Abiertas"
        config={getCommunicationConfig('inventario-fuentes-abiertas')}
        importApi={Riego.import}
        requirePermission={{ section: 'riego', permission: 'import-inventario-ndp' }}
      />

      <hr />

      <CommunicationImport
        title="Cargas de Elementos Relacionados"
        config={getCommunicationConfig('inventario-elementos-relacionados')}
        importApi={Riego.import}
        requirePermission={{
          section: 'riego',
          permission: 'import-inventario-elementos-relacionados',
        }}
      />

      <hr />

      <CommunicationExport
        config={getCommunicationConfig('inventario')}
        exportApi={Riego.export}
        title="Consultas"
        campos={campos}
        requirePermission={{ section: 'riego', permission: 'export-inventario' }}
      />
    </Page>
  );
};

export default InventarioCommunicationsPage;
