import { useMemo } from 'react';
import { MainInfo } from '../../../../API/riego/materiales/interfaces';
import { DynamicFormMetadata } from '../../../../components/DynamicForm';

interface UseMetadataResult {
  mainInfoMetadata: DynamicFormMetadata<MainInfo>[];
}

const useMetadata = (mainInfo: MainInfo): UseMetadataResult => {
  const tipo = mainInfo.tipo;
  const mainInfoMetadata = useMemo(() => getMainInfoMetadata(tipo), [tipo]);
  return {
    mainInfoMetadata,
  };
};
export default useMetadata;

const getMainInfoMetadata = (
  tipo: 'Codificado' | 'No codificado',
): DynamicFormMetadata<MainInfo>[] => [
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'descripcion',
      props: {
        label: 'Descripción',
        type: 'text',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Select',
      propKey: 'tipo',
      props: {
        label: 'Seleccionar tipo',
        options: [
          { text: 'Codificado', value: 'Codificado' },
          { text: 'No Codificado', value: 'No codificado' },
        ],
      },
    },
  },
  ...((!tipo
    ? []
    : tipo === 'Codificado'
    ? [
        {
          grid: { xs: 6, sm: 4, md: 3 },
          input: {
            tag: 'Input',
            propKey: 'idMaterialCodificado',
            props: {
              label: 'Id Material',
              type: 'text',
            },
          },
        },
        {
          grid: { xs: 6, sm: 4, md: 3 },
          input: {
            tag: 'Input',
            propKey: 'costeLineaMaterialCodificado',
            props: {
              label: 'Coste linea',
              type: 'number',
            },
          },
        },
      ]
    : [
        {
          grid: { xs: 6, sm: 4, md: 3 },
          input: {
            tag: 'Input',
            propKey: 'costeUnitarioMaterialNoCodificado',
            props: {
              label: 'Coste unitario',
              type: 'number',
            },
          },
        },
        {
          grid: { xs: 6, sm: 4, md: 3 },
          input: {
            tag: 'Input',
            propKey: 'tipoLineaMaterialNoCodificado',
            props: {
              label: 'Tipo linea',
              type: 'text',
            },
          },
        },
      ]) as DynamicFormMetadata<MainInfo>[]),
];
