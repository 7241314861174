import { ChangeEvent, FC, useContext, useState } from 'react';
import { Actuaciones } from '../../../../../../API/riego';
import { ActuacionByDate } from '../../../../../../API/riego/actuaciones/interfaces';
import Col from '../../../../../../components/Col';
import Input from '../../../../../../components/Input';
import Row from '../../../../../../components/Row';
import Select from '../../../../../../components/Select';
import { showErrorAlert } from '../../../../../../libs/LazarusAlert';
import { MaterialesContext } from '../../context';

const SelectActuacion: FC = () => {
  const { data, handleChangeActuacion } = useContext(MaterialesContext);
  const [actuaciones, setActuaciones] = useState<ActuacionByDate[]>([]);
  const [actuacionDate, setActuacionDate] = useState<string>('');

  const getOptions = () => {
    return actuaciones.map(actuacion => ({
      text: `${actuacion.idActuacion} / ${actuacion.idActuacionExterno} / ${actuacion.descripcion}`,
      value: actuacion.idActuacion,
    }));
  };

  const handleChangeDate = (ev: ChangeEvent<HTMLInputElement>) => {
    const newActuacionDate = ev.target.value;
    setActuacionDate(newActuacionDate);
    Actuaciones.byDate(newActuacionDate)
      .then(actuacionesByDate => {
        setActuaciones(actuacionesByDate);
      })
      .catch(err => {
        console.log(err);
        showErrorAlert({ text: 'Ha ocurrido un error al consultar las actuaciones' });
      });
  };

  return (
    <Row style={{ marginTop: 25 }} className="select-actuacion">
      <Col xs={12} md={2} className="select-actuacion__col1">
        <Input
          type="date"
          label="Fecha de la actuación"
          value={actuacionDate}
          onChange={handleChangeDate}
        />
      </Col>
      <Col xs={12} md={6}>
        <Select
          label="Actuación"
          options={getOptions()}
          value={data.idActuacion}
          onChange={ev => handleChangeActuacion(ev.target.value)}
        />
      </Col>
    </Row>
  );
};

export default SelectActuacion;
