import { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
// import Logo from '../../assets/img/logo-black.svg';
import Button from '../../components/Button';
import Checkbox from '../../components/Checkbox';
import Input from '../../components/Input';
import { appConfig } from '../../config/app';
import { useAuth } from '../../hooks/useAuth';
import { showErrorAlert } from '../../libs/LazarusAlert';

interface LocationProps {
  state: {
    from: Location;
  };
}

const LoginPage = (): JSX.Element => {
  const { isAuthenticated, signIn } = useAuth();
  const navigate = useNavigate();
  const location = (useLocation() as unknown) as LocationProps;
  const [loginData, setLoginData] = useState({
    email: '',
    password: '',
    remember: false,
  });
  const from = location.state?.from?.pathname || '/';
  const { logo } = appConfig;

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    signIn(loginData)
      .then(() => {
        navigate(from);
      })
      .catch(err => {
        console.log(err);
        showErrorAlert({ title: '¡Error de autenticación!', text: err.response.data.error });
      });
  };

  if (isAuthenticated) {
    navigate(from);
  }

  return (
    <div className="lazarus__container">
      <div className="lazarus__grid">
        <div className="login__container">
          <div className="login__logo">
            <img src={logo.black} alt="Logo" />
          </div>
          <div className="login__form">
            <form autoComplete="off" onSubmit={handleSubmit}>
              <h3 className="login__form-title">Iniciar sesión</h3>

              <Input
                label="Email"
                type="email"
                className="login__form-group"
                value={loginData.email}
                onChange={ev => setLoginData({ ...loginData, email: ev.target.value })}
              />
              <Input
                label="Contraseña"
                type="password"
                value={loginData.password}
                onChange={ev => setLoginData({ ...loginData, password: ev.target.value })}
                className="login__form-group"
              />

              <Link className="login__link" to="/recuperar-contrasena">
                Olvidé mi contraseña
              </Link>

              <Checkbox
                checked={loginData.remember}
                onChecked={checked => {
                  setLoginData({ ...loginData, remember: checked });
                }}
                label="Recordarme"
              />

              <Button block color="primary" className="login__submit">
                Iniciar sesión
              </Button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
