export const campos = [
  { value: 'solicitud', text: 'Solicitud' },
  { value: 'descripcion', text: 'Descripcion' },
  { value: 'descripcionLarga', text: 'Descripcion larga' },
  { value: 'causa', text: 'Causa' },
  { value: 'codigoDistrito', text: 'Código distrito' },
  { value: 'codigoZona', text: 'Código zona' },
  { value: 'introducidas', text: 'Introducidas' },
  { value: 'codSuperficieVerde', text: 'Código superficie verde' },
  { value: 'codUnidadGestion', text: 'Código unidad gestión' },
  { value: 'estadoNombre', text: 'Estado nombre' },
  { value: 'coordenadas', text: 'Coordenadas' },
  { value: 'claseFuncionGIS', text: 'Clase función GIS' },
  { value: 'fechaInicioPlan', text: 'Fecha inicio plan' },
  { value: 'fechaFinPlan', text: 'Fecha fin plan' },
  { value: 'otrosMotivos', text: 'Otros motivos' },
];
