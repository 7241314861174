import { PostRequest } from '.';
import { arrayToNumberedObject } from '../../../shared/utils';

export const requestTransformer = (request: PostRequest): FormData => {
  const form = new FormData();

  const requestItems: Record<string, any> = {
    ...request.mainInfo,
    ...request.camposPrincipales,
    ...request.bocasRiego,
    ...request.codigos,
    ...request.comentarios,
    ...request.contrato,
    ...request.edificio,
    ...request.otros,
    ...request.tarifa,
    ...request.riegoAutomatico,
    ...request.sitios,
    ...request.ultimaLectura,
    ...arrayToNumberedObject(request.serviciosSuministrados, 'idServicioSuministrado'),
  };

  Object.entries(requestItems).forEach(([key, value]) => form.append(key, value));

  if (!request.mainInfo.id) form.append('id', '-1');
  form.append('multi', '');
  form.append('multiItems', '');

  return form;
};
