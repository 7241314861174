import { useNavigate } from 'react-router-dom';
import { Limpieza } from '../../../../API';
import CommunicationExport from '../../../../components/CommunicationExport';
import CommunicationImport from '../../../../components/CommunicationImport';
import H1 from '../../../../components/H1';
import Page from '../../../../components/Page';
import { IconName } from '../../../../shared/types';
import { getCommunicationConfig } from '../../shared/utils';
import { campos } from './campos';

const InventarioCommunicationsPage = (): JSX.Element => {
  const navigate = useNavigate();

  const breadcrumbItems = [
    {
      icon: 'water' as IconName,
      label: 'Limpieza',
    },
    {
      label: 'Inventario',
    },
  ];

  const btnBackConfig = {
    onClick: () => navigate('/limpieza/listado/inventario'),
  };

  return (
    <Page
      title="Limpieza"
      icon="water"
      className="communications limpieza"
      titleType="breadcrumb"
      breadcrumb={breadcrumbItems}
      btnBackConfig={btnBackConfig}>
      <H1>Comunicaciones</H1>

      <CommunicationImport
        title="Cargas"
        config={getCommunicationConfig('inventario')}
        importApi={Limpieza.import}
        requirePermission={{ section: 'limpieza', permission: 'import-inventario' }}
      />

      <hr />

      <CommunicationImport
        title="Cargas de Fuentes Abiertas"
        config={getCommunicationConfig('inventario-fuentes-abiertas')}
        importApi={Limpieza.import}
        requirePermission={{ section: 'limpieza', permission: 'import-inventario-ndp' }}
      />

      <hr />

      <CommunicationExport
        config={getCommunicationConfig('inventario')}
        exportApi={Limpieza.export}
        title="Consultas"
        campos={campos}
        requirePermission={{ section: 'limpieza', permission: 'export-inventario' }}
      />
    </Page>
  );
};

export default InventarioCommunicationsPage;
