import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getById, post } from '../../../../API/riego/actuaciones';
import { MainInfo } from '../../../../API/riego/actuaciones/interfaces';
import { EspecificacionTecnica } from '../../../../API/shared/actuaciones/interfaces';
import { Documento } from '../../../../API/shared/interfaces';
import BoxComplexInput from '../../../../components/BoxComplexInput';
import Button from '../../../../components/Button';
import Col from '../../../../components/Col';
import CollapsibleSection from '../../../../components/CollapsibleSection';
import DynamicForm from '../../../../components/DynamicForm';
import H1 from '../../../../components/H1';
import Row from '../../../../components/Row';
import useEdit from '../../../../hooks/useEdit';
import { showErrorAlert, showSuccessAlert } from '../../../../libs/LazarusAlert';
import useMetadata from './hooks';

const ActuacionesListPage = (): JSX.Element => {
  const [especificacionesTecnicas, setEspecificacionesTecnicas] = useState<EspecificacionTecnica[]>(
    [],
  );
  const [documentos, setDocumentos] = useState<Documento[]>([]);
  const [mainInfo, setMainInfo] = useState<MainInfo>({} as MainInfo);
  const [loading, setLoading] = useState(false);

  const { mainInfoMetadata, especificacionesTecnicasMetadata, documentosMetadata } = useMetadata(
    setMainInfo,
  );

  const navigate = useNavigate();

  const data = useEdit(getById, '/riego/listado/actuaciones');

  useEffect(() => {
    if (data) {
      setMainInfo(data.mainInfo);
      setDocumentos(data.documentos);
      setEspecificacionesTecnicas(data.especificacionesTecnicas);
    }
  }, [data]);

  const handleSubmit = () => {
    setLoading(true);
    post({ mainInfo, documentos, especificacionesTecnicas })
      .then(() => {
        showSuccessAlert({ title: '¡Éxito!', text: 'Actuación guardado con éxito.' });
        navigate('/riego/listado/actuaciones');
      })
      .catch(() => {
        showErrorAlert({ title: 'Error', text: 'Error al guardar la actuación.' });
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      <H1>Datos</H1>
      <CollapsibleSection title="Información principal">
        <DynamicForm
          spacing="lg"
          onChange={setMainInfo}
          metadata={mainInfoMetadata}
          value={mainInfo}
        />
      </CollapsibleSection>
      <Row style={{ marginBottom: 30 }}>
        <BoxComplexInput
          closedMessage={`Hay ${especificacionesTecnicas.length} especifaciones técnicas.`}
          metadata={especificacionesTecnicasMetadata}
          onRemove={row => setEspecificacionesTecnicas(cur => cur.filter(c => c !== row))}
          onAdd={row => setEspecificacionesTecnicas(cur => [...cur, row])}
          label="Especificaciones técnicas"
          dataSource={especificacionesTecnicas}
        />
      </Row>
      <Row style={{ marginBottom: 30 }}>
        <BoxComplexInput
          closedMessage={`Hay ${documentos.length} documentos.`}
          metadata={documentosMetadata}
          onRemove={row => setDocumentos(cur => cur.filter(c => c !== row))}
          onAdd={row => setDocumentos(cur => [...cur, row])}
          label="Documentos"
          dataSource={documentos}
        />
      </Row>
      <Row justifyContent="center">
        <Col xs={3} sm={2}>
          <Button color="primary" outline disabled={loading}>
            Cancelar
          </Button>
        </Col>
        <Col xs={3} sm={2}>
          <Button color="primary" onClick={handleSubmit} disabled={loading}>
            Guardar
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default ActuacionesListPage;
