import { getKeyValue } from '../../../../shared/utils';
import { mapEstadoColor } from '../../../shared/utils';
import { Actuacion, GetRiegoActuacionesResponse } from '../interfaces';

export const responseTransformer = (response: string): Actuacion[] => {
  const responseJSON: GetRiegoActuacionesResponse = JSON.parse(response);

  return responseJSON.actuaciones.map(i => ({
    id: i.id,
    barrio: i.actuacion?.barrio || 0,
    distrito: i.actuacion?.distrito || 0,
    jerarquiaClasificacion: i.actuacion?.jerarquiaClasificacion || '',
    nombreVia: i.actuacion?.nombreVia || '',
    nroVia: i.actuacion?.numeroVia || 0,
    estado: {
      color: getKeyValue(i.actuacion.estado_general.nombre)(mapEstadoColor),
      label: i.actuacion.estado_general.nombre,
    },
  }));
};
