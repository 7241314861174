import { ZonasVerdes } from '../../../../API';
import CommunicationExport from '../../../../components/CommunicationExport';
import CommunicationImport from '../../../../components/CommunicationImport';
import Page from '../../../../components/Page';
import { getCommunicationConfig } from '../../shared/utils';
import { campos } from './campos';

const PlanificacionesCommunicationsPage = (): JSX.Element => {
  return (
    <Page className="communications" titleType="title">
      <CommunicationImport
        title="Cargas"
        config={getCommunicationConfig('planificaciones')}
        importApi={ZonasVerdes.import}
        requirePermission={{ section: 'zzvv', permission: 'import-planificaciones' }}
      />

      <hr />

      <CommunicationExport
        config={getCommunicationConfig('planificaciones')}
        exportApi={ZonasVerdes.export}
        title="Consultas"
        campos={campos}
        requirePermission={{ section: 'zzvv', permission: 'export-planificaciones' }}
      />
    </Page>
  );
};

export default PlanificacionesCommunicationsPage;
