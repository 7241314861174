import { AxiosResponse } from 'axios';
import { createOauthApiInstance } from '../../api';
import { RefreshTokenResponse } from '../interfaces';

export const execute = async (token: string): Promise<RefreshTokenResponse> => {
  const refreshRes: AxiosResponse<RefreshTokenResponse> = await createOauthApiInstance().post(
    `refreshToken`,
    {
      headers: { Authorization: `Bearer ${token}` },
    },
  );
  return refreshRes.data;
};
