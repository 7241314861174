import { Route } from '../../routes/routes';
import InventarioCreatePage from './Inventario/Create';
import ActuacionesCreatePage from './Actuaciones/Create';
import PlanificacionesCreatePage from './Planificaciones/Create';

export const createsRoutes: Route[] = [
  {
    name: 'Inventario',
    path: 'inventario',
    to: `inventario`,
    Component: InventarioCreatePage,
    requirePermission: 'create-inventario',
  },
  {
    name: 'Actuaciones',
    path: 'actuaciones',
    to: `actuaciones`,
    Component: ActuacionesCreatePage,
    requirePermission: 'create-actuaciones',
  },
  {
    name: 'Planificaciones',
    path: 'planificaciones',
    to: `planificaciones`,
    Component: PlanificacionesCreatePage,
    requirePermission: 'create-planificacion-sectores',
  },
];
