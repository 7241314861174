import cn from 'classnames';
import { FC } from 'react';

interface Props {
  /**
   * Indicar si hay error
   */
  error?: string;

  /**
   * Indicar label
   */
  label?: string;

  /**
   * Input checked
   */
  checked?: boolean;

  /**
   * Handle checked
   */
  onChecked: (check: boolean) => void;

  /**
   * Handle checked
   */
  onClick?: () => void;

  /**
   * Agregar clases de css propias
   */
  className?: string;
}

const Checkbox: FC<Props & React.InputHTMLAttributes<HTMLInputElement>> = ({
  checked,
  onChecked,
  error,
  label,
  onClick,
  className,
  ...props
}) => {
  const classNames = cn(
    'input-group-checkbox',
    {
      error: error,
    },
    className,
  );

  const labelClassName = cn({
    active: props.value,
  });

  const onEvent = () => {
    if (onChecked) onChecked(!checked);
    if (onClick) onClick();
  };

  return (
    <>
      <div className={classNames}>
        <input {...props} checked={checked} type="checkbox" />
        <div onClick={onEvent} className="input"></div>
        <svg
          onClick={onEvent}
          width="10"
          height="7"
          viewBox="0 0 10 7"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M9.40396 0.20257C9.34029 0.138382 9.26455 0.0874352 9.1811 0.0526676C9.09765 0.0178999 9.00814 0 8.91773 0C8.82733 0 8.73782 0.0178999 8.65436 0.0526676C8.57091 0.0874352 8.49517 0.138382 8.43151 0.20257L3.32956 5.31136L1.18606 3.16101C1.11996 3.09716 1.04193 3.04695 0.956429 3.01326C0.870925 2.97956 0.779622 2.96304 0.687731 2.96463C0.595841 2.96622 0.505164 2.98589 0.420877 3.02252C0.33659 3.05916 0.260343 3.11203 0.196491 3.17813C0.132639 3.24423 0.0824322 3.32226 0.0487361 3.40777C0.0150401 3.49327 -0.00148507 3.58457 0.000104722 3.67646C0.00169452 3.76835 0.021368 3.85903 0.0580017 3.94332C0.0946353 4.02761 0.147512 4.10385 0.213612 4.1677L2.84334 6.79743C2.907 6.86162 2.98274 6.91257 3.0662 6.94733C3.14965 6.9821 3.23916 7 3.32956 7C3.41997 7 3.50948 6.9821 3.59293 6.94733C3.67638 6.91257 3.75213 6.86162 3.81579 6.79743L9.40396 1.20926C9.47347 1.14513 9.52895 1.0673 9.56689 0.980672C9.60483 0.894042 9.62442 0.800492 9.62442 0.705916C9.62442 0.611341 9.60483 0.51779 9.56689 0.43116C9.52895 0.34453 9.47347 0.266699 9.40396 0.20257Z"
            fill="#459C00"
          />
        </svg>
        {label && (
          <label onClick={onEvent} className={labelClassName}>
            {label}
          </label>
        )}
      </div>
      {error && (
        <span className="form-error">
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M8 5V8.45M8 11H8.0075M14.75 8C14.75 11.7279 11.7279 14.75 8 14.75C4.27208 14.75 1.25 11.7279 1.25 8C1.25 4.27208 4.27208 1.25 8 1.25C11.7279 1.25 14.75 4.27208 14.75 8Z"
              stroke="#E7641A"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          {error}
        </span>
      )}
    </>
  );
};

export default Checkbox;
