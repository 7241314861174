export const campos = [
  { value: 'localizacion', text: 'Localización' },
  { value: 'descripcion', text: 'Descripción' },
  { value: 'descripcionLarga', text: 'Descripción larga' },
  { value: 'distrito', text: 'Distrito' },
  { value: 'barrio', text: 'Barrio' },
  { value: 'estado', text: 'Estado' },
  { value: 'contrato', text: 'Contrato' },
  { value: 'lote', text: 'Lote' },
  { value: 'claseFuncionGIS', text: 'Clase función GIS' },
  { value: 'coordenadas', text: 'Coordenadas' },
  { value: 'ndp', text: 'NDP' },
  { value: 'descripcionUbicacion', text: 'Descripción ubicación' },
  { value: 'tipoVia', text: 'Tipo via' },
  { value: 'nombreVia', text: 'Nombre vía' },
  { value: 'numeroVia', text: 'Número vía' },
  { value: 'codigoPostal', text: 'Código postal' },
  { value: 'observaciones', text: 'Observaciones' },
  { value: 'observacionesLargas', text: 'Observaciones largas' },
  { value: 'tipo', text: 'Tipo' },
  { value: 'caracterSV', text: 'Caracter SV' },
  { value: 'gradoProteccion', text: 'Grado protección' },
  { value: 'tipologiaPG', text: 'Tipología PG' },
  { value: 'tipologiaSV', text: 'Tipología SV' },
];
