import { RoleData } from '../../../pages/Permissions/context';
import { createApiInstance } from '../../api';
import { Role } from '../interfaces';

export const execute = async (id: number | string, roleData: RoleData): Promise<Role> => {
  const api = createApiInstance('application/json');
  const { data } = await api.put<Role>(`/roles/${id}`, roleData);

  return data;
};
