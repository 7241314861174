import { ImportExportResult } from '../../shared/interfaces';

export const responseTransformer = (response: string): ImportExportResult => {
  const responseJSON: ImportExportResult = JSON.parse(response);

  return {
    ...responseJSON,
    file: responseJSON.file?.replace('..', '') || '',
  };
};
