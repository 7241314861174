import { FC } from 'react';
import { NavLink } from 'react-router-dom';
import { useAppDispatch } from '../../redux/hooks';
import { setActiveItem } from '../../redux/NavbarSlice/navbarSlice';
import { SubModule } from '../../routes/routes';
import NavLinkMenu from './NavLinkMenu';

interface Props {
  submodules: SubModule[];
}

const Navbar: FC<Props> = ({ submodules }) => {
  const dispatch = useAppDispatch();

  return (
    <nav className="module-page__navbar">
      {submodules.map((subModule, idx) =>
        subModule.subRoutes ? (
          <NavLinkMenu subModule={subModule} />
        ) : (
          <NavLink
            key={idx}
            to={subModule.to}
            onClick={() => {
              dispatch(setActiveItem({ activeItem: subModule.name }));
              console.log('Clicked on subModule:', submodules); // Agrega un log aquí
            }}
            className={({ isActive }) =>
              isActive
                ? 'module-page__link module-page__item module-page__link--active'
                : 'module-page__link module-page__item'
            }
          >
            <span>{subModule.name}</span>
          </NavLink>
        ),
      )}
    </nav>
  );
};

export default Navbar;
