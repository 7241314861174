import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import {
  BocasRiego,
  CamposPrincipales,
  Codigos,
  Comentarios,
  Contrato,
  Edificio,
  Fuente,
  MainInfo,
  Otros,
  RiegoAutomatico,
  ServicioSuministrado,
  Sitios,
  Tarifa,
  UltimaLectura,
} from '../../../../API/riego/puntos-de-suministro/interfaces';
import {
  getEstadosActuaciones,
  getJerarquiasClasificacionActuaciones,
} from '../../../../API/shared/actuaciones';
import { EstadoElemento, JerarquiaClasificacion } from '../../../../API/shared/interfaces';
import { DynamicFormMetadata } from '../../../../components/DynamicForm';

interface UseMetadataResult {
  mainInfoMetadata: DynamicFormMetadata<MainInfo>[];
  camposPrincipalesMetadata: DynamicFormMetadata<CamposPrincipales>[];
  bocasRiegoMetadata: DynamicFormMetadata<BocasRiego>[];
  codigosMetadata: DynamicFormMetadata<Codigos>[];
  comentariosMetadata: DynamicFormMetadata<Comentarios>[];
  contratoMetadata: DynamicFormMetadata<Contrato>[];
  edificioMetadata: DynamicFormMetadata<Edificio>[];
  fuenteMetadata: DynamicFormMetadata<Fuente>[];
  otrosMetadata: DynamicFormMetadata<Otros>[];
  tarifaMetadata: DynamicFormMetadata<Tarifa>[];
  riegoAutomaticoMetadata: DynamicFormMetadata<RiegoAutomatico>[];
  sitiosMetadata: DynamicFormMetadata<Sitios>[];
  ultimaLecturaMetadata: DynamicFormMetadata<UltimaLectura>[];
  serviciosSuministradosMetadata: DynamicFormMetadata<ServicioSuministrado>[];
}

const useMetadata = (setMainInfo: Dispatch<SetStateAction<MainInfo>>): UseMetadataResult => {
  const [jerarquiasClasificacion, setJerarquiasClasificacion] = useState<JerarquiaClasificacion[]>(
    [],
  );
  const [estadosActuacion, setEstadosActuacion] = useState<EstadoElemento[]>([]);

  useEffect(() => {
    getJerarquiasClasificacionActuaciones('riego').then(res => setJerarquiasClasificacion(res));
    getEstadosActuaciones().then(res => setEstadosActuacion(res));

    //TODO, vamos a trabajar cancelando request ahora?
    //return { cancellRequests... };
  }, []);

  const mainInfoMetadata = useMemo(
    () => getMainInfoMetadata(jerarquiasClasificacion, estadosActuacion, setMainInfo),
    [jerarquiasClasificacion, estadosActuacion, setMainInfo],
  );

  return {
    mainInfoMetadata,
    camposPrincipalesMetadata,
    bocasRiegoMetadata,
    codigosMetadata,
    comentariosMetadata,
    contratoMetadata,
    edificioMetadata,
    fuenteMetadata,
    otrosMetadata,
    tarifaMetadata,
    riegoAutomaticoMetadata,
    sitiosMetadata,
    ultimaLecturaMetadata,
    serviciosSuministradosMetadata,
  };
};
export default useMetadata;

const getMainInfoMetadata = (
  jerarquias: JerarquiaClasificacion[],
  estados: EstadoElemento[],
  setMainInfo: Dispatch<SetStateAction<MainInfo>>,
): DynamicFormMetadata<MainInfo>[] => [
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'descripcion',
      props: {
        label: 'Descripción',
        type: 'text',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'padre',
      props: {
        label: 'Nivel superior',
        type: 'text',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Select',
      propKey: 'jerarquiaClasificacion',
      props: {
        label: 'Jerarquía clasificación',
        options: jerarquias.map(j => ({ text: j.codigo, value: j.codigo })),
        onChange: e => {
          const desc = jerarquias.find(j => j.codigo === e.currentTarget.value)?.descripcion || '';
          setMainInfo(cur => ({
            ...cur,
            jerarquiaClasificacionDescripcion: desc,
          }));
        },
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'jerarquiaClasificacionDescripcion',
      props: {
        label: 'Descripción de clase',
        type: 'text',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'distrito',
      props: {
        label: 'Distrito',
        type: 'number',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'barrio',
      props: {
        label: 'Barrio',
        type: 'number',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'tipoVia',
      props: {
        label: 'Tipo de vía',
        type: 'text',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'nombreVia',
      props: {
        label: 'Nombre de vía',
        type: 'text',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'numeroVia',
      props: {
        label: 'Número de vía',
        type: 'number',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'idInventarioInterno',
      props: {
        label: 'Código interno',
        type: 'text',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'idInventarioExterno',
      props: {
        label: 'Código externo',
        type: 'text',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'claseFuncionGIS',
      props: {
        label: 'Clase función',
        type: 'text',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'codigoPostal',
      props: {
        label: 'Código Postal',
        type: 'number',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Select',
      propKey: 'estado',
      props: {
        label: 'Estado',
        options: estados.map(e => ({ text: e.valor, value: e.valor })),
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'fechaEstado',
      props: {
        label: 'Fecha estado',
        type: 'date',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'fechaInstalacion',
      props: {
        label: 'Fecha instalación',
        type: 'date',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'ndp',
      props: {
        label: 'NDP',
        type: 'number',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'observaciones',
      props: {
        label: 'Resumen',
        type: 'text',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'observacionesLargas',
      props: {
        label: 'Descripción detallada',
        type: 'text',
      },
    },
  },
];

const camposPrincipalesMetadata: DynamicFormMetadata<CamposPrincipales>[] = [
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'idInventario',
      props: {
        label: 'Id del punto de suministro',
        type: 'number',
        readOnly: true,
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'idPuntoSuministro',
      props: {
        label: 'Id inventario asociado',
        type: 'number',
      },
    },
  },
];

const bocasRiegoMetadata: DynamicFormMetadata<BocasRiego>[] = [
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Select',
      propKey: 'bocasRiegoAcera',
      props: {
        label: '¿Bocas de riego acera?',
        options: [
          {
            text: 'Sí',
            value: 'Sí',
          },
          {
            text: 'No',
            value: 'No',
          },
        ],
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Select',
      propKey: 'bocasRiegoParque',
      props: {
        label: '¿Bocas de riego parque?',
        options: [
          {
            text: 'Sí',
            value: 'Sí',
          },
          {
            text: 'No',
            value: 'No',
          },
        ],
      },
    },
  },
];

const codigosMetadata: DynamicFormMetadata<Codigos>[] = [
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'parque',
      props: {
        label: 'Parque',
        type: 'number',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'grupoParque',
      props: {
        label: 'Grupo parque',
        type: 'number',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'codigoZonaVerde',
      props: {
        label: 'Código zona verde',
        type: 'text',
      },
    },
  },
];

const comentariosMetadata: DynamicFormMetadata<Comentarios>[] = [
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'comentarios',
      props: {
        label: 'Comentarios',
        type: 'text',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'comentariosLargos',
      props: {
        label: 'Comentarios largos',
        type: 'text',
      },
    },
  },
];

const contratoMetadata: DynamicFormMetadata<Contrato>[] = [
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Select',
      propKey: 'auditadoContrato',
      props: {
        label: 'Auditado contrat',
        options: [
          {
            text: 'Sí',
            value: 'Sí',
          },
          {
            text: 'No',
            value: 'No',
          },
        ],
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'breveContrato',
      props: {
        label: 'Breve contrato',
        type: 'text',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'conclusionContrato',
      props: {
        label: 'Conclusión contrato',
        type: 'text',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'contratoCYII',
      props: {
        label: 'Contrato CYII',
        type: 'text',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'estadoContrato',
      props: {
        label: 'Estado contrato',
        type: 'text',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'pendienteBaja',
      props: {
        label: 'Pendiente baja',
        type: 'text',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'pendienteInspeccion',
      props: {
        label: 'Pendiente inspección',
        type: 'text',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'serviciosSuministrados',
      props: {
        label: 'Servicios suministrador',
        type: 'text',
      },
    },
  },
];

const edificioMetadata: DynamicFormMetadata<Edificio>[] = [
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Select',
      propKey: 'edificioMunicipal',
      props: {
        label: '¿Edificio municipal?',
        options: [
          {
            text: 'Sí',
            value: 'Sí',
          },
          {
            text: 'No',
            value: 'No',
          },
        ],
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Select',
      propKey: 'edificioMunicipal',
      props: {
        label: '¿Edificio municipal?',
        options: [
          {
            text: 'Sí',
            value: 'Sí',
          },
          {
            text: 'No',
            value: 'No',
          },
        ],
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Select',
      propKey: 'edificioNoMunicipal',
      props: {
        label: '¿Edificio no municipal?',
        options: [
          {
            text: 'Sí',
            value: 'Sí',
          },
          {
            text: 'No',
            value: 'No',
          },
        ],
      },
    },
  },
];

const fuenteMetadata: DynamicFormMetadata<Fuente>[] = [
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'fuenteBeber',
      props: {
        label: 'Fuente beber',
        type: 'text',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Select',
      propKey: 'fuenteOrnamental',
      props: {
        label: '¿Fuente ornamental?',
        options: [
          {
            text: 'Sí',
            value: 'Sí',
          },
          {
            text: 'No',
            value: 'No',
          },
        ],
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Select',
      propKey: 'fuenteParque',
      props: {
        label: '¿Fuente parque?',
        options: [
          {
            text: 'Sí',
            value: 'Sí',
          },
          {
            text: 'No',
            value: 'No',
          },
        ],
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Select',
      propKey: 'fuentePublica',
      props: {
        label: '¿Fuente pública?',
        options: [
          {
            text: 'Sí',
            value: 'Sí',
          },
          {
            text: 'No',
            value: 'No',
          },
        ],
      },
    },
  },
];

const otrosMetadata: DynamicFormMetadata<Otros>[] = [
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'puntoAcometida',
      props: {
        label: 'Punto acometida',
        type: 'text',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'calibre',
      props: {
        label: 'Calibre',
        type: 'text',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'contador',
      props: {
        label: 'Contador',
        type: 'text',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'marca',
      props: {
        label: 'Marca',
        type: 'text',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'tipoAgua',
      props: {
        label: 'Tipo agua',
        type: 'text',
      },
    },
  },
];

const tarifaMetadata: DynamicFormMetadata<Tarifa>[] = [
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'tarifa',
      props: {
        label: 'Tarifa',
        type: 'number',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'observacionTarifa',
      props: {
        label: 'Observación tarifa',
        type: 'text',
      },
    },
  },
];

const riegoAutomaticoMetadata: DynamicFormMetadata<RiegoAutomatico>[] = [
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Select',
      propKey: 'riegoAutomatico',
      props: {
        label: '¿Riego automático?',
        options: [
          {
            text: 'Sí',
            value: 'Sí',
          },
          {
            text: 'No',
            value: 'No',
          },
        ],
      },
    },
  },
];

const sitiosMetadata: DynamicFormMetadata<Sitios>[] = [
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Select',
      propKey: 'casetaJardinero',
      props: {
        label: '¿Caseta jardinero?',
        options: [
          {
            text: 'Sí',
            value: 'Sí',
          },
          {
            text: 'No',
            value: 'No',
          },
        ],
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Select',
      propKey: 'jardinPrivado',
      props: {
        label: '¿Jardín privado?',
        options: [
          {
            text: 'Sí',
            value: 'Sí',
          },
          {
            text: 'No',
            value: 'No',
          },
        ],
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Select',
      propKey: 'polideportivo',
      props: {
        label: '¿Polideportivo?',
        options: [
          {
            text: 'Sí',
            value: 'Sí',
          },
          {
            text: 'No',
            value: 'No',
          },
        ],
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Select',
      propKey: 'usoPrivado',
      props: {
        label: '¿Uso privado?',
        options: [
          {
            text: 'Sí',
            value: 'Sí',
          },
          {
            text: 'No',
            value: 'No',
          },
        ],
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Select',
      propKey: 'otros',
      props: {
        label: 'Otros',
        options: [
          {
            text: 'Sí',
            value: 'Sí',
          },
          {
            text: 'No',
            value: 'No',
          },
        ],
      },
    },
  },
];

const ultimaLecturaMetadata: DynamicFormMetadata<UltimaLectura>[] = [
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'anoUltimaLectura',
      props: {
        label: 'Año última lectura',
        type: 'text',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'tarifaUltimaLectura',
      props: {
        label: 'Tarifa última lectura',
        type: 'text',
      },
    },
  },
];

const serviciosSuministradosMetadata: DynamicFormMetadata<ServicioSuministrado>[] = [
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'activoServicioSuministrado',
      props: {
        label: 'Activo servicio suminsitrado',
        type: 'text',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'instalacionServicioSuministrado',
      props: {
        label: 'Instalación servicio suminsitrado',
        type: 'text',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'direccionServicioSuministrado',
      props: {
        label: 'Dirección servicio suminsitrado',
        type: 'text',
      },
    },
  },
];
