import React, { useState, useEffect } from 'react';
import LeafletMap from './Components/LeafletMap';
import { getCartografia, getVehiculosUtils } from './utils';
import { VehiculoMapa, GeoJSONLayer } from './interface';
import { Vehiculos } from '../../../../API/flotas';

const VehiculoMapPage = () => {
  const [geoJSONLayers, setGeoJSONLayers] = useState<GeoJSONLayer[]>([]);
  const [vehicles, setVehicles] = useState<VehiculoMapa[]>([]);
  const matriculaExcluida = '6363LTJ';

  const getVehiculos = async () => {
    try {
      const vehiculosGet = await Vehiculos.get();
      return vehiculosGet.filter(vehiculo => vehiculo.matricula !== matriculaExcluida);
    } catch (error) {
      console.error('Error al obtener vehículos:', error);
    }
  };

  const fetchData = async () => {
    try {
      const vehiculoData = (await getVehiculos()) || [];

      if (vehiculoData && vehiculoData.length > 0) {
        // Ejecutar las dos funciones asincrónicas en paralelo
        const [geoJSONLayers, vehicles] = await Promise.all([
          getCartografia(),
          getVehiculosUtils(vehiculoData),
        ]);

        // Verificar si se recibieron las capas de cartografía
        if (geoJSONLayers) {
          setGeoJSONLayers(geoJSONLayers);
        } else {
          setGeoJSONLayers([]);
        }

        // Verificar si se recibieron las planificaciones
        if (vehicles) {
          setVehicles(vehicles);
        } else {
          setVehicles([]);
        }
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  useEffect(() => {
    fetchData();

    const interval = setInterval(fetchData, 180000); // Ejecutar cada 3 minutos (tiempo en ms)

    return () => {
      clearInterval(interval); // Limpiar el intervalo cuando el componente se desmonte
    };
  }, []);

  return (
    <div>
      {geoJSONLayers.length > 0 && vehicles.length > 0 && (
        <LeafletMap vehicles={vehicles} geoJSONLayers={geoJSONLayers} />
      )}
    </div>
  );
};

export default VehiculoMapPage;
