import { FC } from 'react';
import Col from '../../../../../../components/Col';
import Select, { Option } from '../../../../../../components/Select/index';
import Input from '../../../../../../components/Input/index';
import Row from '../../../../../../components/Row';
import Icon from '../../../../../../components/Icon/index';

interface Props {
  materiales: Option[];
  material: MaterialCodificadoData;
  idx: number;
  handleChangeMaterial: (idx: number, material: MaterialCodificadoData) => void;
  handleRemoveMaterial: (idx: number) => void;
}

export interface MaterialCodificadoData {
  materialId: string;
  cantidad: string;
  tarifa: string;
}

const MaterialCodificado: FC<Props> = ({
  idx,
  materiales,
  material,
  handleChangeMaterial,
  handleRemoveMaterial,
}) => {
  const handleChange = (name: keyof MaterialCodificadoData, value: string) => {
    const newMaterial = { ...material, [name]: value };
    handleChangeMaterial(idx, newMaterial);
  };

  return (
    <Row style={{ marginTop: 25 }} className="material-codificado">
      <Col xs={12} md={5} className="material-codificado__materialId">
        <Select
          label="Material"
          onChange={ev => handleChange('materialId', ev.target.value)}
          value={material.materialId}
          options={materiales}
        />
      </Col>
      <Col xs={12} md={2} className="material-codificado__cantidad">
        <Input
          type="text"
          onChange={ev => handleChange('cantidad', ev.target.value)}
          value={material.cantidad}
          label="Cantidad"
        />
      </Col>
      <Col xs={12} md={2} className="material-codificado__tarifa">
        <Input
          type="text"
          onChange={ev => handleChange('tarifa', ev.target.value)}
          value={material.tarifa}
          label="Tarifa"
        />
      </Col>
      <Col xs={12} md={1}>
        <Icon onClick={() => handleRemoveMaterial(idx)} color="black" name="trash" />
      </Col>
    </Row>
  );
};

export default MaterialCodificado;
