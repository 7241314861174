export const campos = [
  { value: 'idInventario', text: 'Id. inventario' },
  { value: 'idInventarioExterno', text: 'Id. inventario externo' },
  { value: 'actuPropuestaInspeccion', text: 'Actuación propuesta' },
  { value: 'consecuenciasFalloInspeccion', text: 'Consecuencias del fallo' },
  { value: 'defectosInspeccion', text: 'Defectos' },
  { value: 'dianaInspeccion', text: 'Diana' },
  { value: 'distanciaCaidaInspeccion', text: 'Distancia caída diana (metros)' },
  { value: 'evalInstrumentalInspeccion', text: 'Evaluación instrumental' },
  { value: 'evolEsperadaInspeccion', text: 'Evolución esperada' },
  { value: 'fechaRealizacionInspeccion', text: 'Fecha de realización' },
  { value: 'fichaEvaluacionInspeccion', text: '¿Necesita fecha de evaluación?' },
  { value: 'frecuenciaRevisionInspeccion', text: 'Frecuencia de revisión (años)' },
  { value: 'principalInspeccion', text: 'Es principal' },
  { value: 'prioridadInspeccion', text: 'Prioridad' },
  { value: 'probFalloInspeccion', text: 'Probabilidad de fallo' },
  { value: 'probImpactoInspeccion', text: 'Probabilidad de impacto' },
  { value: 'probRoturaRamaInspeccion', text: 'Probabilidad de rotura de la rama' },
  { value: 'probRoturaTroncoInspeccion', text: 'Probabilidad de rotura del tronco' },
  { value: 'probVuelcoInspeccion', text: 'Probabilidad de vuelco' },
  { value: 'ratioOcupacionInspeccion', text: 'Ratio de ocupación' },
  { value: 'riesgoAparenteInspeccion', text: 'Riesgo aparente' },
  { value: 'tamanoParteInspeccion', text: 'Tamaño parte defectible' },
  { value: 'tecnicoInspeccion', text: 'Técnico' },
  { value: 'valorGlobalRiesgoInspeccion', text: 'Valoración global del riesgo' },
];
