import { appConfig } from '../../../config/app';
import { CommunicationConfig, CoreModule, RiegoSubModule } from '../../../config/interfaces';

export const getRiegoCoreModule = (): CoreModule | undefined => {
  const { coreModules } = appConfig;
  return coreModules.find(coreModule => coreModule.module === 'riego');
};

export const getCommunicationConfig = (
  subModule: RiegoSubModule,
): CommunicationConfig | undefined => {
  const riegoModule = getRiegoCoreModule();
  const config = riegoModule?.communicationConfig.find(cc => cc.subModule === subModule);

  return config;
};
