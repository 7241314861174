import Pagination from '@material-ui/lab/Pagination';
import { ChangeEvent } from 'react';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const LazarusPagination = ({ rowCount, currentPage, rowsPerPage, onChangePage }: any) => {
  const countPages = Math.ceil(rowCount / rowsPerPage);
  const handleChangePage = (event: ChangeEvent<unknown>, page: number) => {
    onChangePage(page);
  };
  return (
    <Pagination color="primary" count={countPages} page={currentPage} onChange={handleChangePage} />
  );
};

export default LazarusPagination;
