import { ChangeEvent, FC } from 'react';
import Input, { LazarusInputProps } from '../Input';
import Select, { LazarusSelectProps } from '../Select';

type DynamicInputType = {
  tag: 'Input';
  props: LazarusInputProps;
};

type DynamicSelectType = {
  tag: 'Select';
  props: LazarusSelectProps;
};

export type DynamicInputMetadata<T = any> = (DynamicInputType | DynamicSelectType) & {
  propKey: keyof T & string;
  inputTransformer?: (
    key: string,
    e: ChangeEvent<HTMLInputElement> & ChangeEvent<HTMLSelectElement>,
  ) => Promise<[key: string, value: any][]>;
  defaultValue?: any;
};

export type DynamicInputProps = DynamicInputMetadata & {
  onChange?: (event: [key: string, value: any][]) => void;
  value?: Exclude<any, undefined>;
  disabled?: boolean;
};

const DynamicInput: FC<DynamicInputProps> = ({
  tag,
  props,
  propKey,
  onChange,
  inputTransformer,
  value,
  disabled,
}) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement> & ChangeEvent<HTMLSelectElement>) => {
    if (onChange) {
      if (inputTransformer) {
        inputTransformer(propKey, e).then(res => onChange(res));
      } else {
        onChange([[propKey, e.target.value]]);
      }
    }

    if (props.onChange) props.onChange(e);
  };

  if (tag === 'Input') {
    return (
      <Input
        {...props}
        onChange={handleChange}
        value={value || ''}
        disabled={props.disabled || disabled}
      />
    );
  }

  return (
    <Select
      {...props}
      onChange={handleChange}
      value={value}
      disabled={props.disabled || disabled}
    />
  );
};

export default DynamicInput;
