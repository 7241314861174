import { Route } from '../../routes/routes';
import PlanificacionesListPage from './Planificaciones/List';
import PlanificacionMapPage from './Planificaciones/Map';
import RutasListPage from './Rutas/List';
import RutaMapPage from './Rutas/Map';
import InformesPage from './Informes/List';

export const listsRoutes: Route[] = [
  {
    name: 'Rutas',
    path: '/rutas',
    to: `rutas`,
    Component: RutasListPage,
    MapComponent: RutaMapPage,
    requirePermission: 'view-rutas',
  },
  {
    name: 'Planificaciones',
    path: '/planificaciones',
    to: `planificaciones`,
    Component: PlanificacionesListPage,
    MapComponent: PlanificacionMapPage,
    requirePermission: 'view-flotas-planificaciones',
  },
  {
    name: 'Informes',
    path: '/informes',
    to: `informes`,
    Component: InformesPage,
    requirePermission: 'view-rutas',
  },
];
