import { useState } from 'react';
import { MaterialesData } from '../context';

export interface MaterialesHookData {
  data: MaterialesData;
  handleChange: (newData: { name: keyof MaterialesData; value: string }) => void;
  handleChangeActuacion: (newIdActuacion: string) => void;
}

export function useMateriales(): MaterialesHookData {
  const [data, setData] = useState<MaterialesData>({
    idsElementosCodificados: '',
    idsElementosNoCodificados: '',
    cantidadesElementosCodificados: '',
    cantidadesElementosNoCodificados: '',
    tarifasElementosCodificados: '',
    idActuacion: '',
  });

  const handleChange = ({ name, value }: { name: keyof MaterialesData; value: string }) => {
    setData(prev => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const handleChangeActuacion = (newIdActuacion: string) => {
    setData({ ...data, idActuacion: newIdActuacion });
  };

  return { data, handleChange, handleChangeActuacion };
}
