import { appConfig } from '../../../config/app';
import { CommunicationConfig, CoreModule, LimpiezaSubModule } from '../../../config/interfaces';
import proj4 from 'proj4';
import * as wellknown from 'wellknown';

export const getLimpiezaCoreModule = (): CoreModule | undefined => {
  const { coreModules } = appConfig;
  return coreModules.find(coreModule => coreModule.module === 'limpieza');
};

export const getCommunicationConfig = (
  subModule: LimpiezaSubModule,
): CommunicationConfig | undefined => {
  const limpiezaModule = getLimpiezaCoreModule();
  const config = limpiezaModule?.communicationConfig.find(cc => cc.subModule === subModule);

  return config;
};

export function parseCoordenadas(coordString: string) {
  // Define la proyección UTM correspondiente (por ejemplo, UTM Zone 33T)
  proj4.defs('EPSG:25830', '+proj=utm +zone=30 +ellps=GRS80 +units=m +no_defs');

  let latLng: number[] = [];

  try {
    // Intenta analizar la cadena de coordenadas en formato WKT
    const geometry = wellknown.parse(coordString);

    if (
      geometry &&
      geometry.type === 'Point' &&
      Array.isArray(geometry.coordinates) &&
      geometry.coordinates.length === 2
    ) {
      const x = geometry.coordinates[0];
      const y = geometry.coordinates[1];

      // Realiza la conversión a latitud y longitud
      const lonLat = proj4('EPSG:25830', 'EPSG:4326', [x, y]);
      latLng = [lonLat[1], lonLat[0]];

      // latLng ahora contiene las coordenadas en formato [latitud, longitud]
      console.log('Latitud:', latLng[1]);
      console.log('Longitud:', latLng[0]);
    } else {
      console.error('El formato de coordenadas no es válido.');
    }
  } catch (error) {
    console.error('Error al analizar las coordenadas:', error);
  }

  return latLng;
}

export function parseMultiPolygon(multiPolygonWKT: string) {
  const polygons = wellknown.parse(multiPolygonWKT);

  if (polygons && polygons.type === 'MultiPolygon') {
    return polygons.coordinates[0].map(polygonCoordinates => {
      return polygonCoordinates.map(coordinate =>
        parseCoordenadas('POINT(' + coordinate[0] + ' ' + coordinate[1] + ')'),
      );
    });
  }

  return [];
}
