import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getById, post } from '../../../../API/riego/puntos-de-suministro';
import {
  BocasRiego,
  CamposPrincipales,
  Codigos,
  Comentarios,
  Contrato,
  Edificio,
  Fuente,
  MainInfo,
  Otros,
  RiegoAutomatico,
  ServicioSuministrado,
  Sitios,
  Tarifa,
  UltimaLectura,
} from '../../../../API/riego/puntos-de-suministro/interfaces';
import BoxComplexInput from '../../../../components/BoxComplexInput';
import Button from '../../../../components/Button';
import Col from '../../../../components/Col';
import CollapsibleSection from '../../../../components/CollapsibleSection';
import DynamicForm from '../../../../components/DynamicForm';
import H1 from '../../../../components/H1';
import Row from '../../../../components/Row';
import useEdit from '../../../../hooks/useEdit';
import { showErrorAlert, showSuccessAlert } from '../../../../libs/LazarusAlert';
import useMetadata from './hooks';

const PuntosDeSuministroCreatePage = (): JSX.Element => {
  const [mainInfo, setMainInfo] = useState<MainInfo>({} as MainInfo);
  const [camposPrincipales, setCamposPrincipales] = useState<CamposPrincipales>(
    {} as CamposPrincipales,
  );
  const [bocasRiego, setBocasRiego] = useState<BocasRiego>({} as BocasRiego);
  const [codigos, setCodigos] = useState<Codigos>({} as Codigos);
  const [comentarios, setComentarios] = useState<Comentarios>({} as Comentarios);
  const [contrato, setContrato] = useState<Contrato>({} as Contrato);
  const [edificio, setEdificio] = useState<Edificio>({} as Edificio);
  const [fuente, setFuente] = useState<Fuente>({} as Fuente);
  const [otros, setOtros] = useState<Otros>({} as Otros);
  const [tarifa, setTarifa] = useState<Tarifa>({} as Tarifa);
  const [riegoAutomatico, setRiegoAutomatico] = useState<RiegoAutomatico>({} as RiegoAutomatico);
  const [sitios, setSitios] = useState<Sitios>({} as Sitios);
  const [ultimaLectura, setUltimaLectura] = useState<UltimaLectura>({} as UltimaLectura);
  const [serviciosSuministrados, setServiciosSuminsitrados] = useState<ServicioSuministrado[]>([]);

  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const dataToEdit = useEdit(getById, '/riego/listado/puntos-de-suministro');

  useEffect(() => {
    if (dataToEdit) {
      setMainInfo(dataToEdit.mainInfo);
      setBocasRiego(dataToEdit.bocasRiego);
      setCamposPrincipales(dataToEdit.camposPrincipales);
      setCodigos(dataToEdit.codigos);
      setComentarios(dataToEdit.comentarios);
      setEdificio(dataToEdit.edificio);
      setFuente(dataToEdit.fuente);
      setUltimaLectura(dataToEdit.ultimaLectura);
      setOtros(dataToEdit.otros);
      setTarifa(dataToEdit.tarifa);
      setServiciosSuminsitrados(dataToEdit.serviciosSuministrados);
      setRiegoAutomatico(dataToEdit.riegoAutomatico);
      setContrato(dataToEdit.contrato);
      setSitios(dataToEdit.sitios);
    }
  }, [dataToEdit]);

  const {
    mainInfoMetadata,
    camposPrincipalesMetadata,
    bocasRiegoMetadata,
    codigosMetadata,
    comentariosMetadata,
    contratoMetadata,
    edificioMetadata,
    fuenteMetadata,
    otrosMetadata,
    tarifaMetadata,
    riegoAutomaticoMetadata,
    sitiosMetadata,
    ultimaLecturaMetadata,
    serviciosSuministradosMetadata,
  } = useMetadata(setMainInfo);

  const handleSubmit = () => {
    const request = {
      mainInfo,
      camposPrincipales,
      bocasRiego,
      codigos,
      comentarios,
      contrato,
      edificio,
      fuente,
      otros,
      tarifa,
      riegoAutomatico,
      sitios,
      ultimaLectura,
      serviciosSuministrados,
    };

    setLoading(true);
    post(request)
      .then(() => {
        showSuccessAlert({ title: '¡Éxito!', text: 'Punto de suministro guardado con éxito.' });
        navigate('/riego/listado/puntos-de-suministro');
      })
      .catch(() => {
        showErrorAlert({ title: 'Error', text: 'Error al guardar el material.' });
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      <H1>Datos</H1>
      <CollapsibleSection title="Información principal">
        <DynamicForm
          spacing="lg"
          onChange={setMainInfo}
          metadata={mainInfoMetadata}
          value={mainInfo}
        />
      </CollapsibleSection>
      <CollapsibleSection title="Id principales">
        <DynamicForm
          spacing="lg"
          onChange={setCamposPrincipales}
          metadata={camposPrincipalesMetadata}
          value={camposPrincipales}
        />
      </CollapsibleSection>
      <CollapsibleSection title="Id principales">
        <DynamicForm
          spacing="lg"
          onChange={setCamposPrincipales}
          metadata={camposPrincipalesMetadata}
          value={camposPrincipales}
        />
      </CollapsibleSection>
      <CollapsibleSection title="Bocas de riego">
        <DynamicForm
          spacing="lg"
          onChange={setBocasRiego}
          metadata={bocasRiegoMetadata}
          value={bocasRiego}
        />
      </CollapsibleSection>
      <CollapsibleSection title="Códigos">
        <DynamicForm
          spacing="lg"
          onChange={setCodigos}
          metadata={codigosMetadata}
          value={codigos}
        />
      </CollapsibleSection>
      <CollapsibleSection title="Comentarios">
        <DynamicForm
          spacing="lg"
          onChange={setComentarios}
          metadata={comentariosMetadata}
          value={comentarios}
        />
      </CollapsibleSection>
      <CollapsibleSection title="Contrato">
        <DynamicForm
          spacing="lg"
          onChange={setContrato}
          metadata={contratoMetadata}
          value={contrato}
        />
      </CollapsibleSection>
      <CollapsibleSection title="Edificio">
        <DynamicForm
          spacing="lg"
          onChange={setEdificio}
          metadata={edificioMetadata}
          value={edificio}
        />
      </CollapsibleSection>
      <CollapsibleSection title="Fuente">
        <DynamicForm spacing="lg" onChange={setFuente} metadata={fuenteMetadata} value={fuente} />
      </CollapsibleSection>
      <CollapsibleSection title="Otros">
        <DynamicForm spacing="lg" onChange={setOtros} metadata={otrosMetadata} value={otros} />
      </CollapsibleSection>
      <CollapsibleSection title="Tarifa">
        <DynamicForm spacing="lg" onChange={setTarifa} metadata={tarifaMetadata} value={tarifa} />
      </CollapsibleSection>
      <CollapsibleSection title="Riego automático">
        <DynamicForm
          spacing="lg"
          onChange={setRiegoAutomatico}
          metadata={riegoAutomaticoMetadata}
          value={riegoAutomatico}
        />
      </CollapsibleSection>
      <CollapsibleSection title="Sitios">
        <DynamicForm spacing="lg" onChange={setSitios} metadata={sitiosMetadata} value={sitios} />
      </CollapsibleSection>
      <CollapsibleSection title="Última lectura">
        <DynamicForm
          spacing="lg"
          onChange={setUltimaLectura}
          metadata={ultimaLecturaMetadata}
          value={ultimaLectura}
        />
      </CollapsibleSection>
      <Row style={{ marginBottom: 30 }}>
        <BoxComplexInput
          closedMessage={`Hay ${serviciosSuministrados.length} servicios suministrados.`}
          metadata={serviciosSuministradosMetadata}
          onRemove={row => setServiciosSuminsitrados(cur => cur.filter(c => c !== row))}
          onAdd={row => setServiciosSuminsitrados(cur => [...cur, row])}
          label="Piezas de recambio"
          dataSource={serviciosSuministrados}
        />
      </Row>
      <Row justifyContent="center">
        <Col xs={3} sm={2}>
          <Button color="primary" outline disabled={loading}>
            Cancelar
          </Button>
        </Col>
        <Col xs={3} sm={2}>
          <Button color="primary" disabled={loading} onClick={handleSubmit}>
            Guardar
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default PuntosDeSuministroCreatePage;
