import { FC, useState } from 'react';
import { SubModule } from '../../../routes/routes';
import Icon from '../../Icon';
import Menu from '../../Menu';
import cn from 'classnames';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { setActiveItem } from '../../../redux/NavbarSlice/navbarSlice';

interface Props {
  subModule: SubModule;
}

const NavLinkMenu: FC<Props> = ({ subModule }) => {
  const activeItem = useAppSelector(state => state.navbar.activeItem);
  const dispatch = useAppDispatch();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const onClickItem = () => {
    setIsOpen(false);
    dispatch(setActiveItem({ activeItem: subModule.name }));
  };

  const classNames = cn(
    'module-page__link',
    { 'module-page__link--active': activeItem === subModule.name },
    { 'module-page__link--open': isOpen },
  );

  return (
    <div className="module-page__item" style={{ display: 'flex', position: 'relative' }}>
      <p className={classNames} onClick={() => setIsOpen(!isOpen)}>
        <span>{subModule.name}</span>
        <Icon
          name={isOpen ? 'down' : 'right'}
          color={activeItem === subModule.name ? 'primary' : 'black'}
        />
      </p>

      {subModule.subRoutes && (
        <Menu onClickItem={onClickItem} open={isOpen} items={subModule.subRoutes} />
      )}
    </div>
  );
};

export default NavLinkMenu;
