import { createApiInstance } from '../../api';
import { User } from '../interfaces';
import { responseTransformer } from './response-transformer';

export const execute = async (filters: { group?: string }): Promise<User[]> => {
  const api = createApiInstance();
  const { data } = await api.get<User[]>('/usuarios', {
    params: filters,
    transformResponse: [responseTransformer],
  });

  return data;
};
