import { createApiInstance } from '../../../api';
import { ProgramacionPlanificacion } from '../interfaces';

export const execute = async (dataForCreate: ProgramacionPlanificacion): Promise<any> => {
  const api = createApiInstance();
  const { data } = await api.post<ProgramacionPlanificacion>(
    '/flotas/programaciones/programar-planificaciones',
    dataForCreate,
  );

  return data;
};
