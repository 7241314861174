import { GetByIdResponse } from '.';
import { Documento } from '../../../shared/interfaces';
import { EspecificacionTecnica } from '../../../shared/inventarios/interfaces';
import {
  MainInfo,
  ElementoRelacionado,
  AtributoObservacion,
  PiezaRecambio,
  ElementoInventario,
} from '../interfaces';

export const responseTransformer = (response: string): GetByIdResponse | null => {
  const i: ElementoInventario = JSON.parse(response);

  if (!i?.id) return null;

  return {
    mainInfo: {
      idInventario: i.elemento_inventario.idInventario,
      descripcion: i.elemento_inventario.descripcion,
      padre: i.elemento_inventario.padre,
      jerarquiaClasificacion: i.elemento_inventario.jerarquiaClasificacion,
      jerarquiaClasificacionDescripcion: i.elemento_inventario.jerarquiaClasificacionDescripcion,
      distrito: i.elemento_inventario.distrito,
      distritoDescripcion: i.elemento_inventario.distritoDescripcion,
      barrio: i.elemento_inventario.barrio,
      barrioDescripcion: i.elemento_inventario.barrioDescripcion,
      tipoVia: i.elemento_inventario.tipoVia,
      nombreVia: i.elemento_inventario.nombreVia,
      numeroVia: i.elemento_inventario.numeroVia,
      idInventarioInterno: i.elemento_inventario.idInventarioInterno,
      idInventarioExterno: i.elemento_inventario.idInventarioExterno,
      claseFuncionGIS: i.elemento_inventario.claseFuncionGIS,
      descripcionLarga: i.elemento_inventario.descripcionLarga,
      codigoPostal: i.elemento_inventario.codigoPostal,
      contrato: i.elemento_inventario.contrato,
      contratoDescripcion: i.elemento_inventario.contratoDescripcion || '',
      coordenadas: i.elemento_inventario.coordenadas || '',
      estado: i.elemento_inventario.estado,
      descripcionUbicacion: i.elemento_inventario.descripcionUbicacion || '',
      fechaEstado: i.elemento_inventario.fechaEstado.substring(0, 10),
      fechaDesinstalacion: i.elemento_inventario.fechaInstalacion.substring(0, 10),
      fechaInstalacion: i.elemento_inventario.fechaInstalacion.substring(0, 10),
      lote: i.elemento_inventario.lote,
      loteDescripcion: i.elemento_inventario.loteDescripcion || '',
      ndp: i.elemento_inventario.ndp,
      observaciones: i.elemento_inventario.observaciones,
      observacionesLargas: i.elemento_inventario.observacionesLargas,
      planta: i.elemento_inventario.planta || '',
      posicionamientoMapa: i.elemento_inventario.posicionamientoMapa,
      servicio: i.elemento_inventario.servicio,
      servicioDescripcion: i.elemento_inventario.servicioDescripcion || '',
      usuarioEditor: i.elemento_inventario.usuarioEditor || '',
      id: i.elemento_inventario.id,
      id_elemento_inventario: i.id_elemento_inventario,
      accesible: i.elemento_inventario.accesible || '',
    } as MainInfo,
    atributosObservacion: i.elemento_inventario.atributo_observacion.map(a => ({
      id: a.id,
      descripcionObservacion: a.descripcionObservacion,
      descripcionLargaObservacion: a.descripcionLargaObservacion,
    })) as AtributoObservacion[],
    documentos: i.elemento_inventario.documentos.map(dc => ({
      documentoAdjunto: dc.documentoAdjunto,
      descripcionDoc: dc.descripcionDoc,
      idDocumento: dc.idDocumento,
      urlDocumento: dc.urlDocumento,
      tipoDocumento: dc.tipoDocumento,
      id: dc.id,
    })) as Documento[],
    piezasRecambios: i.elemento_inventario.pieza_recambio.map(p => ({
      id: p.id,
      codigoArticulo: p.codigoArticulo,
      cantidadArticulo: p.cantidadArticulo,
    })) as PiezaRecambio[],
    elementosRelacionados: i.elemento_inventario.elemento_relacionado.map(e => ({
      id: e.id,
      activoDestino: e.activoDestino,
      activoOrigen: e.activoOrigen,
      activoUbicacionDestino: e.activoUbicacionDestino,
      activoUbicacionOrigen: e.activoUbicacionOrigen,
      idRelacionActivo: e.idRelacionActivo,
    })) as ElementoRelacionado[],
    especificacionesTecnicas: i.elemento_inventario.especificaciones_tecnicas.map(
      et =>
        ({
          et_cambiado: et.ET_Cambiado ? 'Sí' : 'No',
          et_descripcion: et.ET_Descripcion,
          et_id: et.ET_ID,
          et_idLinea: et.ET_IDLinea,
          et_seccion: et.ET_Seccion,
          et_unidadMedida: et.ET_UnidadMedida,
          opcion: et.ET_ValorAlfanumerico
            ? 'ET_ValorAlfanumerico'
            : et.ET_ValorAlfanumerico
            ? 'ET_ValorNumerico'
            : 'ET_Tabla',
          et_valor: et.ET_ValorAlfanumerico || et.ET_ValorNumerico || et.ET_ValorTabla,
          id: et.id,
          id_inventario: et.id_inventario,
        } as EspecificacionTecnica),
    ),
  };
};
