import { Dispatch, FC, SetStateAction } from 'react';
import { NavLink } from 'react-router-dom';
import Icon from '../../Icon';
import { HeaderMenu } from '../interfaces';

interface Props {
  /**
   * Menu abierto?
   */
  open: boolean;

  /**
   * Items a mostrar en el header
   */
  headerMenu: HeaderMenu[];

  /**
   * Handler de estado del menu
   */
  handleOpenHeader: Dispatch<SetStateAction<boolean>>;
}

const HeaderMenuComponent: FC<Props & React.HTMLAttributes<HTMLDivElement>> = ({
  open,
  headerMenu,
  handleOpenHeader,
}) => {
  return (
    <div className={`header-menu__items ${open ? 'open' : ''}`}>
      {headerMenu.map((item, i) => (
        <NavLink
          to={item.path}
          key={i}
          onClick={() => {
            handleOpenHeader(false);
          }}>
          <div className="header-menu__item">
            <div className="header-menu__item-container">
              <Icon color="white" name={item.icon} />
              <p className="header-menu__item-text text-white">{item.label}</p>
            </div>
          </div>
        </NavLink>
      ))}
    </div>
  );
};

export default HeaderMenuComponent;
