import { BoxCriterioItem } from '../../../components/BoxCriterio';
import { appConfig } from '../../../config/app';
import { ZZVVSubModule } from '../../../config/interfaces';
import { ExportRequest, ExportRequestArtemisa } from '../../shared/interfaces';

export const requestTransformer = ({
  criterios,
  functionName,
  indiceResultado,
}: //subModule,
{
  criterios: BoxCriterioItem[];
  functionName: string;
  indiceResultado: string;
  resultadosEsperados?: string;
  subModule?: ZZVVSubModule;
}): ExportRequest | ExportRequestArtemisa => {
  const campos = [];
  const operadores = [];
  const consultas = [];

  for (const criterio of criterios) {
    campos.push(criterio.campo);
    operadores.push(criterio.operador);
    consultas.push(criterio.criterio);
  }

  //const isArtemisa = appConfig.appTitle === 'Artemisa';
  const moduleToFind = 'zzvv'; // Módulo a buscar

  // Busca la configuración correspondiente en communicationConfig basada en el módulo 'zzvv'
  const communicationConfigItem = appConfig.coreModules
    .find(module => module.module === moduleToFind)
    ?.communicationConfig.find(config => config.exportFunctionName === functionName);

  // Obtén el límite si existe en la configuración
  const limite = communicationConfigItem?.resultadosEsperados ?? '200';

  /*if (isArtemisa) {
    if (
      subModule &&
      ['incidencias', 'inspecciones', 'evaluaciones-visuales', 'zonas-exclusion'].includes(
        subModule,
      )
    ) {
      return {
        function: functionName,
        campos: campos.join(','),
        operadores: operadores.join(','),
        consultas: consultas.join(','),
        indiceResultado: indiceResultado,
        resultadosEsperados: limite,
      };
    }

    return {
      function: functionName,
      campo: campos.join(']['),
      operador: operadores.join(']['),
      consulta: consultas.join(']['),
      indiceResultado: indiceResultado,
      resultadosEsperados: limite,
    };
  }*/

  return {
    function: functionName,
    campos: campos.join(';'),
    operadores: operadores.join(';'),
    consultas: consultas.join(';'),
    indiceResultado: indiceResultado,
    resultadosEsperados: limite,
  };
};
