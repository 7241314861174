import React, { useState, useEffect, useContext } from 'react';
import LeafletMap from './Components/LeafletMap';
import { getCartografia, getRutasUtils } from './utils';
import { GeoJSONLayer, RutaMapa } from './interface';
import { RutasContext } from '../../context';
import { Rutas } from '../../../../API/flotas';
import { Ruta } from '../../../../API/flotas/rutas/interfaces';

const RutaMapPage = () => {
  const [geoJSONLayers, setGeoJSONLayers] = useState<GeoJSONLayer[]>([]);
  const [routes, setRoutes] = useState<RutaMapa[]>([]);
  const { rutas } = useContext(RutasContext);
  // const center: [number, number] = [40.4920557, -3.6338581];
  // const zoom = 13;

  const fetchData = async () => {
    try {
      if (rutas.length > 0) {
        // Obtener las rutas actualizadas
        let updatedRoutes: Ruta[] = [];

        // Obtener las rutas actualizadas una a una
        for (const rutaElem of rutas) {
          const ruta = await Rutas.get([
            { key: 'matricula', value: rutaElem.matricula },
            { key: 'desde', value: rutaElem.fecha },
            { key: 'hasta', value: rutaElem.fecha },
          ]);
          updatedRoutes = updatedRoutes.concat(ruta);
        }

        // Ejecutar las dos funciones asincrónicas en paralelo
        const [geoJSONLayers, routes] = await Promise.all([
          getCartografia(),
          getRutasUtils(updatedRoutes),
        ]);

        // Verificar si se recibieron las capas de cartografía
        if (geoJSONLayers) {
          setGeoJSONLayers(geoJSONLayers);
        } else {
          setGeoJSONLayers([]);
        }

        // Verificar si se recibieron las rutas
        if (routes) {
          setRoutes(routes);
        } else {
          setRoutes([]);
        }
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  useEffect(() => {
    fetchData();

    const interval = setInterval(fetchData, 30000); // Ejecutar cada 0,5 minutos (tiempo en ms)

    return () => {
      clearInterval(interval); // Limpiar el intervalo cuando el componente se desmonte
    };
  }, [rutas]);

  return (
    <div>
      {geoJSONLayers.length > 0 && routes.length > 0 && (
        <LeafletMap routes={routes} geoJSONLayers={geoJSONLayers} />
      )}
    </div>
  );
};

export default RutaMapPage;
