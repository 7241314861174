import { useEffect, useMemo, useState } from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';
import { Navigate, useParams } from 'react-router-dom';
import { Roles, Users } from '../../API';
import { Role } from '../../API/roles/interfaces';
import { User } from '../../API/users/interfaces';
import Icon from '../../components/Icon';
import NoResults from '../../components/NoResults';
import Page from '../../components/Page';
import LazarusPagination from '../../components/Pagination';
import { useAuth } from '../../hooks/useAuth';
import { useDatatable } from '../../hooks/useDatatable';
import { showErrorAlert, showSuccessAlert, showWarningAlert } from '../../libs/LazarusAlert';
import { setShowLogo } from '../../redux/HeaderSlice/headerSlice';
import { useAppDispatch } from '../../redux/hooks';
import { getCommonModule } from '../../shared/utils';
import NavFilters, { ActiveModule } from './components/NavFilters';
import UserModal from './components/UserModal';

interface UserParams {
  coreModule?: ActiveModule;
}

const UserIndex = (): JSX.Element => {
  const [openNewModal, setOpenNewModal] = useState<boolean>(false);
  const [openEditModal, setOpenEditModal] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<User>();
  const [roles, setRoles] = useState<Role[]>([]);
  const dispatch = useAppDispatch();
  const params: UserParams = useParams();
  const userCommonModule = getCommonModule('Usuarios');
  if (!userCommonModule || !userCommonModule.active) return <Navigate to="/" />;

  const {
    filteredItems,
    handleSetRows,
    handleChangeFilterText,
    handleClear,
    filterText,
  } = useDatatable<User>({});
  const { canUse } = useAuth();

  const handleFilter = () => {
    Users.get({ group: params.coreModule }).then(res => handleSetRows(res));
  };

  const columns: TableColumn<User>[] = useMemo(
    () => [
      {
        name: 'Imagen',
        cell: row => (
          <div className="table__img">
            <img src={row.photo} alt="Foto Perfil" />
          </div>
        ),
      },
      {
        name: 'Nombre',
        selector: row => row.name,
      },
      {
        name: 'Correo',
        selector: row => row.email,
      },
      {
        name: 'Rol',
        selector: row => row.role.name,
      },
      {
        name: 'Editar',
        cell: row =>
          canUse('edit-users', 'app') ? (
            <Icon onClick={() => handleOpenEditUserModal(row)} name="edit" color="primary" />
          ) : null,
      },
      {
        name: 'Eliminar',
        cell: row =>
          canUse('delete-users', 'app') ? (
            <Icon onClick={() => handleDelete(row)} name="trash" color="primary" />
          ) : null,
      },
    ],
    [],
  );

  const handleDelete = (user: User) => {
    showWarningAlert({
      title: `Eliminar usuario ${user.name}`,
      text: 'Al continuar, se perderán los datos y no podrás recuperarlos',
    }).then(result => {
      if (result.isConfirmed) {
        Users.delete(user.id)
          .then(() => {
            showSuccessAlert({
              title: 'Usuario eliminado',
            });

            handleFilter();
          })
          .catch(err => {
            console.log(err);
            showErrorAlert({ text: 'No se ha podido eliminar el usuario' });
          });
      }
    });
  };

  const handleOpenNewUserModal = (): void => {
    setSelectedUser(undefined);
    setOpenNewModal(!openNewModal);
  };

  const handleOpenEditUserModal = (user?: User): void => {
    setSelectedUser(user);
    setOpenEditModal(!openEditModal);
  };

  useEffect(() => {
    dispatch(setShowLogo({ showLogo: true }));
  }, []);

  useEffect(() => {
    handleFilter();
  }, [params]);

  useEffect(() => {
    Roles.get().then(res => setRoles(res));
  }, []);

  return (
    <Page title="Usuarios" icon="user" titleType="title" className="users">
      <NavFilters
        activeModule={params.coreModule || 'users'}
        textNewButton="Nuevo usuario"
        handleClickNewButton={handleOpenNewUserModal}
        handleChangeFilterText={handleChangeFilterText}
        handleClear={handleClear}
        filterText={filterText}
      />

      <div className="table table-users">
        <DataTable
          responsive
          columns={columns}
          data={filteredItems}
          pagination
          paginationPerPage={10}
          noDataComponent={<NoResults />}
          paginationComponent={LazarusPagination}
        />
      </div>
      {openNewModal && (
        <UserModal
          title="Nuevo Usuario"
          openModal={openNewModal}
          handleOpenModal={handleOpenNewUserModal}
          successCallback={handleFilter}
          roles={roles}
        />
      )}

      {openEditModal && (
        <UserModal
          title="Editar Usuario"
          openModal={openEditModal}
          handleOpenModal={() => handleOpenEditUserModal()}
          userData={{ ...selectedUser, roleId: selectedUser?.role.id.toString(), photo: undefined }}
          successCallback={handleFilter}
          roles={roles}
        />
      )}
    </Page>
  );
};

export default UserIndex;
