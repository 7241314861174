import { FilterItem } from '../../../../components/Table/Filters';
import { GetLimpiezaActuacionesRequest } from '../interfaces';

export const requestTransformer = (filterItems: FilterItem[]): GetLimpiezaActuacionesRequest => {
  const request: GetLimpiezaActuacionesRequest = { filtros: {} };
  for (const filter of filterItems) {
    request.filtros[filter.key] = filter.value;
  }

  return request;
};
