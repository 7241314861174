import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';
import { Vehiculos } from '../../../../API/flotas';
import { Vehiculo } from '../../../../API/flotas/vehiculos/interfaces';
import MaterialIconMenu, { MaterialIconMenuProps } from '../../../../components/MaterialIconMenu';
import Navbar from '../../../../components/Navbar';
import NoResults from '../../../../components/NoResults';
import Page from '../../../../components/Page';
import LazarusPagination from '../../../../components/Pagination';
import Filters, { ModuleNavigations } from '../../../../components/Table/Filters';
import { useAuth } from '../../../../hooks/useAuth';
import { useDatatable } from '../../../../hooks/useDatatable';
import { downloadCSV } from '../../../../shared/utils';
import { basicRoutes } from '../../routes';
import { listsRoutes } from '../../routes.lists';
import VehicleModal from './components/VehicleModal';
import { TabPanel } from '../../../../components/Layout/ListLayout/index';
import VehiculoMapPage from '../Map';

const addSelectedToRow = (row: Vehiculo): void => {
  document.getElementById(`row-${row.id}`)?.classList.add('active');
};

const removeSelectedToRow = (row: Vehiculo) => {
  document.getElementById(`row-${row.id}`)?.classList.remove('active');
};

export const moduleNavigations: ModuleNavigations = {};

const styles = {
  // marginTop: 20,
};

const VehiculosListPage = (): JSX.Element => {
  const {
    filteredItems,
    handleSetRows,
    handleChangeSelected,
    filterText,
    handleClear,
    handleChangeFilterText,
    selectedRows,
  } = useDatatable<Vehiculo>({
    removeSelectedToRow,
    addSelectedToRow,
  });
  const [selectedVehicle, setSelectedVehicle] = useState<Vehiculo>();
  const [openEditModal, setOpenEditModal] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState(0);
  const { canUse } = useAuth();
  const [vehiculos, setVehiculos] = useState<Vehiculo[]>([]);
  const matriculaExcluida = '6363LTJ';

  const columns: TableColumn<Vehiculo>[] = useMemo(
    () => [
      {
        name: 'ID',
        selector: row => row.id,
      },
      {
        name: 'Tipo',
        selector: row => row.tipo.nombre,
      },
      {
        name: 'Alias',
        selector: row => row.cAlias,
      },
      {
        name: 'Matricula',
        selector: row => row.matricula,
      },
      {
        name: 'Descripción',
        selector: row => row.cDescripcion,
      },
      {
        name: 'Propietario',
        selector: row => row.propietarioOrganizacion,
      },
      {
        name: 'Estado',
        selector: row => row.estado,
      },
      {
        cell: row => {
          if (!canUse('edit-vehiculos')) return null;

          const paramsToMenu: MaterialIconMenuProps<Vehiculo> = {
            row,
            size: 'medium',
            rows: selectedRows.length ? selectedRows : [row],
            onOpenMenu: () => addSelectedToRow(row),
            onCloseMenu: () => removeSelectedToRow(row),
            actions: [{ label: 'Editar', onClick: row => handleEditModal(row) }],
          };
          return <MaterialIconMenu {...paramsToMenu} />;
        },
        allowOverflow: true,
        button: true,
        width: '71px',
      },
    ],
    [selectedRows],
  );

  const getVehiculos = async () => {
    try {
      const vehiculosGet = await Vehiculos.get();
      const res = vehiculosGet.filter(vehiculo => vehiculo.matricula !== matriculaExcluida);
      handleSetRows(res);
      setVehiculos(res);
      vehiculos.length;
      return res;
    } catch (error) {
      console.error('Error al obtener vehículos:', error);
    }
  };

  // eslint-disable-next-line @typescript-eslint/ban-types
  const handleActiveTab = async (event: ChangeEvent<{}>, activeTab: number) => {
    setActiveTab(activeTab);

    if (activeTab === 1) {
      await getVehiculos();
      handleShowMap();
    }
  };

  const handleEditModal = (vehiculo?: Vehiculo): void => {
    setSelectedVehicle(vehiculo);
    setOpenEditModal(!openEditModal);
  };

  const handleShowMap = async () => {
    try {
      const res = await getVehiculos(); // Espera a que se resuelva la promesa
      if (res) {
        setVehiculos(res); // Actualiza el estado con los vehículos si res tiene un valor
      } else {
        // Tratar el caso en el que res es undefined
        console.error('getVehiculos() devolvió undefined');
      }
    } catch (error) {
      console.error('Error al obtener vehículos:', error);
    }
  };

  useEffect(() => {
    handleShowMap(); // Llama a handleShowMap directamente
  }, []);

  return (
    <div className="flotas">
      <Page title="Flotas" icon="truck" titleType="title">
        <Navbar
          submodules={basicRoutes.concat(
            listsRoutes
              .filter(
                listRoute => listRoute.requirePermission && canUse(listRoute.requirePermission),
              )
              .map(listRoute => ({
                ...listRoute,
                to: `/flotas/listado/${listRoute.to}`,
              })),
          )}
        />

        <Tabs
          value={activeTab}
          onChange={handleActiveTab}
          aria-label="tabs"
          className="list-map-tabs"
        >
          <Tab label="Lista" />
          <Tab id="map-tab" label="Mapa" />
        </Tabs>
        <TabPanel value={activeTab} index={0} className="tab-panel">
          <Filters
            handleClear={handleClear}
            filterText={filterText}
            handleChangeFilterText={handleChangeFilterText}
            handleFilter={getVehiculos}
            metadata={[]}
            hasItems={!!filteredItems.length}
            moduleNavigations={moduleNavigations}
            requirePermissions={requirePermissions}
            onExportTable={() => {
              downloadCSV(
                filteredItems,
                [
                  { columnHeader: 'ID', columnSlug: 'id' },
                  { columnHeader: 'Descripción', columnSlug: 'cDescripcion' },
                  { columnHeader: 'Matricula', columnSlug: 'matricula' },
                  { columnHeader: 'Propietario', columnSlug: 'propietarioOrganizacion' },
                  { columnHeader: 'Estado', columnSlug: 'estado' },
                ],
                'vehiculos.csv',
              );
            }}
          />

          <div style={styles}>
            <DataTable
              responsive
              columns={columns}
              data={filteredItems}
              pagination
              paginationPerPage={10}
              noDataComponent={<NoResults />}
              paginationComponent={LazarusPagination}
              onSelectedRowsChange={handleChangeSelected}
            />

            {openEditModal && selectedVehicle && (
              <VehicleModal
                title="Editar Vehículo"
                openModal={openEditModal}
                handleOpenModal={() => handleEditModal()}
                vehicleData={selectedVehicle}
                successCallback={getVehiculos}
              />
            )}
          </div>
        </TabPanel>
        <TabPanel value={activeTab} index={1}>
          <VehiculoMapPage />
        </TabPanel>
      </Page>
    </div>
  );
};

export default VehiculosListPage;

export const requirePermissions = {
  lists: { section: 'flotas', permission: 'list-vehiculos' },
};
