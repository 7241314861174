import { GetByIdResponse } from '.';
import { EspecificacionTecnica } from '../../../shared/inventarios/interfaces';
import { MainInfo, GetLimpiezaInventarioResponse } from '../interfaces';

export const responseTransformer = (response: string): GetByIdResponse[] => {
  const responseJSON: GetLimpiezaInventarioResponse = JSON.parse(response);

  return responseJSON.elementosInventario.map(i => ({
    mainInfo: {
      id: i.id,
      idMainInfo: i.id,
      id_elemento_inventario: i.id_elemento_inventario,
      descripcion: i.elemento_inventario.descripcion,
      padre: i.elemento_inventario.padre,
      jerarquiaClasificacion: i.elemento_inventario.jerarquiaClasificacion,
      jerarquiaClasificacionDescripcion: i.elemento_inventario.jerarquiaClasificacionDescripcion,
      distrito: i.elemento_inventario.distrito,
      distritoDescripcion: i.elemento_inventario.distritoDescripcion,
      barrio: i.elemento_inventario.barrio,
      barrioDescripcion: i.elemento_inventario.barrioDescripcion,
      tipoVia: i.elemento_inventario.tipoVia,
      nombreVia: i.elemento_inventario.nombreVia,
      numeroVia: i.elemento_inventario.numeroVia,
      idInventarioInterno: i.elemento_inventario.idInventarioInterno,
      idInventarioExterno: i.elemento_inventario.idInventarioExterno,
      claseFuncionGIS: i.elemento_inventario.claseFuncionGIS,
      descripcionLarga: i.elemento_inventario.descripcionLarga,
      codigoPostal: i.elemento_inventario.codigoPostal,
      contrato: i.elemento_inventario.contrato,
      contratoDescripcion: i.elemento_inventario.contratoDescripcion,
      coordenadas: i.elemento_inventario.coordenadas,
      estado: i.elemento_inventario.estado,
      descripcionUbicacion: i.elemento_inventario.descripcionUbicacion,
      fechaEstado: i.elemento_inventario.fechaEstado?.split(' ')[0],
      fechaDesinstalacion: i.fechaDesinstalacion?.split(' ')[0],
      fechaInstalacion: i.elemento_inventario.fechaInstalacion,
      lote: i.elemento_inventario.lote,
      loteDescripcion: i.elemento_inventario.loteDescripcion,
      ndp: i.elemento_inventario.ndp,
      observaciones: i.elemento_inventario.observaciones,
      observacionesLargas: i.elemento_inventario.observacionesLargas,
      planta: i.elemento_inventario.planta,
      posicionamientoMapa: i.elemento_inventario.posicionamientoMapa,
      servicio: i.elemento_inventario.servicio,
      servicioDescripcion: i.elemento_inventario.servicioDescripcion,
      sensorLlenado: i.sensorLlenado,
      sensorExpendedor: i.sensorExpendedor,
    } as MainInfo,
    especificacionesTecnicas:
      i.elemento_inventario.especificaciones_tecnicas.map(especificacionTecnica => {
        const valorAlfanumerico = Number(especificacionTecnica.ET_ValorAlfanumerico);
        const valorNumerico = Number(especificacionTecnica.ET_ValorNumerico);
        const valorTabla = Number(especificacionTecnica.ET_ValorTabla);

        const opcion = valorAlfanumerico
          ? 'ET_ValorAlfanumerico'
          : valorNumerico
          ? 'ET_ValorNumerico'
          : 'ET_Tabla';

        return {
          id: especificacionTecnica.id,
          et_idLinea: especificacionTecnica.ET_IDLinea,
          et_id: especificacionTecnica.ET_ID,
          et_descripcion: especificacionTecnica.ET_Descripcion,
          et_seccion: especificacionTecnica.ET_Seccion,
          et_unidadMedida: especificacionTecnica.ET_UnidadMedida,
          et_valor: String(valorAlfanumerico) || String(valorNumerico) || String(valorTabla),
          et_cambiado: especificacionTecnica.ET_Cambiado ? 'Sí' : 'No',
          opcion,
          id_inventario: especificacionTecnica.id_inventario,
        };
      }) || ([] as EspecificacionTecnica[]),
  }));
};
