import { useNavigate } from 'react-router-dom';
import { Riego } from '../../../../API';
import CommunicationExport from '../../../../components/CommunicationExport';
import CommunicationImport from '../../../../components/CommunicationImport';
import H1 from '../../../../components/H1';
import Page, { PageProps } from '../../../../components/Page';
import { IconName } from '../../../../shared/types';
import { getCommunicationConfig, getRiegoCoreModule } from '../../shared/utils';
import { campos } from './campos';

const PuntosDeSuministroCommunicationsPage = (): JSX.Element => {
  const navigate = useNavigate();
  const riegoCoreModule = getRiegoCoreModule();
  if (!riegoCoreModule) return <></>;

  let pageProps: PageProps = {
    className: 'communications riego',
    titleType: 'title',
    children: undefined,
  };

  if (riegoCoreModule.submodulesGroups?.length > 1) {
    const breadcrumb = [
      {
        icon: 'water' as IconName,
        label: 'Riego',
      },
      {
        label: 'Puntos de Suministro',
      },
    ];

    const btnBackConfig = {
      onClick: () => navigate('/riego/listado/puntos-de-suministro'),
    };

    pageProps = {
      ...pageProps,
      titleType: 'breadcrumb',
      breadcrumb,
      title: 'Riego',
      icon: 'water',
      btnBackConfig,
    };
  }

  return (
    <Page {...pageProps}>
      {riegoCoreModule.submodulesGroups?.length > 1 && <H1>Comunicaciones</H1>}

      <CommunicationImport
        title="Cargas"
        config={getCommunicationConfig('puntos-suministro')}
        importApi={Riego.import}
        requirePermission={{ section: 'riego', permission: 'import-puntos-suministro' }}
      />

      <hr />

      <CommunicationExport
        config={getCommunicationConfig('puntos-suministro')}
        exportApi={Riego.export}
        title="Consultas"
        campos={campos}
        requirePermission={{ section: 'riego', permission: 'export-puntos-suministro' }}
      />
    </Page>
  );
};

export default PuntosDeSuministroCommunicationsPage;
