import {
  FechaFrecuencia,
  Frecuencia,
  MainInfo,
  TestigosFrecuencia,
  TramoPlanificado,
} from '../../../../API/limpieza/planificaciones/interfaces';
import { DynamicFormMetadata } from '../../../../components/DynamicForm';

interface UseMetadataResult {
  mainInfoMetadata: DynamicFormMetadata<MainInfo>[];
  tramosPlanificadosMetadata: DynamicFormMetadata<TramoPlanificado>[];
  frecuenciasMetadata: DynamicFormMetadata<Frecuencia>[];
  fechasFrecuenciasMetadata: DynamicFormMetadata<FechaFrecuencia>[];
  testigosFrecuenciasMetadata: DynamicFormMetadata<TestigosFrecuencia>[];
}

export default function useMetadata(): UseMetadataResult {
  return {
    mainInfoMetadata,
    tramosPlanificadosMetadata,
    frecuenciasMetadata,
    fechasFrecuenciasMetadata,
    testigosFrecuenciasMetadata,
  };
}

const tramosPlanificadosMetadata: DynamicFormMetadata<TramoPlanificado>[] = [
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'idTramo',
      props: {
        label: 'ID Tramo',
        type: 'text',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'medidaTramo',
      props: {
        label: 'Medida Tramo (Formato X.XX)',
        type: 'text',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'unidadMedidaTramo',
      props: {
        label: 'Unidad de medida del tramo',
        type: 'text',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'coordenadaTramo',
      props: {
        label: 'Coordenada del tramo',
        type: 'text',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'descripcionTramo',
      props: {
        label: 'Descripción del tramo',
        type: 'text',
      },
    },
  },
];

const frecuenciasMetadata: DynamicFormMetadata<Frecuencia>[] = [
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'idFrecuencia',
      props: {
        label: 'ID de la frecuencia',
        type: 'text',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'descripcionFrecuencia',
      props: {
        label: 'Descripción de la frecuencia',
        type: 'text',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'numMax',
      props: {
        label: 'Número Máximo',
        type: 'number',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'numMin',
      props: {
        label: 'Número mínimo',
        type: 'number',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'numLinea',
      props: {
        label: 'Número linea',
        type: 'number',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'intervalo',
      props: {
        label: 'Intervalo',
        type: 'number',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'unidadFrecuencia',
      props: {
        label: 'Unidad Frecuencia',
        type: 'text',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'turno',
      props: {
        label: 'Turno',
        type: 'text',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'horaInicio',
      props: {
        label: 'Hora inicio',
        type: 'time',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'horaFin',
      props: {
        label: 'Hora fin',
        type: 'time',
      },
    },
  },
];

const fechasFrecuenciasMetadata: DynamicFormMetadata<FechaFrecuencia>[] = [
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'inicioFechaActiva',
      props: {
        label: 'Inicio fecha activa',
        type: 'date',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'finFechaActiva',
      props: {
        label: 'Fin fecha activa',
        type: 'date',
      },
    },
  },
];

const testigosFrecuenciasMetadata: DynamicFormMetadata<TestigosFrecuencia>[] = [
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Select',
      propKey: 'L',
      props: {
        label: 'Lunes',
        options: [
          { text: 'Sí', value: 'Sí' },
          { text: 'No', value: 'No' },
        ],
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Select',
      propKey: 'M',
      props: {
        label: 'Martes',
        options: [
          { text: 'Sí', value: 'Sí' },
          { text: 'No', value: 'No' },
        ],
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Select',
      propKey: 'X',
      props: {
        label: 'Miercoles',
        options: [
          { text: 'Sí', value: 'Sí' },
          { text: 'No', value: 'No' },
        ],
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Select',
      propKey: 'J',
      props: {
        label: 'Jueves',
        options: [
          { text: 'Sí', value: 'Sí' },
          { text: 'No', value: 'No' },
        ],
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Select',
      propKey: 'V',
      props: {
        label: 'Viernes',
        options: [
          { text: 'Sí', value: 'Sí' },
          { text: 'No', value: 'No' },
        ],
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Select',
      propKey: 'S',
      props: {
        label: 'Sábado',
        options: [
          { text: 'Sí', value: 'Sí' },
          { text: 'No', value: 'No' },
        ],
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Select',
      propKey: 'D',
      props: {
        label: 'Domingo',
        options: [
          { text: 'Sí', value: 'Sí' },
          { text: 'No', value: 'No' },
        ],
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Select',
      propKey: 'F',
      props: {
        label: 'Festivo',
        options: [
          { text: 'Sí', value: 'Sí' },
          { text: 'No', value: 'No' },
        ],
      },
    },
  },
];

const mainInfoMetadata: DynamicFormMetadata<MainInfo>[] = [
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'sector',
      props: {
        label: 'Sector',
        type: 'text',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'descripcion',
      props: {
        label: 'Descripción',
        type: 'text',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'contrato',
      props: {
        label: 'Contrato',
        type: 'text',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Select',
      propKey: 'aDemanda',
      props: {
        label: 'A demanda',
        options: [
          { text: 'Sí', value: 'Sí' },
          { text: 'No', value: 'No' },
        ],
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'tipoActuacion',
      props: {
        label: 'Tipo Actuación',
        type: 'text',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'concesionaria',
      props: {
        label: 'Concesrionaria',
        type: 'text',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'fechaInicio',
      props: {
        label: 'Fecha Inicio',
        type: 'date',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'fechaFin',
      props: {
        label: 'Fecha Fin',
        type: 'date',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'lote',
      defaultValue: '1',
      props: {
        label: 'Lote',
        type: 'number',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'distrito',
      props: {
        label: 'Distrito',
        type: 'text',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'tipoEje',
      props: {
        label: 'Tipo Eje',
        type: 'text',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'categoriaSector',
      props: {
        label: 'Categoría Sector',
        type: 'text',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'prestacion',
      props: {
        label: 'Prestación',
        type: 'text',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'unidadMedidaSector',
      props: {
        label: 'Unidad Medida Sector',
        type: 'text',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'coordenadaSector',
      props: {
        label: 'Coordenada Sector',
        type: 'text',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'motivoRevision',
      props: {
        label: 'Motivo revisión',
        type: 'text',
      },
    },
  },

  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'descripcionLarga',
      props: {
        label: 'Descripción larga',
        type: 'text',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'jerarquiaClasificacion',
      props: {
        label: 'Jerarquia clasificacion',
        type: 'text',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'barrio',
      props: {
        label: 'Barrio',
        type: 'text',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'claseFuncionGIS',
      props: {
        label: 'Clase función GIS',
        type: 'text',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'posicionamientoMapa',
      props: {
        label: 'Posicionamiento mapa',
        type: 'text',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'inicioSector',
      props: {
        label: 'Inicio sector',
        type: 'text',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'finSector',
      props: {
        label: 'Fin sector',
        type: 'text',
      },
    },
  },
  /*




frecuencias:
numLinea
testigos
tramosPlanificados:
medidaConfirmadaTramo
medidaCuadradosTramo
descripcionLargaTramo
inicioTramo
finTramo
  */
];
