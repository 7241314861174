import React from 'react';
import { appConfig } from '../../config/app';
import { SubModule } from '../../routes/routes';
import ActuacionesCommunicationsPage from './Actuaciones/Communications';
import EspeciesCommunicationsPage from './Especies/Communications';
import EvaluacionesVisualesCommunicationsPage from './EvaluacionesVisuales/Communications';
import IncidenciasCommunicationsPage from './Incidencias/Communications';
import InspeccionesCommunicationsPage from './Inspecciones/Communications';
import InventorioCommunicationsPage from './Inventario/Communications';
import PlanificacionesCommunicationsPage from './Planificaciones/Communications';
import SuperficiesCommunicationsPage from './Superficies/Communications';
import UnidadGestionCommunicationsPage from './UnidadGestion/Communications';
import ZonasExclusionCommunicationsPage from './ZonasExclusion/Communications';

const { navbarType } = appConfig;
let communicationsRoutes: SubModule[] = [];

if (navbarType === 'group-links') {
  communicationsRoutes = [
    {
      name: 'Inventario',
      path: 'inventario',
      to: `inventario`,
      Component: InventorioCommunicationsPage,
      requirePermission: 'view-inventario',
    },
    {
      name: 'Superficies',
      path: 'superficies',
      to: `superficies`,
      Component: SuperficiesCommunicationsPage,
      requirePermission: 'view-superficies',
    },
    {
      name: 'Unidades de gestión',
      path: 'unidad-de-gestion',
      to: `unidad-de-gestion`,
      Component: UnidadGestionCommunicationsPage,
      requirePermission: 'view-unidad-gestion',
    },
    {
      name: 'Programaciones',
      path: 'programaciones',
      to: `programaciones`,
      Component: React.Fragment,
      subRoutes: [
        {
          name: 'Actuaciones',
          path: 'actuaciones',
          to: `actuaciones`,
          Component: ActuacionesCommunicationsPage,
          requirePermission: 'view-actuaciones',
        },
        {
          name: 'Planificaciones',
          path: 'planificaciones',
          to: `planificaciones`,
          Component: PlanificacionesCommunicationsPage,
          requirePermission: 'view-planificaciones',
        },
      ],
    },
    {
      name: 'Riesgos de arbolado',
      path: 'riesgos-arbolado',
      to: `riesgos-arbolado`,
      Component: React.Fragment,
      subRoutes: [
        {
          name: 'Especies',
          path: 'especies',
          to: `especies`,
          Component: EspeciesCommunicationsPage,
          requirePermission: 'view-especies',
        },
        {
          name: 'Incidencias',
          path: 'incidencias',
          to: `incidencias`,
          Component: IncidenciasCommunicationsPage,
          requirePermission: 'view-incidencias',
        },
        {
          name: 'Inspecciones',
          path: 'inspecciones',
          to: `inspecciones`,
          Component: InspeccionesCommunicationsPage,
          requirePermission: 'view-inspecciones',
        },
        {
          name: 'Evaluaciones visuales',
          path: 'evaluaciones-visuales',
          to: `evaluaciones-visuales`,
          Component: EvaluacionesVisualesCommunicationsPage,
          requirePermission: 'view-evaluaciones-visuales',
        },
      ],
    },
    {
      name: 'Zonas de exclusión',
      path: 'zonas-de-exclusion',
      to: `zonas-de-exclusion`,
      Component: ZonasExclusionCommunicationsPage,
      requirePermission: 'view-zonas-exclusion',
    },
  ];
} else {
  communicationsRoutes = [
    {
      name: 'Inventario',
      path: 'inventario',
      to: `inventario`,
      Component: InventorioCommunicationsPage,
      requirePermission: 'view-inventario',
    },
    {
      name: 'Actuaciones',
      path: 'actuaciones',
      to: `actuaciones`,
      Component: ActuacionesCommunicationsPage,
      requirePermission: 'view-actuaciones',
    },
    {
      name: 'Superficies',
      path: 'superficies',
      to: `superficies`,
      Component: SuperficiesCommunicationsPage,
      requirePermission: 'view-superficies',
    },
    {
      name: 'Unidades de gestión',
      path: 'unidad-de-gestion',
      to: `unidad-de-gestion`,
      Component: UnidadGestionCommunicationsPage,
      requirePermission: 'view-unidad-gestion',
    },
    {
      name: 'Especies',
      path: 'especies',
      to: `especies`,
      Component: EspeciesCommunicationsPage,
      requirePermission: 'view-especies',
    },
    {
      name: 'Incidencias',
      path: 'incidencias',
      to: `incidencias`,
      Component: IncidenciasCommunicationsPage,
      requirePermission: 'view-incidencias',
    },
    {
      name: 'Inspecciones',
      path: 'inspecciones',
      to: `inspecciones`,
      Component: InspeccionesCommunicationsPage,
      requirePermission: 'view-inspecciones',
    },
    {
      name: 'Evaluaciones visuales',
      path: 'evaluaciones-visuales',
      to: `evaluaciones-visuales`,
      Component: EvaluacionesVisualesCommunicationsPage,
      requirePermission: 'view-evaluaciones-visuales',
    },
    {
      name: 'Planificaciones',
      path: 'planificaciones',
      to: `planificaciones`,
      Component: PlanificacionesCommunicationsPage,
      requirePermission: 'view-planificaciones',
    },
    {
      name: 'Zonas de exclusión',
      path: 'zonas-de-exclusion',
      to: `zonas-de-exclusion`,
      Component: ZonasExclusionCommunicationsPage,
      requirePermission: 'view-zonas-exclusion',
    },
  ];
}

export { communicationsRoutes };
