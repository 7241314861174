import { FC } from 'react';
import cn from 'classnames';
import { Color, IconName } from '../../shared/types';

type BtnType = 'sm' | 'lg';

export interface LazarusIconButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  /**
   * Color del ícono
   */
  color: Color;

  /**
   * Tipo de botón
   */
  btnType: BtnType;

  /**
   * Nombre del ícono
   */
  name: IconName;

  /**
   * Agregar clases de css propias
   */
  className?: string;
}

const IconButton: FC<LazarusIconButtonProps> = ({
  color,
  className,
  btnType = 'sm',
  name,
  ...props
}) => {
  const classNamesBtn = cn(
    'btnIcon',
    {
      [`btnIcon-${btnType}`]: btnType,
    },
    className,
  );

  const classNameIcon = cn('lazarus', {
    [`text-${color}`]: color,
    [`ico-${name}`]: name,
  });

  return (
    <button className={classNamesBtn} {...props}>
      <span className={classNameIcon}></span>
    </button>
  );
};

export default IconButton;
