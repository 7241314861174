import { ReactNode, useMemo } from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';
import { useNavigate } from 'react-router-dom';
import { Contadores } from '../../../../API/riego';
import { Contador } from '../../../../API/riego/contadores/interfaces';
import Badge from '../../../../components/Badge';
import Checkbox from '../../../../components/Checkbox';
import MaterialIconMenu, { MaterialIconMenuProps } from '../../../../components/MaterialIconMenu';
import NoResults from '../../../../components/NoResults';
import LazarusPagination from '../../../../components/Pagination';
import Filters, {
  DynamicFormMetadata,
  ModuleNavigations,
  FilterItem,
} from '../../../../components/Table/Filters';
import { useDatatable } from '../../../../hooks/useDatatable';

const addSelectedToRow = (row: Contador): void => {
  document.getElementById(`row-${row.id}`)?.classList.add('active');
};

const removeSelectedToRow = (row: Contador) => {
  document.getElementById(`row-${row.id}`)?.classList.remove('active');
};

const moduleNavigations: ModuleNavigations = {
  communications: `/riego/comunicaciones/contadores`,
  creates: `/riego/crear/contadores`,
};

const ContadoresListPage = (): JSX.Element => {
  const navigate = useNavigate();

  const {
    filteredItems,
    handleSetRows,
    handleChangeSelected,
    filterText,
    handleClear,
    handleChangeFilterText,
    selectedRows,
  } = useDatatable<Contador>({
    removeSelectedToRow,
    addSelectedToRow,
  });

  const columns: TableColumn<Contador>[] = useMemo(
    () => [
      {
        name: 'ID',
        selector: row => row.id,
      },
      {
        name: 'Planta',
        selector: row => row.planta,
      },
      {
        name: 'Última Lectura',
        selector: row => row.ultimaLectura,
      },
      {
        name: 'Fecha Última Lectura',
        selector: row => row.fechaUltimaLectura,
      },
      {
        name: 'Estado',
        cell: row => <Badge color={row.estado.color} text={row.estado.label} />,
      },
      {
        cell: row => {
          const paramsToMenu: MaterialIconMenuProps<Contador> = {
            row,
            size: 'medium',
            rows: selectedRows.length ? selectedRows : [row],
            onOpenMenu: () => addSelectedToRow(row),
            onCloseMenu: () => removeSelectedToRow(row),
            actions: [
              { label: 'Ver en mapa', onClick: row => console.log('Ver en mapa', row) },
              {
                label: 'Editar',
                onClick: row => navigate(`/riego/editar/contadores/${row.id}`),
                onlyApplyToOne: true,
              },
              { label: 'Enviar a MiNT', onClick: row => console.log('Enviar a MiNT', row) },
            ],
          };
          return <MaterialIconMenu {...paramsToMenu} />;
        },
        allowOverflow: true,
        button: true,
        width: '71px',
      },
    ],
    [selectedRows],
  );

  const SelectableCheckbox = (Checkbox as unknown) as ReactNode;

  const handleFilter = (filterItems: FilterItem[]) => {
    Contadores.get(filterItems).then(contadoresResponse => handleSetRows(contadoresResponse));
  };

  return (
    <>
      <Filters
        handleClear={handleClear}
        filterText={filterText}
        handleChangeFilterText={handleChangeFilterText}
        handleFilter={handleFilter}
        metadata={filterMetadata}
        hasItems={!!filteredItems.length}
        moduleNavigations={moduleNavigations}
        requirePermissions={requirePermissions}
      />
      <DataTable
        responsive
        columns={columns}
        data={filteredItems}
        pagination
        selectableRows
        selectableRowsHighlight
        paginationPerPage={10}
        noDataComponent={<NoResults />}
        paginationComponent={LazarusPagination}
        onSelectedRowsChange={handleChangeSelected}
        selectableRowsComponent={SelectableCheckbox}
      />
    </>
  );
};

export default ContadoresListPage;

const requirePermissions = {
  lists: { section: 'riego', permission: 'list-contadores' },
  communications: { section: 'riego', permission: 'import-contadores' },
  creates: { section: 'riego', permission: 'create-contadores' },
};

const filterMetadata: DynamicFormMetadata[] = [
  {
    grid: {
      xs: 6,
      sm: 4,
      md: 2,
    },
    input: {
      propKey: 'instalacion',
      props: {
        label: 'Instalación',
        type: 'text',
      },
      tag: 'Input',
    },
  },
  {
    grid: {
      xs: 6,
      sm: 4,
      md: 2,
    },
    input: {
      propKey: 'instalacion_cultivo',
      props: {
        label: 'Instalación Cultivo',
        type: 'text',
      },
      tag: 'Input',
    },
  },
  {
    grid: {
      xs: 6,
      sm: 4,
      md: 2,
    },
    input: {
      propKey: 'zona_de_control',
      props: {
        label: 'Zona de Control',
        type: 'text',
      },
      tag: 'Input',
    },
  },
  {
    grid: {
      xs: 6,
      sm: 4,
      md: 2,
    },
    input: {
      propKey: 'planta',
      props: {
        label: 'Planta',
        type: 'text',
      },
      tag: 'Input',
    },
  },
  {
    grid: {
      xs: 6,
      sm: 4,
      md: 2,
    },
    input: {
      propKey: 'id_inventario_asociado',
      props: {
        label: 'Id Inventario Asociado',
        type: 'text',
      },
      tag: 'Input',
    },
  },
];
