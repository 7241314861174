import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { post, getById } from '../../../../API/limpieza/inventarios';
import { MainInfo } from '../../../../API/limpieza/inventarios/interfaces';
import { EspecificacionTecnica } from '../../../../API/shared/inventarios/interfaces';
import BoxComplexInput from '../../../../components/BoxComplexInput';
import Button from '../../../../components/Button';
import Col from '../../../../components/Col';
import CollapsibleSection from '../../../../components/CollapsibleSection';
import DynamicForm from '../../../../components/DynamicForm';
import H1 from '../../../../components/H1';
import Row from '../../../../components/Row';
import useEdit from '../../../../hooks/useEdit';
import { showErrorAlert, showSuccessAlert } from '../../../../libs/LazarusAlert';
import useMetadata from './hooks';

const InventarioCreatePage = (): JSX.Element => {
  const [especificacionesTecnicas, setEspecificacionesTecnicas] = useState<EspecificacionTecnica[]>(
    [],
  );
  const [mainInfo, setMainInfo] = useState<MainInfo>({} as MainInfo);
  const { mainInfoMetadata, especificacionesTecnicasMetadata } = useMetadata(setMainInfo);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const dataToEdit = useEdit(getById, '/limpieza/listado/inventario');

  useEffect(() => {
    if (dataToEdit) {
      setMainInfo(dataToEdit.mainInfo);
      setEspecificacionesTecnicas(dataToEdit.especificacionesTecnicas);
    }
  }, [dataToEdit]);

  const handleSubmit = () => {
    setLoading(true);
    post({ mainInfo, especificacionesTecnicas })
      .then(() => {
        showSuccessAlert({ title: '¡Éxito!', text: 'Inventario guardado con éxito.' });
        navigate('/limpieza/listado/inventario');
      })
      .catch(() => {
        showErrorAlert({ title: 'Error', text: 'Error al guardar el inventario.' });
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      <H1>Datos</H1>
      <CollapsibleSection title="Información principal">
        <DynamicForm
          spacing="lg"
          onChange={setMainInfo}
          metadata={mainInfoMetadata}
          value={mainInfo}
        />
      </CollapsibleSection>
      <Row style={{ marginBottom: 30 }}>
        <BoxComplexInput
          closedMessage={`Hay ${especificacionesTecnicas.length} especifaciones técnicas.`}
          metadata={especificacionesTecnicasMetadata}
          onRemove={row => setEspecificacionesTecnicas(cur => cur.filter(c => c !== row))}
          onAdd={row => setEspecificacionesTecnicas(cur => [...cur, row])}
          label="Especificaciones técnicas"
          dataSource={especificacionesTecnicas}
        />
      </Row>
      <Row justifyContent="center">
        <Col xs={3} sm={2}>
          <Button color="primary" outline disabled={loading}>
            Cancelar
          </Button>
        </Col>
        <Col xs={3} sm={2}>
          <Button color="primary" onClick={handleSubmit} disabled={loading}>
            Guardar
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default InventarioCreatePage;
