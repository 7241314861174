import cn from 'classnames';
import { FC, ReactNode } from 'react';

interface Props {
  /**
   * Nombre del ícono
   */
  children: ReactNode;

  /**
   * Agregar clases de css propias
   */
  className?: string;
}

const H2: FC<Props & React.HTMLAttributes<HTMLHeadingElement>> = ({
  className,
  children,
  ...props
}) => {
  const classNames = cn('module-page__h2', className);

  return (
    <h2 className={classNames} {...props}>
      {children}
    </h2>
  );
};

export default H2;
