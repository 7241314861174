import { GetByIdResponse } from '.';
import {
  FechaFrecuencia,
  Frecuencia,
  GetLimpiezaPlanificacionResponse,
  MainInfo,
  TestigosFrecuencia,
  TramoPlanificado,
} from '../interfaces';

export const responseTransformer = (response: string): GetByIdResponse[] => {
  const responseJSON: GetLimpiezaPlanificacionResponse = JSON.parse(response);

  return responseJSON.planificacion.map(p => {
    let frecuenciaFechas: FechaFrecuencia[] = [];
    let frecuenciaTestigos: TestigosFrecuencia[] = [];

    p.frecuencias?.forEach(f => {
      frecuenciaFechas =
        f.frecuencias_fechas?.map(
          ff =>
            ({
              id: ff.id,
              inicioFechaActiva: ff.inicioFechaActiva?.split(' ')[0],
              finFechaActiva: ff.finFechaActiva?.split(' ')[0],
            } as FechaFrecuencia),
        ) || [];
      frecuenciaTestigos = f.frecuencias_testigos?.map(ft => ({
        id: ft.id,
        L: ft.L ? 'Sí' : 'No',
        M: ft.M ? 'Sí' : 'No',
        X: ft.X ? 'Sí' : 'No',
        J: ft.J ? 'Sí' : 'No',
        V: ft.V ? 'Sí' : 'No',
        S: ft.S ? 'Sí' : 'No',
        D: ft.D ? 'Sí' : 'No',
        F: ft.F ? 'Sí' : 'No',
      }));
    });

    return {
      mainInfo: {
        id: p.id,
        sector: p.sector,
        descripcion: p.descripcion,
        contrato: p.contrato,
        aDemanda: p.aDemanda ? 'Sí' : 'No',
        tipoActuacion: p.tipoActuacion,
        concesionaria: p.concesionaria,
        fechaInicio: p.fechaInicio?.split(' ')[0],
        fechaFin: p.fechaFin?.split(' ')[0],
        lote: p.lote,
        distrito: p.distrito,
        tipoEje: p.tipoEje,
        categoriaSector: p.categoriaSector,
        prestacion: p.prestacion,
        unidadMedidaSector: p.unidadMedidaSector,
        coordenadaSector: p.coordenadaSector,
        motivoRevision: p.motivoRevision,
        descripcionLarga: p.descripcionLarga,
        jerarquiaClasificacion: p.jerarquiaClasificacion,
        barrio: p.barrio,
        claseFuncionGIS: p.claseFuncionGIS,
        posicionamientoMapa: p.posicionamientoMapa,
        inicioSector: p.inicioSector,
        finSector: p.finSector,
      } as MainInfo,
      frecuencias:
        p.frecuencias?.map(
          f =>
            ({
              id: f.id,
              descripcionFrecuencia: f.descripcionFrecuencia,
              idFrecuencia: f.idFrecuencia,
              horaFin: f.horaFin,
              horaInicio: f.horaInicio,
              numMax: f.numMax,
              numMin: f.numMin,
              unidadFrecuencia: f.unidadFrecuencia,
              intervalo: f.intervalo,
              numLinea: f.numLinea,
              turno: f.turno,
            } as Frecuencia),
        ) || [],
      tramosPlanificados:
        p.frecuencias_tramos_planificados?.map(
          t =>
            ({
              id: t.id,
              idTramo: t.idTramo,
              medidaTramo: t.medidaTramo,
              unidadMedidaTramo: t.unidadMedidaTramo,
              coordenadaTramo: t.coordenadaTramo,
              descripcionTramo: t.descripcionTramo,
              medidaConfirmadaTramo: t.medidaConfirmadaTramo,
              medidaCuadradosTramo: t.medidaCuadradosTramo,
              descripcionLargaTramo: t.descripcionLargaTramo,
              inicioTramo: t.inicioTramo,
              finTramo: t.finTramo,
            } as TramoPlanificado),
        ) || [],
      fechasFrecuencias: frecuenciaFechas,
      testigosFrecuencias: frecuenciaTestigos,
    };
  });
};
