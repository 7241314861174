import { EspecificacionTecnica } from '../../../shared/inventarios/interfaces';
import { arrayToNumberedObject } from '../../../shared/utils';
import { MainInfo } from '../interfaces';

export const requestTransformer = (request: {
  mainInfo: MainInfo;
  especificacionesTecnicas: EspecificacionTecnica[];
}): FormData => {
  const form = new FormData();
  const requestItems: Record<string, any> = {
    ...request.mainInfo,
    ...arrayToNumberedObject(request.especificacionesTecnicas, 'idEspecificacionTecnica'),
  };

  form.append('_method', 'POST');
  Object.entries(requestItems).forEach(([key, value]) => form.append(key, value));

  if (!request.mainInfo.servicio) form.append('servicio', '10');
  if (!request.mainInfo.id) form.append('id', '-1');
  if (!request.mainInfo.id_elemento_inventario) form.append('id_elemento_inventario', '-1');
  form.append('usuarioEditor', ''); //ESTE CAMPO NO IMPACTA
  form.append('multi', '');
  form.append('multiItems', '');

  return form;
};
