/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { AxiosError } from 'axios';
import { FC, useState } from 'react';
import Button from '../../../components/Button';
import Input from '../../../components/Input';
import Modal from '../../../components/Modal';
import ModalBody from '../../../components/Modal/ModalBody';
import ModalFooter from '../../../components/Modal/ModalFooter';
import ModalTitle from '../../../components/Modal/ModalTitle';
import { showSuccessAlert } from '../../../libs/LazarusAlert';
import { editSchema } from '../schemas/edit.schema';
import { GestionEnvios } from '../../../API';

interface Props {
  title: string;
  openModal: boolean;
  handleOpenModal: (ev?: React.MouseEvent | React.FormEvent) => void;
  cargaData: CargaData;
  successCallback?: () => void;
}

interface CargaData {
  id: number;
  descripcion: string;
}

const emptyCargaData = {
  descripcion: '',
};

const CargaModal: FC<Props> = ({
  title,
  cargaData,
  openModal,
  handleOpenModal,
  successCallback,
}) => {
  const [carga, setCarga] = useState<CargaData>(cargaData);
  const [errors, setErrors] = useState<Record<string, string>>({ ...emptyCargaData });

  const handleChange = (key: string, value: string) => {
    setCarga({ ...carga, [key]: value });
  };

  const handleSave = (ev: { preventDefault: () => void }) => {
    setErrors({});
    ev.preventDefault();

    try {
      editSchema.validateSync(carga, { abortEarly: false });
    } catch (exception: any) {
      const newErrors: Record<string, string> = { ...emptyCargaData };
      for (const err of exception.inner) {
        newErrors[err.path] = err.message;
      }

      setErrors(newErrors);
      return;
    }

    GestionEnvios.patch(carga, carga.id)
      .then(() => {
        showSuccessAlert({ title: 'Carga guardado' });
        successCallback && successCallback();
        handleOpenModal();
      })
      .catch((err: AxiosError) => {
        console.log(err);
      });
  };

  return (
    <Modal open={openModal} handleOpen={handleOpenModal}>
      <ModalTitle>{title}</ModalTitle>
      <form className="form-user" onSubmit={handleSave} autoComplete="off">
        <ModalBody>
          <Input
            onChange={(ev: { target: { value: string } }) =>
              handleChange('descripcion', ev.target.value)
            }
            value={carga.descripcion}
            error={errors.descripcion}
            type="text"
            label="Descripción"
          />
        </ModalBody>
        <ModalFooter>
          <Button onClick={handleOpenModal} color="primary" outline>
            Cancelar
          </Button>
          <Button color="primary">Guardar</Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default CargaModal;
