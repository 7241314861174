/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { MaterialesHookData } from './hooks/useMateriales';

export interface MaterialesData {
  idsElementosCodificados: string;
  idsElementosNoCodificados: string;
  cantidadesElementosCodificados: string;
  cantidadesElementosNoCodificados: string;
  tarifasElementosCodificados: string;
  idActuacion: string;
}

export type CheckType = 'section' | 'subsection' | 'function';

const initialState = {
  data: {
    idsElementosCodificados: '',
    cantidadesElementosCodificados: '',
    tarifasElementosCodificados: '',
    idsElementosNoCodificados: '',
    cantidadesElementosNoCodificados: '',
    idActuacion: '',
  },
  handleChange: (newData: { name: keyof MaterialesData; value: string }) => null,
  handleChangeActuacion: (newIdActuacion: string) => null,
};

type MaterialesContextHandler = MaterialesHookData;

export const MaterialesContext = React.createContext<MaterialesContextHandler>(initialState);
