//import { MarkerClusterer } from '@googlemaps/markerclusterer';
import { PlanificacionGet } from '../../../../API/flotas/planificaciones/interfaces';
import { Cartografia } from '../../../../API/flotas';
//import { MovimientoGPS } from '../../../../API/flotas/vehiculos/interfaces';
//import EndMarkerIconSelected from '../../../../assets/img/maps/marker-end-selected.svg';
//import EndMarkerIcon from '../../../../assets/img/maps/marker-end.svg';
//import StartMarkerIconSelected from '../../../../assets/img/maps/marker-start-selected.svg';
//import StartMarkerIcon from '../../../../assets/img/maps/marker-start.svg';
import { GeoJSONLayer } from './interface';

/* ####### Rutas Utils ####### */

export const getPlanificacionesUtils = async (planificaciones: PlanificacionGet[]) => {
  if (planificaciones.length === 0) return [];

  return planificaciones.map(planificacion => ({
    id: planificacion.id.toString(),
    name: planificacion.cDescripcion,
    route: JSON.parse(planificacion.ruta),
  }));
};

export const getCartografia = async () => {
  try {
    const response1 = await Cartografia.getByName('cantones');
    const response2 = await Cartografia.getByName('distritos');
    const response3 = await Cartografia.getByName('superficiesVerdes');
    const response4 = await Cartografia.getByName('unidadGestion');
    const response5 = await Cartografia.getByName('instalaciones');

    const geoJSONLayer1: GeoJSONLayer = {
      id: 'Cantones',
      data: response1,
      color: 'blue',
    };

    const geoJSONLayer2: GeoJSONLayer = {
      id: 'Distritos',
      data: response2,
      color: 'red',
    };

    const geoJSONLayer3: GeoJSONLayer = {
      id: 'Superficies Verdes',
      data: response3,
      color: 'green',
    };

    const geoJSONLayer4: GeoJSONLayer = {
      id: 'Unidades de Gestión',
      data: response4,
      color: 'purple',
    };

    const geoJSONLayer5: GeoJSONLayer = {
      id: 'Instalaciones',
      data: response5,
      color: 'orange',
    };

    return [geoJSONLayer1, geoJSONLayer5, geoJSONLayer3, geoJSONLayer4, geoJSONLayer2]; // Orden modificado para que pinte debajo los dsitritos
  } catch (error) {
    console.error('Error:', error);
  }
};
