import { FC } from "react";
import cn from "classnames";
import { Color, IconName, Size } from "../../shared/types";

interface Props {
  /**
   * Color del ícono
   */
  color: Color;

  /**
   * Tamaño del ícono
   */
  size?: Size;

  /**
   * Nombre del ícono
   */
  name: IconName;

  /**
   * Agregar clases de css propias
   */
  className?: string;
}

const Icon: FC<Props & React.HTMLAttributes<HTMLSpanElement>> = ({
  color,
  className,
  size,
  name,
  ...props
}) => {
  const classNames = cn(
    "lazarus",
    {
      [`text-${color}`]: color,
      [`text-${size}`]: size,
      [`ico-${name}`]: name,
    },
    className
  );

  return <span className={classNames} {...props}></span>;
};

export default Icon;
