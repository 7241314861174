import { useState } from 'react';
import { ImportExportResult } from '../API/shared/interfaces';
import { BoxCriterioItem } from '../components/BoxCriterio';
import { appConfig } from '../config/app';
import { showErrorAlert } from '../libs/LazarusAlert';
import { ZZVVSubModule } from '../config/interfaces';

interface Props {
  exportApi: (
    criterios: BoxCriterioItem[],
    functionName: string,
    indiceResultado: string,
    subModule?: ZZVVSubModule,
  ) => Promise<ImportExportResult>;
  functionName: string;
  indiceResultado: string;
  subModule?: ZZVVSubModule;
}

interface CommunicationExport {
  criterios: BoxCriterioItem[];
  cleanCriterios: () => void;
  onAddCriterio: () => void;
  onRemoveCriterio: (index: number) => void;
  onChangeCriterio: (index: number, newCriterio: BoxCriterioItem) => void;
  file: string;
  loading: boolean;
  handleExport: () => void;
  onChangeIndiceResultado: (indiceResultado: string) => void;
  numeroResultados: string;
  resultadosTotales: string;
}

export function useCommunicationExport({
  functionName,
  subModule,
  exportApi,
}: Props): CommunicationExport {
  const criterioTemplate = { campo: '', operador: '', criterio: '' };
  const [criterios, setCriterios] = useState<BoxCriterioItem[]>([criterioTemplate]);
  const [indiceResultado, setIndiceResultado] = useState<string>('0');
  const [file, setFile] = useState<string>('');
  const [numeroResultados, setNumeroResultados] = useState<string>('');
  const [resultadosTotales, setResultadosTotales] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const cleanCriterios = () => {
    setCriterios([criterioTemplate]);
    setFile('');
  };

  const handleExport = () => {
    setLoading(true);
    setFile('');
    exportApi(criterios, functionName, indiceResultado, subModule)
      .then(res => {
        setLoading(false);
        if (res.end && res.progress === 0) {
          showErrorAlert({
            title: 'Error en la consulta',
            text: res.message,
          });
          return;
        }

        if (res.message === 'No se han encontrado resultados para la consulta realizada') {
          const errorMessage =
            res.errors && res.errors.length > 0 ? res.errors[0].mensaje : res.message;

          showErrorAlert({
            title: 'Sin resultados',
            text: errorMessage,
          });
          return;
        }

        if (res.end && res.progress === 100 && res.file && res.file !== '../') {
          setFile(`${appConfig.apiConfig.baseURL}/${res.file.replace('../', '')}`);
          console.log(res);
          setNumeroResultados(res.numeroResultados);
          setResultadosTotales(res.resultadosTotales);
          return;
        }

        const errorMessage =
          res.errors && res.errors.length > 0 ? res.errors[0].mensaje : res.message;

        showErrorAlert({
          title: 'Sin resultados',
          text: errorMessage,
        });
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
        showErrorAlert({
          title: 'Error en el servidor',
          text: 'No se ha podido realizar la consulta',
        });
      });
  };

  const onAddCriterio = () => {
    setCriterios([...criterios, criterioTemplate]);
  };

  const onRemoveCriterio = (index: number) => {
    const newCriterios = criterios.filter((criterio, i) => i !== index);
    setCriterios(newCriterios);
  };

  const onChangeCriterio = (index: number, newCriterio: BoxCriterioItem) => {
    const newCriterios = [...criterios];
    newCriterios[index] = newCriterio;
    setCriterios(newCriterios);
  };

  const onChangeIndiceResultado = (indiceResultado: string) => {
    setIndiceResultado(indiceResultado);
  };

  return {
    loading,
    file,
    handleExport,
    criterios,
    numeroResultados,
    resultadosTotales,
    cleanCriterios,
    onAddCriterio,
    onRemoveCriterio,
    onChangeCriterio,
    onChangeIndiceResultado,
  };
}
