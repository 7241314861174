import { createApiInstance } from '../../../api';
import {
  FechaFrecuencia,
  Frecuencia,
  MainInfo,
  TestigosFrecuencia,
  TramoPlanificado,
} from '../interfaces';
import { responseTransformer } from './response-transformer';

export interface GetByIdResponse {
  mainInfo: MainInfo;
  tramosPlanificados: TramoPlanificado[];
  frecuencias: Frecuencia[];
  fechasFrecuencias: FechaFrecuencia[];
  testigosFrecuencias: TestigosFrecuencia[];
}

export const execute = async (id: number): Promise<GetByIdResponse | null> => {
  const api = createApiInstance();
  const { data } = await api.get<GetByIdResponse[]>('/limpieza/planificacion-sector', {
    transformResponse: [responseTransformer],
  });

  return data.find(i => i.mainInfo.id === id) || null;
};
