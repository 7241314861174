import { useEffect, useMemo } from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';
import { useNavigate } from 'react-router-dom';
import { Roles } from '../../API';
import { Role } from '../../API/roles/interfaces';
import Icon from '../../components/Icon';
import NoResults from '../../components/NoResults';
import Page from '../../components/Page';
import LazarusPagination from '../../components/Pagination';
import { useAuth } from '../../hooks/useAuth';
import { useDatatable } from '../../hooks/useDatatable';
import { showErrorAlert, showSuccessAlert, showWarningAlert } from '../../libs/LazarusAlert';
import { setShowLogo } from '../../redux/HeaderSlice/headerSlice';
import { useAppDispatch } from '../../redux/hooks';
import NavFilters from '../Users/components/NavFilters';

const RoleIndex = (): JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    filteredItems,
    handleSetRows,
    handleChangeFilterText,
    handleClear,
    filterText,
  } = useDatatable<Role>({});
  const { canUse } = useAuth();

  const columns: TableColumn<Role>[] = useMemo(
    () => [
      {
        name: 'Nombre',
        selector: row => row.name,
      },
      {
        name: 'Editar',
        cell: row =>
          canUse('edit-roles', 'app') ? (
            <Icon onClick={() => handleEdit(row.id)} name="edit" color="primary" />
          ) : null,
      },
      {
        name: 'Eliminar',
        cell: row =>
          canUse('delete-users', 'app') ? (
            <Icon onClick={() => handleDelete(row)} name="trash" color="primary" />
          ) : null,
      },
    ],
    [],
  );

  const handleDelete = (role: Role) => {
    showWarningAlert({
      title: `Eliminar rol ${role.name}`,
      text: 'Al continuar, se perderán los datos y no podrás recuperarlos',
    }).then(result => {
      if (result.isConfirmed) {
        Roles.delete(role.id)
          .then(() => {
            showSuccessAlert({
              title: 'Rol eliminado',
            });

            handleFilter();
          })
          .catch(err => {
            console.log(err);
            showErrorAlert({ text: 'No se ha podido eliminar el rol' });
          });
      }
    });
  };

  const handleFilter = () => {
    Roles.get().then(roles => handleSetRows(roles));
  };

  const handleCreate = () => {
    navigate('/roles/crear');
  };

  const handleEdit = (roleId: number) => {
    navigate(`/roles/editar/${roleId}`);
  };

  useEffect(() => {
    dispatch(setShowLogo({ showLogo: true }));
  }, []);

  useEffect(() => {
    handleFilter();
  }, []);

  return (
    <Page title="Roles" icon="user" titleType="title" className="users">
      <NavFilters
        activeModule="roles"
        textNewButton="Nuevo rol"
        handleClickNewButton={handleCreate}
        handleChangeFilterText={handleChangeFilterText}
        handleClear={handleClear}
        filterText={filterText}
      />

      <div className="table-roles">
        <DataTable
          responsive
          columns={columns}
          data={filteredItems}
          pagination
          paginationPerPage={10}
          noDataComponent={<NoResults />}
          paginationComponent={LazarusPagination}
        />
      </div>
    </Page>
  );
};

export default RoleIndex;
