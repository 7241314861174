import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { MainInfo } from '../../../../API/riego/actuaciones/interfaces';
import { Documento, EstadoElemento } from '../../../../API/shared/interfaces';
import { DynamicFormMetadata } from '../../../../components/DynamicForm';
import { documentosMetadata } from '../../../../API/shared/utils';
import {
  getEstadosActuaciones,
  getJerarquiasClasificacionActuaciones,
} from '../../../../API/shared/actuaciones';
import { JerarquiaClasificacion } from '../../../../API/shared/interfaces';
import { EspecificacionTecnica } from '../../../../API/shared/actuaciones/interfaces';
import { especificacionesTecnicasMetadata } from '../../../../API/shared/actuaciones/utils';

interface UseMetadataResult {
  mainInfoMetadata: DynamicFormMetadata<MainInfo>[];
  documentosMetadata: DynamicFormMetadata<Documento>[];
  especificacionesTecnicasMetadata: DynamicFormMetadata<EspecificacionTecnica>[];
}

export default function useMetadata(
  setMainInfo: Dispatch<SetStateAction<MainInfo>>,
): UseMetadataResult {
  const [jerarquiasClasificacion, setJerarquiasClasificacion] = useState<JerarquiaClasificacion[]>(
    [],
  );
  const [estadosActuacion, setEstadosActuacion] = useState<EstadoElemento[]>([]);

  useEffect(() => {
    getJerarquiasClasificacionActuaciones('riego').then(res => setJerarquiasClasificacion(res));
    getEstadosActuaciones().then(res => setEstadosActuacion(res));

    //TODO, vamos a trabajar cancelando request ahora?
    //return { cancellRequests... };
  }, []);

  const mainInfoMetadata = useMemo(
    () => getMainInfo(jerarquiasClasificacion, estadosActuacion, setMainInfo),
    [jerarquiasClasificacion, estadosActuacion, setMainInfo],
  );

  return { mainInfoMetadata, documentosMetadata, especificacionesTecnicasMetadata };
}

const getMainInfo = (
  jerarquias: JerarquiaClasificacion[],
  estados: EstadoElemento[],
  setMainInfo: Dispatch<SetStateAction<MainInfo>>,
): DynamicFormMetadata<MainInfo>[] => [
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'idActuacion',
      props: {
        disabled: true,
        label: 'ID de la actuación',
        type: 'text',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'idActuacionExterno',
      props: {
        label: 'ID externo de la actuación',
        type: 'text',
        required: true,
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Select',
      propKey: 'jerarquiaClasificacion',
      props: {
        label: 'Jerarquía clasificación',
        options: jerarquias.map(j => ({ text: j.codigo, value: j.codigo })),
        onChange: e => {
          const desc = jerarquias.find(j => j.codigo === e.currentTarget.value)?.descripcion || '';
          setMainInfo(cur => ({
            ...cur,
            jerarquiaClasificacionDescripcion: desc,
          }));
        },
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'jerarquiaClasificacionDescripcion',
      props: {
        label: 'Descripción de la jerarquía',
        type: 'text',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'claseFuncionGIS',
      props: {
        label: 'Clase función GIS',
        type: 'text',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'descripcion',
      props: {
        label: 'Descripción de la actuación',
        type: 'text',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'descripcionLarga',
      props: {
        label: 'Descripción larga de la actuación',
        type: 'text',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'barrio',
      props: {
        label: 'Barrio',
        type: 'number',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'barrioDescripcion',
      props: {
        label: 'Descripción del barrio',
        type: 'text',
      },
    },
  },
  {
    grid: { xs: 12, sm: 8, md: 6 },
    input: {
      tag: 'Select',
      propKey: 'calculoIndicador',
      props: {
        label: '¿Se deben tener indicadores en cuenta?',
        options: [
          {
            text: 'Sí',
            value: 'Sí',
          },
          {
            text: 'No',
            value: 'No',
          },
        ],
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'calculoIndicadorMotivo',
      props: {
        label: 'Cálculco indicador motivo',
        type: 'text',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'codigoPostal',
      props: {
        label: 'Código postal',
        type: 'number',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'concesionaria',
      props: {
        label: 'Concesionaria',
        type: 'text',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'contrato',
      props: {
        label: 'Contrato',
        type: 'text',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'contratoDescripcion',
      props: {
        label: 'Descripción del contrato',
        type: 'text',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'coordenadas',
      props: {
        label: 'Coordenadas',
        type: 'text',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'coordenadaXGIS',
      props: {
        label: 'Coordenadas X GIS',
        type: 'text',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'coordenadaYGIS',
      props: {
        label: 'Coordenadas Y GIS',
        type: 'text',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'coordenadaX',
      props: {
        label: 'Coordenadas X',
        type: 'text',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'coordenadaY',
      props: {
        label: 'Coordenadas Y',
        type: 'text',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'distrito',
      props: {
        label: 'Distrito',
        type: 'number',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'distritoDescripcion',
      props: {
        label: 'Descripción del distrito',
        type: 'text',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'duracionEstimada',
      props: {
        label: 'Duración estimada',
        type: 'text',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Select',
      propKey: 'esPrioritaria',
      props: {
        label: '¿Es prioritaria?',
        options: [
          {
            text: 'Sí',
            value: 'Sí',
          },
          {
            text: 'No',
            value: 'No',
          },
        ],
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Select',
      propKey: 'estado',
      props: {
        label: 'Estado',
        options: estados.map(e => ({ text: e.valor, value: e.valor })),
        onChange: e => {
          const desc = estados.find(s => s.valor === e.currentTarget.value)?.descripcion || '';
          setMainInfo(cur => ({ ...cur, estadoDescripcion: desc }));
        },
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'estadoDescripcion',
      props: {
        label: 'Descripción del estado',
        type: 'text',
        readOnly: true,
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'fechaInicioPlazo',
      props: {
        label: 'Fecha de inicio del plazo',
        type: 'date',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'fechaLimite',
      props: {
        label: 'Fecha limite',
        type: 'date',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'fechaCambio',
      props: {
        label: 'Fecha de cambio',
        type: 'date',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'fechaEstado',
      props: {
        label: 'Fecha de estado',
        type: 'date',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'fechaReporte',
      props: {
        label: 'Fecha de reporte',
        type: 'date',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'fechaInicioPlanificada',
      props: {
        label: 'Fecha de inicio planificada',
        type: 'date',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'fechaFinPlanificada',
      props: {
        label: 'Fecha de fin planificada',
        type: 'date',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'fechaInicioProgramada',
      props: {
        label: 'Fecha de inicio programada',
        type: 'date',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'fechaFinProgramada',
      props: {
        label: 'Fecha de fin programada',
        type: 'date',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'fechaInicioReal',
      props: {
        label: 'Fecha de inicio real',
        type: 'date',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'fechaFinReal',
      props: {
        label: 'Fecha de fin real',
        type: 'date',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'grupoParque',
      props: {
        label: 'Id Grupo del parque',
        type: 'number',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'grupoParqueDescripcion',
      props: {
        label: 'Descripción del grupo del parque',
        type: 'number',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'idInventario',
      defaultValue: 1,
      props: {
        label: 'Id Inventario',
        type: 'number',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'localizacion',
      props: {
        label: 'Localización',
        type: 'text',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'lote',
      defaultValue: 1,
      props: {
        label: 'Lote',
        type: 'number',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'loteDescripcion',
      props: {
        label: 'Descripción del lote',
        type: 'text',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Select',
      propKey: 'medicion',
      props: {
        label: 'Medición',
        options: [
          {
            text: 'Sí',
            value: 'Sí',
          },
          {
            text: 'No',
            value: 'No',
          },
        ],
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'medidaEquivalente',
      props: {
        label: 'Medida equivalente',
        type: 'text',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'medidaProgramada',
      props: {
        label: 'Medida programada',
        type: 'text',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'medidaReal',
      props: {
        label: 'Medida real',
        type: 'text',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'ndp',
      props: {
        label: 'NDP',
        type: 'number',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'numeroAvisoOrigen',
      props: {
        label: 'Número de aviso origen',
        type: 'number',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'nombreVia',
      props: {
        label: 'Nombre vía',
        type: 'text',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'numeroActo',
      props: {
        label: 'Número acto',
        type: 'number',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'numeroVia',
      props: {
        label: 'Número vía',
        type: 'number',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'observacionesActuacion',
      props: {
        label: 'Observaciones de la actuación',
        type: 'text',
      },
    },
  },
  {
    grid: { xs: 12, sm: 8, md: 6 },
    input: {
      tag: 'Input',
      propKey: 'observacionesLargasActuacion',
      props: {
        label: 'Observaciones largas de la actuación',
        type: 'text',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Select',
      propKey: 'ordenDirecta',
      props: {
        label: '¿Es una orden directa?',
        options: [
          {
            text: 'Sí',
            value: 'Sí',
          },
          {
            text: 'No',
            value: 'No',
          },
        ],
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'parque',
      props: {
        label: 'Id parque',
        type: 'text',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'parqueDescripcion',
      props: {
        label: 'Parque descripción',
        type: 'text',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'prestacion',
      props: {
        label: 'Prestación',
        type: 'text',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Select',
      propKey: 'requiereAprobacion',
      props: {
        label: '¿Requiere aprobación?',
        options: [
          {
            text: 'Sí',
            value: 'Sí',
          },
          {
            text: 'No',
            value: 'No',
          },
        ],
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      defaultValue: 10,
      tag: 'Input',
      propKey: 'servicio',
      props: {
        label: 'Servicio',
        type: 'number',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'servicioDescripcion',
      props: {
        label: 'Servicio descripción',
        type: 'text',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'tipoActuacion',
      props: {
        label: 'Tipo actuación',
        type: 'text',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'tipoActuacionEquivalente',
      props: {
        label: 'Tipo actuación equivalente',
        type: 'text',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'tipoElemento',
      props: {
        label: 'Tipo elemento',
        type: 'text',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'tipoVia',
      props: {
        label: 'Tipo vía',
        type: 'text',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'unidadMedida',
      props: {
        label: 'Unidad medida',
        type: 'text',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'unidadMedidaEquivalente',
      props: {
        label: 'Unidad medida equivalente',
        type: 'text',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'usuarioEditor',
      props: {
        label: 'Usuario editor',
        type: 'text',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'usuarioReporte',
      props: {
        label: 'Usuario reporte',
        type: 'text',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'zona',
      props: {
        label: 'Zona',
        type: 'text',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'zonaDescripcion',
      props: {
        label: 'Zona descripción',
        type: 'text',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'costeRealMateriales',
      props: {
        label: 'Coste real de materiales',
        type: 'text',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'costeRealHerramientas',
      props: {
        label: 'Coste real de herramientas',
        type: 'text',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Select',
      propKey: 'pavimento',
      props: {
        label: 'Pavimento',
        options: [
          { text: 'Sí', value: 'Sí' },
          { text: 'No', value: 'No' },
        ],
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'caudal',
      props: {
        label: 'Caudal',
        type: 'text',
      },
    },
  },

  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'presion',
      props: {
        label: 'Presion',
        type: 'text',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'solucionActuaciones',
      props: {
        label: 'Solución actuaciones',
        type: 'text',
      },
    },
  },
];
