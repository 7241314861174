import { ReactNode, useMemo } from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';
import { useNavigate } from 'react-router-dom';
import { PuntosDeSuministro } from '../../../../API/riego';
import { PuntoDeSuministro } from '../../../../API/riego/puntos-de-suministro/interfaces';
import Checkbox from '../../../../components/Checkbox';
import MaterialIconMenu, { MaterialIconMenuProps } from '../../../../components/MaterialIconMenu';
import NoResults from '../../../../components/NoResults';
import LazarusPagination from '../../../../components/Pagination';
import Filters, {
  DynamicFormMetadata,
  ModuleNavigations,
  FilterItem,
} from '../../../../components/Table/Filters';
import { useDatatable } from '../../../../hooks/useDatatable';
import { Operator } from '../../../../hooks/useProtectedModules';

const addSelectedToRow = (row: PuntoDeSuministro): void => {
  document.getElementById(`row-${row.id}`)?.classList.add('active');
};

const removeSelectedToRow = (row: PuntoDeSuministro) => {
  document.getElementById(`row-${row.id}`)?.classList.remove('active');
};

const moduleNavigations: ModuleNavigations = {
  communications: `/riego/comunicaciones/puntos-de-suministro`,
  creates: `/riego/crear/puntos-de-suministro`,
};

const PuntosDeSuministroListPage = (): JSX.Element => {
  const {
    filteredItems,
    handleSetRows,
    handleChangeSelected,
    filterText,
    handleClear,
    handleChangeFilterText,
    selectedRows,
  } = useDatatable<PuntoDeSuministro>({
    removeSelectedToRow,
    addSelectedToRow,
  });

  const navigate = useNavigate();

  const columns: TableColumn<PuntoDeSuministro>[] = useMemo(
    () => [
      {
        name: 'ID',
        selector: row => row.id,
      },
      {
        name: 'Tipo de Agua',
        selector: row => row.tipoDeAgua,
      },
      {
        name: 'Contador',
        selector: row => row.contador,
      },
      {
        name: 'X',
        selector: row => row.x,
      },
      {
        name: 'Y',
        selector: row => row.y,
      },
      {
        cell: row => {
          const paramsToMenu: MaterialIconMenuProps<PuntoDeSuministro> = {
            row,
            size: 'medium',
            rows: selectedRows.length ? selectedRows : [row],
            onOpenMenu: () => addSelectedToRow(row),
            onCloseMenu: () => removeSelectedToRow(row),
            actions: [
              { label: 'Ver en mapa', onClick: row => console.log('Ver en mapa', row) },
              {
                label: 'Editar',
                onClick: row => navigate(`/riego/editar/puntos-de-suministro/${row.id}`),
                onlyApplyToOne: true,
              },
              { label: 'Enviar a MiNT', onClick: row => console.log('Enviar a MiNT', row) },
            ],
          };
          return <MaterialIconMenu {...paramsToMenu} />;
        },
        allowOverflow: true,
        button: true,
        width: '71px',
      },
    ],
    [selectedRows],
  );

  const SelectableCheckbox = (Checkbox as unknown) as ReactNode;

  const handleFilter = (filterItems: FilterItem[]) => {
    PuntosDeSuministro.get(filterItems).then(res => handleSetRows(res));
  };

  return (
    <>
      <Filters
        handleClear={handleClear}
        filterText={filterText}
        handleChangeFilterText={handleChangeFilterText}
        handleFilter={handleFilter}
        metadata={filterMetadata}
        hasItems={!!filteredItems.length}
        moduleNavigations={moduleNavigations}
        requirePermissions={requirePermissions}
      />
      <DataTable
        responsive
        columns={columns}
        data={filteredItems}
        pagination
        selectableRows
        selectableRowsHighlight
        paginationPerPage={10}
        noDataComponent={<NoResults />}
        paginationComponent={LazarusPagination}
        onSelectedRowsChange={handleChangeSelected}
        selectableRowsComponent={SelectableCheckbox}
      />
    </>
  );
};

export default PuntosDeSuministroListPage;

const requirePermissions = {
  lists: { section: 'riego', permission: 'list-puntos-suministro' },
  communications: {
    section: 'riego',
    permission: {
      permissions: ['import-puntos-suministro', 'export-puntos-suministro'],
      operator: '||' as Operator,
    },
  },
  creates: { section: 'riego', permission: 'create-puntos-suministro' },
};

const filterMetadata: DynamicFormMetadata[] = [
  {
    grid: {
      xs: 6,
      sm: 4,
      md: 2,
    },
    input: {
      propKey: 'id_del_punto_de_suministro',
      props: {
        label: 'Id del punto de suministro',
        type: 'text',
      },
      tag: 'Input',
    },
  },
  {
    grid: {
      xs: 6,
      sm: 4,
      md: 2,
    },
    input: {
      propKey: 'parque',
      props: {
        label: 'Parque',
        type: 'text',
      },
      tag: 'Input',
    },
  },
  {
    grid: {
      xs: 6,
      sm: 4,
      md: 2,
    },
    input: {
      propKey: 'grupo_parque',
      props: {
        label: 'Grupo parque',
        type: 'text',
      },
      tag: 'Input',
    },
  },
  {
    grid: {
      xs: 6,
      sm: 4,
      md: 2,
    },
    input: {
      propKey: 'punto_acometida',
      props: {
        label: 'Punto Acometida',
        type: 'text',
      },
      tag: 'Input',
    },
  },
  {
    grid: {
      xs: 6,
      sm: 4,
      md: 2,
    },
    input: {
      propKey: 'ultima_lectura',
      props: {
        label: 'Última Lectura',
        type: 'text',
      },
      tag: 'Input',
    },
  },
  {
    grid: {
      xs: 6,
      sm: 4,
      md: 2,
    },
    input: {
      propKey: 'auditado_contrato',
      props: {
        label: 'Auditado Contrato',
        type: 'text',
      },
      tag: 'Input',
    },
  },
  {
    grid: {
      xs: 6,
      sm: 4,
      md: 2,
    },
    input: {
      propKey: 'bocas_riego_acera',
      props: {
        label: 'Bocas riego acera',
        options: [
          {
            text: 'Sí',
            value: 'Sí',
          },
          {
            text: 'No',
            value: 'no',
          },
        ],
      },
      tag: 'Select',
    },
  },
  {
    grid: {
      xs: 6,
      sm: 4,
      md: 2,
    },
    input: {
      propKey: 'bocas_riego_parque',
      props: {
        label: 'Bocas riego parque',
        options: [
          {
            text: 'Sí',
            value: 'Sí',
          },
          {
            text: 'No',
            value: 'no',
          },
        ],
      },
      tag: 'Select',
    },
  },
  {
    grid: {
      xs: 6,
      sm: 4,
      md: 2,
    },
    input: {
      propKey: 'breve_contrato',
      props: {
        label: 'Breve Contrato',
        type: 'text',
      },
      tag: 'Input',
    },
  },
  {
    grid: {
      xs: 6,
      sm: 4,
      md: 2,
    },
    input: {
      propKey: 'calibre',
      props: {
        label: 'Calibre',
        type: 'text',
      },
      tag: 'Input',
    },
  },
  {
    grid: {
      xs: 6,
      sm: 4,
      md: 2,
    },
    input: {
      propKey: 'caseta_jardinera',
      props: {
        label: 'Caseta jardinera',
        options: [
          {
            text: 'Sí',
            value: 'Sí',
          },
          {
            text: 'No',
            value: 'no',
          },
        ],
      },
      tag: 'Select',
    },
  },
  {
    grid: {
      xs: 6,
      sm: 4,
      md: 2,
    },
    input: {
      propKey: 'codigo_zona_verde',
      props: {
        label: 'Código Zona Verde',
        type: 'text',
      },
      tag: 'Input',
    },
  },
  {
    grid: {
      xs: 6,
      sm: 4,
      md: 2,
    },
    input: {
      propKey: 'comentarios',
      props: {
        label: 'Comentarios',
        type: 'text',
      },
      tag: 'Input',
    },
  },
  {
    grid: {
      xs: 6,
      sm: 4,
      md: 2,
    },
    input: {
      propKey: 'comentarios_largos',
      props: {
        label: 'Comentarios Largos',
        type: 'text',
      },
      tag: 'Input',
    },
  },
  {
    grid: {
      xs: 6,
      sm: 4,
      md: 2,
    },
    input: {
      propKey: 'conclusion_contrato',
      props: {
        label: 'Conclusión Contrato',
        type: 'text',
      },
      tag: 'Input',
    },
  },
  {
    grid: {
      xs: 6,
      sm: 4,
      md: 2,
    },
    input: {
      propKey: 'contador',
      props: {
        label: 'Contador',
        type: 'text',
      },
      tag: 'Input',
    },
  },
  {
    grid: {
      xs: 6,
      sm: 4,
      md: 2,
    },
    input: {
      propKey: 'contrato_cyii',
      props: {
        label: 'Contrato CYII',
        type: 'text',
      },
      tag: 'Input',
    },
  },
  {
    grid: {
      xs: 6,
      sm: 4,
      md: 2,
    },
    input: {
      propKey: 'comentarios',
      props: {
        label: 'Comentarios',
        type: 'text',
      },
      tag: 'Input',
    },
  },
  {
    grid: {
      xs: 6,
      sm: 4,
      md: 2,
    },
    input: {
      propKey: 'edificio_municipal',
      props: {
        label: 'Edificio Municipal',
        options: [
          {
            text: 'Sí',
            value: 'Sí',
          },
          {
            text: 'No',
            value: 'no',
          },
        ],
      },
      tag: 'Select',
    },
  },
  {
    grid: {
      xs: 6,
      sm: 4,
      md: 2,
    },
    input: {
      propKey: 'edificio_no_municipal',
      props: {
        label: 'Edificio No Municipal',
        options: [
          {
            text: 'Sí',
            value: 'Sí',
          },
          {
            text: 'No',
            value: 'no',
          },
        ],
      },
      tag: 'Select',
    },
  },
  {
    grid: {
      xs: 6,
      sm: 4,
      md: 2,
    },
    input: {
      propKey: 'estado_contrato',
      props: {
        label: 'Estado contrato',
        type: 'text',
      },
      tag: 'Input',
    },
  },
  {
    grid: {
      xs: 6,
      sm: 4,
      md: 2,
    },
    input: {
      propKey: 'fuente_beber',
      props: {
        label: 'Fuente Beber',
        type: 'text',
      },
      tag: 'Input',
    },
  },
  {
    grid: {
      xs: 6,
      sm: 4,
      md: 2,
    },
    input: {
      propKey: 'fuente_ornamental',
      props: {
        label: 'Fuente Ornamental',
        type: 'text',
      },
      tag: 'Input',
    },
  },
  {
    grid: {
      xs: 6,
      sm: 4,
      md: 2,
    },
    input: {
      propKey: 'fuente_ornamental',
      props: {
        label: 'Fuente Ornamental',
        options: [
          {
            text: 'Sí',
            value: 'Sí',
          },
          {
            text: 'No',
            value: 'no',
          },
        ],
      },
      tag: 'Select',
    },
  },
  {
    grid: {
      xs: 6,
      sm: 4,
      md: 2,
    },
    input: {
      propKey: 'fuente_parque',
      props: {
        label: 'Fuente Parque',
        options: [
          {
            text: 'Sí',
            value: 'Sí',
          },
          {
            text: 'No',
            value: 'no',
          },
        ],
      },
      tag: 'Select',
    },
  },
  {
    grid: {
      xs: 6,
      sm: 4,
      md: 2,
    },
    input: {
      propKey: 'fuente_publica',
      props: {
        label: 'Fuente Pública',
        options: [
          {
            text: 'Sí',
            value: 'Sí',
          },
          {
            text: 'No',
            value: 'no',
          },
        ],
      },
      tag: 'Select',
    },
  },
  {
    grid: {
      xs: 6,
      sm: 4,
      md: 2,
    },
    input: {
      propKey: 'jardin_privado',
      props: {
        label: 'Jardín Privado',
        options: [
          {
            text: 'Sí',
            value: 'Sí',
          },
          {
            text: 'No',
            value: 'no',
          },
        ],
      },
      tag: 'Select',
    },
  },
  {
    grid: {
      xs: 6,
      sm: 4,
      md: 2,
    },
    input: {
      propKey: 'marca',
      props: {
        label: 'Marca',
        type: 'text',
      },
      tag: 'Input',
    },
  },
  {
    grid: {
      xs: 6,
      sm: 4,
      md: 2,
    },
    input: {
      propKey: 'observacion_tarifa',
      props: {
        label: 'Observación Tarifa',
        type: 'text',
      },
      tag: 'Input',
    },
  },
  {
    grid: {
      xs: 6,
      sm: 4,
      md: 2,
    },
    input: {
      propKey: 'otros',
      props: {
        label: 'Otros',
        options: [
          {
            text: 'Sí',
            value: 'Sí',
          },
          {
            text: 'No',
            value: 'no',
          },
        ],
      },
      tag: 'Select',
    },
  },
  {
    grid: {
      xs: 6,
      sm: 4,
      md: 2,
    },
    input: {
      propKey: 'pendiente_baja',
      props: {
        label: 'Pendiente Baja',
        type: 'text',
      },
      tag: 'Input',
    },
  },
  {
    grid: {
      xs: 6,
      sm: 4,
      md: 2,
    },
    input: {
      propKey: 'pendiente_inspeccion',
      props: {
        label: 'Pendiente Inspección',
        type: 'text',
      },
      tag: 'Input',
    },
  },
  {
    grid: {
      xs: 6,
      sm: 4,
      md: 2,
    },
    input: {
      propKey: 'polideportivo',
      props: {
        label: 'Polideportivo',
        options: [
          {
            text: 'Sí',
            value: 'Sí',
          },
          {
            text: 'No',
            value: 'no',
          },
        ],
      },
      tag: 'Select',
    },
  },
  {
    grid: {
      xs: 6,
      sm: 4,
      md: 2,
    },
    input: {
      propKey: 'riego_automatico',
      props: {
        label: 'Riego Automático',
        options: [
          {
            text: 'Sí',
            value: 'Sí',
          },
          {
            text: 'No',
            value: 'no',
          },
        ],
      },
      tag: 'Select',
    },
  },
  {
    grid: {
      xs: 6,
      sm: 4,
      md: 2,
    },
    input: {
      propKey: 'servicios_suministrados',
      props: {
        label: 'Servicios Suministrados',
        type: 'text',
      },
      tag: 'Input',
    },
  },
  {
    grid: {
      xs: 6,
      sm: 4,
      md: 2,
    },
    input: {
      propKey: 'tarifa',
      props: {
        label: 'Tarifa',
        type: 'text',
      },
      tag: 'Input',
    },
  },
  {
    grid: {
      xs: 6,
      sm: 4,
      md: 2,
    },
    input: {
      propKey: 'tarifa_ultima_lectura',
      props: {
        label: 'Tarifa última lectura',
        type: 'text',
      },
      tag: 'Input',
    },
  },
  {
    grid: {
      xs: 6,
      sm: 4,
      md: 2,
    },
    input: {
      propKey: 'tipo_agua',
      props: {
        label: 'Tipo Agua',
        type: 'text',
      },
      tag: 'Input',
    },
  },
  {
    grid: {
      xs: 6,
      sm: 4,
      md: 2,
    },
    input: {
      propKey: 'uso_privado',
      props: {
        label: 'Uso privado',
        options: [
          {
            text: 'Sí',
            value: 'Sí',
          },
          {
            text: 'No',
            value: 'no',
          },
        ],
      },
      tag: 'Select',
    },
  },
];
