import { AxiosError } from 'axios';
import { useState } from 'react';
import { ImportExportResult } from '../API/shared/interfaces';
import { CommunicationConfig } from '../config/interfaces';
import { showErrorAlert, showSuccessAlert } from '../libs/LazarusAlert';
import { appConfig } from '../config/app';

interface Props {
  config?: CommunicationConfig;
  importApi: (formData: FormData) => Promise<ImportExportResult[]>;
}

interface CommunicationImport {
  loadPercentage: number;
  finalMessage: string;
  errorMessages: string[];
  sending: boolean;
  onUploadFile: (file: File) => void;
  handleSendToMiNT: () => void;
}

export function useCommunicationImport({ config, importApi }: Props): CommunicationImport {
  const [loadPercentage, setLoadPercentage] = useState<number>(0);
  const [finalMessage, setFinalMessage] = useState<string>('');
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const [sending, setSending] = useState<boolean>(false);

  const onUploadFile = (file: File) => {
    if (!config || !config.importFunctionName) return;

    setLoadPercentage(0);
    setErrorMessages([]);
    const idEnvio = Math.random().toString(36).substr(2, 9);
    const data = new FormData();
    data.append('file', file);
    data.append('function', config.importFunctionName);
    data.append('id_envio', idEnvio);

    let successMsg = '';
    importApi(data)
      .then(results => {
        for (const result of results) {
          setLoadPercentage(result.progress);
          if (result.end) {
            setFinalMessage(result.message);
            successMsg = result.message;
            if (result.file) {
              window.open(
                `${appConfig.apiConfig.baseURL}/${result.file.replace('../', '')}`,
                '_blank',
              );
            }
          }
          if (result.error) {
            setLoadPercentage(0);
            setErrorMessages(
              result &&
                typeof result.error !== 'object' &&
                result.error &&
                result.message !== result.error
                ? [result.message + ' ' + result.error]
                : [result.message],
            );
            setFinalMessage('');
            successMsg = '';
          } else if (result.errors) {
            setLoadPercentage(0);
            setErrorMessages(result.errors.map(err => err.mensaje));
            setFinalMessage('');
            successMsg = '';
          }
        }
        if (successMsg) {
          showSuccessAlert({
            title: 'Proceso ejecutado correctamente',
            text: successMsg,
          });
        }
      })
      .catch((err: AxiosError) => {
        console.log('err', err);
        let errorMessage = 'Error desconocido'; // Mensaje de error por defecto

        const responseData: any = err.response?.data; // Asignar err.response.data a una variable de tipo any

        if (responseData && typeof responseData === 'object' && 'error' in responseData) {
          errorMessage = responseData.error;
          console.log('Respuesta de error del servidor:', errorMessage);
        } else if (
          err.response &&
          err.response.data &&
          Array.isArray(err.response.data) &&
          err.response.data.length > 0 &&
          err.response.data[0] &&
          err.response.data[0].error
        ) {
          errorMessage = err.response.data[0].error;
        } else {
          console.log('Error sin respuesta del servidor:', err.message);
        }

        showErrorAlert({ title: 'Error al importar información', text: errorMessage });

        if (err.response && err.response.status === 401) {
          window.location.href = '/login';
        }
      });
  };

  const handleSendToMiNT = () => {
    if (!config || !config.sentFunctionName) return;
    setSending(true);
    const idEnvio = Math.random().toString(36).substr(2, 9);
    const data = new FormData();
    data.append('function', config.sentFunctionName);
    data.append('id_envio', idEnvio);

    importApi(data)
      .then(results => {
        showSuccessAlert({
          title: 'Proceso ejecutado correctamente',
          text: results[0].message || results[0].mensaje,
        });

        setSending(false);
      })
      .catch((err: AxiosError) => {
        console.log('err', err);
        showErrorAlert({ title: 'Error al enviar información' });
        setSending(false);
      });
  };

  return { sending, loadPercentage, onUploadFile, handleSendToMiNT, finalMessage, errorMessages };
}
