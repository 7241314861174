import LinearProgress from '@material-ui/core/LinearProgress';
import { ChangeEvent, FC, useRef } from 'react';
import { CommunicationConfig } from '../../config/interfaces';
import { useImportFile } from '../../hooks/useImportFile';
import { ModulePermission, useProtectedModules } from '../../hooks/useProtectedModules';
import Button from '../Button';
import Col from '../Col';
import H2 from '../H2';
import Row from '../Row';

interface Props {
  title: string;
  config?: CommunicationConfig;
  importApi: (formData: FormData) => Promise<{ message: string }>;
  requirePermission?: ModulePermission;
}

const ImportFile: FC<Props> = ({ title, config, importApi, requirePermission }) => {
  const inputFile = useRef<HTMLInputElement>(null);
  const { loadPercentage, onUploadFile } = useImportFile({
    config,
    importApi,
  });
  const { showModule } = useProtectedModules();

  const handleChangeFile = (ev: ChangeEvent<HTMLInputElement>) => {
    const file = ev.target.files ? ev.target.files[0] : null;
    if (file) {
      onUploadFile(file);
      ev.target.value = '';
    }
  };

  const showImportButton = (): boolean => {
    if (!config) return false;

    return (
      config.actions.includes('carga') ||
      config.actions.includes('carga-pre-envio') ||
      config.actions.includes('carga-envio')
    );
  };

  const getProgressBarText = (): string => {
    if (!config) return '';

    if (config.actions.includes('carga')) return 'Archivo Cargado';
    if (config.actions.includes('carga-pre-envio')) return 'Archivo Cargado y PreEnviado';
    if (config.actions.includes('carga-envio')) return 'Archivo Cargado y Enviado';

    return '';
  };

  if (requirePermission && !showModule(requirePermission)) return null;

  return (
    <>
      <H2>{title}</H2>
      <Row>
        <Col md={3}>
          <div className="import-btn__container">
            {showImportButton() && (
              <>
                <Button
                  onClick={() => {
                    if (inputFile.current) {
                      inputFile.current.click();
                    }
                  }}
                  color="primary"
                >
                  Cargar Archivo
                </Button>
                <input
                  ref={inputFile}
                  onChange={handleChangeFile}
                  type="file"
                  accept="application/zip"
                />
              </>
            )}
          </div>
        </Col>
        <Col md={9}>
          <LinearProgress variant="determinate" value={loadPercentage} />
          <div className="progress-bar">
            <ul style={{ marginTop: 10 }}></ul>
            <p>{getProgressBarText()}</p>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default ImportFile;
