import { GetRiegoPuntosDeSuministroResponse, PuntoDeSuministro } from '../interfaces';

export const responseTransformer = (response: string): PuntoDeSuministro[] => {
  const responseJSON: GetRiegoPuntosDeSuministroResponse = JSON.parse(response);

  return responseJSON.puntosSuministro.map(puntoSuministro => ({
    id: puntoSuministro.id,
    tipoDeAgua: puntoSuministro.tipoAgua || '',
    contador: puntoSuministro.contador || '',
    puntoAcometida: puntoSuministro.puntoAcometida || '',
    x: puntoSuministro.X || '',
    y: puntoSuministro.Y || '',
  }));
};
