import cn from 'classnames';
import { FC } from 'react';

export interface Option {
  text: string;
  value: string | number;
}

export interface LazarusSelectProps extends React.SelectHTMLAttributes<HTMLSelectElement> {
  /**
   * Opciones
   */
  options: Option[];

  /**
   * Indicar si hay error
   */
  error?: string;

  /**
   * Indicar label
   */
  label: string;

  /**
   * Id
   */
  id?: string;

  /**
   * Agregar clases de css propias
   */
  className?: string;

  /**
   * Selector multiple múltiple
   */
  multiple?: boolean;
}

const Select: FC<LazarusSelectProps> = ({ error, label, options, id, className, ...props }) => {
  const classNames = cn(
    'input-group',
    {
      error: error,
    },
    className,
  );

  const labelClassName = cn({
    label: props.value,
  });

  return (
    <div className={classNames}>
      <select id={id} {...props}>
        <option defaultChecked></option>
        {options.map((option: Option) => (
          <option key={option.value} value={option.value}>
            {option.text}
          </option>
        ))}
      </select>
      <label className={labelClassName} htmlFor={id}>
        {label}
      </label>
      {error && (
        <span>
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8 5V8.45M8 11H8.0075M14.75 8C14.75 11.7279 11.7279 14.75 8 14.75C4.27208 14.75 1.25 11.7279 1.25 8C1.25 4.27208 4.27208 1.25 8 1.25C11.7279 1.25 14.75 4.27208 14.75 8Z"
              stroke="#E7641A"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          {error}
        </span>
      )}
    </div>
  );
};

export default Select;
