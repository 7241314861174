import artemisaLogoWhite from '../assets/img/logos/artemisa-white.svg';
import artemisaLogoBlack from '../assets/img/logos/artemisa-black.svg';

import lazarusLogoWhite from '../assets/img/logos/lazarus-white.svg';
import lazarusLogoBlack from '../assets/img/logos/lazarus-black.svg';

export const config = {
  artemisa: {
    white: artemisaLogoWhite,
    black: artemisaLogoBlack,
  },
  lazarus: {
    white: lazarusLogoWhite,
    black: lazarusLogoBlack,
  },
};
