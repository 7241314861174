export const campos = [
  { value: 'idInventarioExterno', text: 'Id. inventario externo' },
  { value: 'idInventario', text: 'Id. inventario' },
  { value: 'actuRealizadaIncidencia', text: 'Actuación realizada' },
  { value: 'alturaRoturaTroncoIncidencia', text: 'Altura punto de rotura Tronco (cm)' },
  { value: 'analisisLaboratorioIncidencia', text: '¿Análisis laboratorio?' },
  { value: 'codigo112Incidencia', text: 'Código 112' },
  { value: 'codigoDGAZVIncidencia', text: 'Código DGAZV' },
  { value: 'copaIncidencia', text: 'Copa/Ramas' },
  { value: 'danosBienesIncidencia', text: 'Daños Bienes' },
  { value: 'danosInfraestructurasIncidencia', text: 'Daños en infraestructura' },
  { value: 'danosObservacionesIncidencia', text: 'Daños - Observaciones' },
  { value: 'danosPersonasIncidencia', text: 'Daños personas' },
  { value: 'danosVegetacionIncidencia', text: 'Daños vegetación' },
  { value: 'datosExternosIncidencia', text: 'Datos externos' },
  { value: 'defectosRelacionadosRamaIncidencia', text: 'Defectos relacionados en rama' },
  { value: 'defectosRelacionadosTroncoIncidencia', text: 'Defectos relacionados en tronco' },
  { value: 'defectosRelacionadosVuelcoIncidencia', text: 'Defectos relacionados vuelco' },
  { value: 'diametroCepellonIncidencia', text: 'Diámetro de cepellón (m)' },
  { value: 'distanciaPuntoInsercIncidencia', text: 'Distancia punto de inserción (cm)' },
  { value: 'esbeltezIncidencia', text: 'Esbeltez (long/)' },
  { value: 'espesorParedResidualIncidencia', text: 'Espesor pared residual (cm)' },
  { value: 'estadoRamasIncidencia', text: 'Estado de las ramas' },
  { value: 'eventoMeteorologicoIncidencia', text: 'Evento meteorológico' },
  { value: 'evolucionEsperadaIncidencia', text: 'Evolución esperada' },
  {
    value: 'fechaActivacionIncidencia',
    text: 'Fecha de activación. Sólo disponible en la inserción',
  },
  { value: 'fechaInspeccionIncidencia', text: 'Fecha de inspección' },
  { value: 'fichaEvaluacionIncidencia', text: 'Ficha de evaluación' },
  { value: 'inclinacionNumIncidencia', text: 'Inclinación (n°)' },
  { value: 'instrumentalesIncidencia', text: '¿Instrumentales?' },
  { value: 'internoIncidencia', text: 'Interno' },
  { value: 'localizaFalloIncidencia', text: 'Localización Fallo' },
  { value: 'longitudTotalRamaIncidencia', text: 'Longitud total rama (m)' },
  { value: 'origenIncidencia', text: 'Origen / Activación' },
  { value: 'perimetroBaseRamaIncidencia', text: 'Perímetro base rama (cm)' },
  { value: 'perimetroRoturaRamaIncidencia', text: 'Perímetro punto de rotura de la rama (cm)' },
  { value: 'perimetroRoturaTroncoIncidencia', text: 'Perímetro punto de rotura del tronco (cm)' },
  { value: 'peritajeIncidencia', text: '¿Necesita peritaje?' },
  { value: 'pesoAproxRamaIncidencia', text: 'Peso aproximado de la rama (Kg)' },
  { value: 'pesoAproxTroncoIncidencia', text: 'Peso aproximado del tronco (Kg)' },
  { value: 'porcPerimetroTroncoIncidencia', text: 'Porcentaje de perímetro del tronco dañado' },
  { value: 'precipitacionIncidencia', text: 'Precipitación' },
  { value: 'primeraIntervencionIncidencia', text: 'Primera intervención' },
  { value: 'profundidadCepellonIncidencia', text: 'Profundidad cepellón (mm)' },
  { value: 'propuestaActuacionIncidencia', text: 'Propuesta de actuación' },
  { value: 'pruebasDiagnosticasIncidencia', text: '¿Necesidad pruebas diagnósticas?' },
  { value: 'puntoRoturaRamaIncidencia', text: 'Punto de rotura de rama' },
  { value: 'raicesIncidencia', text: 'Raices' },
  { value: 'riesgosResidualesIncidencia', text: 'Observan riesgos residuales' },
  { value: 'tempMinMaxIncidencia', text: 'Temperatura mínima/máxima (°C)' },
  { value: 'tipoEjeIncidencia', text: 'Tipo de eje / rama' },
  { value: 'tipoIncidenciaIncidencia', text: 'Tipo de incidencia' },
  { value: 'troncoIncidencia', text: 'Tronco / cruz' },
  { value: 'urgenciaIncidencia', text: 'Urgencia' },
  { value: 'velocidadMaxVientoIncidencia', text: 'Velocidad máxima del viento (Km/h)' },
];
