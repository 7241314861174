import { AxiosResponse } from 'axios';
import { User } from '../../../redux/UserSlice/userSlice';
import { createOauthApiInstance } from '../../api';

export const execute = async (token: string): Promise<User> => {
  const whoamiRes: AxiosResponse<{ data: User }> = await createOauthApiInstance().get(`/user`, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return whoamiRes.data.data;
};
