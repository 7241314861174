import { FC } from 'react';
import Col from '../../../../../../components/Col';
import Select, { Option } from '../../../../../../components/Select/index';
import Input from '../../../../../../components/Input/index';
import Row from '../../../../../../components/Row';
import Icon from '../../../../../../components/Icon/index';

interface Props {
  materiales: Option[];
  material: MaterialNoCodificadoData;
  idx: number;
  handleChangeMaterial: (idx: number, material: MaterialNoCodificadoData) => void;
  handleRemoveMaterial: (idx: number) => void;
}

export interface MaterialNoCodificadoData {
  materialId: string;
  cantidad: string;
}

const MaterialNoCodificado: FC<Props> = ({
  idx,
  materiales,
  material,
  handleChangeMaterial,
  handleRemoveMaterial,
}) => {
  const handleChange = (name: keyof MaterialNoCodificadoData, value: string) => {
    const newMaterial = { ...material, [name]: value };
    handleChangeMaterial(idx, newMaterial);
  };

  return (
    <Row style={{ marginTop: 25 }} className="material-no-codificado">
      <Col xs={12} md={7} className="material-no-codificado__materialId">
        <Select
          label="Material"
          onChange={ev => handleChange('materialId', ev.target.value)}
          value={material.materialId}
          options={materiales}
        />
      </Col>
      <Col xs={12} md={2} className="material-no-codificado__cantidad">
        <Input
          type="text"
          onChange={ev => handleChange('cantidad', ev.target.value)}
          value={material.cantidad}
          label="Cantidad"
        />
      </Col>
      <Col xs={12} md={1}>
        <Icon onClick={() => handleRemoveMaterial(idx)} color="black" name="trash" />
      </Col>
    </Row>
  );
};

export default MaterialNoCodificado;
