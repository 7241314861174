import { AppConfig, NavbarType, PreConfig, Theme } from './interfaces';
import { config } from './logos';

//Artemisa - Riego & ZZVV - Descomentar código para activar y comentar el resto de preConfig
const preConfig: PreConfig = {
  pageTitle: 'Artemisa',
  primaryColor: '#e7641a',
  basePath: {
    zzvv: '/zonas-verdes/comunicaciones/inventario',
    flotas: '/flotas/listado/vehiculos',
  },
  theme: 'artemisa' as Theme,
  modules: ['zzvv', 'flotas'],
  submodulesByModule: {
    zzvv: ['communications'],
    flotas: ['lists', 'communications'],
  },
  logo: config.artemisa,
  navbarType: 'group-links' as NavbarType,
  googleMapsApiKey: '',
};

// const apiBaseURL = 'http://artemisa.utelote4.com:8000';
const apiBaseURL = 'http://artemisa.utelote4.com/apiBack';

export const appConfig: AppConfig = {
   appTitle: preConfig.pageTitle,
   primaryColor: preConfig.primaryColor,
   logo: preConfig.logo,
   navbarType: preConfig.navbarType || 'links',
   googleMapsApiKey: preConfig.googleMapsApiKey,
   apiConfig: {
      baseURL: apiBaseURL,
   },
   theme: preConfig.theme,
   coreModules: [
      {
         active: preConfig.modules.includes('zzvv'),
         icon: 'tree',
         label: 'Zonas verdes',
         basePath: preConfig.basePath.zzvv || '/zonas-verdes/comunicaciones/inventario',
         module: 'zzvv',
         submodulesGroups: preConfig.submodulesByModule?.zzvv || [],
         requirePermission: 'app-zzvv',
         communicationConfig: [
            {
               subModule: 'actuaciones',
               importFunctionName: 'cargarActuaciones',
               exportFunctionName: 'consultarActuaciones',
			   resultadosEsperados: '800',
               actions: ['consulta', 'carga-envio'],
            },
            {
               subModule: 'actuaciones-activos',
               importFunctionName: 'cargarActivosActuaciones',
               actions: ['carga-envio'],
            },
            {
               subModule: 'actuaciones-incidencias',
               importFunctionName: 'cargarIncidenciasActuaciones',
               actions: ['carga-envio'],
            },
            {
               subModule: 'actualiza-ordenes',
               importFunctionName: 'actualizaOrdenes',
               actions: ['carga-envio'],
            },
            {
               subModule: 'actualiza-estado-actuacion',
               importFunctionName: 'actualizaEstadoActuacion',
               actions: ['carga-envio'],
            },
            {
               subModule: 'superficies',
               importFunctionName: 'cargarSuperficies',
               exportFunctionName: 'consultarSuperficies',
               actions: ['consulta', 'carga-envio'],
            },
            {
               subModule: 'unidad-gestion',
               importFunctionName: 'cargarUnidadesGestion',
               exportFunctionName: 'consultarUnidadesGestion',
			   resultadosEsperados: '1000',
               actions: ['consulta', 'carga-envio'],
            },
            {
               subModule: 'inventario',
               importFunctionName: 'cargarInventario',
               exportFunctionName: 'consultarInventario',
			   resultadosEsperados: '300',
               actions: ['consulta', 'carga-pre-envio'],
            },
            {
               subModule: 'especies',
               exportFunctionName: 'consultarEspecies',
			   resultadosEsperados: '1000',
               actions: ['consulta'],
            },
            {
               subModule: 'inspecciones',
               importFunctionName: 'cargarInspecciones',
               exportFunctionName: 'consultarInspecciones',
			   resultadosEsperados: '1000',
               actions: ['consulta', 'carga-envio'],
            },
            {
               subModule: 'inspecciones-fotos',
               importFunctionName: 'cargarFotosInspecciones',
               actions: ['carga-envio'],
            },
            {
               subModule: 'incidencias',
               importFunctionName: 'cargarIncidencias',
               exportFunctionName: 'consultarIncidencias',
			   resultadosEsperados: '1000',
               actions: ['consulta', 'carga-envio'],
            },
            {
               subModule: 'incidencias-fotos',
               importFunctionName: 'cargarFotosIncidencias',
               actions: ['carga-envio'],
            },
            {
               subModule: 'evaluaciones-visuales',
               importFunctionName: 'cargarEvaluacionesVisuales',
               exportFunctionName: 'consultarEvaluacionesVisuales',
			   resultadosEsperados: '1000',
               actions: ['consulta', 'carga-envio'],
            },
            {
               subModule: 'evaluaciones-visuales-fotos',
               importFunctionName: 'cargarFotosEvaluacionesVisuales',
               actions: ['carga-envio'],
            },
            {
               subModule: 'planificaciones',
               importFunctionName: 'cargarPlanificaciones',
               exportFunctionName: 'consultarPlanificaciones',
			   resultadosEsperados: '1000',
               actions: ['consulta', 'carga-envio'],
            },
			{
			  subModule: 'zonas-exclusion',
			  importFunctionName: 'cargarZonasExclusion',
			  exportFunctionName: 'consultarZonasExclusion',
			  resultadosEsperados: '50',
			  actions: ['consulta', 'carga-envio'],
			},
         ],
      },
      {
         active: preConfig.modules.includes('riego'),
         icon: 'water',
         label: 'Riego',
         basePath: preConfig.basePath.riego || '/riego/listado/inventario',
         module: 'riego',
         submodulesGroups: preConfig.submodulesByModule?.riego || [],
         requirePermission: 'app-riego',
         communicationConfig: [
            {
               subModule: 'actuaciones',
               importFunctionName: 'cargarActuaciones',
               sentFunctionName: 'enviarActuaciones',
               exportFunctionName: 'consultarActuaciones',
               actions: ['carga-pre-envio', /* 'pre-envio',  */ 'consulta'],
            },
            {
               subModule: 'inventario',
               importFunctionName: 'cargarInventario',
               sentFunctionName: 'enviarInventario',
               exportFunctionName: 'consultarInventario',
               actions: ['carga-pre-envio', /* 'pre-envio',  */ 'consulta'],
            },
            {
               subModule: 'inventario-fuentes-abiertas',
               importFunctionName: 'cargarInventarioNdp',
               actions: ['carga'],
            },
            {
               subModule: 'inventario-elementos-relacionados',
               importFunctionName: 'cargarElementosInventarioRelacionados',
               sentFunctionName: 'enviarElementosInventarioRelacionados',
               actions: ['carga-envio' /* , 'envio' */],
            },
            {
               subModule: 'piezas-recambio',
               sentFunctionName: 'cargarPiezasRecambio',
               actions: ['envio'],
            },
            {
               subModule: 'puntos-suministro',
               importFunctionName: 'cargarPuntosSuministro',
               sentFunctionName: 'enviarPuntosSuministro',
               exportFunctionName: 'consultarPuntoSuministro',
               actions: ['carga-envio', /* 'envio',  */ 'consulta'],
            },
            {
               subModule: 'contadores',
               importFunctionName: 'cargarContadores',
               sentFunctionName: 'enviarContadores',
               actions: ['carga-envio' /* , 'envio' */],
            },
            {
               subModule: 'materiales',
               sentFunctionName: 'cargarMaterialesActuacion',
               actions: ['envio'],
            },
         ],
      },
      {
         active: preConfig.modules.includes('limpieza'),
         icon: 'trash',
         label: 'Limpieza',
         basePath: preConfig.basePath.limpieza || '/limpieza/listado/inventario',
         module: 'limpieza',
         submodulesGroups: preConfig.submodulesByModule?.limpieza || [],
         requirePermission: 'app-limpieza',
         communicationConfig: [
            {
               subModule: 'actuaciones',
               exportFunctionName: 'consultarActuaciones',
               actions: ['consulta'],
            },
            {
               subModule: 'actuaciones-planificaciones',
               importFunctionName: 'cargarActuacionesPlani',
               actions: ['carga-pre-envio'],
            },
            {
               subModule: 'actuaciones-ejecuciones',
               importFunctionName: 'cargarActuacionesEje',
               actions: ['carga-pre-envio'],
            },
            {
               subModule: 'inventario',
               importFunctionName: 'cargarInventario',
               sentFunctionName: 'enviarInventario',
               exportFunctionName: 'consultarInventario',
               actions: ['carga-pre-envio', /* 'pre-envio',  */ 'consulta'],
            },
            {
               subModule: 'inventario-fuentes-abiertas',
               importFunctionName: 'cargarInventarioNdp',
               actions: ['carga'],
            },
            {
               subModule: 'ubl',
               importFunctionName: 'cargarUBL',
               sentFunctionName: 'enviarUBL',
               exportFunctionName: 'consultarUBL',
               actions: ['carga-pre-envio', /* 'pre-envio',  */ 'consulta'],
            },
            {
               subModule: 'planificacion-sectores',
               sentFunctionName: 'enviarPlanificaciones',
               importFunctionName: 'cargarInventario',
               exportFunctionName: 'consultarPlanificacionSectores',
               actions: ['carga-pre-envio', /* 'pre-envio',  */ 'consulta'],
            },
         ],
      },
      {
         active: preConfig.modules.includes('flotas'),
         icon: 'truck',
         label: 'Flotas',
         basePath: preConfig.basePath.flotas || '/flotas/listado/vehiculos',
         module: 'flotas',
         submodulesGroups: preConfig.submodulesByModule?.flotas || [],
         requirePermission: 'app-flotas',
         communicationConfig: [
            {
               subModule: 'planificaciones',
               exportFunctionName: 'import',
               actions: ['carga'],
            },
         ],
      },
   ],
   commonModules: [
      {
         active: true,
         icon: 'right-circle',
         label: 'Gestión de Envíos',
         basePath: '/gestion-de-envios',
         requirePermission: 'view-gestion-envios',
      },
      {
         active: true,
         icon: 'user',
         label: 'Usuarios',
         basePath: '/usuarios',
         requirePermission: 'view-users',
      },
   ],
};