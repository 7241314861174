export const campos = [
  { value: 'idActuacion', text: 'Id actuación' },
  { value: 'idActuacionExterno', text: 'Id actuación externo' },
  { value: 'idInventario', text: 'Id inventario' },
  { value: 'barrio', text: 'Barrio' },
  { value: 'barrioDescripcion', text: 'Barrio descripción' },
  { value: 'calculoIndicadorMotivo', text: 'Cálculo indicador' },
  { value: 'claseFuncionGIS', text: 'Clase función GIS' },
  { value: 'codigoPostal', text: 'Código postal' },
  { value: 'concesionaria', text: 'Concesionaria' },
  { value: 'contrato', text: 'Contrato' },
  { value: 'contratoDescripcion', text: 'Contrato descripción' },
  { value: 'coordenadas', text: 'Coordenadas' },
  { value: 'coordenadaX', text: 'Coordenada X' },
  { value: 'coordenadaY', text: 'Coordenada Y' },
  { value: 'descripcion', text: 'Descripción' },
  { value: 'descripcionLarga', text: 'Descripción larga' },
  { value: 'distrito', text: 'Distrito' },
  { value: 'distritoDescrpcion', text: 'Distrito descripción' },
  { value: 'duracionEstimada', text: 'Duración estimada' },
  { value: 'esPrioritaria', text: 'Es prioritaria' },
  { value: 'estado', text: 'Estado' },
  { value: 'estadoDescripcion', text: 'Estado descripción' },
  { value: 'fechaCambio', text: 'Fecha cambio' },
  { value: 'fechaEstado', text: 'Fecha estado' },
  { value: 'fechaFinPlanificada', text: 'Fecha fin planificada' },
  { value: 'fechaFinProgramada', text: 'Fecha fin programada' },
  { value: 'fechaFinReal', text: 'Fecha fin real' },
  { value: 'fechaInicioPlanificada', text: 'Fecha inicio planificada' },
  { value: 'fechaInicioProgramada', text: 'Fecha inicio planificada' },
  { value: 'fechaInicioReal', text: 'Fecha inicio real' },
  { value: 'fechaReporte', text: 'Fecha reporte' },
  { value: 'grupoParque', text: 'Grupo parque' },
  { value: 'grupoParqueDescripcion', text: 'Grupo parque descripción' },
  { value: 'jerarquiaClasificacion', text: 'Jerarquía clasificación' },
  { value: 'jerarquiaClasificacionDescripcion', text: 'Jerarquía clasificación descripción' },
  { value: 'localizacion', text: 'Localización' },
  { value: 'lote', text: 'Lote' },
  { value: 'loteDescripcion', text: 'Lote descripción' },
  { value: 'medicion', text: 'Medición' },
  { value: 'medidaEquivalente', text: 'Medición equivalente' },
  { value: 'medidaProgramada', text: 'Medida programada' },
  { value: 'medidaReal', text: 'Medida real' },
  { value: 'ndp', text: 'NDP' },
  { value: 'nombreVia', text: 'Nombre vía' },
  { value: 'numeroActo', text: 'Número activo' },
  { value: 'numeroAvisoOrigen', text: 'Número aviso origen' },
  { value: 'numeroVia', text: 'Número vía' },
  { value: 'ordenDirecta', text: 'Orden directa' },
  { value: 'parque', text: 'Parque' },
  { value: 'parqueDescripcion', text: 'Parque descripción' },
  { value: 'prestacion', text: 'Prestación' },
  { value: 'servicio', text: 'Servicio' },
  { value: 'servicioDescripcion', text: 'Servicio descripción' },
  { value: 'tipoActuacionEquivalente', text: 'Tipo actuación equivalente' },
  { value: 'tipoElemento', text: 'Tipo elemento' },
  { value: 'tipoVia', text: 'Tipo vía' },
  { value: 'unidadMedida', text: 'Unidad medida' },
  { value: 'unidadMedidaEquivalente', text: 'Unidad medida equivalente' },
  { value: 'usuarioEditor', text: 'Usuario editor' },
  { value: 'usuarioReporte', text: 'Usuario reporte' },
  { value: 'zona', text: 'Zona' },
  { value: 'zonaDescripcion', text: 'Zona descripción' },
  { value: 'coordenadaXGIS', text: 'Coordenada X GIS' },
  { value: 'coordenadaYGIS', text: 'Coordenada Y GIS' },
  { value: 'ambito', text: 'Ámbito' },
  { value: 'calculoIndicador', text: 'Cálculo Indicador' },
  { value: 'especie', text: 'Especie' },
  { value: 'fechaInicioPlazo', text: 'Fecha inicio plazo' },
  { value: 'fechaLimite', text: 'Fecha limite' },
  { value: 'observacionesActuacion', text: 'Observaciones actuación' },
  { value: 'observacionesLargasActuacion', text: 'Observaciones largas actuación' },
  { value: 'origen', text: 'Origen' },
  { value: 'peticionario', text: 'Peticionario' },
  { value: 'planZV', text: 'Plan ZV' },
  { value: 'procedencia', text: 'Procedencia' },
  { value: 'requiereAprobacion', text: 'Requiere aprobación' },
  { value: 'superficieVerde', text: 'Superficie verde' },
  { value: 'tecnicoEmpresa', text: 'Técnico empresa' },
  { value: 'tecnicoMunicipal', text: 'Técnico municipal' },
  { value: 'tipoActuacion', text: 'Tipo actuación' },
  { value: 'tipoDano', text: 'Tipo daño' },
  { value: 'tipoIncidencia', text: 'Tipo incidencia' },
  { value: 'unidadMedidaReal', text: 'Unidad medida real' },
];
