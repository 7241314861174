import LinearProgress from '@material-ui/core/LinearProgress';
import { ChangeEvent, FC, useRef, useState, useEffect } from 'react';
import { ImportExportResult } from '../../API/shared/interfaces';
import { CommunicationConfig } from '../../config/interfaces';
import { useCommunicationImport } from '../../hooks/useCommunicationImport';
import { ModulePermission, useProtectedModules } from '../../hooks/useProtectedModules';
import Badge from '../Badge';
import Button from '../Button';
import Col from '../Col';
import H2 from '../H2';
import Row from '../Row';

interface Props {
  title: string;
  config?: CommunicationConfig;
  importApi: (formData: FormData) => Promise<ImportExportResult[]>;
  requirePermission?: ModulePermission;
}

const CommunicationImport: FC<Props> = ({ title, config, importApi, requirePermission }) => {
  const inputFile = useRef<HTMLInputElement>(null);
  const [sendingFile, setSendingFile] = useState(false);
  const { loadPercentage, onUploadFile, handleSendToMiNT, errorMessages, sending } =
    useCommunicationImport({
      config,
      importApi,
    });
  const { showModule } = useProtectedModules();

  const handleChangeFile = (ev: ChangeEvent<HTMLInputElement>) => {
    const file = ev.target.files ? ev.target.files[0] : null;
    if (file) {
      onUploadFile(file);
      ev.target.value = '';
      setSendingFile(true);
    }
  };

  const showImportButton = (): boolean => {
    if (!config) return false;

    return (
      config.actions.includes('carga') ||
      config.actions.includes('carga-pre-envio') ||
      config.actions.includes('carga-envio')
    );
  };

  const showSendButton = (): boolean => {
    if (!config) return false;
    return config.actions.includes('envio') || config.actions.includes('pre-envio');
  };

  const getSendText = (): string => {
    if (!config) return '';
    if (sending) return 'Enviando...';
    if (config.actions.includes('pre-envio')) return 'PreEnviar a MiNT';
    if (config.actions.includes('envio')) return 'Enviar a MiNT';

    return '';
  };

  const getProgressBarText = (): string => {
    if (!config) return '';

    if (config.actions.includes('carga')) return 'Archivo Cargado';
    if (config.actions.includes('carga-pre-envio')) return 'Archivo Cargado y PreEnviado';
    if (config.actions.includes('carga-envio')) return 'Archivo Cargado y Enviado';

    return '';
  };

  useEffect(() => {
    if (loadPercentage === 100) {
      setSendingFile(false);
    }
  }, [loadPercentage]);

  if (requirePermission && !showModule(requirePermission)) return null;

  return (
    <>
      <H2>{title}</H2>
      <Row>
        <Col md={3}>
          <div className="import-btn__container">
            {showImportButton() && (
              <>
                <Button
                  onClick={() => {
                    if (inputFile.current) {
                      inputFile.current.click();
                    }
                  }}
                  color="primary"
                >
                  Cargar Archivo
                </Button>
                <input
                  ref={inputFile}
                  onChange={handleChangeFile}
                  type="file"
                  accept="text/csv,application/zip"
                />
              </>
            )}
            {showSendButton() && (
              <Button disabled={sending} onClick={() => handleSendToMiNT()} color="primary" outline>
                {getSendText()}
              </Button>
            )}
          </div>
        </Col>
        <Col md={9}>
          <LinearProgress variant="determinate" value={loadPercentage} />
          <div className="progress-bar">
            <ul style={{ marginTop: 10 }}>
              {sendingFile === true && errorMessages.length === 0 ? (
                <ul>
                  <li key="sending">
                    <Badge color="yellow" text="Enviando elementos..." />
                  </li>
                </ul>
              ) : (
                <ul>
                  {errorMessages.map((err, i) => (
                    <li key={i}>
                      <Badge color="danger" text={err} />
                    </li>
                  ))}
                </ul>
              )}
            </ul>
            <p>{getProgressBarText()}</p>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default CommunicationImport;
