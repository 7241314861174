import cn from "classnames";
import { FC, ReactNode } from "react";

interface Props {
  /**
   * Agregar clases de css propias
   */
  className?: string;

  /**
   * Contenido del modal
   */
  children: ReactNode;
}

const ModalBody: FC<Props & React.HTMLAttributes<HTMLDivElement>> = ({
  className,
  children,
  ...props
}) => {
  const classNames = cn("modal__body", className);

  return (
    <div {...props} className={classNames}>
      {children}
    </div>
  );
};

export default ModalBody;
