import { MainInfo } from '../interfaces';

export const requestTransformer = (request: { mainInfo: MainInfo }): FormData => {
  const form = new FormData();
  const requestItems: Record<string, any> = {
    ...request.mainInfo,
  };

  Object.entries(requestItems).forEach(([key, value]) => form.append(key, value));
  if (!requestItems.id) form.append('id', '-1');
  form.append('multi', '');
  form.append('multiItems', '');

  return form;
};
