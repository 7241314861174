import { ReactNode, useMemo, useContext, useEffect } from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';
import { useNavigate } from 'react-router-dom';
import { Actuaciones } from '../../../../API/limpieza';
import { Actuacion } from '../../../../API/limpieza/actuaciones/interfaces';
import Badge from '../../../../components/Badge';
import Checkbox from '../../../../components/Checkbox';
import MaterialIconMenu, { MaterialIconMenuProps } from '../../../../components/MaterialIconMenu';
import NoResults from '../../../../components/NoResults';
import LazarusPagination from '../../../../components/Pagination';
import Filters, {
  DynamicFormMetadata,
  FilterItem,
  ModuleNavigations,
} from '../../../../components/Table/Filters';
import { useDatatable } from '../../../../hooks/useDatatable';
import { Operator } from '../../../../hooks/useProtectedModules';
import { ActuacionesContext, RowsActuacionesContext } from '../../context';
//import { FiltersContext } from '../../../Flotas/context';

const addSelectedToRow = (row: Actuacion): void => {
  document.getElementById(`row-${row.id}`)?.classList.add('active');
};

const removeSelectedToRow = (row: Actuacion) => {
  document.getElementById(`row-${row.id}`)?.classList.remove('active');
};

const moduleNavigations: ModuleNavigations = {
  communications: `/limpieza/comunicaciones/actuaciones`,
  creates: '/limpieza/crear/actuaciones',
};

let goToMap = false;

const ActuacionesListPage = (): JSX.Element => {
  const {
    filteredItems,
    handleSetRows,
    handleChangeSelected,
    filterText,
    handleClear,
    handleChangeFilterText,
    selectedRows,
  } = useDatatable<Actuacion>({
    removeSelectedToRow,
    addSelectedToRow,
    context: RowsActuacionesContext,
  });

  const navigate = useNavigate();

  const { actuaciones, setActuaciones } = useContext(ActuacionesContext);

  const handleShowMap = (actuacion: Actuacion) => {
    goToMap = true;
    const rowsToProcess: Actuacion[] = Array.from(selectedRows); // Almacenar el valor de selectedRows

    if (selectedRows.length == 0) {
      console.log(rowsToProcess);
      // Selección 1 fila
      setActuaciones([actuacion]);
    } else {
      console.log(rowsToProcess);
      // Selección múltiple
      setActuaciones(rowsToProcess);
    }
  };

  const columns: TableColumn<Actuacion>[] = useMemo(
    () => [
      {
        name: 'Id de MiNT',
        selector: row => row.idActuacion || '',
      },
      {
        name: 'Barrio',
        selector: row => row.barrio,
      },
      {
        name: 'Distrito',
        selector: row => row.distrito,
      },
      {
        name: 'Jerarquía de clasificación',
        selector: row => row.jerarquiaClasificacion,
      },
      {
        name: 'Nombre vía',
        selector: row => row.nombreVia,
      },
      {
        name: 'Nro vía',
        selector: row => row.nroVia,
      },
      {
        name: 'Estado',
        cell: row => <Badge color={row.estado.color} text={row.estado.label} />,
      },
      {
        name: 'Coordenadas',
        selector: row => row.coordenadas,
      },
      {
        cell: row => {
          const paramsToMenu: MaterialIconMenuProps<Actuacion> = {
            row,
            size: 'medium',
            rows: selectedRows.length ? selectedRows : [row],
            onOpenMenu: () => addSelectedToRow(row),
            onCloseMenu: () => removeSelectedToRow(row),
            actions: [
              { label: 'Ver en mapa', onClick: row => handleShowMap(row) },
              {
                label: 'Editar',
                onClick: row => navigate(`/limpieza/editar/actuaciones/${row.id}`),
                onlyApplyToOne: true,
              },
              // { label: 'Enviar a MiNT', onClick: row => console.log('Enviar a MiNT', row) },
            ],
          };
          return <MaterialIconMenu {...paramsToMenu} />;
        },
        allowOverflow: true,
        button: true,
        width: '71px',
      },
    ],
    [selectedRows],
  );

  const SelectableCheckbox = Checkbox as unknown as ReactNode;

  const handleFilter = (filterItems: FilterItem[]) => {
    Actuaciones.get(filterItems).then(res => handleSetRows(res));
  };

  useEffect(() => {
    if (actuaciones.length > 0 && goToMap) {
      goToMap = false;
      document.getElementById('map-tab')?.click();
    }
  }, [actuaciones]);

  return (
    <>
      <Filters
        handleClear={handleClear}
        filterText={filterText}
        handleChangeFilterText={handleChangeFilterText}
        handleFilter={handleFilter}
        metadata={filterMetadata}
        hasItems={!!filteredItems.length}
        moduleNavigations={moduleNavigations}
        requirePermissions={requirePermissions}
        //context={FiltersContext}
      />
      <DataTable
        responsive
        columns={columns}
        data={filteredItems}
        pagination
        selectableRows
        selectableRowsHighlight
        paginationPerPage={10}
        noDataComponent={<NoResults />}
        paginationComponent={LazarusPagination}
        onSelectedRowsChange={handleChangeSelected}
        selectableRowsComponent={SelectableCheckbox}
      />
    </>
  );
};

export default ActuacionesListPage;

const requirePermissions = {
  lists: { section: 'limpieza', permission: 'list-actuaciones' },
  communications: {
    section: 'limpieza',
    permission: {
      permissions: ['import-actuaciones', 'export-actuaciones'],
      operator: '||' as Operator,
    },
  },
  creates: { section: 'limpieza', permission: 'create-actuaciones' },
};

const filterMetadata: DynamicFormMetadata[] = [
  {
    grid: { xs: 6, sm: 4, md: 2 },
    input: {
      tag: 'Input',
      propKey: 'idActuacion',
      props: {
        label: 'ID de la actuación',
        type: 'text',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 2 },
    input: {
      tag: 'Input',
      propKey: 'idActuacionExterno',
      props: {
        label: 'ID externo de la actuación',
        type: 'text',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 2 },
    input: {
      tag: 'Input',
      propKey: 'coordenadas',
      props: {
        label: 'Coordenadas',
        type: 'text',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 2 },
    input: {
      tag: 'Input',
      propKey: 'barrio',
      props: {
        label: 'Barrio',
        type: 'text',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 2 },
    input: {
      tag: 'Input',
      propKey: 'claseFuncionGIS',
      props: {
        label: 'Clase función GIS',
        type: 'text',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 2 },
    input: {
      tag: 'Input',
      propKey: 'distrito',
      props: {
        label: 'Distrito',
        type: 'number',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 2 },
    input: {
      tag: 'Input',
      propKey: 'jerarquiaClasificacion',
      props: {
        label: 'Jerarquía Clasificación',
        type: 'text',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 2 },
    input: {
      tag: 'Input',
      propKey: 'estado',
      props: {
        label: 'Estado',
        type: 'text',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 2 },
    input: {
      tag: 'Input',
      propKey: 'idInventario',
      props: {
        label: 'ID Inventario',
        type: 'text',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 2 },
    input: {
      tag: 'Input',
      propKey: 'descripcion',
      props: {
        label: 'Descripción',
        type: 'text',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 2 },
    input: {
      tag: 'Input',
      propKey: 'fechaInicioPlanificada',
      props: {
        label: 'Fecha de inicio planificada',
        type: 'date',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 2 },
    input: {
      tag: 'Input',
      propKey: 'fechaFinPlanificada',
      props: {
        label: 'Fecha de fin planificada',
        type: 'date',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 2 },
    input: {
      tag: 'Input',
      propKey: 'fechaInicioProgramada',
      props: {
        label: 'Fecha de inicio programada',
        type: 'date',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 2 },
    input: {
      tag: 'Input',
      propKey: 'fechaFinProgramada',
      props: {
        label: 'Fecha de fin programada',
        type: 'date',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 2 },
    input: {
      tag: 'Input',
      propKey: 'fechaInicioReal',
      props: {
        label: 'Fecha de inicio real',
        type: 'date',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 2 },
    input: {
      tag: 'Input',
      propKey: 'fechaFinReal',
      props: {
        label: 'Fecha de fin real',
        type: 'date',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 2 },
    input: {
      tag: 'Input',
      propKey: 'lote',
      props: {
        label: 'Lote',
        type: 'text',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 2 },
    input: {
      tag: 'Input',
      propKey: 'ndp',
      props: {
        label: 'NDP',
        type: 'number',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 2 },
    input: {
      tag: 'Input',
      propKey: 'numeroVia',
      props: {
        label: 'Número Vía',
        type: 'text',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 2 },
    input: {
      tag: 'Input',
      propKey: 'nombreVia',
      props: {
        label: 'Nombre Vía',
        type: 'text',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 2 },
    input: {
      tag: 'Input',
      propKey: 'tipoVia',
      props: {
        label: 'Tipo Vía',
        type: 'text',
      },
    },
  },
];
