import { AxiosError } from 'axios';
import { ReactNode, useEffect, useMemo, useState } from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';
import { Navigate } from 'react-router-dom';
import { GestionEnvios } from '../../API';
import {
  Carga,
  CargaEstado,
  CargaModulo,
  CargaOperacion,
  GetFilter,
} from '../../API/gestion-envios/interfaces';
import Checkbox from '../../components/Checkbox';
import Icon from '../../components/Icon';
import IconButton from '../../components/IconButton';
import NoResults from '../../components/NoResults';
import Page from '../../components/Page';
import LazarusPagination from '../../components/Pagination';
import Filters, {
  DynamicFormMetadata,
  FilterItem,
  ModuleNavigations,
} from '../../components/Table/Filters';
import { appConfig } from '../../config/app';
import { useAuth } from '../../hooks/useAuth';
import { useDatatable } from '../../hooks/useDatatable';
import { showErrorAlert, showSuccessAlert, showWarningAlert } from '../../libs/LazarusAlert';
import { setShowLogo } from '../../redux/HeaderSlice/headerSlice';
import { useAppDispatch } from '../../redux/hooks';
import { IconName } from '../../shared/types';
import { getCommonModule } from '../../shared/utils';
import CargaModal from './components/CargaModal';
import DescargaXMLModal from './components/DescargaXMLModal';
import MaterialIconMenu, { MaterialIconMenuProps } from '../../components/MaterialIconMenu';

const addSelectedToRow = (row: Carga): void => {
  document.getElementById(`row-${row.id}`)?.classList.add('active');
};

const removeSelectedToRow = (row: Carga) => {
  document.getElementById(`row-${row.id}`)?.classList.remove('active');
};

const GestionEnviosIndex = (): JSX.Element => {
  const per_page = 15;
  const [cargas, setCargas] = useState<Carga[]>([]);
  const [estados, setEstados] = useState<CargaEstado[]>([]);
  const [operaciones, setOperaciones] = useState<CargaOperacion[]>([]);
  const [filteredOperaciones, setFilteredOperaciones] = useState<CargaOperacion[]>([]);
  const [modulos, setModulos] = useState<CargaModulo[]>([]);
  const [, /* loading */ setLoading] = useState<boolean>(false);
  const [totalRows, setTotalRows] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const dispatch = useAppDispatch();
  const userCommonModule = getCommonModule('Gestión de Envíos');
  const { canUse } = useAuth();

  // Recuperar el estado de filtros y paginación desde el almacenamiento local
  const storedFilters = localStorage.getItem('gestionEnviosFilters');
  const initialFiltersState = storedFilters ? JSON.parse(storedFilters) : [];
  const [localFilterItems, setLocalFilterItems] = useState<FilterItem[]>(initialFiltersState);
  const [initialLoad, setInitialLoad] = useState(true);

  const {
    filteredItems,
    // handleSetRows,
    handleChangeSelected,
    filterText,
    handleClear,
    handleChangeFilterText,
    selectedRows,
  } = useDatatable<Carga>({
    removeSelectedToRow,
    addSelectedToRow,
    initialFilters: initialFiltersState,
  });
  const isArtemisa = appConfig.appTitle === 'Artemisa';

  const [selectedCarga, setSelectedCarga] = useState<Carga>();
  const [openEditModal, setOpenEditModal] = useState<boolean>(false);
  const [openDescargaXMLModal, setOpenDescargaXMLModal] = useState<boolean>(false);

  if (!userCommonModule || !userCommonModule.active) return <Navigate to="/" />;

  const SelectableCheckbox = Checkbox as unknown as ReactNode;

  const columns: TableColumn<Carga>[] = useMemo(
    () => [
      {
        name: 'Control',
        cell: row => {
          if (!canUse('edit-gestion-envios', 'app')) return null;

          let icon: IconName = 'check';
          if (row.estado === 'En pausa') {
            icon = 'play';
          } else if (row.estado === 'Pendiente') {
            icon = 'pause';
          } else if (row.estado === 'En ejecución') {
            icon = 'pause';
          }
          return (
            <Icon
              name={icon}
              style={{ cursor: 'pointer' }}
              onClick={() => {
                if (icon === 'pause') pauseCarga(row.id);
                else if (icon === 'play') restartCarga(row.id);
              }}
              color="primary"
            />
          );
        },
        width: '5.729%',
        style: {
          justifyContent: 'center',
        },
      },
      {
        name: 'Estado',
        selector: row => row.estado,
        width: '6.513%',
      },
      {
        name: 'Modulo',
        selector: row => row.modulo,
        width: '7.5%',
      },
      {
        name: 'Sección',
        selector: row => row.seccion.replace('Carga ', ''),
        width: '7.757%',
      },
      {
        name: 'Fecha',
        cell: row => <div style={{ whiteSpace: 'normal' }}>{row.fecha}</div>,
        width: '11.301%',
        style: {
          whiteSpace: 'normal',
        },
      },
      {
        name: 'Descripción',
        selector: row => row.descripcion,
        width: '11%',
      },
      {
        name: 'Total',
        selector: row => row.total,
        width: '7%',
        style: {
          justifyContent: 'center',
        },
      },
      {
        name: 'Finalizados',
        selector: row => row.finalizados,
        width: '7%',
        style: {
          justifyContent: 'center',
        },
      },
      {
        name: 'Errores',
        selector: row => row.errores,
        width: '7%',
        style: {
          justifyContent: 'center',
        },
      },
      {
        name: 'Resultados',
        cell: row =>
          row.archivoCarga == null && canUse('download-gestion-envios', 'app') ? (
            <Icon
              name="download"
              style={{ cursor: 'pointer' }}
              color="primary"
              onClick={() => getArchivoResultados(row.id)}
            />
          ) : null,
        width: '8.663%',
        style: {
          justifyContent: 'center',
        },
      },
      {
        name: 'Carga',
        cell: row =>
          row.archivoCarga != null && canUse('download-gestion-envios', 'app') ? (
            <Icon
              name="download"
              style={{ cursor: 'pointer' }}
              color="primary"
              onClick={() => getArchivoCarga(row.id)}
            />
          ) : null,
        width: '6.667%',
      },
      {
        name: 'Descarga',
        cell: row =>
          row.archivoDescarga != null && canUse('download-gestion-envios', 'app') ? (
            <Icon
              name="download"
              style={{ cursor: 'pointer' }}
              color="primary"
              onClick={() => getArchivoDescarga(row.id)}
            />
          ) : null,
        width: '6.618%',
      },
      {
        cell: row => {
          // if (!canUse('edit-gestion-envios')) return null;

          const paramsToMenu: MaterialIconMenuProps<Carga> = {
            row,
            size: 'medium',
            rows: selectedRows.length ? selectedRows : [row],
            onOpenMenu: () => addSelectedToRow(row),
            onCloseMenu: () => removeSelectedToRow(row),
            actions: [{ label: 'Editar', onClick: row => handleEditModal(row) }],
          };
          return <MaterialIconMenu {...paramsToMenu} />;
        },
        width: '2.618%',
      },
    ],
    [selectedRows],
  );

  const handleEditModal = (carga?: Carga): void => {
    setSelectedCarga(carga);
    setOpenEditModal(!openEditModal);
  };

  const handleDescargaFicherosXMLModal = (): void => {
    setOpenDescargaXMLModal(!openDescargaXMLModal);
  };

  const pauseCarga = (cargaId: number) => {
    GestionEnvios.pause(cargaId).then(() => getCargas(currentPage));
  };

  const restartCarga = (cargaId: number) => {
    GestionEnvios.restart(cargaId).then(() => getCargas(currentPage));
  };

  const getArchivoCarga = (cargaId: number) => {
    GestionEnvios.getArchivoCarga(cargaId).then(res => {
      if (res.success && res.file) {
        window.open(`${appConfig.apiConfig.baseURL}/${res.file.replace('../', '')}`, '_blank');
      } else {
        showErrorAlert({
          text: 'No se ha podido obtener el archivo de carga desde el servidor',
        });
      }
    });
  };

  const getArchivoDescarga = (cargaId: number) => {
    GestionEnvios.getArchivoDescarga(cargaId).then(res => {
      if (res.success && res.file) {
        window.open(`${appConfig.apiConfig.baseURL}/${res.file.replace('../', '')}`, '_blank');
      } else {
        showErrorAlert({
          text: 'No se ha podido obtener el archivo de descarga desde el servidor',
        });
      }
    });
  };

  const getArchivoResultados = (cargaId: number) => {
    GestionEnvios.getArchivoResultados(cargaId).then(res => {
      if (res.result) {
        const data = res.result;
        const fileName = `${res.filename}.csv`;
        const link = document.createElement('a');
        link.href = 'data:text/csv;charset=utf-8,' + data;
        link.download = fileName;
        link.click();
      } else {
        showErrorAlert({
          text: 'No se ha podido obtener el archivo de resultados desde el servidor',
        });
      }
    });
  };

  const getCargas = (page: number, filterItems?: FilterItem[]) => {
    setLoading(true);
    let filters: GetFilter = { per_page, page };

    if (filterItems) {
      filterItems.forEach(item => {
        filters = { ...filters, [item.key]: item.value };
      });
    }

    GestionEnvios.get(filters)
      .then(response => {
        setCargas(response.data);
        setCurrentPage(response.meta.current_page);
        setTotalRows(response.meta.total);
        setLoading(false);
      })
      .catch((err: AxiosError) => {
        setLoading(false);
        console.log(err);

        /*showErrorAlert({
          title: 'Error en el servidor',
          text: 'Ha ocurrido un error inesperado al traer información de las cargas',
        });*/

        if (err.response && err.response.status === 401) {
          window.location.href = '/login';
        }
      });
  };

  const handleFilter = (filterItems: FilterItem[]) => {
    localStorage.setItem('gestionEnviosFilters', JSON.stringify(filterItems));
    setLocalFilterItems(filterItems);
    getCargas(currentPage, filterItems);
  };

  const handlePageChange = (page: number) => {
    localStorage.setItem('gestionEnviosPage', page.toString());
    getCargas(page, localFilterItems);
  };

  const handleChangeModulo = (moduloId: string | number) => {
    setFilteredOperaciones(
      operaciones.filter(operacion => Number(operacion.idModulo) === Number(moduloId)),
    );
  };

  const handleDeleteCargas = () => {
    if (selectedRows.length === 0) {
      showErrorAlert({ text: 'Debe seleccionar al menos un elemento para eliminar' });
      return;
    }

    showWarningAlert({
      title: `Eliminar cargas seleccionadas (${selectedRows.length})`,
      text: 'Al continuar, se perderán los datos y no podrás recuperarlos',
    }).then(result => {
      if (result.isConfirmed) {
        const cargasId = selectedRows.map(selectedRow => selectedRow.id);
        GestionEnvios.deleteCargas(cargasId)
          .then(() => {
            showSuccessAlert({
              title: 'Elementos eliminados',
            });

            getCargas(1);
          })
          .catch(err => {
            console.log(err);
            showErrorAlert({ text: 'No se ha podido eliminar' });
          });
      }
    });
  };

  useEffect(() => {
    dispatch(setShowLogo({ showLogo: true }));
  }, []);

  useEffect(() => {
    const loadData = async () => {
      if (initialLoad) {
        setLocalFilterItems([]);
        setCurrentPage(1);
        setInitialLoad(false);
      }

      // Verifica si localFilterItems se ha vaciado antes de llamar a getCargas
      //if (localFilterItems.length === 0) {
      await getCargas(currentPage, localFilterItems);
      //}
    };

    // Llamamos a la función loadData directamente para la carga inicial
    loadData();

    // Establecemos un intervalo solo si no estamos en la carga inicial
    if (!initialLoad) {
      const intervalId = setInterval(() => {
        loadData();
      }, 10000);

      // Limpieza del intervalo cuando el componente se desmonta o el efecto cambia
      return () => clearInterval(intervalId);
    }
  }, [currentPage, localFilterItems, initialLoad]);

  useEffect(() => {
    GestionEnvios.getEstados().then(resEstados => {
      setEstados(resEstados);
    });

    GestionEnvios.getOperaciones().then(resOperaciones => {
      setOperaciones(resOperaciones);
    });

    GestionEnvios.getModulos().then(resModulos => {
      const hiddenModulos = isArtemisa
        ? ['Inspecciones', 'Mobiliario Urbano', 'GIAIM', 'Limpieza']
        : ['Inspecciones', 'Mobiliario Urbano', 'GIAIM'];
      setModulos(resModulos.filter(modulo => !hiddenModulos.includes(modulo.modulo)));
    });
  }, []);

  return (
    <Page
      title="Gestión de envíos"
      className="gestion-envios"
      icon="right-circle"
      titleType="title"
    >
      <div className="tab-panel">
        {!isArtemisa && (
          <IconButton
            className="cargas-filters"
            style={{ cursor: 'pointer', right: '155px', top: '-55px', position: 'absolute' }}
            color="primary"
            name="document"
            btnType="sm"
            onClick={handleDescargaFicherosXMLModal}
          ></IconButton>
        )}

        <Filters
          className="cargas-filters"
          handleClear={handleClear}
          filterText={filterText}
          handleChangeFilterText={handleChangeFilterText}
          handleFilter={handleFilter}
          metadata={filterMetadata(estados, filteredOperaciones, modulos)}
          hasItems={!!filteredItems.length}
          moduleNavigations={moduleNavigations}
          requirePermissions={requirePermissions}
          handleDelete={handleDeleteCargas}
          customHandleChange={{ field: 'modulo_id', method: handleChangeModulo }}
        />
      </div>
      <DataTable
        responsive
        columns={columns}
        data={cargas}
        progressPending={false}
        selectableRows
        selectableRowsHighlight
        onSelectedRowsChange={handleChangeSelected}
        selectableRowsComponent={SelectableCheckbox}
        pagination
        paginationServer
        paginationPerPage={per_page}
        noDataComponent={<NoResults />}
        paginationTotalRows={totalRows}
        paginationComponent={LazarusPagination}
        onChangePage={handlePageChange}
      />

      {openEditModal && selectedCarga && (
        <CargaModal
          title="Editar Carga"
          openModal={openEditModal}
          handleOpenModal={() => handleEditModal()}
          cargaData={selectedCarga}
          successCallback={getCargas.bind(null, currentPage)}
        />
      )}

      {openDescargaXMLModal && (
        <DescargaXMLModal
          title="Descargar ficheros XML"
          openModal={openDescargaXMLModal}
          handleOpenModal={() => handleDescargaFicherosXMLModal()}
        />
      )}
    </Page>
  );
};

export default GestionEnviosIndex;

const requirePermissions = {
  creates: { section: '', permission: '' },
  communications: { section: '', permission: '' },
  removes: { section: '', permission: '' },
};

const moduleNavigations: ModuleNavigations = {};

const filterMetadata = (
  estados: CargaEstado[],
  operaciones: CargaOperacion[],
  modulos: CargaModulo[],
): DynamicFormMetadata[] => {
  return [
    {
      grid: { xs: 6, sm: 4, md: 2 },
      input: {
        propKey: 'estado_id',
        props: {
          label: 'Estado',
          options: estados.map(estado => ({ text: estado.estado, value: estado.id })),
        },
        tag: 'Select',
      },
    },
    {
      grid: { xs: 6, sm: 4, md: 2 },
      input: {
        propKey: 'modulo_id',
        props: {
          label: 'Modulo',
          options: modulos.map(modulo => ({
            text: modulo.modulo,
            value: modulo.id,
          })),
        },
        tag: 'Select',
      },
    },
    {
      grid: { xs: 6, sm: 4, md: 2 },
      input: {
        propKey: 'operacion_id',
        props: {
          label: 'Operación',
          options: operaciones.map(operacion => ({
            text: operacion.operacion.replace('Carga ', ''),
            value: operacion.id,
          })),
        },
        tag: 'Select',
      },
    },
    {
      grid: { xs: 6, sm: 4, md: 3 },
      input: {
        tag: 'Input',
        propKey: 'fecha',
        props: {
          label: 'Fecha',
          type: 'date',
        },
      },
    },
  ];
};
