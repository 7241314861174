import { useNavigate } from 'react-router-dom';
import { Limpieza } from '../../../../API';
import CommunicationImport from '../../../../components/CommunicationImport';
import H1 from '../../../../components/H1';
import Page from '../../../../components/Page';
import { IconName } from '../../../../shared/types';
import { getCommunicationConfig } from '../../shared/utils';

const VehiculosCommunicationsPage = (): JSX.Element => {
  const navigate = useNavigate();

  const breadcrumbItems = [
    {
      icon: 'water' as IconName,
      label: 'Limpieza',
    },
    {
      label: 'Vehículos',
    },
  ];

  const btnBackConfig = {
    onClick: () => navigate(-1),
  };

  return (
    <Page
      title="Limpieza"
      icon="water"
      className="communications limpieza"
      titleType="breadcrumb"
      breadcrumb={breadcrumbItems}
      btnBackConfig={btnBackConfig}
    >
      <H1>Vehículos programados</H1>

      <CommunicationImport
        title="Cargar y Enviar"
        config={getCommunicationConfig('vehiculos-programados')}
        importApi={Limpieza.import}
        requirePermission={{ section: 'limpieza', permission: 'import-vehiculos' }}
      />

      <hr />

      <H1>Vehículos realizados</H1>

      <CommunicationImport
        title="Cargar y Enviar"
        config={getCommunicationConfig('vehiculos-realizados')}
        importApi={Limpieza.import}
        requirePermission={{ section: 'limpieza', permission: 'import-vehiculos' }}
      />
    </Page>
  );
};

export default VehiculosCommunicationsPage;
