import { Route } from '../../routes/routes';
import PlanificacionesCommunicationsPage from './Planificaciones/Communications';

export const communicationsRoutes: Route[] = [
  {
    name: 'Planificaciones',
    path: 'planificaciones',
    to: `planificaciones`,
    Component: PlanificacionesCommunicationsPage,
    requirePermission: 'view-flotas-planificaciones',
  },
];
