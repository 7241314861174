import { ZonasVerdes } from '../../../../API';
import CommunicationExport from '../../../../components/CommunicationExport';
import CommunicationImport from '../../../../components/CommunicationImport';
import Page from '../../../../components/Page';
import { getCommunicationConfig } from '../../shared/utils';
import { campos } from './campos';

const InspeccionesCommunicationsPage = (): JSX.Element => {
  return (
    <Page className="communications" titleType="title">
      <CommunicationImport
        title="Cargas"
        config={getCommunicationConfig('inspecciones')}
        importApi={ZonasVerdes.import}
        requirePermission={{ section: 'zzvv', permission: 'import-inspecciones' }}
      />

      <hr />

      <CommunicationImport
        title="Cargas de Fotos"
        config={getCommunicationConfig('inspecciones-fotos')}
        importApi={ZonasVerdes.import}
        requirePermission={{ section: 'zzvv', permission: 'import-fotos-inspecciones' }}
      />

      <hr />

      <CommunicationExport
        config={getCommunicationConfig('inspecciones')}
        exportApi={ZonasVerdes.export}
        title="Consultas"
        campos={campos}
        subModule="inspecciones"
        requirePermission={{ section: 'zzvv', permission: 'export-inspecciones' }}
      />
    </Page>
  );
};

export default InspeccionesCommunicationsPage;
