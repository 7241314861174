import { getKeyValue } from '../../../../shared/utils';
import { mapEstadoColor } from '../../../shared/utils';
import { GetLimpiezaInventarioResponse, Inventario } from '../interfaces';

export const responseTransformer = (response: string): Inventario[] => {
  const responseJSON: GetLimpiezaInventarioResponse = JSON.parse(response);

  return responseJSON.elementosInventario.map(
    ({ id, elemento_inventario }): Inventario => ({
      id,
      idInventario: elemento_inventario.idInventario,
      barrio: elemento_inventario.barrio,
      distrito: elemento_inventario.distrito,
      codigoPostal: elemento_inventario.codigoPostal,
      contrato: parseInt(elemento_inventario.contrato),
      contratoDescripcion: elemento_inventario.contratoDescripcion,
      ndp: parseInt(elemento_inventario.ndp),
      lote: elemento_inventario.lote,
      loteDescripcion: elemento_inventario.loteDescripcion,
      jerarquiaClasificacion: elemento_inventario.jerarquiaClasificacion,
      nombreVia: elemento_inventario.nombreVia,
      nroVia: elemento_inventario.numeroVia,
      tipoVia: elemento_inventario.tipoVia,
      estado: {
        color: getKeyValue(elemento_inventario.estado_general.nombre)(mapEstadoColor),
        label: elemento_inventario.estado_general.nombre,
      },
      claseFuncionGIS: elemento_inventario.claseFuncionGIS,
      descripcion: elemento_inventario.descripcion,
      coordenadas: elemento_inventario.coordenadas,
      coordenadaX: elemento_inventario.coordenadaX,
      coordenadaY: elemento_inventario.coordenadaY,
    }),
  );
};
