import { BoxCriterioItem } from '../../../components/BoxCriterio';
import { appConfig } from '../../../config/app';
import { ExportRequest, ExportRequestArtemisa } from '../../shared/interfaces';

export const requestTransformer = ({
  criterios,
  functionName,
  indiceResultado,
}: {
  criterios: BoxCriterioItem[];
  functionName: string;
  indiceResultado: string;
}): ExportRequest | ExportRequestArtemisa => {
  const campos = [];
  const operadores = [];
  const consultas = [];

  for (const criterio of criterios) {
    campos.push(criterio.campo);
    operadores.push(criterio.operador);
    consultas.push(criterio.criterio);
  }

  const isArtemisa = appConfig.appTitle === 'Artemisa';
  if (isArtemisa) {
    return {
      function: functionName,
      campo: campos.join(']['),
      operador: operadores.join(']['),
      consulta: consultas.join(']['),
      indiceResultado: indiceResultado,
    };
  }

  return {
    function: functionName,
    campos: campos.join(';'),
    operadores: operadores.join(';'),
    consultas: consultas.join(';'),
    indiceResultado: indiceResultado,
  };
};
