import { FC, useState } from 'react';
import { ImportExportResult } from '../../API/shared/interfaces';
import { CommunicationConfig, ZZVVSubModule } from '../../config/interfaces';
import { useCommunicationExport } from '../../hooks/useCommunicationExport';
import { ModulePermission, useProtectedModules } from '../../hooks/useProtectedModules';
import BoxCriterio, { BoxCriterioItem } from '../BoxCriterio';
import Button from '../Button';
import Col from '../Col';
import H2 from '../H2';
import Input from '../Input';
import Row from '../Row';
import { Option } from '../Select';
import { appConfig } from '../../config/app';

interface Props {
  title: string;
  campos: Option[];
  config?: CommunicationConfig;
  exportApi: (
    criterios: BoxCriterioItem[],
    functionName: string,
    indiceResultado: string,
  ) => Promise<ImportExportResult>;
  requirePermission?: ModulePermission;
  subModule?: ZZVVSubModule;
}

const CommunicationExport: FC<Props> = ({
  title,
  campos,
  config,
  exportApi,
  requirePermission,
  subModule,
}) => {
  if (!config || !config.exportFunctionName) return <></>;

  // Define el estado para indiceResultado y su función de actualización
  const [indiceResultado, setIndiceResultado] = useState<string>('0');

  const isArtemisa = appConfig.appTitle === 'Artemisa';

  const {
    loading,
    file,
    criterios,
    numeroResultados,
    resultadosTotales,
    cleanCriterios,
    onAddCriterio,
    onRemoveCriterio,
    onChangeCriterio,
    handleExport,
    onChangeIndiceResultado,
  } = useCommunicationExport({
    functionName: config.exportFunctionName,
    exportApi,
    subModule,
    indiceResultado: '0',
  });

  const { showModule } = useProtectedModules();

  if (requirePermission && !showModule(requirePermission)) return null;

  // Obtén el límite si existe en la configuración
  const limite = config.resultadosEsperados ?? '200';

  return (
    <>
      <H2>
        {title} - Límite de {limite} elementos
      </H2>

      <br></br>
      <Row className="communications__row">
        <Col md={2}>
          <Input
            type="text"
            label="Página"
            value={indiceResultado}
            onChange={e => {
              setIndiceResultado(e.target.value);
              onChangeIndiceResultado(e.target.value); // Llama a la función aquí para actualizar el valor
            }}
          />
        </Col>
      </Row>
      <Row className="communications__row">
        <Col md={7}>
          <BoxCriterio
            campos={campos}
            criterios={criterios}
            onAddCriterio={onAddCriterio}
            onRemoveCriterio={onRemoveCriterio}
            onChangeCriterio={onChangeCriterio}
          />
        </Col>

        <Col className="communications__col" md={3}>
          <div className="communications__btn-group">
            <Button color="disabled" block onClick={handleExport} disabled={loading}>
              Consultar
            </Button>
            <Button color="primary" outline block onClick={() => cleanCriterios()}>
              Limpiar consulta
            </Button>
          </div>
          <div className="communications__btn-group download-document">
            <p>{loading && <span>Realizando consulta...</span>}</p>
          </div>
          {file && (
            <div className="communications__btn-group download-document">
              <p>Documento</p>
              <Button
                color="primary"
                icon="download"
                className="btn-download"
                onClick={() => window.open(file, '_blank')}
              >
                Descargar
              </Button>
              {!isArtemisa && (
                <p>
                  <span>
                    Descargados {numeroResultados} de {resultadosTotales}
                  </span>
                </p>
              )}
            </div>
          )}
        </Col>
      </Row>
    </>
  );
};

export default CommunicationExport;
