import cn from 'classnames';
import { FC, ReactNode } from 'react';
import { Color, IconName, TitleType } from '../../shared/types';
import Breadcrumb, { BreadcrumbItem } from '../Breadcrumb';
import Button from '../Button';
import Icon from '../Icon';

interface BtnBackConfig {
  text?: string;
  icon?: IconName;
  color?: Color;
  onClick?: () => void;
}

export interface PageProps extends React.HTMLAttributes<HTMLDivElement> {
  /**
   * Tipo de título
   */
  titleType: TitleType;

  /**
   * Icono del título
   */
  icon?: IconName;

  /**
   * Config del boton "back"
   */
  btnBackConfig?: BtnBackConfig;

  /**
   * Breadcrumb items
   */
  breadcrumb?: BreadcrumbItem[];

  /**
   * Título de la página
   */
  title?: string;

  /**
   * Contenido de la página
   */
  children: ReactNode;

  /**
   * Agregar clases de css propias
   */
  className?: string;
}

const Page: FC<PageProps> = ({
  icon,
  titleType,
  btnBackConfig,
  breadcrumb,
  className,
  title,
  children,
  ...props
}) => {
  const classNames = cn('module-page', {}, className);

  return (
    <div className={classNames} {...props}>
      {titleType === 'title' && title && (
        <div className="module-page__title">
          {icon && <Icon name={icon} color="black" />}
          <p>{title}</p>
        </div>
      )}

      {titleType === 'breadcrumb' && breadcrumb && (
        <div className="module-page__title-container">
          <Breadcrumb items={breadcrumb} />
          {btnBackConfig && (
            <Button
              icon={btnBackConfig.icon || 'left'}
              color={btnBackConfig.color || 'primary'}
              onClick={btnBackConfig.onClick}
              outline>
              {btnBackConfig.text || 'Volver'}
            </Button>
          )}
        </div>
      )}

      {children}
    </div>
  );
};

export default Page;
