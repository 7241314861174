import { Route } from '../../routes/routes';
import UsersPage from './';

export const userRoutes: Route[] = [
  {
    to: '/usuarios',
    path: 'usuarios',
    Component: UsersPage,
    name: 'Usuarios',
  },
  {
    to: '/usuarios/:coreModule',
    path: 'usuarios/:coreModule',
    Component: UsersPage,
    name: 'Usuarios',
  },
];
