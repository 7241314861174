import cn from "classnames";
import { FC, ReactNode } from "react";

interface Props {
  /**
   * Agregar clases de css propias
   */
  className?: string;

  /**
   * Indicar si abrir el modal
   */
  open: boolean;

  /**
   * Manejador de estado del modal
   */
  handleOpen: (ev: React.MouseEvent | React.FormEvent) => void;

  /**
   * Contenido del modal
   */
  children: ReactNode;
}

const Modal: FC<Props & React.HTMLAttributes<HTMLDivElement>> = ({
  open,
  handleOpen,
  className,
  children,
  ...props
}) => {
  const classNames = cn("modal", { open }, className);

  return (
    <>
      <div className={classNames} {...props}>
        <div className="modal__dialog">{children}</div>
      </div>
      <div
        style={{ zIndex: 2 }}
        onClick={handleOpen}
        className={`dark-opacity ${open ? "show" : ""}`}
      ></div>
    </>
  );
};

export default Modal;
