import { FilterItem } from '../../../../components/Table/Filters';
import { GetLimpiezaPlanificacionRequest } from '../interfaces';

export const requestTransformer = (filterItems: FilterItem[]): GetLimpiezaPlanificacionRequest => {
  const request: GetLimpiezaPlanificacionRequest = { filtros: {} };
  for (const filter of filterItems) {
    request.filtros[filter.key] = filter.value;
  }

  return request;
};
