import axios from 'axios';
import { BoxCriterioItem } from '../../../components/BoxCriterio';
import { createApiInstance } from '../../api';
import { ImportExportResult } from '../../shared/interfaces';
import { requestTransformer } from './request-transformer';
import { responseTransformer } from './response-transformer';
import { ZZVVSubModule } from '../../../config/interfaces';

export const execute = async (
  criterios: BoxCriterioItem[],
  functionName: string,
  indiceResultado: string,
  subModule?: ZZVVSubModule,
): Promise<ImportExportResult> => {
  const api = createApiInstance();
  const { data } = await api.post<ImportExportResult>(
    '/zzvv/export',
    { criterios, functionName, indiceResultado, subModule },
    {
      transformRequest: [requestTransformer, ...(axios.defaults.transformRequest as any)],
      transformResponse: [responseTransformer],
    },
  );

  return data;
};
