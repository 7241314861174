import { FC, ReactNode } from 'react';
import styles from './Row.module.scss';
import cn from 'classnames';

type Spacing = 'xs' | 'sm' | 'md' | 'lg';
type JustifyContent = 'flex-start' | 'center' | 'flex-end' | 'space-between';
type AlignItems = 'flex-start' | 'center' | 'flex-end';

type JustifyContentResponsive = {
  [K in Spacing]: JustifyContent;
};

export interface LazarusRowProps extends React.HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
  spacing?: Spacing;
  justifyContent?: JustifyContent;
  justifyContentResponsive?: JustifyContentResponsive;
  alignItems?: AlignItems;
  className?: string;
}

const Row: FC<LazarusRowProps> = ({
  children,
  spacing,
  justifyContent,
  justifyContentResponsive,
  alignItems,
  className,
  ...props
}) => {
  let classNames = cn(
    styles.row,
    {
      [styles[`row_spacing_${spacing}`]]: spacing,
      [styles[`row_justifyContent_${justifyContent}`]]: justifyContent,
      [styles[`row_alignItems_${alignItems}`]]: alignItems,
    },
    className,
  );

  if (justifyContentResponsive) {
    const spacings = Object.keys(justifyContentResponsive) as Spacing[];
    const justifyContentStyles: Record<string, string> = {};
    spacings.forEach(spacingV => {
      justifyContentStyles[
        styles[`row_justifyContent_${justifyContentResponsive[spacingV]}_${spacingV}`]
      ] = justifyContentResponsive[spacingV];
    });

    classNames = cn(classNames, justifyContentStyles);
  }

  return (
    <div className={classNames} {...props}>
      {children}
    </div>
  );
};

export default Row;
