import { GetByIdResponse } from '.';
import { EspecificacionTecnica } from '../../../shared/actuaciones/interfaces';
import { Documento } from '../../../shared/interfaces';
import { ElementoActuacion, MainInfo } from '../interfaces';

export const responseTransformer = (response: string): GetByIdResponse | null => {
  const item: ElementoActuacion = JSON.parse(response);

  if (!item?.id) return null;

  return {
    mainInfo: {
      id: item.id,
      idMainInfo: item.actuacion.id,
      id_actuacion: item.id_actuacion,
      idActuacion: item.actuacion.idActuacion || '',
      idActuacionExterno: item.actuacion.idActuacionExterno,
      tipoActuacion: item.tipoActuacion,
      tipoActuacionEquivalente: item.actuacion.tipoActuacionEquivalente,
      numeroActo: item.actuacion.numeroActo,
      numeroAvisoOrigen: item.actuacion.numeroAvisoOrigen,
      requiereAprobacion: item.requiereAprobacion ? 'Sí' : 'No',
      barrio: item.actuacion?.barrio,
      barrioDescripcion: item.actuacion.barrioDescripcion,
      distrito: item.actuacion?.distrito,
      distritoDescripcion: item.actuacion.distritoDescripcion,
      jerarquiaClasificacion: item.actuacion?.jerarquiaClasificacion,
      jerarquiaClasificacionDescripcion: item.actuacion.jerarquiaClasificacionDescripcion,
      nombreVia: item.actuacion?.nombreVia,
      numeroVia: item.actuacion?.numeroVia,
      tipoVia: item.actuacion.tipoVia,
      observacionesActuacion: item.observacionesActuacion,
      observacionesLargasActuacion: item.observacionesLargasActuacion,
      ordenDirecta: item.actuacion.ordenDirecta ? 'Sí' : 'No',
      calculoIndicadorMotivo: item.actuacion.calculoIndicadorMotivo,
      calculoIndicador: item.calculoIndicador ? 'Sí' : 'No',
      caudal: item.caudal,
      claseFuncionGIS: item.actuacion.claseFuncionGIS,
      codigoPostal: item.actuacion.codigoPostal,
      concesionaria: item.actuacion.concesionaria,
      contrato: item.actuacion.contrato,
      contratoDescripcion: item.actuacion.contratoDescripcion,
      coordenadas: item.actuacion.coordenadas,
      coordenadaX: item.actuacion.coordenadaX,
      coordenadaY: item.actuacion.coordenadaY,
      coordenadaXGIS: item.actuacion.coordenadaXGIS,
      coordenadaYGIS: item.actuacion.coordenadaYGIS,
      costeRealHerramientas: item.costeRealHerramientas,
      costeRealMateriales: item.costeRealMateriales,
      fechaCambio: item.actuacion.fechaCambio?.substring(0, 10),
      fechaEstado: item.actuacion.fechaEstado?.substring(0, 10),
      fechaInicioProgramada: item.actuacion.fechaInicioProgramada?.substring(0, 10),
      fechaFinProgramada: item.actuacion.fechaFinPlanificada?.substring(0, 10),
      fechaInicioReal: item.actuacion.fechaInicioReal?.substring(0, 10),
      fechaFinReal: item.actuacion.fechaFinReal?.substring(0, 10),
      fechaInicioPlanificada: item.actuacion.fechaInicioPlanificada?.substring(0, 10),
      fechaFinPlanificada: item.actuacion.fechaFinPlanificada?.substring(0, 10),
      fechaInicioPlazo: item.fechaInicioPlazo?.substring(0, 10),
      fechaLimite: item.fechaLimite?.substring(0, 10),
      fechaReporte: item.actuacion.fechaReporte?.substring(0, 10),
      descripcion: item.actuacion.descripcion,
      descripcionLarga: item.actuacion.descripcionLarga,
      duracionEstimada: item.actuacion.duracionEstimada,
      lote: item.actuacion.lote,
      loteDescripcion: item.actuacion.loteDescripcion,
      localizacion: item.actuacion.localizacion,
      zonaDescripcion: item.actuacion.zonaDescripcion,
      zona: item.actuacion.zona,
      estado: item.actuacion.estado,
      estadoDescripcion: item.actuacion.estadoDescripcion,
      esPrioritaria: item.actuacion.esPrioritaria ? 'Sí' : 'No',
      tipoElemento: item.actuacion.tipoElemento,
      parque: item.actuacion.parque,
      parqueDescripcion: item.actuacion.parqueDescripcion,
      servicio: item.actuacion.servicio,
      servicioDescripcion: item.actuacion.servicioDescripcion,
      grupoParque: item.actuacion.grupoParque,
      grupoParqueDescripcion: item.actuacion.grupoParqueDescripcion,
      medidaEquivalente: item.actuacion.medidaEquivalente,
      medidaProgramada: item.actuacion.medidaProgramada,
      medidaReal: item.actuacion.medidaReal,
      medicion: item.actuacion.medicion ? 'Sí' : 'No',
      unidadMedida: item.actuacion.unidadMedida,
      unidadMedidaEquivalente: item.actuacion.unidadMedidaEquivalente,
      ndp: item.actuacion.ndp,
      pavimento: item.pavimento ? 'Sí' : 'No',
      prestacion: item.actuacion.prestacion,
      presion: item.presion,
      solucionActuaciones: item.solucionActuaciones,
      idInventario: item.actuacion.idInventario,
      usuarioEditor: item.actuacion.usuarioEditor,
      usuarioReporte: item.actuacion.usuarioReporte,
    } as MainInfo,
    documentos: item.actuacion.documentos.map(dc => ({
      documentoAdjunto: dc.documentoAdjunto || '',
      descripcionDoc: dc.descripcionDoc || '',
      idDocumento: dc.idDocumento,
      urlDocumento: dc.urlDocumento || '',
      tipoDocumento: dc.tipoDocumento || '',
      id: dc.id,
    })) as Documento[],
    especificacionesTecnicas: item.actuacion.especificaciones_tecnicas.map(
      et =>
        ({
          ea_cambiado: et.EA_Cambiado ? 'Sí' : 'No',
          ea_descripcion: et.EA_Descripcion,
          ea_id: et.EA_ID,
          ea_idLinea: et.EA_IDLinea,
          ea_seccion: et.EA_Seccion,
          ea_unidadMedida: et.EA_UnidadMedida,
          opcion: et.EA_ValorAlfanumerico
            ? 'EA_ValorAlfanumerico'
            : et.EA_ValorAlfanumerico
            ? 'EA_ValorNumerico'
            : 'EA_Tabla',
          ea_valor: et.EA_ValorAlfanumerico || et.EA_ValorNumerico || et.EA_ValorTabla,
          id: et.id,
          id_actuacion: et.id_actuacion,
        } as EspecificacionTecnica),
    ),
  };
};
