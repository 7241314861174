import { FC, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Riego } from '../../../../../../API';
import Button from '../../../../../../components/Button';
import Col from '../../../../../../components/Col';
import Row from '../../../../../../components/Row';
import { showErrorAlert, showSuccessAlert } from '../../../../../../libs/LazarusAlert';
import { getCommunicationConfig } from '../../../../shared/utils';
import { MaterialesContext } from '../../context';

const ActionButtons: FC = () => {
  const navigate = useNavigate();
  const { data } = useContext(MaterialesContext);
  const config = getCommunicationConfig('materiales');

  const handleCancel = () => {
    navigate(-1);
  };

  const handleSend = () => {
    if (!config || !config.sentFunctionName) return;

    const formData = new FormData();
    const idEnvio = Math.random().toString(36).substr(2, 9);

    formData.append('function', config.sentFunctionName);
    formData.append('id_envio', idEnvio);
    Object.entries(data).forEach(([key, value]) => {
      formData.append(key, value);
    });

    Riego.import(formData)
      .then(results => {
        showSuccessAlert({
          title: 'Proceso ejecutado correctamente',
          text: results[0].message || results[0].mensaje,
        }).then(() => {
          handleCancel();
        });
      })
      .catch(err => {
        console.log('err', err);
        showErrorAlert({ title: 'Error al enviar información' });
      });
  };

  return (
    <Row className="buttons-container" justifyContent="center">
      <Col xs={12}>
        <div className="buttons-container__actions">
          <Button onClick={handleCancel} color="primary" outline>
            Cancelar
          </Button>
          <Button onClick={handleSend} color="primary">
            Enviar
          </Button>
        </div>
      </Col>
    </Row>
  );
};

export default ActionButtons;
