import { getKeyValue } from '../../../../shared/utils';
import { mapEstadoColor } from '../../../shared/utils';
import { GetRiegoInventarioResponse, Inventario } from '../interfaces';

export const responseTransformer = (response: string): Inventario[] => {
  const responseJSON: GetRiegoInventarioResponse = JSON.parse(response);

  return responseJSON.elementosInventario.map(
    ({ elemento_inventario }): Inventario => ({
      id: elemento_inventario.id,
      barrio: elemento_inventario.barrio,
      distrito: elemento_inventario.distrito,
      jerarquiaClasificacion: elemento_inventario.jerarquiaClasificacion,
      nombreVia: elemento_inventario.nombreVia,
      nroVia: elemento_inventario.numeroVia,
      estado: {
        color: getKeyValue(elemento_inventario.estado_general.nombre)(mapEstadoColor),
        label: elemento_inventario.estado_general.nombre,
      },
    }),
  );
};
