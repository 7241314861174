import React, { Component } from 'react';
import Select from 'react-select';

interface Option {
  value: string;
  label: string;
}

interface SelectMultipleProps {
  options: Option[];
  label?: string;
  onChange?: (selectedOption: Option[]) => void;
}

interface SelectMultipleState {
  selectedOption: Option[] | null;
}

class SelectMultiple extends Component<SelectMultipleProps, SelectMultipleState> {
  state: SelectMultipleState = {
    selectedOption: null,
  };

  handleChange = (selectedOption: any) => {
    this.setState({ selectedOption });

    // Llama a la devolución de llamada onChange si está definida
    if (this.props.onChange) {
      this.props.onChange(selectedOption || []);
    }
  };

  render() {
    const { options, label } = this.props;

    return (
      <div style={{ display: 'flex', gap: '5px', flexDirection: 'column', width: '100%' }}>
        {label && <label style={{ color: '#7b7b7b', marginBottom: '5px' }}>{label}</label>}
        <Select
          isMulti
          value={this.state.selectedOption}
          onChange={this.handleChange}
          options={options}
        />
      </div>
    );
  }
}

export default SelectMultiple;
