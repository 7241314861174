import { FC, useContext } from 'react';
import { Permission, Section } from '../../../API/permissions/interfaces';
import Checkbox from '../../../components/Checkbox';
import { RoleContext } from '../context';

interface Props {
  section: Section;
  subsection: string;
  permissions: Permission[];
}

const PermissionLevel3: FC<Props> = ({ section, subsection, permissions }): JSX.Element => {
  const { handleCheck, role } = useContext(RoleContext);

  return (
    <div className="permissions__level3">
      {permissions.map(permission => (
        <Checkbox
          key={permission.id}
          defaultChecked={role.permissions.includes(permission.id)}
          checked={role.permissions.includes(permission.id)}
          onChecked={() => handleCheck('function', section, subsection, permission.id)}
          readOnly
          label={permission.name}
        />
      ))}
    </div>
  );
};

export default PermissionLevel3;
