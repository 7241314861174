import { ZonasVerdes } from '../../../../API';
import CommunicationExport from '../../../../components/CommunicationExport';
import CommunicationImport from '../../../../components/CommunicationImport';
import Page from '../../../../components/Page';
import { getCommunicationConfig } from '../../shared/utils';
import { campos } from './campos';

const EvaluacionesVisualesCommunicationsPage = (): JSX.Element => {
  return (
    <Page className="communications" titleType="title">
      <CommunicationImport
        title="Cargas"
        config={getCommunicationConfig('evaluaciones-visuales')}
        importApi={ZonasVerdes.import}
        requirePermission={{ section: 'zzvv', permission: 'import-evaluaciones-visuales' }}
      />

      <hr />

      <CommunicationImport
        title="Cargas de Fotos"
        config={getCommunicationConfig('evaluaciones-visuales-fotos')}
        importApi={ZonasVerdes.import}
        requirePermission={{ section: 'zzvv', permission: 'import-fotos-evaluaciones-visuales' }}
      />

      <hr />

      <CommunicationExport
        config={getCommunicationConfig('evaluaciones-visuales')}
        exportApi={ZonasVerdes.export}
        title="Consultas"
        campos={campos}
        subModule="evaluaciones-visuales"
        requirePermission={{ section: 'zzvv', permission: 'export-evaluaciones-visuales' }}
      />
    </Page>
  );
};

export default EvaluacionesVisualesCommunicationsPage;
