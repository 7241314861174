import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Permissions, Roles } from '../../../API';
import { GetPermissionResponse, Permission, Section } from '../../../API/permissions/interfaces';
import { getLimpiezaCoreModule } from '../../Limpieza/shared/utils';
import { getRiegoCoreModule } from '../../Riego/shared/utils';
import { getZZVVCoreModule } from '../../ZonasVerdes/shared/utils';
import { CheckType, RoleData } from '../context';
import { getFlotasCoreModule } from '../../Flotas/shared/utils';

interface PermissionHookData {
  role: RoleData;
  errors: { name: string };
  validate: () => boolean;
  allChecked: Section[];
  permissions?: GetPermissionResponse;
  activeSections: Section[];
  activeSubSections?: Record<string, string[]>;
  modules: any;
  permissionKeys: Section[];
  handleChange: (roleProperty: string, newValue: string) => void;
  handleCheckSection: (section: Section) => void;
  handleCheckAll: (section: Section) => void;
  handleCheckSubSection: (section: Section, subSectionName: string) => void;
  handleCheck: (
    checkType: CheckType,
    sectionName: Section,
    subsection?: string,
    permissionId?: number,
  ) => null | undefined;
}

export function usePermission(): PermissionHookData {
  const [role, setRole] = useState<RoleData>({ name: '', permissions: [] });
  const [allChecked, setAllChecked] = useState<Section[]>([]);
  const [errors, setErrors] = useState({ name: '' });
  const [permissions, setPermissions] = useState<GetPermissionResponse>();
  const [activeSections, setActiveSection] = useState<Section[]>([]);
  const [activeSubSections, setActiveSubSections] = useState<Record<string, string[]>>();
  const params = useParams<{ roleId?: string }>();

  const modules: any = {
    limpieza: getLimpiezaCoreModule(),
    riego: getRiegoCoreModule(),
    zzvv: getZZVVCoreModule(),
    flotas: getFlotasCoreModule(),
  };

  const permissionKeys: Section[] = permissions ? (Object.keys(permissions) as Section[]) : [];

  const handleChange = (roleProperty: string, newValue: string) => {
    setRole({ ...role, [roleProperty]: newValue });
  };

  const validate = (): boolean => {
    const errors: { name: string } = { name: '' };

    if (!role.name) {
      errors.name = 'El nombre es obligatorio';
      setErrors(errors);
      return false;
    }

    return true;
  };

  const uncheckSection = (section: Section) => {
    setActiveSection(activeSections.filter(s => s !== section));
    setActiveSubSections(prev => {
      const newSubSection = { ...prev };
      delete newSubSection[section];
      return newSubSection;
    });
    if (permissions) {
      const sectionPermissions = permissions[section];
      if (sectionPermissions) {
        let removePermissionsIds: number[] = [];
        Object.entries(sectionPermissions).forEach(
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          ([, permissions]) => {
            removePermissionsIds = removePermissionsIds.concat(permissions.map(p => p.id));
          },
        );
        const newPermissions = role.permissions.filter(p => !removePermissionsIds.includes(p));
        setRole({ ...role, permissions: newPermissions });
      }
    }
  };

  const handleCheckAll = (section: Section) => {
    if (activeSections.includes(section)) {
      setAllChecked(allChecked.filter(s => s !== section));
    } else {
      setAllChecked([...allChecked, section]);
    }

    if (activeSections.includes(section)) {
      uncheckSection(section);
    } else {
      setActiveSection([...activeSections, section]);

      if (permissions) {
        const sectionPermissions = permissions[section];
        if (sectionPermissions) {
          let permissionsIdsToAdd: number[] = [...role.permissions];
          const newActiveSubSections: Record<string, string[]> = activeSubSections
            ? { ...activeSubSections, [section]: [] }
            : { [section]: [] };

          Object.entries(sectionPermissions).forEach(([submodule, permissions]) => {
            if (!newActiveSubSections[section].includes(submodule)) {
              newActiveSubSections[section].push(submodule);
            }
            permissionsIdsToAdd = permissionsIdsToAdd.concat(permissions.map(p => p.id));
          });
          setRole({ ...role, permissions: permissionsIdsToAdd });
          setActiveSubSections(newActiveSubSections);
        }
      }
    }
  };

  const handleCheckSection = (section: Section) => {
    setAllChecked(allChecked.filter(s => s !== section));
    if (activeSections.includes(section)) {
      uncheckSection(section);
    } else {
      setActiveSection([...activeSections, section]);
      setActiveSubSections(prev =>
        prev ? { ...activeSubSections, [section]: [] } : { [section]: [] },
      );
    }
  };

  const handleCheckSubSection = (section: Section, subSectionName: string) => {
    if (!activeSubSections) return;
    setAllChecked(allChecked.filter(s => s !== section));
    if (activeSubSections[section].includes(subSectionName)) {
      setActiveSubSections(prev => {
        const newSubSection = { ...prev };
        if (permissions) {
          const sectionPermissions: any = permissions[section];
          const subSectionPermissions = sectionPermissions[subSectionName];
          const removePermissionsIds = subSectionPermissions.map((p: Permission) => p.id);
          const newPermissions = role.permissions.filter(p => !removePermissionsIds.includes(p));
          setRole({ ...role, permissions: newPermissions });
        }
        newSubSection[section] = newSubSection[section].filter(s => s !== subSectionName);
        return newSubSection;
      });
    } else {
      setActiveSubSections(prev => {
        const newSubSection = { ...prev };
        newSubSection[section] = [...newSubSection[section], subSectionName];
        return newSubSection;
      });
    }
  };

  const handleCheck = (
    checkType: CheckType,
    sectionName: Section,
    subsection?: string,
    permissionId?: number,
  ) => {
    if (!permissions) return null;
    const sectionPermissions = permissions[sectionName];

    if (!sectionPermissions) return null;

    setAllChecked(allChecked.filter(s => s !== sectionName));
    let permissionsToUpdate: number[] = [...role.permissions];
    switch (checkType) {
      case 'section':
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        Object.entries(sectionPermissions).forEach(([subsection, subsectionPermissions]) => {
          permissionsToUpdate = subsectionPermissions.map(permission => permission.id);
        });
        break;

      case 'subsection':
        if (!subsection) return null;
        Object.entries(sectionPermissions).forEach(([subsectionEntry, subsectionPermissions]) => {
          if (subsectionEntry === subsection) {
            permissionsToUpdate = subsectionPermissions.map(permission => permission.id);
          }
        });
        break;

      case 'function':
        if (!permissionId) return null;

        if (!permissionsToUpdate.includes(permissionId)) {
          permissionsToUpdate.push(permissionId);
        } else {
          permissionsToUpdate = permissionsToUpdate.filter(p => p !== permissionId);
        }
        break;

      default:
        return null;
    }

    setRole({
      ...role,
      permissions: permissionsToUpdate,
    });
  };

  useEffect(() => {
    Permissions.get().then(response => setPermissions(response));
  }, []);

  useEffect(() => {
    params.roleId && Roles.find(params.roleId).then(roleResponse => setRole(roleResponse));
  }, [params.roleId]);

  useEffect(() => {
    if (permissions && role.id) {
      const permissionsIds = role.permissions;
      const newActiveSections: Section[] = [];
      const newActiveSubSections: Record<string, string[]> = {};
      Object.entries(permissions).forEach(([section, sectionPermissions]) => {
        Object.entries(sectionPermissions).forEach(([subsection, subsectionPermissions]) => {
          (subsectionPermissions as Permission[]).forEach((permission: Permission) => {
            if (permissionsIds.includes(permission.id)) {
              if (!newActiveSections.includes(section as Section)) {
                newActiveSections.push(section as Section);
                newActiveSubSections[section] = [];
              }
              if (!newActiveSubSections[section].includes(subsection)) {
                newActiveSubSections[section].push(subsection);
              }
            }
          });
        });
      });

      setActiveSection(newActiveSections);
      setActiveSubSections(newActiveSubSections);
    }
  }, [role.id]);

  return {
    role,
    errors,
    allChecked,
    permissions,
    activeSections,
    activeSubSections,
    modules,
    permissionKeys,
    handleChange,
    handleCheckSection,
    handleCheck,
    handleCheckSubSection,
    handleCheckAll,
    validate,
  };
}
