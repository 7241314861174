import { createAjaxApiInstance } from '../../../api';
import { JerarquiaClasificacion } from '../../interfaces';

export const execute = async (
  controller: 'limpieza' | 'riego',
): Promise<JerarquiaClasificacion[]> => {
  const { data } = await createAjaxApiInstance().get<JerarquiaClasificacion[]>(
    `/getJerarquiasClasificacionInventario/${controller}`,
  );

  return data;
};
