import { Route } from "../../routes/routes";
import HomePage from "./";

export const homeRoutes: Route[] = [
  {
    to: "/",
    path: "",
    Component: HomePage,
    name: "Home",
  },
];
