import { ReactNode, useMemo } from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';
import { useNavigate } from 'react-router-dom';
import { Planificaciones } from '../../../../API/limpieza';
import { Planificacion } from '../../../../API/limpieza/planificaciones/interfaces';
import Checkbox from '../../../../components/Checkbox';
import MaterialIconMenu, { MaterialIconMenuProps } from '../../../../components/MaterialIconMenu';
import NoResults from '../../../../components/NoResults';
import LazarusPagination from '../../../../components/Pagination';
import Filters, {
  DynamicFormMetadata,
  FilterItem,
  ModuleNavigations,
} from '../../../../components/Table/Filters';
import { useDatatable } from '../../../../hooks/useDatatable';
import { Operator } from '../../../../hooks/useProtectedModules';

const addSelectedToRow = (row: Planificacion): void => {
  document.getElementById(`row-${row.id}`)?.classList.add('active');
};

const removeSelectedToRow = (row: Planificacion) => {
  document.getElementById(`row-${row.id}`)?.classList.remove('active');
};

const moduleNavigations: ModuleNavigations = {
  communications: `/limpieza/comunicaciones/planificaciones`,
  creates: `/limpieza/crear/planificaciones`,
};

const PlanificacionesListPage = (): JSX.Element => {
  const {
    filteredItems,
    handleSetRows,
    handleChangeSelected,
    filterText,
    handleClear,
    handleChangeFilterText,
    selectedRows,
  } = useDatatable<Planificacion>({
    removeSelectedToRow,
    addSelectedToRow,
  });

  const navigate = useNavigate();

  const columns: TableColumn<Planificacion>[] = useMemo(
    () => [
      {
        name: 'ID',
        selector: row => row.id,
      },
      {
        name: 'Sector',
        selector: row => row.sector,
      },
      {
        name: 'Barrio',
        selector: row => row.barrio,
      },
      {
        name: 'Distrito',
        selector: row => row.distrito,
      },
      /* {
        name: 'Jerarquía de clasificación',
        selector: row => row.jerarquiaClasificacion,
      }, */
      {
        name: 'Tipo Actuacion',
        selector: row => row.tipoActuacion,
      },
      {
        name: 'Fecha inicio',
        selector: row => row.fechaInicio,
      },
      {
        name: 'Fecha fin',
        selector: row => row.fechaFin,
      },
      {
        cell: row => {
          const paramsToMenu: MaterialIconMenuProps<Planificacion> = {
            row,
            size: 'medium',
            rows: selectedRows.length ? selectedRows : [row],
            onOpenMenu: () => addSelectedToRow(row),
            onCloseMenu: () => removeSelectedToRow(row),
            actions: [
              // { label: 'Ver en mapa', onClick: row => console.log('Ver en mapa', row) },
              {
                label: 'Editar',
                onClick: row => navigate(`/limpieza/editar/planificaciones/${row.id}`),
                onlyApplyToOne: true,
              },
              // { label: 'Enviar a MiNT', onClick: row => console.log('Enviar a MiNT', row) },
            ],
          };
          return <MaterialIconMenu {...paramsToMenu} />;
        },
        allowOverflow: true,
        button: true,
        width: '71px',
      },
    ],
    [selectedRows],
  );

  const SelectableCheckbox = Checkbox as unknown as ReactNode;

  const handleFilter = (filterItems: FilterItem[]) => {
    Planificaciones.get(filterItems).then(res => handleSetRows(res));
  };

  return (
    <>
      <Filters
        handleClear={handleClear}
        filterText={filterText}
        handleChangeFilterText={handleChangeFilterText}
        handleFilter={handleFilter}
        metadata={filterMetadata}
        hasItems={!!filteredItems.length}
        moduleNavigations={moduleNavigations}
        requirePermissions={requirePermissions}
      />
      <DataTable
        responsive
        columns={columns}
        data={filteredItems}
        pagination
        selectableRows
        selectableRowsHighlight
        paginationPerPage={10}
        noDataComponent={<NoResults />}
        paginationComponent={LazarusPagination}
        onSelectedRowsChange={handleChangeSelected}
        selectableRowsComponent={SelectableCheckbox}
      />
    </>
  );
};

export default PlanificacionesListPage;

const requirePermissions = {
  lists: { section: 'limpieza', permission: 'list-planificacion-sectores' },
  communications: {
    section: 'limpieza',
    permission: {
      permissions: ['import-planificacion-sectores', 'export-planificacion-sectores'],
      operator: '||' as Operator,
    },
  },
  creates: { section: 'limpieza', permission: 'create-actuaciones' },
};

const filterMetadata: DynamicFormMetadata[] = [
  {
    grid: {
      xs: 6,
      sm: 4,
      md: 2,
    },
    input: {
      propKey: 'sector',
      props: {
        label: 'Sector',
        type: 'text',
      },
      tag: 'Input',
    },
  },
  {
    grid: {
      xs: 6,
      sm: 4,
      md: 2,
    },
    input: {
      propKey: 'descripcion',
      props: {
        label: 'Descripción',
        type: 'text',
      },
      tag: 'Input',
    },
  },
  {
    grid: {
      xs: 6,
      sm: 4,
      md: 2,
    },
    input: {
      propKey: 'contrato',
      props: {
        label: 'Contrato',
        type: 'text',
      },
      tag: 'Input',
    },
  },
  {
    grid: {
      xs: 6,
      sm: 4,
      md: 2,
    },
    input: {
      propKey: 'a_demanda',
      props: {
        label: 'a Demanda',
        options: [
          {
            text: 'Sí',
            value: 'Sí',
          },
          {
            text: 'No',
            value: 'no',
          },
        ],
      },
      tag: 'Select',
    },
  },
  {
    grid: {
      xs: 6,
      sm: 4,
      md: 2,
    },
    input: {
      propKey: 'tipo_actuacion',
      props: {
        label: 'Tipo Actuación',
        type: 'text',
      },
      tag: 'Input',
    },
  },
  {
    grid: {
      xs: 6,
      sm: 4,
      md: 2,
    },
    input: {
      propKey: 'concesionaria',
      props: {
        label: 'Concesionaria',
        type: 'text',
      },
      tag: 'Input',
    },
  },
  {
    grid: {
      xs: 6,
      sm: 4,
      md: 2,
    },
    input: {
      propKey: 'fecha_de_inicio',
      props: {
        label: 'Fecha de inicio',
        type: 'date',
      },
      tag: 'Input',
    },
  },
  {
    grid: {
      xs: 6,
      sm: 4,
      md: 2,
    },
    input: {
      propKey: 'fecha_de_fin',
      props: {
        label: 'Fecha de fin',
        type: 'date',
      },
      tag: 'Input',
    },
  },
  {
    grid: {
      xs: 6,
      sm: 4,
      md: 2,
    },
    input: {
      propKey: 'lote',
      props: {
        label: 'Lote',
        type: 'text',
      },
      tag: 'Input',
    },
  },
  {
    grid: {
      xs: 6,
      sm: 4,
      md: 2,
    },
    input: {
      propKey: 'distrito',
      props: {
        label: 'Distrito',
        type: 'number',
      },
      tag: 'Input',
    },
  },
  {
    grid: {
      xs: 6,
      sm: 4,
      md: 2,
    },
    input: {
      propKey: 'tipo_eje',
      props: {
        label: 'Tipo Eje',
        type: 'text',
      },
      tag: 'Input',
    },
  },
  {
    grid: {
      xs: 6,
      sm: 4,
      md: 2,
    },
    input: {
      propKey: 'categoria_sector',
      props: {
        label: 'Categoria Sector',
        type: 'text',
      },
      tag: 'Input',
    },
  },
  {
    grid: {
      xs: 6,
      sm: 4,
      md: 2,
    },
    input: {
      propKey: 'prestacion',
      props: {
        label: 'Prestación',
        type: 'text',
      },
      tag: 'Input',
    },
  },
  {
    grid: {
      xs: 6,
      sm: 4,
      md: 2,
    },
    input: {
      propKey: 'unidad_medida_sector',
      props: {
        label: 'Unidad Medida Sector',
        type: 'text',
      },
      tag: 'Input',
    },
  },
  {
    grid: {
      xs: 6,
      sm: 4,
      md: 2,
    },
    input: {
      propKey: 'coordenada_sector',
      props: {
        label: 'Coordenada Sector',
        type: 'text',
      },
      tag: 'Input',
    },
  },
  {
    grid: {
      xs: 6,
      sm: 4,
      md: 2,
    },
    input: {
      propKey: 'filtro_motivo_revision',
      props: {
        label: 'Filtro Motivo Revisión',
        type: 'text',
      },
      tag: 'Input',
    },
  },
];
