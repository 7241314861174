import { createApiInstance } from '../../../api';
import {
  BocasRiego,
  CamposPrincipales,
  Codigos,
  Comentarios,
  Contrato,
  Edificio,
  Fuente,
  MainInfo,
  Otros,
  RiegoAutomatico,
  ServicioSuministrado,
  Sitios,
  Tarifa,
  UltimaLectura,
} from '../interfaces';
import { responseTransformer } from './response-transformer';

export interface GetByIdResponse {
  mainInfo: MainInfo;
  camposPrincipales: CamposPrincipales;
  bocasRiego: BocasRiego;
  codigos: Codigos;
  comentarios: Comentarios;
  contrato: Contrato;
  edificio: Edificio;
  fuente: Fuente;
  otros: Otros;
  tarifa: Tarifa;
  riegoAutomatico: RiegoAutomatico;
  sitios: Sitios;
  ultimaLectura: UltimaLectura;
  serviciosSuministrados: ServicioSuministrado[];
}

export const execute = async (id: number): Promise<GetByIdResponse | null> => {
  const api = createApiInstance();
  const { data } = await api.get<GetByIdResponse | null>(`/riego/puntos-de-suministro/${id}`, {
    transformResponse: [responseTransformer],
  });

  return data;
};
