import React, { useState, useEffect, useContext } from 'react';
import LeafletMap from './Components/LeafletMap';
import { getActuacionesUtils } from './utils';
import { ActuacionMapa } from './interface';
import { ActuacionesContext } from '../../context';

const ActuacionesMapPage = () => {
  const [elements, setElements] = useState<ActuacionMapa[]>([]);
  const { actuaciones } = useContext(ActuacionesContext);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (actuaciones.length > 0) {
          // Ejecutar las dos funciones asincrónicas en paralelo
          const [actuacionesRes] = await Promise.all([getActuacionesUtils(actuaciones)]);

          // Verificar si se recibieron las actuaciones
          if (actuacionesRes) {
            setElements(actuacionesRes);
          } else {
            setElements([]);
          }
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchData();
  }, [actuaciones]);

  return <div>{elements.length > 0 && <LeafletMap actuaciones={elements} />}</div>;
};

export default ActuacionesMapPage;
