import { lazy } from 'react';
import { Route, RouteModule } from '../../routes/routes';
import { communicationsRoutes } from './routes.communications';
import { listsRoutes } from './routes.lists';
import VehiculosListPage from './Vehiculos/List';

export const basePath = '/flotas';

const LazyListLayout = lazy(
  () => import(/* webpackChunkName: "FlotasLayout" */ './Layouts/ListLayout'),
);

const LazyCommunicationsLayout = lazy(
  () => import(/* webpackChunkName: "FlotasLayout" */ './Layouts/CommunicationsLayout'),
);

export const flotasRoutes: RouteModule = {
  lists: {
    path: `/flotas/listado/*`,
    to: `/flotas/listado/`,
    Component: LazyListLayout,
    name: 'Flotas - Listado Layout',
    submodules: listsRoutes,
  },
  communications: {
    path: `/flotas/comunicaciones/*`,
    to: `/flotas/comunicaciones/`,
    Component: LazyCommunicationsLayout,
    name: 'Flotas - Comunicaciones Layout',
    submodules: communicationsRoutes,
  },
};

export const basicRoutes: Route[] = [
  {
    to: '/flotas/listado/vehiculos',
    path: 'flotas/listado/vehiculos',
    Component: VehiculosListPage,
    name: 'Vehículos',
  },
];
