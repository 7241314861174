import Page from '../../../../components/Page';
import React, { useState, useEffect } from 'react';
import Col from '../../../../components/Col';
import Input from '../../../../components/Input';
import Row from '../../../../components/Row';
import Select from '../../../../components/Select';
import SelectMultiple from '../../../../components/SelectMultiple';

import Button from '../../../../components/Button';
import H2 from '../../../../components/H2';
import { Distritos, Informes, Tipos, Vehiculos } from '../../../../API/flotas';
import { InformeType } from '../../../../shared/types';
import { showErrorAlert, showSuccessAlert } from '../../../../libs/LazarusAlert';
import { appConfig } from '../../../../config/app';

import { FormInformeRutas, FormInformeVehiculos } from '../../../../API/flotas/informes/interfaces';

export interface Option {
  text: string;
  value: string | number;
}

export interface LazarusSelectProps extends React.SelectHTMLAttributes<HTMLSelectElement> {
  options: Option[];
  error?: string;
  label: string;
  id?: string;
  className?: string;
}

const InformesPage = () => {
  const [distritosOptions, setDistritosOptions] = useState<{ label: string; value: string }[]>([]);
  const [tiposOptions, setTiposOptions] = useState<{ text: string; value: string }[]>([]);
  const [vehiculosOptions, setVehiculosOptions] = useState<
    { label: string; value: string; tipo_id: string }[]
  >([]);
  const [filteredVehiculosOptions, setFilteredVehiculosOptions] = useState<
    { label: string; value: string; tipo_id: string }[]
  >([]);
  const [filteredVehiculosRutasOptions, setFilteredVehiculosRutasOptions] = useState<
    { label: string; value: string; tipo_id: string }[]
  >([]);
  const [isWaitingForResponse, setIsWaitingForResponse] = useState(false);
  const [isGeneratingInformeVehiculos, setIsGeneratingInformeVehiculos] = useState(false);
  const [isGeneratingInformeRutas, setIsGeneratingInformeRutas] = useState(false);
  const [mostrarFechaHoraEspecifica, setMostrarFechaHoraEspecifica] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      // Llamada a la función getDistritos para obtener los datos
      const responseDistritos = await getDistritos();
      setDistritosOptions(responseDistritos);

      // Llamada a la función getTipos para obtener los datos
      const responseTipos = await getTipos();
      setTiposOptions(responseTipos);

      // Vehiculos
      const responseVehiculos = await getVehiculos();
      setVehiculosOptions(responseVehiculos);

      // Inicializar filteredVehiculosOptions y filteredVehiculosRutasOptions con los mismos valores que vehiculosOptions
      setFilteredVehiculosOptions(responseVehiculos);
      setFilteredVehiculosRutasOptions(responseVehiculos);
    };

    fetchData();

    // Ocultar el tab de 'List'
    const isActiveTab = window.location.href.includes('/informes');
    const tabs = document.querySelectorAll('.list-map-tabs');

    if (isActiveTab) {
      tabs.forEach(tab => {
        if (tab instanceof HTMLElement) {
          tab.style.display = 'none';
        }
      });
    }

    // Lógica para obtener la fecha y hora actual en informe de Vehículos
    const currentDate = new Date();
    const currentDateString = currentDate.toISOString().split('T')[0]; // Obtenemos la fecha en formato YYYY-MM-DD
    const currentHour = currentDate.getHours();
    const currentMinute = currentDate.getMinutes();

    // Siempre haya dos dígitos para la hora y los minutos
    const formattedHour = currentHour < 10 ? `0${currentHour}` : currentHour;
    const formattedMinute = currentMinute < 10 ? `0${currentMinute}` : currentMinute;

    const currentTimeString = `${formattedHour}:${formattedMinute}`;

    // Actualizamos los valores por defecto en el estado del formulario
    setFormVehiculosData({
      ...formVehiculosData,
      fecha_desde: currentDateString,
      hora_desde: currentTimeString,
      fecha_hasta: currentDateString,
      hora_hasta: currentTimeString,
    });

    // Lógica para obtener la fecha y hora actual en informe de Rutas
    // Actualizamos los valores por defecto en el estado del formulario
    setFormRutasData({
      ...formVehiculosData,
      fecha_desde: currentDateString,
      hora_desde: '00:00',
      fecha_hasta: currentDateString,
      hora_hasta: currentTimeString,
    });

    return () => {
      // Se llama al salir de la página para mostrar de nuevo el tab 'Lista'
      tabs.forEach(tab => {
        if (tab instanceof HTMLElement) {
          tab.style.display = 'flex';
        }
      });
    };
  }, []);

  const getDistritos = async () => {
    const response = await Distritos.get();

    const rest = response.map((option: any) => ({
      label: option.nombre,
      value: option.codigo,
    }));
    console.log('response map' + rest);

    return rest;
  };

  const getTipos = async () => {
    const response = await Tipos.get();

    const rest = response.map((option: any) => ({
      text: option.nombre,
      value: option.id,
    }));
    console.log('response map' + rest);

    return rest;
  };

  const getVehiculos = async () => {
    const response = await Vehiculos.get();

    const rest = response.map((option: any) => ({
      label: option.matricula,
      value: option.matricula,
      tipo_id: option.tipo ? option.tipo.id : 'null',
    }));
    console.log('response map' + rest);

    return rest;
  };

  const reportOptions = [
    { value: 'excel' as InformeType, label: 'Excel' },
    { value: 'geojson' as InformeType, label: 'GEOJSON' },
    { value: 'pdf' as InformeType, label: 'PDF' },
  ].map(option => ({
    text: option.label,
    value: option.value,
  }));

  const classOptions = [
    {
      text: 'Vehículo',
      value: 'vehiculo',
    },
  ];

  const [formVehiculosData, setFormVehiculosData] = useState<FormInformeVehiculos>({
    fecha_desde: '',
    hora_desde: '',
    fecha_hasta: '',
    hora_hasta: '',
    distrito: '',
    tipo_vehiculo: '',
    clase: '',
    tipo_informe: 'excel',
    tipo_fecha: 'fecha',
    matricula: '',
  });

  const handleInformeVehiculosSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // Habilita el estado de espera
    setIsWaitingForResponse(true);
    setIsGeneratingInformeVehiculos(true);

    try {
      const response = await Informes.postInformeVehiculos(formVehiculosData);
      console.log('response postInformeVehiculos ' + response);

      window.open(`${appConfig.apiConfig.baseURL}/${response.url}`, '_blank');
      showSuccessAlert({ title: 'Descarga de ficheros finalizada' });
    } catch (error) {
      console.error('Error en la respuesta:', error);
      showErrorAlert({ title: 'Error al generar el informe' });
    }

    // Deshabilita el estado de espera
    setIsWaitingForResponse(false);
    setIsGeneratingInformeVehiculos(false);
  };

  const [formRutasData, setFormRutasData] = useState<FormInformeRutas>({
    fecha_desde: '',
    fecha_hasta: '',
    hora_desde: '',
    hora_hasta: '',
    distrito: '',
    tipo_vehiculo: '',
    clase: '',
    tipo_informe: 'excel',
    matricula: '',
  });

  const handleInformeRutasSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // Habilita el estado de espera
    setIsWaitingForResponse(true);
    setIsGeneratingInformeRutas(true);

    try {
      const response = await Informes.postInformeRutas(formRutasData);
      console.log('response postInformeVehiculos ' + response);

      window.open(`${appConfig.apiConfig.baseURL}/${response.url}`, '_blank');
      showSuccessAlert({ title: 'Descarga de ficheros finalizada' });
    } catch (error) {
      console.error('Error en la respuesta:', error);
      showErrorAlert({ title: 'Error al generar el informe' });
    }

    // Deshabilita el estado de espera
    setIsWaitingForResponse(false);
    setIsGeneratingInformeRutas(false);
  };

  interface Option {
    value: string;
    text: string;
  }

  // Mapear tiposOptions para convertirlos en objetos Option
  const tiposOpciones: Option[] = [
    { value: ' ', text: 'Seleccionar todos' },
    ...tiposOptions.map(option => ({
      value: option.value,
      text: option.text,
    })),
  ];

  const filtrarMatriculasPorTipo = (
    tipoVehiculoId: string,
    vehiculosOptions: { label: string; value: string; tipo_id: string }[],
  ) => {
    // Filtrar las opciones de matrículas que coincidan con el tipo de vehículo seleccionado
    if (tipoVehiculoId !== ' ') {
      return vehiculosOptions.filter(option => option.tipo_id == tipoVehiculoId);
    }
    return vehiculosOptions;
  };

  const handleTipoVehiculoChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const tipoSeleccionado = e.target.value;
    setFormVehiculosData({ ...formVehiculosData, tipo_vehiculo: tipoSeleccionado });

    // Filtrar las opciones de matrículas en función del tipo de vehículo seleccionado
    const matriculasFiltradas = filtrarMatriculasPorTipo(tipoSeleccionado, vehiculosOptions);
    setFilteredVehiculosOptions(matriculasFiltradas);
  };

  const handleTipoVehiculoRutasChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const tipoSeleccionado = e.target.value;
    setFormRutasData({ ...formRutasData, tipo_vehiculo: tipoSeleccionado });

    // Filtrar las opciones de matrículas en función del tipo de vehículo seleccionado
    const matriculasFiltradas = filtrarMatriculasPorTipo(tipoSeleccionado, vehiculosOptions);
    setFilteredVehiculosRutasOptions(matriculasFiltradas);
  };

  const handleSelectTipoFechaChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const opcionSeleccionada = e.target.value;
    setFormVehiculosData({ ...formVehiculosData, tipo_fecha: opcionSeleccionada });

    if (opcionSeleccionada === 'ultima_semana') {
      // Calcular fecha y hora de inicio de la última semana
      const fechaHasta = new Date();
      const fechaDesde = new Date(fechaHasta);
      fechaDesde.setDate(fechaDesde.getDate() - 7);

      setFormVehiculosData({
        ...formVehiculosData,
        fecha_desde: fechaDesde.toISOString().split('T')[0],
        hora_desde: '00:00',
        fecha_hasta: fechaHasta.toISOString().split('T')[0],
        hora_hasta: '23:59',
        tipo_fecha: opcionSeleccionada,
      });

      // Ocultar los campos de fecha y hora específicos
      setMostrarFechaHoraEspecifica(false);
    } else {
      // Si es fecha única, mandamos en desde lo mismo que se ha
      // introducido en el formulario
      setFormVehiculosData({
        ...formVehiculosData,
        fecha_desde: formVehiculosData.fecha_desde,
        hora_desde: formVehiculosData.hora_desde,
      });

      // Mostrar los campos de fecha y hora específicos
      setMostrarFechaHoraEspecifica(true);
    }
  };

  return (
    <Page className="communications flotas-informes" titleType="title">
      <H2>Crear informe de vehículos</H2>

      <form onSubmit={handleInformeVehiculosSubmit}>
        <Row style={{ marginTop: 25, width: '50%' }} className="select-actuacion">
          <Col xs={12} md={11} className="select-actuacion__col1">
            <Select
              label="Fecha de datos"
              options={[
                { value: 'fecha', text: 'Fecha específica' },
                { value: 'ultima_semana', text: 'Última semana' },
              ]}
              value={formVehiculosData.tipo_fecha}
              onChange={handleSelectTipoFechaChange}
            ></Select>
          </Col>

          <Col xs={12} md={1} className="select-actuacion__col1"></Col>

          {mostrarFechaHoraEspecifica && (
            <>
              <Col xs={12} md={5} className="select-actuacion__col1">
                <Input
                  type="date"
                  label="Fecha"
                  value={formVehiculosData.fecha_hasta}
                  onChange={e =>
                    setFormVehiculosData({ ...formVehiculosData, fecha_hasta: e.target.value })
                  }
                />
              </Col>

              <Col xs={12} md={1} className="select-actuacion__col1"></Col>

              <Col xs={12} md={5} className="select-actuacion__col1">
                <Input
                  type="time"
                  label="Hora"
                  value={formVehiculosData.hora_hasta}
                  onChange={e =>
                    setFormVehiculosData({ ...formVehiculosData, hora_hasta: e.target.value })
                  }
                />
              </Col>

              <Col xs={12} md={1} className="select-actuacion__col1"></Col>
            </>
          )}

          <Col xs={12} md={5} className="select-actuacion__col1">
            <Select
              label="Tipo de vehículo"
              options={tiposOpciones}
              value={formVehiculosData.tipo_vehiculo}
              onChange={handleTipoVehiculoChange}
            />
          </Col>

          <Col xs={12} md={1} className="select-actuacion__col1"></Col>

          <Col xs={12} md={5} className="select-actuacion__col1">
            <SelectMultiple
              label="Matrículas"
              options={filteredVehiculosOptions}
              onChange={selectedOption => {
                const selectedValue = selectedOption.map(option => option.value).join(', ');
                setFormVehiculosData({ ...formVehiculosData, matricula: selectedValue });
              }}
            />
          </Col>

          <Col xs={12} md={1} className="select-actuacion__col1"></Col>

          <Col xs={12} md={5} className="select-actuacion__col1">
            <SelectMultiple
              options={distritosOptions}
              label="Distrito"
              onChange={selectedOption => {
                const selectedValue = selectedOption.map(option => option.value).join(', ');
                setFormVehiculosData({ ...formVehiculosData, distrito: selectedValue });
              }}
            />
          </Col>

          <Col xs={12} md={1} className="select-actuacion__col1"></Col>

          <Col xs={12} md={5} className="select-actuacion__col1">
            <Select
              label="Clase"
              options={classOptions}
              value={classOptions[0].value}
              onChange={e => setFormVehiculosData({ ...formVehiculosData, clase: e.target.value })}
            />
          </Col>

          <Col xs={12} md={1} className="select-actuacion__col1"></Col>

          <Col xs={12} md={5} className="select-actuacion__col1">
            <Select
              label="Tipo de informe"
              options={reportOptions}
              value={formVehiculosData.tipo_informe}
              onChange={e => {
                const selectedValue = e.target.value as InformeType;
                setFormVehiculosData({ ...formVehiculosData, tipo_informe: selectedValue });
              }}
            />
          </Col>
        </Row>

        <div>
          <Button color="primary" type="submit" disabled={isWaitingForResponse}>
            Enviar
          </Button>
          <p hidden={!isGeneratingInformeVehiculos}>Generando informe...</p>
        </div>
      </form>

      <hr />

      <H2>Crear informe de rutas</H2>

      <form onSubmit={handleInformeRutasSubmit}>
        <Row style={{ marginTop: 25, width: '50%' }} className="select-actuacion">
          <Col xs={12} md={5} className="select-actuacion__col1">
            <Input
              type="date"
              label="Fecha desde"
              value={formRutasData.fecha_desde}
              onChange={e => setFormRutasData({ ...formRutasData, fecha_desde: e.target.value })}
            />
          </Col>

          <Col xs={12} md={1} className="select-actuacion__col1"></Col>

          <Col xs={12} md={5} className="select-actuacion__col1">
            <Input
              type="time"
              label="Hora desde"
              value={formRutasData.hora_desde}
              onChange={e => setFormRutasData({ ...formRutasData, hora_desde: e.target.value })}
            />
          </Col>

          <Col xs={12} md={1} className="select-actuacion__col1"></Col>

          <Col xs={12} md={5} className="select-actuacion__col1">
            <Input
              type="date"
              label="Fecha hasta"
              value={formRutasData.fecha_hasta}
              onChange={e => setFormRutasData({ ...formRutasData, fecha_hasta: e.target.value })}
            />
          </Col>

          <Col xs={12} md={1} className="select-actuacion__col1"></Col>

          <Col xs={12} md={5} className="select-actuacion__col1">
            <Input
              type="time"
              label="Hora hasta"
              value={formRutasData.hora_hasta}
              onChange={e => setFormRutasData({ ...formRutasData, hora_hasta: e.target.value })}
            />
          </Col>

          <Col xs={12} md={5} className="select-actuacion__col1">
            <Select
              label="Tipo de vehículo"
              options={tiposOpciones}
              value={formRutasData.tipo_vehiculo}
              onChange={handleTipoVehiculoRutasChange}
            />
          </Col>

          <Col xs={12} md={1} className="select-actuacion__col1"></Col>

          <Col xs={12} md={5} className="select-actuacion__col1">
            <SelectMultiple
              label="Matrículas"
              options={filteredVehiculosRutasOptions}
              onChange={selectedOption => {
                const selectedValue = selectedOption.map(option => option.value).join(', ');
                setFormRutasData({ ...formRutasData, matricula: selectedValue });
              }}
            />
          </Col>

          <Col xs={12} md={1} className="select-actuacion__col1"></Col>

          <Col xs={12} md={5} className="select-actuacion__col1">
            <SelectMultiple
              options={distritosOptions}
              label="Distrito"
              onChange={selectedOption => {
                const selectedValue = selectedOption.map(option => option.value).join(', ');
                setFormRutasData({ ...formRutasData, distrito: selectedValue });
              }}
            />
          </Col>

          <Col xs={12} md={1} className="select-actuacion__col1"></Col>

          <Col xs={12} md={5} className="select-actuacion__col1">
            <Select
              label="Clase"
              options={classOptions}
              value={classOptions[0].value}
              onChange={e => setFormRutasData({ ...formRutasData, clase: e.target.value })}
            />
          </Col>

          <Col xs={12} md={1} className="select-actuacion__col1"></Col>

          <Col xs={12} md={5} className="select-actuacion__col1">
            <Select
              label="Tipo de informe"
              options={reportOptions}
              value={formRutasData.tipo_informe}
              onChange={e => {
                const selectedValue = e.target.value as InformeType;
                setFormRutasData({ ...formRutasData, tipo_informe: selectedValue });
              }}
            />
          </Col>
        </Row>

        <div>
          <Button color="primary" type="submit" disabled={isWaitingForResponse}>
            Enviar
          </Button>
          <p hidden={!isGeneratingInformeRutas}>Generando informe...</p>
        </div>
      </form>
    </Page>
  );
};

export default InformesPage;
