import { FC } from 'react';
import Icon from '../Icon';
import Input from '../Input';
import OutlineButton from '../OutlineButton';
import Select, { Option } from '../Select';
import { operadores } from './operadores';

export interface BoxCriterioItem {
  campo: string;
  operador: string;
  criterio: string;
}

interface Props {
  criterios: BoxCriterioItem[];
  campos: Option[];
  onAddCriterio: () => void;
  onRemoveCriterio: (index: number) => void;
  onChangeCriterio: (index: number, newCriterio: BoxCriterioItem) => void;
}

const BoxCriterio: FC<Props> = ({
  campos,
  criterios,
  onAddCriterio,
  onRemoveCriterio,
  onChangeCriterio,
}) => {
  return (
    <div className="box-criterio">
      <div className="box-criterio__title">
        <p className="box-criterio__p">Criterios</p>
        <OutlineButton color="primary" icon="plus" onClick={onAddCriterio}>
          Agregar
        </OutlineButton>
      </div>
      {criterios.map((criterio, i) => (
        <div key={i} className="box-criterio__grid">
          <div className="box-criterio__item">
            <Select
              label="Campo"
              options={campos}
              value={criterio.campo}
              onChange={ev => onChangeCriterio(i, { ...criterio, campo: ev.target.value })}
            />
          </div>
          <div className="box-criterio__item">
            <Select
              label="Operador"
              options={operadores}
              value={criterio.operador}
              onChange={ev => onChangeCriterio(i, { ...criterio, operador: ev.target.value })}
            />
          </div>
          <div className="box-criterio__item">
            <Input
              type="text"
              label="Criterio"
              value={criterio.criterio}
              onChange={ev => onChangeCriterio(i, { ...criterio, criterio: ev.target.value })}
            />
          </div>
          <div className="box-criterio__item box-criterio__item-icon">
            <Icon onClick={() => onRemoveCriterio(i)} color="black" name="trash" />
          </div>
        </div>
      ))}
    </div>
  );
};

export default BoxCriterio;
