import { Suspense } from 'react';
import { Helmet } from 'react-helmet';
import { Provider } from 'react-redux';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import './assets/fonts/lazarus.svg';
import './assets/fonts/lazarus.ttf';
import './assets/fonts/lazarus.woff';
import './assets/scss/app.scss';
import BlockOpenMenu from './components/BlockOpenMenu';
import { appConfig } from './config/app';
import { persistor, store } from './redux/store';
import { routes } from './routes/routes';

const fallback = null; //TODO: crear un loading para el sitio ?

const App = (): JSX.Element => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Suspense fallback={fallback}>
          <BrowserRouter>
            <Routes>
              {routes.map(({ path, Component }) => (
                <Route key={path} path={path} element={<Component />} />
              ))}
              <Route path="/*" element={<Navigate to="/login" />} />
            </Routes>
          </BrowserRouter>
        </Suspense>
        <Helmet>
          <title>{appConfig.appTitle}</title>
        </Helmet>
        <BlockOpenMenu />
      </PersistGate>
    </Provider>
  );
};

export default App;
