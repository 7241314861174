import { Route } from '../../routes/routes';
import GestionEnvios from './';

export const gestionEnviosRoutes: Route[] = [
  {
    to: '/gestion-de-envios',
    path: 'gestion-de-envios',
    Component: GestionEnvios,
    name: 'Gestión de Envíos',
  },
];
