import { MainInfo, ElementoMaterial } from '../interfaces';

export const responseTransformer = (response: string): MainInfo | null => {
  const material: ElementoMaterial = JSON.parse(response);

  if (!material?.id) return null;

  return {
    id: material.id,
    tipo: material.tipo,
    descripcion: material.descripcion,
    idMaterialCodificado: material.material_codificado?.idMaterialCodificado,
    costeLineaMaterialCodificado: material.material_codificado?.costeLineaMaterialCodificado,
    costeUnitarioMaterialNoCodificado:
      material.material_no_codificado?.costeUnitarioMaterialNoCodificado,
    tipoLineaMaterialNoCodificado: material.material_no_codificado?.tipoLineaMaterialNoCodificado,
    idAtributoCodificadoLista: material.material_codificado?.id_material,
    idAtributoNoCodificadoLista: material.material_no_codificado?.id_material,
  };
};
