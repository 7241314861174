export const campos = [
  { value: 'idInventario', text: 'Id. Inventario' },
  { value: 'abioticoEvalArbolado', text: 'Abiotico' },
  { value: 'abultamientoRaicesEvalArbolado', text: ' Abultamiento de Raices' },
  { value: 'acarosEvalArbolado', text: 'Ácaros' },
  { value: 'accesoEdificioDianaEvalArbolado', text: 'Acceso edificio' },
  { value: 'actuacionPropuesta1EvalArbolado', text: 'Actuación propuesta' },
  { value: 'actuacionPropuesta2EvalArbolado', text: 'Actuación propuesta 2' },
  { value: 'actuacionPropuesta3EvalArbolado', text: 'Actuación propuesta 3' },
  { value: 'ahilamientoEvalArbolado', text: 'Ahilamiento' },
  { value: 'alteracionesTerrenoEvalArbolado', text: 'Alteraciones del terreno' },
  { value: 'anchuraHeridasCruzEvalArbolado', text: 'Anchura heridas cruz' },
  { value: 'anchuraHeridasTroncoEvalArbolado', text: 'Anchura heridas tronco' },
  { value: 'anchuraOquedadCruzEvalArbolado', text: 'Anchura oquedad cruz' },
  { value: 'anchuraOquedadRaicesEvalArbolado', text: 'Anchura oquedad raíces' },
  { value: 'anchuraOquedadRamaEvalArbolado', text: 'Anchura oquedad rama' },
  { value: 'anchuraOquedadTroncoEvalArbolado', text: 'Anchura oquedad tronco' },
  { value: 'anclajesSubterrSustEvalArbolado', text: 'Anclajes Subterráneos' },
  { value: 'anclajeRigidoSustEvalArbolado', text: 'Anclaje Rígido' },
  { value: 'antracnosisEvalArbolado', text: 'Antracnosis' },
  { value: 'aparcamientoDianaEvalArbolado', text: 'Aparcamiento' },
  { value: 'apoyosVerticalesSustEvalArbolado', text: ' Apoyos verticales' },
  { value: 'arquitecturaPobreRamaEvalArbolado', text: 'Arquitectura pobre rama' },
  { value: 'asfixiaRadicalEvalArbolado', text: 'Asfixia radical' },
  { value: 'bocaMetroDianaEvalArbolado', text: 'Boca de metro' },
  { value: 'brotesEpicormicosEvalArbolado', text: ' Brotes epicórmicos' },
  { value: 'cableadoDinamicoSustEvalArbolado', text: 'Cableado dinámico' },
  { value: 'cableadoEstaticoSustEvalArbolado', text: 'Cableado estático' },
  { value: 'caidaRamaEvalArbolado', text: 'Caída de ramas' },
  { value: 'cambiosRecientesEvalArbolado', text: 'Cambios recientes' },
  { value: 'cambioCotaCrzEvalArbolado', text: 'Cambio cota terreno' },
  { value: 'cambioDirecTroncoEvalArbolado', text: 'Cambio dirección tronco' },
  { value: 'cambioSistemaRiegoEvalArbolado', text: 'Cambio sistema de riego' },
  { value: 'cavidadesCruzEvalArbolado', text: 'Cavidades/Oquedades cruz' },
  { value: 'cavidadesRaicesEvalArbolado', text: 'Cavidades/Oquedades raices' },
  { value: 'cavidadesRamaEvalArbolado', text: 'Cavidades/Oquedades rama' },
  { value: 'cavidadesTroncoEvalArbolado', text: ' Cavidades/Oquedades tronco' },
  { value: 'centroPublicoDianaEvalArbolado', text: 'Centro público' },
  { value: 'chancrosCruzEvalArbolado', text: 'Chancros cruz' },
  { value: 'chancrosRamaEvalArbolado', text: 'Chancros rama' },
  { value: 'chancrosTroncoEvalArbolado', text: 'Chancros tronco' },
  { value: 'cirugiaPodaEvalArbolado', text: 'Cirugía poda' },
  { value: 'clasBiomecanicaEvalArbolado', text: 'Clasificación biomecánica' },
  { value: 'clorosisEnfEvalArbolado', text: 'Clorosis' },
  { value: 'codominanciasCruzEvalArbolado', text: 'Codominancias cruz' },
  { value: 'codominanciasRamaEvalArbolado', text: ' Codominancias rama' },
  { value: 'codominanciasTroncoEvalArbolado', text: 'Codominancias tronco' },
  { value: 'colegioDianaEvalArbolado', text: 'Colegio' },
  { value: 'compactacionCrzEvalArbolado', text: 'Compactación' },
  { value: 'consecuenciasFalloEvalArbolado', text: 'Consecuencias del fallo' },
  { value: 'contrafuertesRaicesEvalArbolado', text: 'Contrafuertes raíces' },
  { value: 'cortezaIncluidaEvalArbolado', text: 'Corteza incluida' },
  { value: 'cubiertaAlcorqueEvalArbolado', text: 'Tipo de cobertura' },
  { value: 'cubrimientoHeridasEvalArbolado', text: 'Cubrimiento de heridas' },
  { value: 'cuelloEnterradoRaicesEvalArbolado', text: 'Cuello enterrado' },
  { value: 'cuerposCruzEvalArbolado', text: 'Cuerpos fructíferos cruz' },
  { value: 'cuerposMuestraEvalArbolado', text: ' Cuerpos fructíferos muestra' },
  { value: 'cuerposRaicesEvalArbolado', text: 'Cuerpos fructíferos raices' },
  { value: 'cuerposRamaEvalArbolado', text: 'Cuerpos fructíferos rama' },
  { value: 'cuerposTroncoEvalArbolado', text: 'Cuerpos fructíferos tronco' },
  { value: 'danosMecanicosFEEvalArbolado', text: 'Daños mecánicos' },
  { value: 'danosRaicesEvalArbolado', text: 'Daños raíces' },
  { value: 'descalzadoRaicesEvalArbolado', text: 'Descalzado raíces' },
  { value: 'desequilibradoEvalArbolado', text: 'Desequilibrado' },
  { value: 'empujeGlobalEvalArbolado', text: 'Empuje global' },
  { value: 'engrosamientoCruzEvalArbolado', text: 'Engrosamiento anormal cruz' },
  { value: 'engrosamientoRaicesEvalArbolado', text: 'Engrosamiento anormal raíces' },
  { value: 'engrosamientoTroncoEvalArbolado', text: 'Engrosamiento anormal tronco' },
  { value: 'ensanchamBasalRaicesEvalArbolado', text: 'Ensanchamiento basal raices' },
  { value: 'escolitidoAFEvalArbolado', text: 'Escolitido' },
  { value: 'esperanzaSupervivenciaEvalArbolado', text: 'Esperanza de supervivencia' },
  { value: 'evolEsperadaEvalArbolado', text: 'Evolución esperada' },
  { value: 'exudacionesCruzEvalArbolado', text: 'Exudaciones cruz' },
  { value: 'exudacionesRamaEvalArbolado', text: 'Exudaciones rama' },
  { value: 'exudacionesTroncoEvalArbolado', text: 'Exudaciones tronco' },
  { value: 'factorCargaEvalArbolado', text: 'Factor carga' },
  { value: 'fechaEvalArbolado', text: 'Fecha de evaluación' },
  { value: 'fechaNuevaRevisionEvalArbolado', text: 'Fecha de nueva revisión' },
  { value: 'fechaUltimaActuPodaEvalArbolado', text: 'Fecha de última actuación' },
  { value: 'fisurasCruzEvalArbolado', text: 'Fisuras cruz' },
  { value: 'fisurasCuelloEvalArbolado', text: 'Fisuras en cuello' },
  { value: 'fisurasRamaEvalArbolado', text: 'Fisuras en rama' },
  { value: 'fisurasTroncoEvalArbolado', text: 'Fisuras en tronco' },
  { value: 'formacionPodaEvalArbolado', text: 'Formación poda' },
  { value: 'frecuenciaRevisionEvalArbolado', text: 'Frecuencia de revisión en años' },
  { value: 'galerucaDFEvalArbolado', text: 'Galeruca' },
  { value: 'grafiosisEnfEvalArbolado', text: 'Grafiosis' },
  { value: 'heridasCruzEvalArbolado', text: 'Heridas cruz' },
  { value: 'heridasRamaEvalArbolado', text: 'Heridas rama' },
  { value: 'heridasRaicesEvalArbolado', text: 'Heridas raíces' },
  { value: 'heridasTroncoEvalArbolado', text: 'Heridas tonco' },
  { value: 'hojasMuestraEvalArbolado', text: 'Hojas' },
  { value: 'hongosFoliaresDfEvalArbolado', text: 'Hongos foliares' },
  { value: 'hongosXilofagosDfEvalArbolado', text: 'Hongos xilófagos' },
  { value: 'inclinacionEvalArbolado', text: 'inclinacionEvalArbolado' },
  { value: 'longitudGrietaCruzEvalArbolado', text: 'Longitud grieta cruz' },
  { value: 'longitudGrietaRaicesEvalArbolado', text: 'Longitud grieta raíces' },
  { value: 'longitudGrietaRamaEvalArbolado', text: 'Longitud grieta rama' },
  { value: 'longitudGrietaTroncoEvalArbolado', text: 'Longitud grieta tronco' },
  { value: 'longitudHeridaCruzEvalArbolado', text: 'Longitud herida' },
  { value: 'longitudOquedadCramaEvalArbolado', text: 'Longitud oquedad rama' },
  { value: 'longitudOquedadCruzEvalArbolado', text: 'Longitud oquedad cruz' },
  { value: 'longitudOquedadRaicesEvalArbolado', text: 'Longitud oquedad raíces' },
  { value: 'longitudOquedadTroncoEvalArbolado', text: 'Longitud oquedad tronco' },
  { value: 'longitudHeridasTroncoEvalArbolado', text: 'Longitud heridas tronco' },
  { value: 'maderaMuestraEvalArbolado', text: 'Madera / ramas' },
  { value: 'maderaReacCruzEvalArbolado', text: 'Madera de reacción Cruz' },
  { value: 'maderaReacRamaEvalArbolado', text: 'Madera de reacción Ramas' },
  { value: 'maderaReacTroncoEvalArbolado', text: 'Madera de reacción Tronco' },
  { value: 'mantenimientoPodaEvalArbolado', text: 'Mantenimiento poda' },
  { value: 'marquesinaDianaEvalArbolado', text: 'Marquesina' },
  { value: 'mecanicoOAEvalArbolado', text: 'Mecánico' },
  { value: 'moteadoEnfEvalArbolado', text: 'Moteado' },
  { value: 'motivoEvalArbolado', text: 'Motivo' },
  { value: 'moverDianaEvalArbolado', text: 'Posibilidad de mover/ eliminar la diana' },
  { value: 'nivelExposicionVientoEvalArbolado', text: 'Nivel exposición viento' },
  { value: 'nivelInterferenciaEvalArbolado', text: 'Nivel de interferencia' },
  { value: 'nivelRiesgoEvalArbolado', text: 'Nivel de riesgo' },
  { value: 'numActuacionesPodaEvalArbolado', text: 'Número de actuaciones de poda.' },
  { value: 'observacionesEvalArbolado', text: 'Observaciones' },
  { value: 'oidioEnfEvalArbolado', text: 'Oidio' },
  { value: 'orugasDFEvalArbolado', text: 'Orugas' },
  { value: 'otrosCruzEvalArbolado', text: 'Otros cruz' },
  { value: 'otrosCrzEvalArbolado', text: 'Otros CRZ' },
  { value: 'otrosDFTroncoEvalArbolado', text: 'Otros daños fitopatológicos' },
  { value: 'otrosDianaEvalArbolado', text: 'Otros diana' },
  { value: 'otrosEnfEvalArbolado', text: 'Otros enfermedades' },
  { value: 'otrosFEEvalArbolado', text: 'Otros fallos estructurales' },
  { value: 'otrosMuestraEvalArbolado', text: 'Otros recogidas muestras' },
  { value: 'otrosOAEvalArbolado', text: 'Otros orígenes afecciones' },
  { value: 'otrosRaicesEvalArbolado', text: 'Otros raíces' },
  { value: 'otrosRamaEvalArbolado', text: 'Otros ramas' },
  { value: 'otrosSustEvalArbolado', text: 'Otros sustentación artificial' },
  { value: 'otrosTroncoEvalArbolado', text: 'Otros tronco' },
  { value: 'pavimentacionCruzEvalArbolado', text: 'Pavimentación' },
  { value: 'pendienteEvalArbolado', text: 'Pendiente (%)' },
  { value: 'peritajeCausasEvalArbolado', text: 'Peritaje' },
  { value: 'porcEstructVivasEvalArbolado', text: 'Porcentaje de estructuras vivas' },
  {
    value: 'porcPerimetroTroncoEvalArbolado',
    text: 'Porcentaje de perímetro tronco seco, hueco o muerto',
  },
  { value: 'porcSecoCopaEvalArbolado', text: 'Porcentaje seco de la copa' },
  { value: 'porcSecoRamasEvalArbolado', text: 'Porcentaje seco de las ramas' },
  { value: 'presenciaPalancasEvalArbolado', text: 'Presencia de palancas (ramas salientes)' },
  { value: 'principalEvalArbolado', text: 'Principal' },
  { value: 'prioridadEvalArbolado', text: 'Urgencia / Prioridad' },
  { value: 'probabilidadFalloEvalArbolado', text: 'Probabilidad fallo' },
  { value: 'probabilidadImpactoEvalArbolado', text: 'Probabilidad de impacto' },
  { value: 'procesionariaDFEvalArbolado', text: 'Procesionaria del pino' },
  { value: 'profundCavidadRaicesEvalArbolado', text: 'Profundidad cavidad/oquedad (cm)' },
  { value: 'profundCavidadTroncoEvalArbolado', text: 'Profundidad cavidad/oquedad (cm).' },
  { value: 'profundCavidadCruzEvalArbolado', text: 'Profundidad cavidad/oquedad (cm).' },
  { value: 'propuestaClausuraEvalArbolado', text: 'Propuesta clausura' },
  { value: 'pudricionCruzEvalArbolado', text: 'Pudrición cruz' },
  { value: 'pudricionRaicesEvalArbolado', text: 'Pudrición raíces' },
  { value: 'pudricionRamaEvalArbolado', text: 'Pudrición ramas' },
  { value: 'pudricionTroncoEvalArbolado', text: 'Pudrición tronco' },
  { value: 'pulgonDFEvalArbolado', text: 'Pulgón-cochinilla' },
  { value: 'quioscoDianaEvalArbolado', text: 'Quiosco / terraza' },
  { value: 'raicesEstrangulantesEvalArbolado', text: 'Raíces estrangulantes /espiralizadas' },
  { value: 'raicesMuestraEvalArbolado', text: 'Raíces Muestra' },
  { value: 'raicesSuperficialesEvalArbolado', text: 'Raíces superficiales' },
  { value: 'ramasPrincSecasEvalArbolado', text: 'Raíces principales secas' },
  { value: 'ratioOcupacionEvalArbolado', text: 'Ratio de ocupación' },
  { value: 'reduccionCopaPodaEvalArbolado', text: ' Reducción de copa' },
  { value: 'regresionCopaEvalArbolado', text: 'Regresión de copa' },
  {
    value: 'restringirAccesoDianaEvalArbolado',
    text: 'Posibilidad de restringir acceso a la zona de diana',
  },
  { value: 'resultpruebaInstrumentalEvalArbolado', text: 'Resultados pruebas instrumentales' },
  { value: 'roturaCimalesEvalArbolado', text: 'Rotura cimales' },
  { value: 'roturaRamasEvalArbolado', text: 'Rotura ramas' },
  { value: 'roturaTroncoEvalArbolado', text: 'Rotura tronco' },
  { value: 'socarrinaEnfEvalArbolado', text: 'Socarrina' },
  { value: 'sueloMuestraEvalArbolado', text: 'Suelo' },
  { value: 'superficieVelaEvalArbolado', text: 'Superficie de vela' },
  { value: 'tamanoHojaEvalArbolado', text: 'Tamaño/Deform. Hoja' },
  { value: 'tecnicoEvalArbolado', text: 'Técnico' },
  { value: 'terciadosEvalArbolado', text: 'Terciados' },
  { value: 'terciadosConsRamaEvalArbolado', text: 'Terciado No consolidado' },
  { value: 'terciadoPodaEvalArbolado', text: 'Terciado' },
  { value: 'termitaDFEvalArbolado', text: 'Termita' },
  { value: 'tigreDFEvalArbolado', text: 'Tigre' },
  { value: 'tipologAgrupacionEvalArbolado', text: 'Tipología agrupación' },
  { value: 'tipoAguaEvalArbolado', text: 'Tipo agua' },
  { value: 'tipoCuerpoCruzEvalArbolado', text: 'Tipo cuerpo fructífero cruz' },
  { value: 'tipoCuerpoRaicesEvalArbolado', text: 'Tipo cuerpo fructífero raíces' },
  { value: 'tipoCuerpoRamaEvalArbolado', text: 'Tipo cuerpo fructífero rama' },
  { value: 'tipoCuerpoTroncoEvalArbolado', text: 'Tipo cuerpo fructífero tronco' },
  { value: 'tipoInterferenciaEvalArbolado', text: 'Tipo interferencia crónica interferencia' },
  { value: 'tipoRiegoEvalArbolado', text: 'Tipo de riego' },
  { value: 'transPeatonalDianaEvalArbolado', text: 'Tránsito peatonal' },
  { value: 'valoracionVitalidadEvalArbolado', text: 'Valoración global vitalidad' },
  { value: 'valoracionGlobalRiesgoEvalArbolado', text: 'Valoración global del riesgo' },
  { value: 'vehiculosDianaEvalArbolado', text: 'Vehículos' },
  { value: 'vientosSustEvalArbolado', text: 'Vientos' },
  { value: 'volumenSueloLimEvalArbolado', text: 'Volumen suelo limitado (m3)' },
  { value: 'vuelcoEvalArbolado', text: 'Vuelco' },
  { value: 'zanjasCrzEvalArbolado', text: 'Zanjas' },
  { value: 'zonaDianaEvalArbolado', text: 'Zona de diana' },
  { value: 'zonaEstancialDianaEvalArbolado', text: 'Zona estancial' },
  { value: 'zonaInfantilDianaEvalArbolado', text: 'Zona infantil' },
  { value: 'instruRadarEvalArbolado', text: 'Tree radar' },
  { value: 'instruTestEvalArbolado', text: 'Test tracción' },
  { value: 'instruOtrosEvalArbolado', text: 'Otros' },
  { value: 'tomografoCuelloEvalArbolado', text: 'Cuello tomografo' },
  { value: 'tomografoTroncoEvalArbolado', text: 'Tronco Tomógrafo' },
  { value: 'tomografoCruzEvalArbolado', text: 'Cruz Tomógrafo' },
  { value: 'tomografoCimalesEvalArbolado', text: 'Cimales / Ramas Tomógrafo' },
  { value: 'resistografoCuelloEvalArbolado', text: 'Cuello Resistógrafo' },
  { value: 'resistografoTroncoEvalArbolado', text: 'Tronco Resistógrafo' },
  { value: 'resistografoCruzEvalArbolado', text: 'Cruz Resistógrafo' },
  { value: 'resistografoCimalesEvalArbolado', text: 'Cimales / Ramas Resistógrafo' },
  { value: 'idExternoEvalArbolado', text: 'id Externo Evaluación abolado' },
];
