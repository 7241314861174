import Popover from '@material-ui/core/Popover';
import { Dispatch, FC, SetStateAction, useContext, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Col, { LazarusColProps } from '../../../components/Col';
import IconButton from '../../../components/IconButton';
import InputSearch from '../../../components/InputSearch';
import Row from '../../../components/Row';
import { appConfig } from '../../../config/app.example';
import { RequireModuelPermissions, useProtectedModules } from '../../../hooks/useProtectedModules';
import Button from '../../Button';
import DynamicInput, { DynamicInputMetadata } from '../../DynamicInput';
import Icon from '../../Icon';

export type DynamicFormMetadata<T = any> = {
  input: DynamicInputMetadata<T>;
  grid: LazarusColProps;
};

export interface ModuleNavigations {
  communications?: string;
  creates?: string;
}

interface ContextBase {
  filterValue: FilterItem[];
  setFilterValue: Dispatch<SetStateAction<FilterItem[]>>;
}

interface FilterProps {
  className?: string;
  handleClear: () => void;
  filterText: string;
  handleChangeFilterText: (text: string) => void;
  moduleNavigations?: ModuleNavigations;
  handleFilter: (items: FilterItem[]) => void;
  metadata: DynamicFormMetadata[];
  hasItems: boolean;
  requirePermissions?: RequireModuelPermissions;
  handleDelete?: () => void;
  customHandleChange?: {
    field: string;
    method: (value: number | string) => void;
  };
  onExportTable?: () => void;
  context?: React.Context<ContextBase>;
}

export type FilterItem = { key: string; value?: string };

const Filters: FC<FilterProps> = ({
  className,
  handleClear,
  filterText,
  handleChangeFilterText,
  moduleNavigations,
  handleFilter,
  handleDelete,
  metadata,
  hasItems,
  requirePermissions,
  customHandleChange,
  onExportTable,
  context,
}) => {
  const anchorEl = useRef<HTMLButtonElement | null>(null);
  let [filterValue, setFilterValue] = useState<FilterItem[]>(
    metadata.map(i => ({ key: i.input.propKey, value: i.input.defaultValue })),
  );

  if (context) {
    const { filterValue: filterValueContext, setFilterValue: setFilterValueContext } =
      useContext(context);

    filterValue = filterValueContext;
    setFilterValue = setFilterValueContext;

    if (filterValue.length === 0) {
      setFilterValue(metadata.map(i => ({ key: i.input.propKey, value: i.input.defaultValue })));
    }
  }

  const navigation = useNavigate();
  const [open, setOpen] = useState(false);
  const { showModule } = useProtectedModules();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    anchorEl.current = event.currentTarget;
    setOpen(true);
  };
  const handleClose = () => {
    anchorEl.current = null;
    setOpen(false);
  };
  const handleCreateNavigate = () => {
    if (moduleNavigations && moduleNavigations.creates) {
      navigation(moduleNavigations.creates);
    }
  };
  const handleCommunicationsNavigate = () => {
    if (moduleNavigations && moduleNavigations.communications) {
      navigation(moduleNavigations.communications);
    }
  };

  const onFilter = () => {
    handleFilter(filterValue);
    handleClose();
  };

  const id = open ? 'simple-popover' : undefined;

  const onFilterValueChange = (changedValues: [key: string, value: any][]) => {
    const key = changedValues[0][0];
    const value = changedValues[0][1];
    setFilterValue(curr => curr.map(c => (c.key === key ? { ...c, value } : c)));

    if (customHandleChange && customHandleChange.field === key) {
      customHandleChange.method(value);
    }
  };

  const onClear = () => setFilterValue(curr => curr.map(c => ({ ...c, value: undefined })));

  return (
    <Row className={`filter-container ${className}`} justifyContent="flex-end">
      <Col className="user-filters__btn-new">
        <div className="filter-container__icon-buttons">
          {showModule(requirePermissions?.lists) && (
            <>
              {metadata.length > 0 && (
                <IconButton
                  aria-describedby={id}
                  onClick={handleClick}
                  btnType="sm"
                  name="controls"
                  color="primary"
                />
              )}

              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl.current}
                onClose={handleClose}
                className="filter-container__popover"
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
              >
                <Row justifyContent="space-between" alignItems="flex-start">
                  <Col xs={11}>
                    <h3> Filtros </h3>
                  </Col>
                  <Col xs={1} className="filter-container__popover-close">
                    <Icon onClick={handleClose} name="remove" color="black" />
                  </Col>
                </Row>
                <Row className="filter-container__filters">
                  {metadata.map((m, i) => (
                    <Col {...m.grid} key={i}>
                      <DynamicInput
                        {...m.input}
                        onChange={onFilterValueChange}
                        value={filterValue.find(fv => fv.key === m.input.propKey)?.value}
                      />
                    </Col>
                  ))}
                </Row>
                <Row justifyContent="center">
                  <Col md={7} style={{ textAlign: 'center' }}>
                    <Button
                      color="primary"
                      onClick={onClear}
                      outline
                      style={{ border: 'none', color: appConfig.primaryColor }}
                    >
                      Vaciar filtros
                    </Button>
                    <Button color="primary" onClick={onFilter} outline>
                      Buscar
                    </Button>
                  </Col>
                </Row>
              </Popover>
            </>
          )}
          {requirePermissions?.creates && showModule(requirePermissions?.creates) && (
            <IconButton onClick={handleCreateNavigate} btnType="sm" name="plus" color="primary" />
          )}
          {requirePermissions?.communications && showModule(requirePermissions?.communications) && (
            <IconButton
              onClick={handleCommunicationsNavigate}
              btnType="sm"
              name="document"
              color="primary"
            />
          )}
          {requirePermissions?.removes && handleDelete && (
            <IconButton onClick={handleDelete} btnType="sm" name="trash" color="primary" />
          )}

          {onExportTable && (
            <IconButton onClick={onExportTable} btnType="sm" name="download" color="primary" />
          )}
        </div>
      </Col>
      <Col className="filter-container__search-container">
        {(hasItems || filterText) && (
          <InputSearch
            onChange={e => handleChangeFilterText(e.target.value)}
            onClear={handleClear}
            value={filterText}
            placeholder="Buscar"
            type="text"
          />
        )}
      </Col>
    </Row>
  );
};

export default Filters;
