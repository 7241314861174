import { DateTime } from 'luxon';
import { ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';
import { Rutas, Vehiculos } from '../../../../API/flotas';
import { Ruta } from '../../../../API/flotas/rutas/interfaces';
import { Vehiculo } from '../../../../API/flotas/vehiculos/interfaces';
import Checkbox from '../../../../components/Checkbox';
import MaterialIconMenu, { MaterialIconMenuProps } from '../../../../components/MaterialIconMenu';
import NoResults from '../../../../components/NoResults';
import LazarusPagination from '../../../../components/Pagination';
import Filters, {
  DynamicFormMetadata,
  FilterItem,
  ModuleNavigations,
} from '../../../../components/Table/Filters';
import { useDatatable, ChangeSelected } from '../../../../hooks/useDatatable';
import { FiltersContext, RowsContext, RutasContext } from '../../context';
import { downloadCSV } from '../../../../shared/utils';
import { showErrorAlert } from '../../../../libs/LazarusAlert';

const rowsSelectionLimit = 3;

const addSelectedToRow = (row: Ruta): void => {
  document.getElementById(`row-${row.id}`)?.classList.add('active');
};

const removeSelectedToRow = (row: Ruta) => {
  document.getElementById(`row-${row.id}`)?.classList.remove('active');
};

export const moduleNavigations: ModuleNavigations = {};

const SelectableCheckbox = Checkbox as unknown as ReactNode;
let goToMap = false;

const RutasListPage = (): JSX.Element => {
  const {
    filteredItems,
    handleSetRows,
    handleChangeSelected,
    filterText,
    handleClear,
    handleChangeFilterText,
    selectedRows,
  } = useDatatable<Ruta>({
    removeSelectedToRow,
    addSelectedToRow,
    context: RowsContext,
  });
  const [vehiculos, setVehiculos] = useState<Vehiculo[]>([]);
  const { rutas, setRutas } = useContext(RutasContext);

  const handleShowMap = (ruta: Ruta) => {
    goToMap = true;
    const rowsToProcess: Ruta[] = Array.from(selectedRows); // Almacenar el valor de selectedRows

    if (selectedRows.length == 0) {
      // Selección 1 fila
      setRutas([ruta]);
    } else {
      // Selección múltiple
      setRutas(rowsToProcess);
    }
  };

  const handleChangeSelectedWithLimit = (selection: any) => {
    const { selectedCount, selectedRows } = selection;

    if (selectedRows.length > 0) {
      if (selectedCount <= rowsSelectionLimit) {
        const changeSelectedObject: ChangeSelected<Ruta> = {
          allSelected: false, // O true si todas las filas están seleccionadas
          selectedCount: selectedRows.length,
          selectedRows: selectedRows,
        };

        handleChangeSelected(changeSelectedObject);
      } else {
        showErrorAlert({
          text: 'No se pueden seleccionar más de ' + rowsSelectionLimit + ' filas',
        });
      }
    }
  };

  const columns: TableColumn<Ruta>[] = useMemo(
    () => [
      {
        name: 'Fecha',
        selector: row => row.formatted_fecha,
      },
      {
        name: 'Matricula',
        selector: row => row.matricula,
      },
      {
        name: 'Descripción',
        selector: row => row.cDescripcion,
      },
      {
        name: 'Propietario',
        selector: row => row.propietarioOrganizacion,
      },
      {
        name: 'Origen',
        selector: row => row.origen,
      },
      {
        name: 'Destino',
        selector: row => row.destino,
      },
      {
        name: 'Estado',
        selector: row => row.estado,
      },
      {
        cell: row => {
          const paramsToMenu: MaterialIconMenuProps<Ruta> = {
            row,
            size: 'medium',
            rows: selectedRows.length ? selectedRows : [row],
            onOpenMenu: () => addSelectedToRow(row),
            onCloseMenu: () => removeSelectedToRow(row),
            actions: [{ label: 'Ver en mapa', onClick: row => handleShowMap(row) }],
          };
          return <MaterialIconMenu {...paramsToMenu} />;
        },
        allowOverflow: true,
        button: true,
        width: '71px',
      },
    ],
    [selectedRows],
  );

  const getRutas = (filterItems?: FilterItem[]) => {
    Rutas.get(filterItems || []).then(res => handleSetRows(res));
  };

  const handleFilter = useCallback((filterItems: FilterItem[]) => {
    getRutas(filterItems);
  }, []);

  useEffect(() => {
    if (rutas.length > 0 && goToMap) {
      goToMap = false;
      document.getElementById('map-tab')?.click();
    }
  }, [rutas]);

  useEffect(() => {
    Vehiculos.get().then(res => setVehiculos(res));
  }, []);

  return (
    <>
      <Filters
        handleClear={handleClear}
        filterText={filterText}
        handleChangeFilterText={handleChangeFilterText}
        handleFilter={handleFilter}
        metadata={filterMetadata(vehiculos)}
        hasItems={!!filteredItems.length}
        moduleNavigations={moduleNavigations}
        requirePermissions={requirePermissions}
        context={FiltersContext}
        onExportTable={() => {
          const rowsToExport = selectedRows.length > 0 ? selectedRows : filteredItems;

          downloadCSV(
            rowsToExport,
            [
              { columnHeader: 'Fecha', columnSlug: 'formatted_fecha' },
              { columnHeader: 'Matricula', columnSlug: 'matricula' },
              { columnHeader: 'Descripción', columnSlug: 'cDescripcion' },
              { columnHeader: 'Propietario', columnSlug: 'propietarioOrganizacion' },
              { columnHeader: 'Origen', columnSlug: 'origen' },
              { columnHeader: 'Destino', columnSlug: 'destino' },
              { columnHeader: 'Estado', columnSlug: 'estado' },
              { columnHeader: 'Ruta', columnSlug: 'ruta' },
            ],
            'rutas.csv',
          );
        }}
      />
      <DataTable
        responsive
        columns={columns}
        data={filteredItems}
        pagination
        selectableRows
        selectableRowsHighlight
        paginationPerPage={10}
        noDataComponent={<NoResults />}
        paginationComponent={LazarusPagination}
        onSelectedRowsChange={handleChangeSelectedWithLimit}
        selectableRowsComponent={SelectableCheckbox}
      />
    </>
  );
};

export default RutasListPage;

export const requirePermissions = {
  lists: { section: 'flotas', permission: 'list-rutas' },
};

const todayDate = DateTime.now().toFormat('yyyy-MM-dd');

export const filterMetadata = (vehiculos: Vehiculo[]): DynamicFormMetadata[] => [
  {
    grid: { xs: 6, sm: 4, md: 2 },
    input: {
      propKey: 'matricula',
      props: {
        label: 'Matrícula',
        options: vehiculos
          .map(vehiculo => ({
            text: `${vehiculo.matricula} (${vehiculo.cDescripcion})`,
            value: vehiculo.matricula,
          }))
          .sort((a, b) => a.text.localeCompare(b.text)),
      },
      tag: 'Select',
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'desde',
      defaultValue: `${todayDate}`,
      props: {
        label: 'Fecha desde',
        type: 'date',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3 },
    input: {
      tag: 'Input',
      propKey: 'hasta',
      defaultValue: `${todayDate}`,
      props: {
        label: 'Fecha hasta',
        type: 'date',
      },
    },
  },
];
