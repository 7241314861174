import { FC, useContext, useEffect, useState } from 'react';
import { Materiales } from '../../../../../../API/riego';
import H2 from '../../../../../../components/H2';
import OutlineButton from '../../../../../../components/OutlineButton';
import { Option } from '../../../../../../components/Select/index';
import { MaterialesContext } from '../../context';
import MaterialCodificado, { MaterialCodificadoData } from '../MaterialCodificado';

const MaterialesCodificados: FC = () => {
  const [materiales, setMateriales] = useState<Option[]>([]);
  const [materialesCodificados, setMaterialesCodificados] = useState<MaterialCodificadoData[]>([
    {
      materialId: '',
      cantidad: '',
      tarifa: '',
    },
  ]);
  const { handleChange } = useContext(MaterialesContext);

  const handleChangeMaterial = (idx: number, material: MaterialCodificadoData) => {
    const newMateriales = [...materialesCodificados];
    newMateriales[idx] = material;
    setMaterialesCodificados(newMateriales);
    updateContext(newMateriales);
  };

  const updateContext = (newMateriales: MaterialCodificadoData[]) => {
    handleChange({
      name: 'idsElementosCodificados',
      value: newMateriales.map(m => m.materialId).join(','),
    });
    handleChange({
      name: 'cantidadesElementosCodificados',
      value: newMateriales.map(m => m.cantidad).join(','),
    });
    handleChange({
      name: 'tarifasElementosCodificados',
      value: newMateriales.map(m => m.tarifa).join(','),
    });
  };

  const handleRemoveMaterial = (idx: number) => {
    const newMateriales = materialesCodificados.filter((m, i) => i !== idx);
    if (newMateriales.length === 0)
      setMaterialesCodificados([
        {
          materialId: '',
          cantidad: '',
          tarifa: '',
        },
      ]);
    else setMaterialesCodificados(newMateriales);
    updateContext(newMateriales);
  };

  const addMaterial = () => {
    const isValidMateriales = materialesCodificados.every(
      m => m.materialId && m.cantidad && m.tarifa,
    );
    if (!isValidMateriales) return;

    setMaterialesCodificados([
      ...materialesCodificados,
      {
        materialId: '',
        cantidad: '',
        tarifa: '',
      },
    ]);
  };

  useEffect(() => {
    Materiales.getCodificados().then(materialesCodificados => {
      setMateriales(materialesCodificados.map(m => ({ text: m.nombre, value: m.id })));
    });
  }, []);

  return (
    <div className="materiales-codificados">
      <H2>
        Materiales codificados
        <OutlineButton color="primary" icon="plus" onClick={addMaterial}>
          Agregar
        </OutlineButton>
      </H2>

      {materialesCodificados.map((m, idx) => (
        <MaterialCodificado
          key={idx}
          idx={idx}
          materiales={materiales}
          material={m}
          handleChangeMaterial={handleChangeMaterial}
          handleRemoveMaterial={handleRemoveMaterial}
        />
      ))}
    </div>
  );
};

export default MaterialesCodificados;
