import { lazy } from 'react';
import { RouteModule } from '../../routes/routes';
import { communicationsRoutes } from './routes.communications';
import { listsRoutes } from './routes.lists';
import { createsRoutes } from './routes.creates';
import { editsRoutes } from './routes.edits';

export const basePath = '/limpieza';

const LazyListLayout = lazy(
  () => import(/* webpackChunkName: "LimpiezaLayout" */ './Layouts/ListLayout'),
);

const LazyCommunicationsLayout = lazy(
  () => import(/* webpackChunkName: "LimpiezaLayout" */ './Layouts/CommunicationsLayout'),
);

const LazyCreateLayout = lazy(
  () => import(/* webpackChunkName: "LimpiezaLayout" */ './Layouts/CreateLayout'),
);

export const limpiezaRoutes: RouteModule = {
  lists: {
    path: `/limpieza/listado/*`,
    to: `/limpieza/listado/`,
    Component: LazyListLayout,
    name: 'Limpieza - Listado Layout',
    submodules: listsRoutes,
  },
  communications: {
    path: `/limpieza/comunicaciones/*`,
    to: `/limpieza/comunicaciones/`,
    Component: LazyCommunicationsLayout,
    name: 'Limpieza - Comunicaciones Layout',
    submodules: communicationsRoutes,
  },
  creates: {
    path: `/limpieza/crear/*`,
    to: `/limpieza/crear/`,
    Component: LazyCreateLayout,
    name: 'Limpieza - Crear Layout',
    submodules: createsRoutes,
  },
  edits: {
    path: `/limpieza/editar/*`,
    to: `/limpieza/editar/`,
    Component: LazyCreateLayout,
    name: 'Limpieza - Editar Layout',
    submodules: editsRoutes,
  },
};
