/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { Section } from '../../API/permissions/interfaces';

export interface RoleData {
  id?: number;
  name: string;
  permissions: number[];
}

export type CheckType = 'section' | 'subsection' | 'function';

const initialState = {
  role: { name: '', permissions: [] },
  activeSections: [],
  allChecked: [],
  activeSubSections: undefined,
  handleChange: (roleProperty: string, newValue: any) => null,
  handleCheck: (
    checkType: CheckType,
    sectionName: Section,
    subsection?: string,
    permissionId?: number,
  ) => null,
  handleCheckSection: (sectionName: Section) => null,
  handleCheckAll: (sectionName: Section) => null,
  handleCheckSubSection: (sectionName: Section, subSectionName: string) => null,
  modules: {},
};

interface RoleContextHandler {
  role: RoleData;
  modules: any;
  allChecked: Section[];
  activeSections: Section[];
  activeSubSections?: Record<string, string[]>;
  handleChange: (roleProperty: string, newValue: any) => void;
  handleCheck: (
    checkType: CheckType,
    sectionName: Section,
    subsection?: string,
    permissionId?: number,
  ) => void;
  handleCheckSection: (sectionName: Section) => void;
  handleCheckAll: (sectionName: Section) => void;
  handleCheckSubSection: (sectionName: Section, subSectionName: string) => void;
}

export const RoleContext = React.createContext<RoleContextHandler>(initialState);
