import { useNavigate } from 'react-router-dom';
import H1 from '../../../../components/H1';
import H2 from '../../../../components/H2';
import Page, { PageProps } from '../../../../components/Page';
import { IconName } from '../../../../shared/types';
import { getRiegoCoreModule } from '../../shared/utils';
import ActionButtons from './components/ActionButtons';
import MaterialesCodificados from './components/MaterialesCodificados';
import MaterialesNoCodificados from './components/MaterialesNoCodificados';
import SelectActuacion from './components/SelectActuacion';
import { MaterialesContext } from './context';
import { useMateriales } from './hooks/useMateriales';

const MaterialesCommunicationsPage = (): JSX.Element => {
  const navigate = useNavigate();
  const riegoCoreModule = getRiegoCoreModule();
  if (!riegoCoreModule) return <></>;

  let pageProps: PageProps = {
    className: 'communications riego',
    titleType: 'title',
    children: undefined,
  };

  if (riegoCoreModule.submodulesGroups?.length > 1) {
    const breadcrumb = [
      {
        icon: 'water' as IconName,
        label: 'Riego',
      },
      {
        label: 'Materiales',
      },
    ];

    const btnBackConfig = {
      onClick: () => navigate('/riego/listado/materiales'),
    };

    pageProps = {
      ...pageProps,
      titleType: 'breadcrumb',
      breadcrumb,
      title: 'Riego',
      icon: 'water',
      btnBackConfig,
    };
  }

  const { data, handleChange, handleChangeActuacion } = useMateriales();

  return (
    <MaterialesContext.Provider value={{ data, handleChange, handleChangeActuacion }}>
      <Page {...pageProps}>
        {riegoCoreModule.submodulesGroups?.length > 1 && <H1>Comunicaciones</H1>}
        <H2>Enviar materiales de actuación a MiNT</H2>

        <SelectActuacion />

        <MaterialesCodificados />

        <hr />

        <MaterialesNoCodificados />

        <ActionButtons />
      </Page>
    </MaterialesContext.Provider>
  );
};

export default MaterialesCommunicationsPage;
