import { ChangeEvent } from 'react';
import { DynamicFormMetadata } from '../../components/DynamicForm';
import { ArrayToMappedIdObject, Documento, ImportExportResult } from './interfaces';

export const mapEstadoColor = {
  Nueva: 'yellow',
  Modificada: 'warning',
  Enviada: 'success-light',
  Rechazada: 'danger',
};

export const arrayToNumberedObject = <T extends ArrayToMappedIdObject>(
  arr: T[],
  resumeKey = '',
): Record<string, string> => ({
  ...arr.reduce(
    (acc, cur, idx) => ({
      ...acc,
      ...(Object.keys(cur) as (keyof T)[])
        .filter(k => k !== 'id')
        .reduce(
          (item, curKey) => ({
            ...item,
            [`${String(curKey)}${cur.id > 0 ? cur.id : -(idx + 1)}`]: cur[curKey],
          }),
          {},
        ),
    }),
    {},
  ),
  [resumeKey]: arr.map((item, idx) => `${item.id > 0 ? item.id : -(idx + 1)}`).join(','),
});

export const getImportResults = (data: string): ImportExportResult[] => {
  let value = '';
  const values = data.split(/\n/);
  const jsonRegExp = /({.*})[\r\n]/g;

  try {
    const results = [];
    for (let i = 0; i < values.length; i++) {
      value = values[i] + '\r\n';
      let matches: any[] | null = [];
      let match;

      while ((matches = jsonRegExp.exec(value)) != null) {
        match = matches[matches.length - 1];
        const mensaje: ImportExportResult = JSON.parse(match);
        results.push(mensaje);
      }
    }

    return results;
  } catch (err: any) {
    console.log('Error ' + err.message + ' parseando ' + value);
    return [];
  }
};

export const documentosMetadata: DynamicFormMetadata<Documento>[] = [
  {
    grid: { xs: 6, sm: 4, md: 2, lg: 3 },
    input: {
      tag: 'Input',
      propKey: 'idDocumento',
      props: {
        label: 'ID',
        type: 'text',
      },
    },
  },
  {
    grid: { xs: 6, sm: 4, md: 3, lg: 3 },
    input: {
      tag: 'Input',
      propKey: 'descripcionDoc',
      props: {
        label: 'Descripción',
        type: 'text',
      },
    },
  },
  {
    grid: { xs: 3, sm: 4, md: 2, lg: 3 },
    input: {
      tag: 'Input',
      propKey: 'tipoDocumento',
      props: {
        label: 'Tipo',
        type: 'text',
      },
    },
  },
  {
    grid: { xs: 9, sm: 12, md: 5, lg: 3 },
    input: {
      tag: 'Input',
      propKey: 'urlDocumento',
      inputTransformer: async (
        key: string,
        e: ChangeEvent<HTMLInputElement> & ChangeEvent<HTMLSelectElement>,
      ): Promise<[key: string, value: any][]> => {
        if (key !== 'urlDocumento') return [[key, e.target.value]];

        let documentoAdjunto = '';
        const file = e.target.files?.[0];

        if (!file) return [];

        const readFileAsDataURL = async (f: File) => {
          const result_base64 = await new Promise<string>(resolve => {
            const fileReader = new FileReader();
            fileReader.onload = () => resolve(fileReader.result as string);
            fileReader.readAsDataURL(f);
          });

          return result_base64;
        };

        documentoAdjunto = await readFileAsDataURL(file);

        return [
          ['urlDocumento', file.name],
          ['documentoAdjunto', documentoAdjunto],
        ];
      },
      props: {
        label: 'Url',
        type: 'file',
      },
    },
  },
];
