import { FilterItem } from '../../../../components/Table/Filters';

export const requestTransformer = (filterItems: FilterItem[]): Record<string, string> => {
  const request: Record<string, string> = {};

  for (const filter of filterItems) {
    if (filter.key && filter.value) {
      request[filter.key] = filter.value;
    }
  }
  return request;
};
